import React, {useEffect, useState} from "react";
import {useIsMountedRef} from "../../../../Helper/isMounted";
import {FormSubmitButton} from "../../../Helpers/FormSubmitButton";
import {FormCloseButton} from "../../../Helpers/FormCloseButton";
import {ElementInfo} from "../../../Helpers/ElementInfo";
import {rootSetError} from "../../../../Helper/RootVar/Error/Error";
import {apiEditTableSettings} from "../../../../Api/useTable";
import {onChangeFormDataGlobal} from "../../../../Helper/Functions";
import {useNavigate, useParams} from "react-router-dom";
import {rootBuilderLink, rootPAGE_ID_MAX_LENGTH, rootTABLE_NAME_MAX_LENGTH, rootTITLE_NAME_MAX_LENGTH} from "../../../../Helper/RootVar/Contstant";
import {AdvancedSettings} from "../../../Helpers/AdvancedSettings";

/*ADD new elements to a FORM*/
export const Settings = (props) => {
    const {url_menu_id = false, url_page_id = false, url_table_id = false} = useParams();
    const tableLinkLoad = `${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}/t/${url_table_id}`;
    let navigate = useNavigate();
    /*set props*/
    const form_idu = props.form_idu;
    const elements = props.elements.settings;

    const title = props.title;

    const isMountedRef = useIsMountedRef();
    const [infoClick, setInfoClick] = useState({page_id: false, table_id: false});

    /*GET DATA for form from API server*/
    useEffect(() => {
        LoadFormData();
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSlideIn(true);
        }, 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        LoadFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements])


    const LoadFormData = () => {
        let newProperty = {...formData}

        newProperty.edit_page_title = elements.title;
        newProperty.edit_page_description = elements.info;
        newProperty.edit_table_name = elements.table;
        newProperty.edit_page_id = elements.page_id;
        // newProperty.breadcrumb_idu = elements.breadcrumb_idu;
        newProperty.file_upload_idu = elements.file_upload_idu;

        newProperty.multi_delete = elements.multi_delete;
        // newProperty.dynamic_fields = elements.dynamic_fields;
        newProperty.edit_table_breadcrumb_title = elements.table_breadcrumb_title;
        newProperty.auditable_logs = elements.auditable_logs;
        newProperty.use_ajax = elements.use_ajax;
        newProperty.table_width = elements.table_width;
        newProperty.show_width = elements.show_width;
        newProperty.soft_delete = elements.soft_delete;
        newProperty.multi_tenancy = elements.multi_tenancy;
        newProperty.multi_tenancy_team = elements.multi_tenancy_team;
        newProperty.hide_from_import = elements.hide_from_import;
        newProperty.extend_model = elements.extend_model;
        newProperty.extend_controller = elements.extend_controller;
        newProperty.extend_request = elements.extend_request;
        newProperty.disable_create = elements.disable_create;
        newProperty.disable_edit = elements.disable_edit;
        newProperty.disable_delete = elements.disable_delete;
        newProperty.disable_show = elements.disable_show;

        newProperty.breadcrumb_idu = elements.breadcrumb_idu;
        // newProperty.single_row = elements.single_row;

        setFormData(newProperty);

    }

    const [slideIn, setSlideIn] = useState(false);

    const closeForm = () => {
        hideBack();
    };

    const hideBack = () => {
        setSlideIn(false);
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            navigate(`${tableLinkLoad}`);
        }, 200);
    }

    /*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");
    /*form js*/
    const formFields = {
        edit_page_title: "",
        edit_page_description: "",
        edit_table_name: "",
        edit_page_id: "",
        // breadcrumb_idu: 0,
        file_upload_idu: 0,
        multi_delete: 0,
        // dynamic_fields: 0,
        edit_table_breadcrumb_title: "",
        auditable_logs: 0,
        use_ajax: "",
        disable_show: 0,
        table_width: "full",
        show_width: "medium",
        soft_delete: 0,
        multi_tenancy: 0,
        multi_tenancy_team: 0,
        hide_from_import: 0,
        extend_model: 0,
        extend_controller: 0,
        extend_request: 0,
        disable_create: 0,
        disable_edit: 0,
        disable_delete: 0,
        breadcrumb_idu: 0,
        // single_row: 0,
    }
    const [formData, setFormData] = useState(formFields);

    const formFieldsRules = {
        edit_page_title: {required: false, type: "input", rules: {minLength: 1, maxLength: rootTITLE_NAME_MAX_LENGTH}},
        edit_page_description: {required: false, type: "input", rules: {minLength: 1, maxLength: 250}},
        edit_table_name: {required: true, type: "input", rules: {minLength: 1, maxLength: rootTABLE_NAME_MAX_LENGTH, allowedInput: "lower_dash_numbers_protect_start_end"}},
        edit_page_id: {required: true, type: "input", rules: {minLength: 3, maxLength: rootPAGE_ID_MAX_LENGTH, allowedInput: "lower_dash_numbers_protect_start_end"}},
        // breadcrumb_idu: {required: false, type: "select"},
        edit_table_breadcrumb_title: {required: true, type: "input", rules: {minLength: 1, maxLength: 64}},
        file_upload_idu: {required: false, type: "select"},
        table_width: {required: false, type: "select"},
        show_width: {required: false, type: "select"},
        multi_delete: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        // dynamic_fields: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},

        auditable_logs: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        use_ajax: {required: false, type: "checkbox", rules: {checked: "ajax", unchecked: 0}},
        soft_delete: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        multi_tenancy: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        multi_tenancy_team: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        hide_from_import: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        extend_model: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        extend_controller: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        extend_request: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        disable_create: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        disable_edit: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        disable_delete: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        disable_show: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        breadcrumb_idu: {required: false, type: "select"},
        // single_row: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
    }

    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiEditTableSettings({...formData}, form_idu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/

    return (
        <>
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll ${slideIn ? "admiko-show" : ""}`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                            <div className="info">
                                <h2><i className={`${title.icon}`}></i>{title.title}</h2>
                                <p>{title.info}</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-action-menu-body">
                            <div className={`admiko-action-menu-body-action add-menu active`}>
                                <div>
                                    <form action="">
                                        <div className="form-group">
                                            <label htmlFor="edit_page_title">Title:</label>
                                            <div className="form-group-input">
                                                <input className="form-input" autoComplete="off" name="edit_page_title" id="edit_page_title" placeholder="Enter table title" value={formData.edit_page_title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                <p className="ak-admiko-form-error">Required!</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="edit_page_description">Info:</label>
                                            <div className="form-group-input">
                                                <textarea value={formData.edit_page_description} className="form-textarea" name="edit_page_description" placeholder="Enter table info" id="edit_page_description" onChange={(e) => onChangeFormData(e)}></textarea>
                                            </div>
                                        </div>
                                        {elements.can_edit === 1 &&
                                            <>
                                                <div className="form-group">
                                                    <label htmlFor="edit_table_name" className="mouse-pointer" onClick={() => setInfoClick({table_id: !infoClick.table_id})}>Database table: <i className="fa-solid fa-circle-info"></i></label>
                                                    <div className="form-group-input">
                                                        <input className="form-input" autoComplete="off" name="edit_table_name" id="edit_table_name" placeholder="Enter table name" value={formData.edit_table_name} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                        <p className="ak-admiko-form-error">Required!</p>
                                                    </div>
                                                </div>
                                                {infoClick.table_id &&
                                                    <p className="ak-admiko-form-info">Unique database table name. Max {rootTABLE_NAME_MAX_LENGTH} characters. Can't start with 0-9 and _ or ends with _ (Limited: a-z, 0-9 and _).
                                                        Highly recommended to use only a-z and _</p>
                                                }
                                            </>
                                        }
                                        {props.elements.type === "tf" &&
                                            <>
                                                <div className="form-group">
                                                    <label htmlFor="edit_page_id" className="mouse-pointer" onClick={() => setInfoClick({page_id: !infoClick.page_id})}>Page ID: <i className="fa-solid fa-circle-info"></i></label>
                                                    <div className="form-group-input">
                                                        <input className="form-input" autoComplete="off" name="edit_page_id" id="edit_page_id" placeholder="Enter page ID" value={formData.edit_page_id} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                        <p className="ak-admiko-form-error">Required min 3 characters!</p>
                                                    </div>
                                                </div>
                                                {infoClick.page_id &&
                                                    <p className="ak-admiko-form-info">Unique ID to use in url, routes, filenames etc.., Max {rootPAGE_ID_MAX_LENGTH} characters. Can't start with _0-9 or ends with _ (Limited: a-z, 0-9 and _).
                                                        Highly recommended to use only a-z and _</p>
                                                }
                                                <div className="form-group">
                                                    <label htmlFor="edit_table_breadcrumb_title">Breadcrumb title:</label>
                                                    <div className="form-group-input">
                                                        <input className="form-input" autoComplete="off" name="edit_table_breadcrumb_title" id="edit_table_breadcrumb_title" placeholder="Enter breadcrums title" value={formData.edit_table_breadcrumb_title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                        <p className="ak-admiko-form-error">Required!</p>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        {/*<div className="form-group">*/}
                                        {/*    <label htmlFor="table_width">Table width:</label>*/}
                                        {/*    <div className="form-group-input">*/}
                                        {/*        <select value={formData.table_width} className="form-select" name="table_width" id="table_width" onChange={(e) => onChangeFormData(e)}>*/}
                                        {/*            <option value="full" key="full">Full</option>*/}
                                        {/*            <option value="medium" key="medium">Medium</option>*/}
                                        {/*            <option value="small" key="small">Small</option>*/}
                                        {/*        </select>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="form-group">
                                            <label htmlFor="table_width">Table width:</label>
                                            <div className="form-group-input">
                                                <div className="form-group-input-radio-inline">
                                                    {[{title:"Full",value:'full'}, {title:"Medium",value:'medium'}, {title:"Small",value:'small'}].map(item => (
                                                        <div key={item.value} className={`${formData.table_width === item.value ? 'active' : ''}`}>
                                                            <input
                                                                type="radio"
                                                                id={`width_${item.value}`}
                                                                name="table_width"
                                                                value={item.value}
                                                                checked={formData.table_width === item.value}
                                                                onChange={(e) => onChangeFormData(e)}
                                                            />
                                                            <label
                                                                htmlFor={`width_${item.value}`}
                                                            >
                                                                {item.title}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="form-group">*/}
                                        {/*    <label htmlFor="show_width">Show page width:</label>*/}
                                        {/*    <div className="form-group-input">*/}
                                        {/*        <select value={formData.show_width} className="form-select" name="show_width" id="show_width" onChange={(e) => onChangeFormData(e)}>*/}
                                        {/*            <option value="full" key="full">Full</option>*/}
                                        {/*            <option value="medium" key="medium">Medium</option>*/}
                                        {/*            <option value="small" key="small">Small</option>*/}
                                        {/*        </select>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="form-group">
                                            <label htmlFor="file_upload_idu">Bulk upload:</label>
                                            <div className="form-group-input">
                                                {props.elements.settings.file_upload_source.length > 0 ?
                                                    (
                                                        <select value={formData.file_upload_idu} className="form-select" name="file_upload_idu" id="file_upload_idu" onChange={(e) => onChangeFormData(e)}>
                                                            <option key="0-disabled" value="0">Disabled</option>
                                                            {props.elements.settings.file_upload_source.map((e) => {
                                                                return <option key={e.idu} value={e.idu}>{e.title}</option>;
                                                            })}
                                                        </select>
                                                    )
                                                    : (
                                                        <>Enable bulk upload by adding file or image field to your form.</>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="breadcrumb_idu">Breadcrumb source:</label>
                                            <div className="form-group-input">
                                                <select value={formData.breadcrumb_idu} className="form-select" name="breadcrumb_idu" id="breadcrumb_idu" onChange={(e) => onChangeFormData(e)}>
                                                    <option key="0-default" value="0">Default</option>
                                                    {props.elements.settings.breadcrumb_source.map((e) => {
                                                        return <option key={e.idu} value={e.idu}>{e.title}</option>;
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="ak-table-settings">
                                            <div className="form-group">
                                                <label htmlFor="multi_delete">Bulk delete:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="multi_delete" id="multi_delete" value="1" checked={formData.multi_delete === 1} onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="use_ajax">Use Ajax:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="use_ajax" id="use_ajax" value="1" checked={formData.use_ajax === "ajax"} onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {(elements.is_on_root_page === 1) &&
                                                <>
                                                    <div className="form-group">
                                                        <label htmlFor="soft_delete">Soft delete:</label>
                                                        <div className="form-group-input">
                                                            <div className="form-group-input">
                                                                <input className="form-checkbox" type="checkbox" name="soft_delete" id="soft_delete" value="1" checked={formData.soft_delete === 1} onChange={(e) => onChangeFormData(e)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {formData.edit_table_name !== 'admin_auditable_logs' &&
                                                        <div className="form-group">
                                                            <label htmlFor="auditable_logs">Auditable Logs:</label>
                                                            <div className="form-group-input">
                                                                <div className="form-group-input">
                                                                    <input className="form-checkbox" type="checkbox" name="auditable_logs" id="auditable_logs" value="1" checked={formData.auditable_logs === 1} onChange={(e) => onChangeFormData(e)}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    }
                                                    {(formData.edit_table_name !== 'admin_auditable_logs'
                                                            && formData.edit_table_name !== 'admin_roles'
                                                            && formData.edit_table_name !== 'admin_permissions'
                                                            && formData.edit_table_name !== 'admin_users'
                                                        ) &&
                                                        <>
                                                            <div className="form-group">
                                                                <label htmlFor="multi_tenancy">Multi-tenan. by user:</label>
                                                                <div className="form-group-input">
                                                                    <div className="form-group-input">
                                                                        <input className="form-checkbox" type="checkbox" name="multi_tenancy" id="multi_tenancy" value="1" checked={formData.multi_tenancy === 1} onChange={(e) => onChangeFormData(e)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="multi_tenancy_team">Multi-tenan. by team:</label>
                                                                <div className="form-group-input">
                                                                    <div className="form-group-input">
                                                                        <input className="form-checkbox" type="checkbox" name="multi_tenancy_team" id="multi_tenancy_team" value="1" checked={formData.multi_tenancy_team === 1} onChange={(e) => onChangeFormData(e)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    <div className="form-group">
                                                        <label htmlFor="hide_from_import">Hide from import:</label>
                                                        <div className="form-group-input">
                                                            <div className="form-group-input">
                                                                <input className="form-checkbox" type="checkbox" name="hide_from_import" id="hide_from_import" value="1" checked={formData.hide_from_import === 1} onChange={(e) => onChangeFormData(e)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="form-group">
                                                <label htmlFor="extend_model">Extend Model:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="extend_model" id="extend_model" value="1" checked={formData.extend_model === 1} onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="extend_controller">Extend Controller:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="extend_controller" id="extend_controller" value="1" checked={formData.extend_controller === 1} onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="extend_request">Extend Request:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="extend_request" id="extend_request" value="1" checked={formData.extend_request === 1} onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <AdvancedSettings>
                                            <div className="ak-table-settings">
                                                <div className="form-group">
                                                    <label htmlFor="disable_create">Disable create:</label>
                                                    <div className="form-group-input">
                                                        <div className="form-group-input">
                                                            <input className="form-checkbox" type="checkbox" name="disable_create" id="disable_create" value="1" checked={formData.disable_create === 1} onChange={(e) => onChangeFormData(e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="disable_edit">Disable edit:</label>
                                                    <div className="form-group-input">
                                                        <div className="form-group-input">
                                                            <input className="form-checkbox" type="checkbox" name="disable_edit" id="disable_edit" value="1" checked={formData.disable_edit === 1} onChange={(e) => onChangeFormData(e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="disable_delete">Disable delete:</label>
                                                    <div className="form-group-input">
                                                        <div className="form-group-input">
                                                            <input className="form-checkbox" type="checkbox" name="disable_delete" id="disable_delete" value="1" checked={formData.disable_delete === 1} onChange={(e) => onChangeFormData(e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="disable_show">Disable show page:</label>
                                                    <div className="form-group-input">
                                                        <div className="form-group-input">
                                                            <input className="form-checkbox" type="checkbox" name="disable_show" id="disable_show" value="1" checked={formData.disable_show === 1} onChange={(e) => onChangeFormData(e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </AdvancedSettings>
                                        <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                        <FormCloseButton closeForm={(e) => closeForm(e)}/>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {props.elements.type === "tf" &&
                            <ElementInfo section={elements.info_details} header="Table information:"/>
                        }
                    </div>
                </div>
            </div>
        </>
    );

}