import React from "react";
import {Link} from "react-router-dom";
import {rootBuilderLink, setRootScrollToID} from "../../Helper/RootVar/Contstant";

export function ToolBoxFormEditButtons(props) {

    const url_menu_id = props.url_menu_id;
    const url_page_id = props.url_page_id;
    const url_form_id = props.url_form_id;
    const url_table_id = props.url_form_id; /*table ID is form ID*/
    const url_form_page_element = props.url_form_page_element;
    const table_btn = props.table_btn;
    const form_btn = props.form_btn;

    const prependLink = `${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}`;
    const navigateToSection = () => {
        setRootScrollToID(url_form_id);
    }

    return (
        <div className="admiko-tf-settings">
            {table_btn === 1 &&
                <div className={`mouse-pointer table-btn-edit`}>
                    {/*<a href="" onClick={(e)=>navigateToSection(e)}>Table</a>*/}
                    <Link to={`${prependLink}/t/${url_table_id}`} onClick={()=>navigateToSection()}><i className="fa-solid fa-gear"></i> Table</Link>
                </div>
            }
            {form_btn === 1 &&
                <div className={`mouse-pointer from-btn-edit ${url_form_page_element === false && "selected"}`}>
                    <Link to={`${prependLink}/f/${url_form_id}/edit`}><i className="fa-solid fa-pen-to-square"></i> Form</Link>
                </div>
            }
        </div>
    );
}