import React, {useEffect} from "react";
import {FormTitle} from "./Partials/FormTitle";
import {FormColumn} from "./Partials/FormColumn";
import {FormDefault} from "./Partials/FormDefault";
import {FormPlaceholder} from "./Partials/FormPlaceholder";
import {FormDescription} from "./Partials/FormDescription";
import {FormRequired} from "./Partials/FormRequired";
import {FormSubmitButton} from "../../../../../Helpers/FormSubmitButton";
import {FormWidth} from "./Partials/FormWidth";
import {FormMinValue} from "./Partials/FormMinValue";
import {FormMaxValue} from "./Partials/FormMaxValue";
import {FormCloseButton} from "../../../../../Helpers/FormCloseButton";
import {FormCustomClass} from "./Partials/FormCustomClass";
import {FormDisableAKJS} from "./Partials/FormDisableAKJS";
import {FormPrefix} from "./Partials/FormPrefix";
import {FormSuffix} from "./Partials/FormSufix";
import {AdvancedSettings} from "../../../../../Helpers/AdvancedSettings";
import {rootCUSTOM_CLASS_MAX_LENGTH, rootTABLE_COLUMN_MAX_LENGTH} from "../../../../../../Helper/RootVar/Contstant";


export const BigInteger = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormData = props.onChangeFormData;
    const sendFormData = props.sendFormData;
    const submitButton = props.submitButton;
    const closeForm = props.closeForm;
    const checkSaveButtonOnChildStart = props.checkSaveButtonOnChildStart;
    useEffect(() => {
        checkSaveButtonOnChildStart(formFieldsRules);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formFieldsRules = {
        title: {required: true, type: "input", rules: {minLength: 1, maxLength: 64}},
        //elm_name: {required: true, type: "input", rules: {minLength: 1, maxLength: 64, allowedInput: "upper_lower_dash_numbers"}},
        table_column: {required: true, type: "input", rules: {minLength: 1, maxLength: rootTABLE_COLUMN_MAX_LENGTH, allowedInput: "upper_lower_dash_numbers_protect_start_end"}},
        required: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        prefix: {required: false, type: "input", rules: {minLength: 1, maxLength: 20}},
        suffix: {required: false, type: "input", rules: {minLength: 1, maxLength: 20}},
        min_value: {required: false, type: "input", rules: {minLength: 1, maxLength: 20, allowedInput: "numbers_minus"}},
        max_value: {required: false, type: "input", rules: {minLength: 1, maxLength: 20, allowedInput: "numbers_minus"}},
        default: {required: false, type: "input", rules: {minLength: 1, maxLength: 19, allowedInput: "numbers_minus"}},
        //step: {required: false, type: "input", rules: {minLength: 1, maxLength: 19, allowedInput: "numbers_minus"}},
        custom_class: {required: false, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_CLASS_MAX_LENGTH, allowedInput: "upper_lower_dash_minus_numbers_space"}},
        disable_js: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        // number_format: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
    }
    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules)
    };
    return (
        <>
            <form action="">
                <FormTitle formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                {/*<FormVariable formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>*/}
                <FormColumn formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormWidth formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormRequired formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                <FormDefault formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                {formData.can_edit === 1 &&
                    <>
                        <FormMinValue formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                        <FormMaxValue formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    </>
                }
                {/*<FormStep formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>*/}
                {/*<FormNumberFormat formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>*/}
                <AdvancedSettings>
                    <FormDescription formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormPlaceholder formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                    <FormPrefix formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormSuffix formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormCustomClass formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormDisableAKJS formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                </AdvancedSettings>
                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                <FormCloseButton closeForm={(e) => closeForm(e)}/>
            </form>
        </>
    );

}