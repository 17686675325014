import React, {useEffect, useState} from "react";
import {Icons} from "../../../../Icons/Icons";
import {DeleteSection} from "../../../Delete/DeleteSection";
import {rootAllPages, rootBuilderLink, rootHomePageID} from "../../../../../Helper/RootVar/Contstant";
import {useNavigate, useParams} from "react-router-dom";
import {SingleFormPageSettings} from "./SingleFormPageSettings";

/*ADD new elements to a FORM*/
export const SingleFormPage = (props) => {

    const {url_page_id = false} = useParams();
    const actionMenuTab = props.actionMenuTab;
    const toggleActionMenuTab = props.toggleActionMenuTab;
    let navigate = useNavigate();

    const [selectedSection, setSelectedSection] = useState(false);

    useEffect(() => {
        if (rootAllPages.hasOwnProperty(url_page_id)) {
            if (rootAllPages[url_page_id].hasOwnProperty('page')) {
                setSelectedSection(rootAllPages[url_page_id].page);
            } else {
                /*todo:else*/
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (rootAllPages.hasOwnProperty(url_page_id)) {
            if (rootAllPages[url_page_id].hasOwnProperty('page')) {
                setSelectedSection(rootAllPages[url_page_id].page);
            } else {
                /*todo:else*/
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rootAllPages[url_page_id])])

    const deletedParentAction = (response) => {
        if(response.return.parent_idu !== rootHomePageID){
            return navigate(`${rootBuilderLink}/m/${response.return.parent_idu}/p/${response.return.parent_idu}`)
        } else {
            return navigate(`${rootBuilderLink}/m/${rootHomePageID}/p/${rootHomePageID}`)
        }
    };

    return (
        <>
            {actionMenuTab === "form_settings" &&
                <SingleFormPageSettings
                    idu={selectedSection.idu} toggleActionMenuTab={toggleActionMenuTab}
                    section={selectedSection} />
            }
            {actionMenuTab === "icon_manager" &&
                <Icons toggleActionMenuTab={toggleActionMenuTab} section={selectedSection} setSelectedSection={setSelectedSection}/>
            }
            {actionMenuTab === "delete" &&
                <DeleteSection toggleActionMenuTab={toggleActionMenuTab} section={selectedSection} deletedParentAction={deletedParentAction}/>
            }
        </>
    );
}
