import React, {useEffect, useState} from "react";

import {FormSubmitButton} from "../../../Helpers/FormSubmitButton";
import {useIsMountedRef} from "../../../../Helper/isMounted";
import {setRootOpenInAdmikoSectionPageData} from "../../../../Global/AdmikoToolBox/AdmikoSectionPageData";
import {ElementInfo} from "../../../Helpers/ElementInfo";
import {rootSetError} from "../../../../Helper/RootVar/Error/Error";
import {apiEditPageSettings} from "../../../../Api/usePageSections";
import {onChangeFormDataGlobal} from "../../../../Helper/Functions";
import {SystemFileInfo} from "../../../Helpers/SystemFileInfo";
import {rootPAGE_ID_MAX_LENGTH, rootTITLE_NAME_MAX_LENGTH} from "../../../../Helper/RootVar/Contstant";

export const Settings = (props) => {
    /*set props*/
    const idu = props.idu;
    const toggleActionMenuTab = props.toggleActionMenuTab;
    const section = props.section;

    const isMountedRef = useIsMountedRef();
    const [infoClick, setInfoClick] = useState({page_id: false});

    /*GET DATA for form from API server*/
    useEffect(() => {
        let newProperty = {...formData}
        newProperty.idu = 0;
        setFormData(newProperty);
        LoadFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idu, isMountedRef])

    useEffect(() => {
        LoadFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section, isMountedRef])


    const LoadFormData = () => {
        let newProperty = {...formData}
        newProperty.edit_menu_title = section.title;
        newProperty.edit_page_title = section.page_title;
        newProperty.edit_page_breadcrumb_title = section.page_breadcrumb_title;
        newProperty.edit_page_description = section.description;
        newProperty.edit_page_id = section.page_id;
        newProperty.page_width = section.page_width;
        newProperty.edit_page_hide_from_import = section.hide_from_import;
        newProperty.icon = section.icon;

        newProperty.first = section.first;
        newProperty.idu = section.idu;
        setFormData(newProperty)

    }

    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        setSlideIn(true);
    }, [])

    const hideBack = () => {
        setSlideIn(false);

        setTimeout(() => {
            toggleActionMenuTab("main");
            setRootOpenInAdmikoSectionPageData(false);
        }, 200);
    }

    /*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");
    /*form js*/
    const formFields = {
        edit_menu_title: "",
        edit_page_title: "",
        edit_page_description: "",
        edit_page_breadcrumb_title: "",
        first: 0,
        edit_page_id: 0,
        edit_page_hide_from_import: 0,
        idu: 0,
        page_width: "full",
        icon: 0
    };
    const formFieldsRules = {
        edit_menu_title: {required: true, type: "input", rules: {minLength: 1, maxLength: rootTITLE_NAME_MAX_LENGTH}},
        edit_page_title: {required: true, type: "input", rules: {minLength: 1, maxLength: rootTITLE_NAME_MAX_LENGTH}},
        edit_page_breadcrumb_title: {required: true, type: "input", rules: {minLength: 1, maxLength: 64}},
        edit_page_description: {required: false, type: "input", rules: {minLength: 0, maxLength: 250}},
        edit_page_id: {required: true, type: "input", rules: {minLength: 3, maxLength: rootPAGE_ID_MAX_LENGTH, allowedInput: "lower_dash_numbers_protect_start_end"}},
        edit_page_hide_from_import: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        page_width: {required: false, type: "select"},
    }
    const [formData, setFormData] = useState(formFields);

    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };
    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiEditPageSettings({...formData}, idu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/


    /*end form js*/
    return (
        <>
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll ${slideIn ? "admiko-show" : ""}`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                            <div className="info">
                                <h2><i className="fa-solid fa-gears"></i>Page settings:</h2>
                                <p>Edit page properties.</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-action-menu-body">
                            <div className={`admiko-action-menu-body-action add-menu active`}>
                                {(formData.idu !== 0) ? (
                                        <div>
                                            <form action="">

                                                    <>
                                                        <div className="form-group">
                                                            <label htmlFor="edit_menu_title">Menu title:</label>
                                                            <div className="form-group-input">
                                                                <input className="form-input" autoComplete="off" name="edit_menu_title" id="edit_menu_title" placeholder="Enter menu title" value={formData.edit_menu_title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                                <p className="ak-admiko-form-error">Required!</p>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="edit_page_title">Page title:</label>
                                                            <div className="form-group-input">
                                                                <input className="form-input" autoComplete="off" name="edit_page_title" id="edit_page_title" placeholder="Enter page title" value={formData.edit_page_title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                                <p className="ak-admiko-form-error">Required!</p>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="edit_page_description">Description:</label>
                                                            <div className="form-group-input">
                                                                <textarea name="edit_page_description" id="edit_page_description" rows="4" placeholder="Enter page description" className="form-textarea" value={formData.edit_page_description} onChange={(e) => onChangeFormData(e)}></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="edit_page_breadcrumb_title">Breadcrumb title:</label>
                                                            <div className="form-group-input">
                                                                <input className="form-input" autoComplete="off" name="edit_page_breadcrumb_title" id="edit_page_breadcrumb_title" placeholder="Enter breadcrums title" value={formData.edit_page_breadcrumb_title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                                <p className="ak-admiko-form-error">Required!</p>
                                                            </div>
                                                        </div>
                                                        {/*<div className="form-group">*/}
                                                        {/*    <label htmlFor="edit_page_breadcrumb_title">Page width:</label>*/}
                                                        {/*    <div className="form-group-input">*/}
                                                        {/*        <select id="table_width" name="page_width" className="form-select" value={formData.page_width} onChange={(e) => onChangeFormData(e)}>*/}
                                                        {/*            <option value="full" key="full">Full</option>*/}
                                                        {/*            <option value="medium" key="medium">Medium</option>*/}
                                                        {/*            <option value="small" key="small">Small</option>*/}
                                                        {/*        </select>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        <div className="form-group">
                                                            <label htmlFor="page_width">Page width:</label>
                                                            <div className="form-group-input">
                                                                <div className="form-group-input-radio-inline">
                                                                    {[{title:"Full",value:'full'}, {title:"Medium",value:'medium'}, {title:"Small",value:'small'}].map(item => (
                                                                        <div key={item.value} className={`${formData.page_width === item.value ? 'active' : ''}`}>
                                                                            <input
                                                                                type="radio"
                                                                                id={`width_${item.value}`}
                                                                                name="page_width"
                                                                                value={item.value}
                                                                                checked={formData.page_width === item.value}
                                                                                onChange={(e) => onChangeFormData(e)}
                                                                            />
                                                                            <label
                                                                                htmlFor={`width_${item.value}`}
                                                                            >
                                                                                {item.title}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>

                                                {(section.first !== 1 && section.can_edit === 1) &&
                                                    <>
                                                        <div className="form-group">
                                                            <label htmlFor="edit_page_id" className="mouse-pointer" onClick={() => setInfoClick({page_id: !infoClick.page_id})}>Page ID: <i className="fa-solid fa-circle-info"></i></label>
                                                            <div className="form-group-input">
                                                                <input className="form-input" autoComplete="off" name="edit_page_id" id="edit_page_id" placeholder="Enter unique ID" value={formData.edit_page_id} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                                <p className="ak-admiko-form-error">Required min 3 characters!</p>
                                                            </div>
                                                        </div>
                                                        {infoClick.page_id &&
                                                            <p className="ak-admiko-form-info">Unique ID to use in url, routes, filenames etc.., Max {rootPAGE_ID_MAX_LENGTH} characters. Can't start with _0-9 or ends with _ (Limited: a-z, 0-9 and _).
                                                                Highly recommended to use only a-z and _</p>
                                                        }
                                                    </>
                                                }
                                                {(section.is_on_root_page === 1) &&
                                                    <div className="form-group">
                                                        <label htmlFor="edit_page_hide_from_import">Hide from import:</label>
                                                        <div className="form-group-input">
                                                            <div className="form-group-input">
                                                                <input className="form-checkbox" type="checkbox" name="edit_page_hide_from_import" id="edit_page_hide_from_import" value="1" checked={formData.edit_page_hide_from_import === 1} onChange={(e) => onChangeFormData(e)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                            </form>
                                        </div>
                                    )
                                    :
                                    <>
                                        <div className="admiko-ak-loading">
                                            <i className="fa-solid fa-circle-notch"></i>
                                        </div>
                                    </>}
                            </div>
                        </div>
                        <ElementInfo section={section.info_details} header="Page information:"/>
                        {(section.can_edit === 0) &&
                            <SystemFileInfo/>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
