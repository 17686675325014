import React from "react";

export const FormVariable = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="elm_name">Variable name:</label>
            <div className="form-group-input">
                <input className={`form-input ${formData.elm_name === "" && "ak-admiko-form-input-error"}`} autoComplete="off" name="elm_name" id="elm_name" placeholder="Enter variable name" value={formData.elm_name} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                <p className="ak-admiko-form-error">Required!</p>
            </div>
        </div>
    );

}