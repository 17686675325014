import React from "react";
import {FormInfo, Required} from "./Partials/Partials";
import {EditBox} from "../FormContentStart";

export const MapGoogle = (props) => {
    /*set props*/
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const editElement = props.editElement;


    return (
        <div className={`ak-form-group ak-w-${ElementData.width} js-form-elements js-admiko-sort-me-container ${props.element_id === ElementData.idu ? "selected":""}`} data-id={ElementData.idu}>
            <div className={`ak-form-group-box`} onClick={()=> editElement(ElementData.idu)}>
                <div className="ak-form-label">
                    <label>{ElementData.title}<Required ElementData={ElementData}/></label>
                </div>
                <div className="ak-form-input ak-form-input-map">
                        <div className="ak-form-input-map-display">
                            <img alt="" src="/images/google_map.jpg" className="img-fluid"/>
                            <FormInfo ElementData={ElementData}/></div>
                        <div className="ak-form-input-map-input">
                            <div className="ak-form-label"><label>Longitude:</label></div>
                            <div><input type="text" autoComplete="off" placeholder="Longitude" className="form-input"/></div>
                            <div className="ak-form-label"><label>Latitude:</label></div>
                            <div><input type="text" autoComplete="off" placeholder="Latitude" className="form-input"/></div>
                            <p className="ak-form-info">Drag and drop marker or enter Latitude and Longitude</p>
                        </div>
                </div>
            </div>
            <EditBox position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement} idu={ElementData.idu}/>
        </div>
    );

}