import {useState} from 'react';

export let rootAdmikoToolBoxMenuContainerDefault = {close: false, visible: false, part: "", idu: 0}
export let rootAdmikoToolBoxMenuContainer = {close: false, visible: false, part: "", idu: 0}
export let setRootAdmikoToolBoxMenuContainer;

export const useBuilderRootAdmikoToolBoxMenuContainer = () => {
    [rootAdmikoToolBoxMenuContainer, setRootAdmikoToolBoxMenuContainer] = useState(rootAdmikoToolBoxMenuContainerDefault);
};

export const updateRootAdmikoToolBoxMenuContainer = (close = false, visible = false, part = "", idu = 0) => {
    if (rootAdmikoToolBoxMenuContainer.idu === idu && rootAdmikoToolBoxMenuContainer.visible === true) {
        visible = false;
        close = true;
    }
    if (visible && !close) {
        setRootAdmikoToolBoxMenuContainer({close: false, visible: true, part: part, idu: idu});
    } else if (close) {
        setRootAdmikoToolBoxMenuContainer({close: true, visible: true, part: part, idu: idu});
    } else if (!close) {
        setRootAdmikoToolBoxMenuContainer(rootAdmikoToolBoxMenuContainerDefault);
    }
}
export const closeRootAdmikoToolBoxMenuContainer = () => {
    if (rootAdmikoToolBoxMenuContainer.visible) {
        setRootAdmikoToolBoxMenuContainer({close: true, visible: true, part: rootAdmikoToolBoxMenuContainer.part, idu: rootAdmikoToolBoxMenuContainer.idu});
    }
}
