import React, {useEffect, useState} from "react";
import SortMe from "../../../../../Helper/SortMe";

export const PaginationTableOrder = (props) => {
    /*set props*/
    const onChangeFormDataInput = props.onChangeFormDataInput;

    const [sourceLabels, setSourceLabels] = useState([]);

    /*SORT*/
    const [connectedSourceSortLabels, setConnectedSourceSortLabels] = useState([]);
    const [sortLabelGroup, setSortLabelGroup] = useState([])

    useEffect(() => {
        setSourceLabels(props.elements);
        setConnectedSourceSortLabels(props.selected_elements);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setSourceLabels(props.elements);
        setConnectedSourceSortLabels(props.selected_elements);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selected_elements])

    useEffect(() => {
        generateSortLabelGroup(connectedSourceSortLabels);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceLabels])

    /*SORTING*/
    /*SORTING*/
    /*SORTING*/


    useEffect(() => {
        saveSortDataToParent(sortLabelGroup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortLabelGroup])


    const saveSortDataToParent = (labelGroupData) => {
        let newLabelGroup = [];
        for (let i = 0; i < labelGroupData.length; i++) {
            newLabelGroup = [...newLabelGroup, {idu: labelGroupData[i].idu, order: labelGroupData[i].order}];
        }
        let elm = {name: 'order', value: newLabelGroup};
        if (newLabelGroup.length > 0) {
            onChangeFormDataInput(elm);
        }
    }

    const generateSortLabelGroup = (labelGroupData) => {
        let newLabelGroup = [];
        let newSourceLabels = [...sourceLabels];

        for (let i = 0; i < labelGroupData.length; i++) {
            let order = labelGroupData[i].order;
            let newLabelGroupIDs = new Set(labelGroupData.map(({idu}) => idu));

            let index = newLabelGroupIDs.has(labelGroupData[i].idu);
            if (index) {
                newLabelGroupIDs.delete(labelGroupData[i].idu)
            }
            let combined = [...newSourceLabels.filter(({idu}) => !newLabelGroupIDs.has(idu))];
            let indexKey = combined.findIndex(p => p.idu === labelGroupData[i].idu);
            newLabelGroup = [...newLabelGroup, {idu: combined[indexKey].idu, order: order, labelList: combined}];
        }

        setSortLabelGroup(newLabelGroup);
    }

    const onChangeSortSourceLabel = (e, idu) => {

        let newFormValues = [...sortLabelGroup];
        newFormValues.find((o, i) => {
            if (o.idu === idu) {
                newFormValues[i] = {idu: e.target.value, order: newFormValues[i].order, labelList: []};
                generateSortLabelGroup(newFormValues);
                return true;
            }
            return false;
        });
    };

    const onChangeSortOrderSourceLabel = (e, idu) => {
        let newFormValues = [...sortLabelGroup];
        newFormValues.find((o, i) => {
            if (o.idu === idu) {
                newFormValues[i] = {idu: newFormValues[i].idu, order: e.target.value, labelList: []};
                generateSortLabelGroup(newFormValues);
                return true;
            }
            return false;
        });
    };


    const addSortLabelGroup = () => {
        let newLabelGroup = [...sortLabelGroup];
        let newSourceLabels = [...sourceLabels];

        const newLabelGroup2IDs = new Set(newLabelGroup.map(({idu}) => idu));
        const combined = [...newSourceLabels.filter(({idu}) => !newLabelGroup2IDs.has(idu))];

        newLabelGroup = [...newLabelGroup, {idu: combined[0].idu, order: "asc", labelList: []}];
        generateSortLabelGroup(newLabelGroup);
    }


    const removeSortLabelGroup = (i) => {
        let newLabelGroup = [...sortLabelGroup];
        newLabelGroup.splice(i, 1);
        if (newLabelGroup.length < 1) {
            newLabelGroup = [{idu: sourceLabels[0].idu, order: "asc", labelList: []}];
        }
        generateSortLabelGroup(newLabelGroup);
    }

    const saveSortSortMe = (newList) => {
        let newLabelGroup = [];
        let newFormValues = [...sortLabelGroup];
        let indexKey = 0;
        for (let i = 0; i < newList.sortList.length; i++) {
            indexKey = newFormValues.findIndex(p => p.idu === newList.sortList[i]);
            newLabelGroup = [...newLabelGroup, {idu: newFormValues[indexKey].idu, order: newFormValues[indexKey].order, labelList: []}];
        }
        generateSortLabelGroup(newLabelGroup);

    };

    return (
        <>
            <div className="add-more-btn-box">
                <div>
                    Default table order:
                </div>
                <div>
                    {sortLabelGroup.length < sourceLabels.length &&
                        <div className="add-more-btn mouse-pointer" onClick={() => addSortLabelGroup()}><i className="fa-solid fa-plus"></i> Add more</div>
                    }
                </div>
            </div>
            <SortMe saveSortMe={saveSortSortMe} parentContainer="js-ak-source-sort-label-group">
                {sortLabelGroup.map((element, index) => (
                    <FormSortConnectedSourceGroup key={element.idu} removeSortLabelGroup={removeSortLabelGroup}
                                                  ElementData={element} position={index} totalItems={sortLabelGroup.length}
                                                  idu={element.idu} onChangeSortSourceLabel={onChangeSortSourceLabel}
                                                  onChangeSortOrderSourceLabel={onChangeSortOrderSourceLabel}
                    />
                ))}
            </SortMe>

        </>
    );

}


/*SORTING LABELS*/
/*SORTING LABELS*/
/*SORTING LABELS*/
export const FormSortConnectedSourceGroup = (props) => {
    /*set props*/
    const idu = props.idu;
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const onChangeSortSourceLabel = props.onChangeSortSourceLabel;
    const removeSortLabelGroup = props.removeSortLabelGroup;
    const onChangeSortOrderSourceLabel = props.onChangeSortOrderSourceLabel;

    return (
        <>
            <div className="form-group ak-source-label-group js-ak-source-sort-label-group js-admiko-sort-me-container" data-id={ElementData.idu}>
                {/*<label htmlFor={`source-label-${idu}`}>Label:</label>*/}
                <div className="form-group-input">
                    <select className="form-select ak-source-sort-label" value={ElementData.idu} name="source-sort-label" id={`source-sort-label-${idu}`} onChange={(e) => onChangeSortSourceLabel(e, idu)}>
                        {ElementData.labelList.map((e) => {
                            return <option key={e.idu} value={e.idu}>{e.title}</option>;
                        })}
                    </select>
                    <select className="form-select ak-source-sort-order" value={ElementData.order} name="source-sort-order" id={`source-sort-order-${idu}`} onChange={(e) => onChangeSortOrderSourceLabel(e, idu)}>
                        <option value="asc">ASC</option>
                        <option value="desc">DESC</option>
                    </select>
                    <AdmikoToolboxSortEditBox position={position} sortMe={sortMe} totalItems={totalItems} removeSortLabelGroup={removeSortLabelGroup}/>
                </div>
            </div>
        </>
    );

}


export const AdmikoToolboxSortEditBox = (props) => {
    /*set props*/
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;

    const removeSortLabelGroup = props.removeSortLabelGroup;
    return (
        <>
            <div className="admiko-form-settings">
                {totalItems > 1 &&
                    <div className="move-section tft-move js-admiko-sort-me">
                        {position > 0 &&
                            <div className="move-up mouse-pointer js-admiko-move-me-up" onClick={(e) => sortMe.SortMoveUp(e)}><i className="fa-solid fa-chevron-up"></i></div>
                        }
                        <div className="mouse-move js-admiko-move-me"><i className="fa-solid fa-up-down-left-right"></i></div>
                        {position + 1 < totalItems &&
                            <div className="move-down mouse-pointer js-admiko-move-me-down" onClick={(e) => sortMe.SortMoveDown(e)}><i className="fa-solid fa-chevron-down"></i></div>
                        }
                    </div>
                }
                {totalItems > 1 &&
                    <div className="mouse-pointer delete-label-group" onClick={() => removeSortLabelGroup(position)}><i className="fa-solid fa-trash"></i></div>
                }
            </div>
        </>
    );
}