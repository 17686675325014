import React from 'react';
import {rootBuilderLink, rootHighlightPageContentParts} from "../../../../Helper/RootVar/Contstant";
import {Link} from "react-router-dom";

export const TableHeader = ({props}) => {

    const url_menu_id = props.url_menu_id;
    const url_page_id = props.url_page_id;
    const form_id = props.table_id; /*FORM  ID is TABLE ID*/

    const prependLink = `${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}`;

    return (
        <>
            {(props.info.title || props.info.desc) &&
                <div className="ak-section-header">
                    <div className="ak-section-header-left">
                        <h2 className="ak-section-title">{props.info.title}</h2>
                        <div className="ak-section-info">
                            {props.info.desc}
                        </div>
                    </div>
                    <div className="ak-section-header-right">

                    </div>
                </div>
            }
            <div className="ak-section-action">
                <div className="ak-section-action-left">
                    {props.info.search > 0 && props.info.pagination !== "drag_drop" &&
                        <form action="work/app/src/Builder/Content/TableForm" className={`ak-action-search ${(props.table_id === props.url_table_id && rootHighlightPageContentParts === "search") && "selected"}`} onClick={() => props.navigateToToolboxArea(props.info.idu, 'search')}>
                            <input type="text" placeholder="Search" className="form-input"/>

                            <button onClick={(e) => e.preventDefault()}>
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </button>

                        </form>
                    }
                    {props.info.search === 0 && props.info.pagination === "pagination_js" &&
                        <form action="work/app/src/Builder/Content/TableForm" className={`ak-action-search ${(props.table_id === props.url_table_id && rootHighlightPageContentParts === "search") && "selected"}`} onClick={() => props.navigateToToolboxArea(props.info.idu, 'search')}>
                            <input type="text" placeholder="Search" className="form-input"/>

                            <button onClick={(e) => e.preventDefault()}>

                            </button>

                        </form>
                    }

                </div>
                <div className="ak-section-action-right">
                    {props.info.multi_upload !== 0 &&
                        <div>
                            <a href="/" onClick={(e) => e.preventDefault()} className="ak-primary-button"><i className="fa-solid fa-file-arrow-up"></i> Upload</a>
                        </div>
                    }
                    {props.info.disable_create === 0 &&
                        <Link to={`${prependLink}/f/${form_id}/edit`} className="ak-primary-button"><i className="fa-solid fa-plus"></i> Add new</Link>
                    }
                </div>
            </div>
        </>
    );

}