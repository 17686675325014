import axios from "axios";

import {rootProjectID, setRootLeftMenuAll, setRootAllPages, setRootAllPageContent} from "../Helper/RootVar/Contstant";
import {setRootCheckLogout} from "../../Account/Global/Errors";
import {rootSetError} from "../Helper/RootVar/Error/Error";
import {setRootAppUpdate} from "../../Account/Global/AppVersion";

// const clientAxios = axios.create({
//     baseURL: "http://admiko_builder_api.com.test/api/v1"
// });
const clientAxios = axios.create({
    baseURL: "/api/builder/v1",
});

let cancelToken;
// export const fetchData = async (action, dataPrepare, sec_id = "", table_form_idu = "", elm_id = "") => {
export const fetchData = async ({action, dataPrepare, sec_id = "", table_form_idu = "", elm_id = "", use_cancel = false}) => {
    //let start = +new Date();
    // //Check if there are any previous pending requests
    let config = {
        //cancelToken: cancelToken.token
    };
    // console.log('use_cancel')
    // console.log(use_cancel)
    if(use_cancel === true){
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("canceled")
        }
        // //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source()
        config.cancelToken = cancelToken.token;
    }


    // const user = JSON.parse(localStorage.getItem("user"));
    // if (user) {
    //     config.headers = {Authorization: `Bearer ${user.token}`};
    // } else {
    //     return {success: false, message: "Auth Error", return: []};
    // }

    // const user = localStorage.getItem("user");
    const user = JSON.parse(localStorage.getItem("user"));
    if(user){
        config.headers = {Authorization: `Bearer ${user.token}`};
    } else {
        setRootCheckLogout({'return': {"logout":1}})
    }

    let dataToSend = {};

    dataToSend["ak_project_idu"] = rootProjectID;
    if (sec_id !== "") {
        dataToSend["ak_sec_idu"] = sec_id;
    }
    if (table_form_idu !== "") {
        dataToSend["ak_tf_idu"] = table_form_idu;
    }
    if (elm_id !== "") {
        dataToSend["ak_e_idu"] = elm_id;
    }
    dataToSend["ak_action"] = action;
    if (dataToSend && dataPrepare) {
        dataToSend["ak_data"] = dataPrepare;
    }

    dataToSend = JSON.stringify(dataToSend);
    if (process.env.NODE_ENV === 'production') {
        // dataToSend = encodeURIComponent(btoa(encodeURIComponent(dataToSend)));
    } else {
        console.log(dataToSend);
        // dataToSend = encodeURIComponent(btoa(encodeURIComponent(dataToSend)));
    }
    dataToSend = encodeURIComponent(btoa(encodeURIComponent(dataToSend)));

    return await clientAxios.post("", {send: dataToSend}, config)
        .then((response) => {
            let message = "";
            if (response.data.hasOwnProperty("message")) {
                message = response.data.message;
            }
            if (response.data.hasOwnProperty("app_v")) {
                if(response.data.app_v !== "" && response.data.app_v !== process.env.REACT_APP_VERSION){
                    setRootAppUpdate(true);
                }
            }
            let returnData = {};
            if (response.data.hasOwnProperty("success") && response.data.success === true) {
                if (response.data.hasOwnProperty("return")) {
                    returnData = JSON.parse(atob(decodeURIComponent(response.data.return)));
                    if (process.env.NODE_ENV === 'production') {
                        // returnData = JSON.parse(atob(decodeURIComponent(response.data.return)));
                    } else {
                        // returnData = JSON.parse(decodeURIComponent(response.data.return));
                        // returnData = JSON.parse(atob(decodeURIComponent(response.data.return)));
                        console.log("Return:");
                        console.log(returnData);
                    }
                } else {
                    message = "Error RBR03";
                }
                if (returnData.hasOwnProperty("menu")) {
                    setRootLeftMenuAll(returnData.menu);
                }
                if (returnData.hasOwnProperty("pages")) {
                    setRootAllPages(returnData.pages);
                }
                if (returnData.hasOwnProperty("page_data")) {
                    setRootAllPageContent(returnData.page_data);
                }
                // if (returnData.hasOwnProperty.hasOwnProperty("form_data")) {
                //     //     setRootAllFormElements(response.data.return.form_data);
                //     //
                //     // }
            } else {
                // message = "Error RB103";
                if (response.data.hasOwnProperty("return")) {
                    returnData = JSON.parse(atob(decodeURIComponent(response.data.return)));
                    if (process.env.NODE_ENV === 'production') {
                        // returnData = JSON.parse(atob(decodeURIComponent(response.data.return)));
                    } else {
                        // returnData = JSON.parse(decodeURIComponent(response.data.return));
                        // returnData = JSON.parse(decodeURIComponent(response.data.return));
                        // returnData = JSON.parse(atob(decodeURIComponent(response.data.return)));
                        console.log("Return error:");
                        console.log(returnData);
                    }
                } else {
                    message = "Error RBR33";
                }
            }

            setRootCheckLogout({'return':returnData})
            rootSetError({'success': response.data.success, 'message': message,'return':returnData});
            return {'success': response.data.success, 'message': message,'return':returnData};

        })
        .catch((error) => {
            if (process.env.NODE_ENV !== 'production') {
                return {message: "Server busy!", error: error};
            } else {
                console.log('ERROR');
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log('ERROR CANCEL');
                } else {
                    /*todo: else*/
                }
                return {message: "Server busy!", error: error};
            }
        });

}
