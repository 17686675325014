import React from "react";

export const FormTitleTwo = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;
    return (
        <div className="form-group">
            <label htmlFor="title_two">Title:</label>
            <div className="form-group-input">
                <input className={`form-input ${formData.title_two === "" && "ak-admiko-form-input-error"}`} autoComplete="off" name="title_two" id="title_two" placeholder="Enter title" value={formData.title_two} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                <p className="ak-admiko-form-error">Required!</p>
            </div>
        </div>
    );

}