import React from "react";

export const FormNumberFormat = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="number_format">Format number:</label>
            <div className="form-group-input">
                <div className="form-group-input">
                    <input className="form-checkbox" type="checkbox" name="number_format" value="1" checked={formData.number_format === 1} id="number_format" onChange={(e) => onChangeFormDataInput(e)}/>
                </div>
            </div>
        </div>
    );

}