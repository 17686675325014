import {fetchData} from "./useCallApi";

export const apiSortFormElements = async (dataToSend,sec_id) => {
    const dataFetch = {action:"sort_form_element",dataPrepare:dataToSend,"sec_id":sec_id,use_cancel:true};
    return await fetchData(dataFetch);
}
export const apiGetAllFormElements = async (sec_id,table_form_idu) => {
    const dataFetch = {action:"get_all_form_elements","sec_id":sec_id,"table_form_idu":table_form_idu};
    return await fetchData(dataFetch);
}
export const apiAddFormElement = async (dataToSend,sec_id,table_form_idu) => {

    const dataFetch = {action:"add_form_element",dataPrepare:dataToSend,"sec_id":sec_id,"table_form_idu":table_form_idu};
    return await fetchData(dataFetch);
}
export const apiEditFormElement = async (dataToSend,sec_id,element_idu) => {

    const dataFetch = {action:"edit_form_element",dataPrepare:dataToSend,"sec_id":sec_id,"elm_id":element_idu};
    return await fetchData(dataFetch);
}
export const apiDeleteFormElement = async (dataToSend,sec_id,element_idu) => {

    const dataFetch = {action:"delete_form_element",dataPrepare:dataToSend,"sec_id":sec_id,"elm_id":element_idu};
    return await fetchData(dataFetch);
}

export const apiGetConnectedSourceLabels = async (dataToSend,sec_id) => {

    const dataFetch = {action:"get_connected_source_labels",dataPrepare:dataToSend,"sec_id":sec_id};
    return await fetchData(dataFetch);
}

