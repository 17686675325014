import React from "react";

export const DeleteSectionHeader = ({toggleActionMenuTab}) => {

    return (
        <>
            <div className="admiko-action-header mouse-pointer" onClick={() => toggleActionMenuTab('delete')}>
                <div className="info">
                    <h2><i className="fa-solid fa-trash"></i>Delete</h2>
                    {/*<p>Delete page.</p>*/}
                </div>
                <div className="close">
                    <div className="admiko-toggle-tools">
                        <div className="admiko-toggle-box">
                            <div>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
