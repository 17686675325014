import React, {useEffect, useState} from "react";
import {useIsMountedRef} from "../../../../../Helper/isMounted";
import {rootAllPageContent} from "../../../../../Helper/RootVar/Contstant";
import {arrayFindObjectByProp} from "../../../../../Helper/Functions";
import {AddNewFormElement} from "./AddNewFormElement";


/*ADD new elements to a FORM*/
export const AddFormElementContainer = (props) => {
    /*set props*/
    const form_idu = props.form_idu;
    const parent_idu = props.parent_idu;
    const toggleActionMenuTab = props.toggleActionMenuTab;
    const elements = props.elements;
    const title = props.title;

    const [parentChildStatus, setParentChildStatus] = useState(false);

    const isMountedRef = useIsMountedRef();

    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        checkParentChild();
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSlideIn(true);
        }, 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setParentChildStatus(false);
        checkParentChild();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rootAllPageContent[form_idu])])

    const checkParentChild = () => {
        /*check parent_child to enable only on tf_crud and root page*/
        if (rootAllPageContent.hasOwnProperty(form_idu)) {
            if (rootAllPageContent[form_idu].hasOwnProperty('table_settings') && rootAllPageContent[form_idu].table_settings.hasOwnProperty('type')) {
                if(rootAllPageContent[form_idu].table_settings.type === "tf_crud"){
                    if(rootAllPageContent[form_idu].table_settings.settings.hasOwnProperty('is_on_root_page')){
                        if(rootAllPageContent[form_idu].table_settings.settings.is_on_root_page === 1){
                            if (rootAllPageContent[form_idu].hasOwnProperty('form_content') && rootAllPageContent[form_idu].form_content.hasOwnProperty('content')) {
                                if(!arrayFindObjectByProp(rootAllPageContent[form_idu].form_content.content,'type','parent_child')) {
                                    setParentChildStatus(true);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    const hideBack = () => {
        setSlideIn(false);
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            toggleActionMenuTab("main");
        }, 200)
    }

    /*end form js*/
    return (
        <>
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll ${slideIn ? "admiko-show" : ""}`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                            <div className="info">
                                <h2><i className={`${title.icon}`}></i>{title.title}</h2>
                                <p>{title.info}</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-action-menu-body">
                            <div className={`add-form-element-container`}>
                                {elements.map((item, i) => (
                                    <AddNewFormElement parentChildStatus={parentChildStatus} key={item.idu} item={item} form_idu={form_idu} section_idu={parent_idu}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
