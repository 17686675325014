import React,  {useState} from "react";
import {apiUpdatePassword} from "../../Api/useCallApi";
import {useIsMountedRef} from "../../Helpers/isMounted";
import {CheckFormOnChange, CheckFormOnSubmit} from "../../Helpers/CheckFormFields";
import {FormSubmitButton} from "../../Helpers/FormSubmitButton";
import {FormMessage} from "./FormMessage";

export const UpdatePassword = () => {

    const isMountedRef = useIsMountedRef();
    /*send button*/
    const [submitButtonTimer, setSubmitButtonTimer] = useState(0);
    const [submitButton, setSubmitButton] = useState({disable: false, state: ""});
    /*end send button*/

    const formFields = {
        fields: {new_password: "",current_password:""}
    }
    const formErrorData = {
        error: {new_password: false,current_password:false}
    }
    const formFieldsRules = {
        new_password: {required: true,type:"input",rules: {minLength:6,maxLength:150}},
        current_password: {required: true,type:"input",rules: {minLength:6,maxLength:100}},
    }

    const [formData, setFormData] = useState(formFields);
    const [fieldError, setFieldError] = useState(formErrorData);
    const [formErrorMessage, setFormErrorMessage] = useState(false);
    const [formInfoMessage, setFormInfoMessage] = useState(false);

    const onChangeFormData = (e) => {

        if (formFieldsRules.hasOwnProperty(e.target.name)) {
            e.target.value = CheckFormOnChange(e,formFieldsRules,submitButton,setSubmitButton);
        }
        setFormData(prevFormData => ({
            fields: {...prevFormData.fields, [e.target.name]: e.target.value}
        }));
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setFormErrorMessage(false);
        setFormInfoMessage(false);
        /*send button*/
        setSubmitButton({...submitButton, disable: true, state: "progress"});

        if (submitButtonTimer) {
            window.clearTimeout(submitButtonTimer);
            setSubmitButtonTimer(0);
        }
        /*end send button*/
        if(CheckFormOnSubmit(formData, fieldError, setFieldError, formFieldsRules, submitButton, setSubmitButton)){
            setSubmitButton({...submitButton, disable: false, state: ""});
            return;
        }
        let sendFormData = {...formData.fields};

        const response = await apiUpdatePassword(sendFormData);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            if(response.message==='updated'){
                setFormInfoMessage("Password updated.");
            }
            setSubmitButton({...submitButton, disable: false, state: "saved"});
        } else {
            if(response.message==='check_input'){
                setFormErrorMessage("Please check your input.");
            }
            if(response.message==='invalid_password'){
                setFormErrorMessage("Please check your current password.");
            }

            setSubmitButton({...submitButton, disable: false, state: ""});
        }

        /*send button*/
        setSubmitButtonTimer(setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }, 3000));
        /*end send button*/
    }

    return (
        <>
            <div className="ak-form-container">
                <div className="ak-section">
                    <div className="ak-section-content">
                        <form>
                            <div className="ak-section-form-content">
                                <div className="text-xl font-semibold tracking-tight leading-tight mb-4">Set new password:</div>
                                <div className="form-group">
                                    <label htmlFor="new_password">New password *</label>
                                    <input type="password" className={`form-input ${fieldError.error.new_password ? 'form-input-error' : ''}`} value={formData.fields.new_password} onChange={(e) => onChangeFormData(e)} name="new_password" id="new_password" autoComplete="new-password" placeholder="Enter new password"/>
                                    <p className="required-input">New password is required. Min 6 characters.</p>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password_update">Confirm with current password *</label>
                                    <input type="password" className={`form-input ${fieldError.error.current_password ? 'form-input-error' : ''}`} value={formData.fields.current_password} onChange={(e) => onChangeFormData(e)} name="current_password" id="current_password" autoComplete="current-password" placeholder="Enter current password"/>
                                    <p className="required-input">Current password is required.</p>
                                </div>

                                <div>
                                    <FormMessage formErrorMessage={formErrorMessage} formInfoMessage={formInfoMessage}/>
                                </div>
                            </div>
                            <div className="form-buttons-group">
                                <FormSubmitButton buttonText={"Update"} submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    )
};