import React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useAuth} from "./AuthProvider";

export const BuilderProtectedLayout = () => {

    const {user} = useAuth();
    let location = useLocation();

    if (!user) {
        return <Navigate to="/login" state={{from: location}} replace={true}/>;
    }

    return (
        <div className="ak-builder">
            <Outlet/>
        </div>
    )
};
