import {useState} from 'react';

export let rootAdmikoSectionPageData = [];
export let setRootAdmikoSectionPageData;

export let rootOpenInAdmikoSectionPageData = false;
export let setRootOpenInAdmikoSectionPageData;

export const useBuilderRootAdmikoSectionPageData = () => {
    [rootAdmikoSectionPageData, setRootAdmikoSectionPageData] = useState([]);
    [rootOpenInAdmikoSectionPageData, setRootOpenInAdmikoSectionPageData] = useState(false);
};
export const rootLoadAdmikoSectionPageData = (data) => {
    setRootAdmikoSectionPageData(data);
};