import '../assets/css/index.css';

import React from "react";
import {Navigate, Outlet,useLocation} from "react-router-dom";
import {useAuth} from "./AuthProvider";

export const PublicLayout = () => {

    const { user } = useAuth();
    let location = useLocation();

    let from = "/";

    if(location.state !== null){
        if(location.state.from !== null){
            from = location.state.from.pathname;
        }
    }
    //let from = location.state?.from || "/";
    if (user) {
        //navigate(from, { replace: true });
        return <Navigate to={from} replace={true}/>;
    }

    return (
        <div className="ak-app" id="ak-app">
            <Outlet />
        </div>
    )
};