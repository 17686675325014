import React, {useEffect, useState} from "react";
import {FormTitle} from "./Partials/FormTitle";
import {FormColumn} from "./Partials/FormColumn";
import {FormDescription} from "./Partials/FormDescription";
import {FormRequired} from "./Partials/FormRequired";
import {FormSubmitButton} from "../../../../../Helpers/FormSubmitButton";
import {FormWidth} from "./Partials/FormWidth";
import {FormFileType} from "./Partials/FormFileType";
import {FormFileSize} from "./Partials/FormFileSize";
import {FormUploadFolder} from "./Partials/FormUploadFolder";
import {FormCloseButton} from "../../../../../Helpers/FormCloseButton";
import {CheckCustomValuesFields} from "../../../../../../Helper/RootVar/Error/CheckFormFields";
import {FormCustomClass} from "./Partials/FormCustomClass";
import {AdvancedSettings} from "../../../../../Helpers/AdvancedSettings";
import {FormVarLength} from "./Partials/FormVarLength";
import {rootCUSTOM_CLASS_MAX_LENGTH, rootTABLE_COLUMN_MAX_LENGTH} from "../../../../../../Helper/RootVar/Contstant";

export const Image = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormData = props.onChangeFormData;
    const sendFormData = props.sendFormData;
    const submitButton = props.submitButton;
    const closeForm = props.closeForm;
    const checkSaveButtonOnChildStart = props.checkSaveButtonOnChildStart;

    const [thumbs, setThumbs] = useState(formData.thumbs);

    useEffect(() => {
        checkSaveButtonOnChildStart(formFieldsRules);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formFieldsRules = {
        title: {required: true, type: "input", rules: {minLength: 1, maxLength: 64}},
        //elm_name: {required: true, type: "input", rules: {minLength: 1, maxLength: 64, allowedInput: "upper_lower_dash_numbers"}},
        table_column: {required: true, type: "input", rules: {minLength: 1, maxLength: rootTABLE_COLUMN_MAX_LENGTH, allowedInput: "upper_lower_dash_numbers_protect_start_end"}},
        required: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        file_type: {required: false, type: "input", rules: {minLength: 1, maxLength: 100, allowedInput: "upper_lower_dash_numbers_dots_semi"}},
        max_size: {required: false, type: "input", rules: {minLength: 1, maxLength: 8, allowedInput: "numbers_dots"}},
        upload_folder: {required: true, type: "input", rules: {minLength: 1, maxLength: 200, allowedInput: "lower_numbers_dash_backslash"}},
        image_width: {required: true, type: "input", rules: {minLength: 1, maxLength: 5, allowedInput: "numbers"}},
        image_height: {required: true, type: "input", rules: {minLength: 1, maxLength: 5, allowedInput: "numbers"}},
        thumbs: {
            required: true, array_required: false, type: "array", fields: {
                folder: {required: true, type: "input", rules: {minLength: 1, maxLength: 200, allowedInput: "lower_numbers_dash_backslash"}},
                width: {required: true, type: "input", rules: {minLength: 1, maxLength: 5, allowedInput: "numbers"}},
                height: {required: true, type: "input", rules: {minLength: 1, maxLength: 5, allowedInput: "numbers"}}
            }
        },
        custom_class: {required: false, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_CLASS_MAX_LENGTH, allowedInput: "upper_lower_dash_minus_numbers_space"}},
        var_length: {required: true, type: "input", rules: {minLength: 1, maxLength: 3, allowedInput: "numbers"}},
    }
    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules)
    };

    const formFieldsThumbRules = {
        prefix: {required: false, type: "input", rules: {minLength: 1, maxLength: 30, allowedInput: "lower_numbers_dash_minus"}},
        folder: {required: true, type: "input", rules: {minLength: 1, maxLength: 200, allowedInput: "lower_numbers_dash_backslash"}},
        width: {required: true, type: "input", rules: {minLength: 1, maxLength: 5, allowedInput: "numbers"}},
        height: {required: true, type: "input", rules: {minLength: 1, maxLength: 5, allowedInput: "numbers"}}
    }

    useEffect(() => {
        let elm = {target: {name: 'thumbs', value: thumbs}};
        onChangeFormDataInput(elm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thumbs])

    const addNewThumb = () => {
        let newThumb = [...thumbs];
        newThumb = [...newThumb, {prefix: "thb_",folder: formData.upload_folder,action: "resize", width: "500", height: "500"}];
        setThumbs(newThumb);
    };
    const editThumb = (e,index) => {
        if (formFieldsThumbRules.hasOwnProperty(e.target.name)) {
            e.target.value = CheckCustomValuesFields(e, formFieldsThumbRules);
        }
        let newThumb = [...thumbs];
        newThumb[index] = {...newThumb[index], [e.target.name]: e.target.value};
        setThumbs(newThumb);
    };
    const removeThumb = (i) => {
        let newThumb = [...thumbs];
        newThumb.splice(i, 1);
        setThumbs(newThumb);
    };


    return (
        <>
            <form action="">
                <FormTitle formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                {/*<FormVariable formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>*/}
                <FormColumn formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormWidth formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormRequired formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                <FormFileType formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormFileSize formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                <div className="form-group">
                    <label htmlFor="image_webp">WebP support:</label>
                    <div className="form-group-input">
                        <select value={formData.image_webp} className="form-select" name="image_webp" id="image_webp" onChange={(e) => onChangeFormDataInput(e)}>
                            <option value="none">Disabled</option>
                            <option value="convert">Convert to WebP</option>
                            <option value="add">Add WebP</option>
                        </select>
                    </div>
                </div>
                <AdvancedSettings>
                    <FormDescription formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                    <FormVarLength formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormCustomClass formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                </AdvancedSettings>
                <h3><i className="fa-solid fa-image"></i> Original image settings:</h3>
                <FormUploadFolder formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                <div className="admikoToolBoxImageSettings">
                    <div className="form-group">
                        <label htmlFor="image_action">Action:</label>
                        <div className="form-group-input">
                            <select value={formData.image_action} className="form-select" name="image_action" id="image_action" onChange={(e) => onChangeFormDataInput(e)}>
                                <option value="resize">Resize</option>
                                <option value="crop">Crop</option>
                                <option value="none">No action</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="image_width">Width:</label>
                        <div className="form-group-input">
                            <input className="form-input" autoComplete="off" name="image_width" id="image_width" placeholder="Max width" value={formData.image_width} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                            <p className="ak-admiko-form-error">Required!</p>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="image_height">Height:</label>
                        <div className="form-group-input">
                            <input className="form-input" autoComplete="off" name="image_height" id="image_height" placeholder="Max height" value={formData.image_height} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                            <p className="ak-admiko-form-error">Required!</p>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-group-input">
                        <button type="button" className="from-button button-submit" onClick={(e) => addNewThumb(e)}>
                            <span className="button-text"><i className="fa-solid fa-images"></i> Create a new smaller image from the original.</span>
                        </button>
                    </div>
                </div>

                {formData.thumbs.map((element, index) => (
                    <ImageThumbs key={index} editThumb={editThumb} index={index} removeThumb={removeThumb}
                                 ElementData={element} totalItems={formData.thumbs.length}

                    />
                ))}

                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                <FormCloseButton closeForm={(e) => closeForm(e)}/>
            </form>
        </>
    );

}


export const ImageThumbs = (props) => {
    /*set props*/
    const index = props.index;
    const ElementData = props.ElementData;
    const editThumb = props.editThumb;
    const removeThumb = props.removeThumb;

    return (
        <>
            <div className="admikoToolBoxImageThumb">
                <div className="admikoToolBoxImageThumbHeader">
                    <h4><i className="fa-solid fa-image"></i> Thumbnail image settings:</h4> <div className="mouse-pointer" onClick={() => removeThumb(index)}><i className="fa-solid fa-trash"></i></div>
                </div>
                <div className="admikoToolBoxImageThumbSettings">
                    <div className="form-group">
                        <label htmlFor={`"prefix"-${index}`}>Prefix:</label>
                        <div className="form-group-input">
                            <input className="form-input" autoComplete="off" name="prefix" id={`"prefix"-${index}`} placeholder="Enter image prefix" value={ElementData.prefix} type="text" onChange={(e) => editThumb(e,index)}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor={`"folder"-${index}`}>Upload folder:</label>
                        <div className="form-group-input">
                            <input className="form-input" autoComplete="off" name="folder" id={`"folder"-${index}`} placeholder="Enter upload folder" value={ElementData.folder} type="text" onChange={(e) => editThumb(e,index)}/>
                            <p className="ak-admiko-form-error">Required!</p>
                        </div>
                    </div>
                </div>
                <div className="admikoToolBoxImageThumbAction">
                    <div className="form-group">
                        <label htmlFor={`"action"-${index}`}>Action:</label>
                        <div className="form-group-input">
                            <select value={ElementData.action} className="form-select" name="action" id={`"action"-${index}`} onChange={(e) => editThumb(e,index)}>
                                <option value="resize">Resize</option>
                                <option value="crop">Crop</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor={`"width"-${index}`}>Width:</label>
                        <div className="form-group-input">
                            <input className="form-input" autoComplete="off" name="width" id={`"width"-${index}`} placeholder="Max width" value={ElementData.width} type="text" onChange={(e) => editThumb(e,index)}/>
                            <p className="ak-admiko-form-error">Required!</p>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor={`"height"-${index}`}>Height:</label>
                        <div className="form-group-input">
                            <input className="form-input" autoComplete="off" name="height" id={`"height"-${index}`} placeholder="Max height" value={ElementData.height} type="text" onChange={(e) => editThumb(e,index)}/>
                            <p className="ak-admiko-form-error">Required!</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}