import React from "react";
import {FormInfo, Required} from "./Partials/Partials";
import {EditBox} from "../FormContentStart";

export const CheckBox = (props) => {
    /*set props*/
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const editElement = props.editElement;

    return (
        <>
            <div className={`ak-form-group ak-w-${ElementData.width} js-form-elements js-admiko-sort-me-container ${props.element_id === ElementData.idu ? "selected" : ""}`} data-id={ElementData.idu}>
                <div className={`ak-form-group-box`} onClick={() => editElement(ElementData.idu)}>
                    <div className="ak-form-label">
                        <label>{ElementData.title}<Required ElementData={ElementData}/></label>
                    </div>
                    {(ElementData.checkbox_toggle === 1) ? (
                        <>
                            <div className="ak-form-input ak-form-input-checkbox-toggle">
                                <div>
                                    <label>
                                        <input type="checkbox" value="" className="sr-only peer" checked={ElementData.default === "checked"} onChange={(e) => e.preventDefault()}/>
                                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                    </label>
                                </div>
                                <FormInfo ElementData={ElementData}/>
                            </div>

                        </>
                    ) : (
                        <>
                            <div className="ak-form-input ak-form-input-checkbox">
                                <label className="label-checkbox">
                                    <input type="checkbox" className="form-checkbox" name="checkbox" value="1" checked={ElementData.default === "checked"} onChange={(e) => e.preventDefault()}/>
                                </label>
                                <FormInfo ElementData={ElementData}/>
                            </div>
                        </>
                    )}
                </div>
                <EditBox position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement} idu={ElementData.idu}/>
            </div>
        </>
    );

}