import React from "react";

export const FormElementDirection = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="element_direction">Items direction:</label>
            <div className="form-group-input">
                <select value={formData.element_direction} className="form-select" name="element_direction" id="element_direction" onChange={(e) => onChangeFormDataInput(e)}>
                    <option value="vertical">Vertical</option>
                    <option value="horizontal">Horizontal</option>
                </select>
            </div>
        </div>
    );

}