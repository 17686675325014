import React, {Component} from 'react';

import {TabSortMenuItem} from "./TabSortMenuItem";
import {TabSortMenuFolder} from "./TabSortMenuFolder";
import {rootSetError} from "../../../Helper/RootVar/Error/Error";
import {apiSortMenu} from "../../../Api/useMenu";

class TabSortMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {data: []};

        // this._w = window;
        // this._b = document.body;
        // this._d = document.documentElement;

        // this.stopX = true;
        // this.stopY = true;
        //this.scrollInterval = false;
        this.isScrollingUp = false;
        this.isScrollingDown = false;
        this.scrollInterval = "";

        this.ElementDragged = false;
        this.ElementDraggedIsRoot = false;

        this.ElementDraggingActive = false;
        this.ElementDraggedClick = {};
        this.ElementDraggedClone = null;
        this.SortableContainers = [];
        this.SortableSubContainers = [];
        this.Container = null;
        this.Element = false;
        this.isSwaped = false;
        this.DraggedItem = false;
        this.OverItem = false;

        this.EmptyChild = document.createElement('div');
        this.EmptyChild.className = "empty-folder js-empty-folder js-admiko-menu-item-detect";
        this.EmptyChild.innerHTML = 'Drag & drop page.';

    }

    //async componentDidMount() { }
    componentDidMount() {
        this.setState({data: this.props.rootLeftMenuAll});
        this.shouldComponentUpdate(1);
    }

    shouldComponentUpdate(stopUp = 0) {
        if (stopUp === 1) {
            return false;
        } else {
            return true;
        }
    }

    saveMenu = () => {

        const myElements = document.getElementsByClassName('ak-admiko-menu-item');
        let newList = {
            sortList: []
        };

        for (let i = 0; i < myElements.length; i++) {
            let newId = myElements[i].dataset.id;
            if (newId) {
                if (myElements[i].dataset.folder && myElements[i].dataset.folder !== newId) {
                    newId = newId + '-ak-' + myElements[i].dataset.folder;
                }
                newList.sortList.push(newId)
            }
        }

        (async () => {

            const response = await apiSortMenu(newList);
            if (response.success === true) {

            } else {
                rootSetError(response);
            }
        })()
    };

    render() {
        return (<>
            <div className="admiko-action-menu-body admiko-ak-grow ak-admiko-pre-scroll">
                <div className="ak-admiko-add-scroll" id="js-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        {this.state.data ?
                            <>
                                <div className={`admiko-action-menu-body-action sort-menu`}>
                                    <h3>Sidebar content:</h3>
                                    <div className="ak-admiko-menu-sort js-sortable" id="ak-admiko-menu-sort" onMouseDown={this.dragMouseDown} onMouseMove={this.dragMouseMove} onMouseUp={this.dragMouseUp}>
                                        {this.state.data.map((item) => {
                                            return item.type === "container" ?
                                                <TabSortMenuFolder key={item.idu} item={item} ikey={item.idu} moveUp={this.moveUp} moveDown={this.moveDown}/>
                                                :
                                                <TabSortMenuItem key={item.idu} item={item} moveUp={this.moveUp} moveDown={this.moveDown}/>
                                        })}
                                    </div>
                                </div>
                            </>
                            :
                            <><p>Loading...</p></>}
                    </div>
                </div>
            </div>
        </>)



    }
    moveUp = (e) => {
        e.preventDefault();
        let currentItem = e.target.closest(".js-admiko-menu-item-detect");
        let current = e.target.closest(".js-admiko-menu-item-detect");
        let currentIndex = [...current.parentNode.children].indexOf(current);
        let prevEl = current.previousElementSibling;
        let allowInFolder = true;

        if(current.classList.contains('menu-header')){
           /*prevent moving inide folder*/
            allowInFolder = false;
        }

        if (current.closest(".js-folder-header") && current.closest(".js-sortable-folder")) {
            /*move folder*/
            current = current.closest(".js-sortable-folder");
            prevEl = current.previousElementSibling;
            currentIndex = [...current.parentNode.children].indexOf(current);
            if(prevEl !== null && currentIndex > 0){
                prevEl.before(current);
            }
        } else if (allowInFolder && prevEl !== null && prevEl.classList.contains('js-sortable-folder') && !current.closest(".js-sortable-folder")) {
            /*move inside folder*/
            if(currentIndex > 0){
                prevEl.querySelector('.js-droppable-folder').querySelector('.js-empty-folder').before(current);
            }
        } else  if (current.closest(".js-sortable-folder") && currentIndex === 0) {
            /*move outside folder*/
            current.closest(".js-sortable-folder").before(current);
        } else {
            /*move normal*/
            if(prevEl !== null && currentIndex > 0){
                prevEl.before(current);
            }
        }

        this.emptyFolderAction(e,currentItem);
    }

    moveDown = (e) => {
        e.preventDefault();
        let currentItem = e.target.closest(".js-admiko-menu-item-detect");
        let current = e.target.closest(".js-admiko-menu-item-detect");
        let currentIndex = [...current.parentNode.children].indexOf(current);
        let totalChildsMain = e.target.closest(".js-sortable").childElementCount;

        let nextEl = current.nextElementSibling;
        let allowInFolder = true;

        if(current.classList.contains('menu-header')){
            /*prevent moving inide folder*/
            allowInFolder = false;
        }

        if (current.closest(".js-folder-header") && current.closest(".js-sortable-folder")) {
            /*move folder*/
            current = current.closest(".js-sortable-folder");
            nextEl = current.nextElementSibling;
            currentIndex = [...current.parentNode.children].indexOf(current);
            if(nextEl !== null && ((currentIndex+1) < totalChildsMain)){
                nextEl.after(current);
            }
        } else if (allowInFolder && nextEl !== null && nextEl.classList.contains('js-sortable-folder') && !current.closest(".js-sortable-folder")) {
            /*move inside folder*/
            if((currentIndex+1) < totalChildsMain){
                nextEl.querySelector('.js-droppable-folder').prepend(current);
            }
        } else if (current.closest(".js-sortable-folder")) {
            /*move outside folder*/
            if(!nextEl.classList.contains('js-empty-folder') && (currentIndex+1) < (totalChildsMain-1)){
                nextEl.after(current);
            } else {
                current.closest(".js-sortable-folder").after(current);
            }
        } else {
            /*move normal*/
            if(nextEl !== null && ((currentIndex+1) < totalChildsMain)){
                nextEl.after(current);
            }
        }

        this.emptyFolderAction(e,currentItem);
    }

    emptyFolderAction = (e,currentItem) => {

        if (currentItem.closest('.js-sortable-folder') && !currentItem.classList.contains('js-folder-header')) {
            currentItem.dataset.folder = currentItem.closest('.js-sortable-folder').dataset.id;
        } else {
            currentItem.dataset.folder = '';
        }

        let folders = e.target.closest(".js-sortable").querySelectorAll(".js-droppable-folder");
        for (let a = 0; a < folders.length; ++a) {
            if (folders[a].children.length === 1) {
                folders[a].querySelector('.js-empty-folder').style['display'] = "block";
            } else {
                folders[a].querySelector('.js-empty-folder').style['display'] = "none";
            }
        }

        this.saveMenu();
    }


    dragMouseDown = (e) => {
        e.stopPropagation();
        if (e.target.closest('.ak-admiko-menu-item').closest(".drag-clone-element")) {
            this.trashDragItem();
            return;
        }
        this.ElementDragged = e.target.closest('.ak-admiko-menu-item');

        this.ElementDraggedToClone = e.target.closest('.ak-admiko-menu-item');
        if (e && e.target && (e.target.classList.contains('fa-up-down') || e.target.classList.contains('ak-admiko-menu-action-move')) && this.ElementDragged) {
            e.preventDefault();
            this.ElementDragged.closest(".ak-admiko-add-scroll-fix").style["height"] = this.ElementDragged.closest(".ak-admiko-add-scroll-fix").offsetHeight + 50 + 'px';
            this.ElementDragged.closest(".ak-admiko-add-scroll-fix").style["overflow-y"] = 'hidden';

            this.SortableContainers = document.querySelectorAll(".js-admiko-menu-item-detect");

            this.SortableSubContainers = document.querySelectorAll(".js-droppable-folder");
            if (e.target.closest('.js-droppable-folder') === null && e.target.closest('.js-sortable-folder')) {
                this.ElementDraggedIsRoot = true;
                this.ElementDraggedToClone = e.target.closest('.js-sortable-folder');
            } else if (e.target.closest('.menu-header')) {
                this.ElementDraggedIsRoot = true;
            }

            this.ElementDraggingActive = true;
            this.ElementDraggedClick = this.getPoint(e);
            this.makeDragItem(this.ElementDraggedToClone);
        } else {
            this.trashDragItem();
        }
    }

    dragMouseMove = (e) => {
        e.stopPropagation();
        if(this.ElementDraggingActive === true){
            this.scrollPage(e);
        }
        if (this.ElementDraggedClone && this.ElementDraggingActive) {
            e.preventDefault();
            let point = this.getPoint(e);
            let skip = 0;
            this.OverItem = false;
            this.DraggedItem = this.ElementDragged;
            this.moveItem(this.ElementDraggedClone, (point.x - this.ElementDraggedClick.x), (point.y - this.ElementDraggedClick.y));

            // keep an eye for other sortable lists and switch over to it on hover
            for (let a = 0; a < this.SortableContainers.length; ++a) {
                let subContainer = this.SortableContainers[a];
                if (this.onTop(subContainer, point.x, point.y)) {
                    this.Element = subContainer;
                    this.OverItem = subContainer;
                }
            }
            if (this.Element.dataset.id === this.ElementDragged.dataset.id) {
                return;
            }
            /*root elements*/
            if (this.ElementDraggedIsRoot && this.Element.closest(".js-sortable-folder") && this.Element.closest('.js-droppable-folder') === null) {
                this.OverItem = this.Element.closest(".js-sortable-folder");
            }
            if (this.ElementDraggedIsRoot && this.Element.closest('.js-droppable-folder')) {
                skip = 1;
            }
            /*set drag for whole folder*/
            if (this.ElementDragged.closest(".js-sortable-folder") && this.ElementDragged.closest('.js-droppable-folder') === null) {
                this.DraggedItem = this.ElementDragged.closest(".js-sortable-folder");
            }
            /*end root elements*/

            /*over folder title*/
            if (this.Element.closest(".js-sortable-folder") && this.Element.closest('.js-droppable-folder') === null) {
                this.OverItem = this.Element.closest(".js-sortable-folder");
            }

            if (skip === 0 && this.DraggedItem && this.OverItem) {
                this.swapItems(this.DraggedItem, this.OverItem);
            }
        }
    }

    scrollPage = (event)=> {
        let myDiv = document.getElementById('js-scroll');
        let mouseY = event.clientY;
        let position =myDiv.getBoundingClientRect();
        const scrollSpeed = 5;

        if(position.top+40 > mouseY && myDiv.scrollTop > 0){
            this.isScrollingUp = true;
            clearInterval(this.scrollInterval);
            this.scrollInterval = setInterval(function() {
                myDiv.scrollTop -= scrollSpeed;
            }, 10);
        } else if(position.bottom-20<mouseY && (myDiv.scrollHeight-myDiv.clientHeight)>myDiv.scrollTop){
            this.isScrollingDown = true;
            clearInterval(this.scrollInterval);
            this.scrollInterval = setInterval(function() {
                myDiv.scrollTop += scrollSpeed;
            }, 10);
        } else {
            clearInterval(this.scrollInterval);
            this.isScrollingUp = false;
            this.isScrollingDown = false;
        }
    }

    dragMouseUp = (e) => {
        e.stopPropagation();

        clearInterval(this.scrollInterval);
        this.isScrollingUp = false;
        this.isScrollingDown = false;

        if (this.ElementDraggedClone && this.ElementDraggingActive && this.isSwaped) {
            this.isSwaped = false;
            this.trashDragItem()
            this.saveMenu();
        }
        this.trashDragItem()

    }


    getPoint = (e) => {
        let scrollX = Math.max(0, window.scrollX || document.documentElement.scrollLeft || document.body.scrollLeft || 0) - (document.documentElement.clientLeft || 0),
            scrollY = Math.max(0, window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0) - (document.documentElement.clientTop || 0),
            pointX = e ? (Math.max(0, e.pageX || e.clientX || 0) - scrollX) : 0,
            pointY = e ? (Math.max(0, e.pageY || e.clientY || 0) - scrollY) : 0;
        return {x: pointX, y: pointY};
        //
        // let scrollX = Math.max(0, this._w.pageXOffset || this._d.scrollLeft || this._b.scrollLeft || 0) - (this._d.clientLeft || 0),
        //     scrollY = Math.max(0, this._w.pageYOffset || this._d.scrollTop || this._b.scrollTop || 0) - (this._d.clientTop || 0),
        //     pointX = e ? (Math.max(0, e.pageX || e.clientX || 0) - scrollX) : 0,
        //     pointY = e ? (Math.max(0, e.pageY || e.clientY || 0) - scrollY) : 0;
        //
        // return {x: pointX, y: pointY};
    }

    makeDragItem = (item) => {

        this.trashDragItem();
        this.ScrollContainer = {x: item.closest('.ak-admiko-add-scroll').scrollLeft, y: item.closest('.ak-admiko-add-scroll').scrollTop};

        this.ElementDraggedClone = document.createElement(item.tagName);
        this.ElementDraggedClone.className = item.className;
        this.ElementDraggedClone.classList.add("drag-clone-element");
        this.ElementDraggedClone.innerHTML = item.innerHTML;
        this.ElementDraggedClone.style["position"] = "absolute";
        this.ElementDraggedClone.style["z-index"] = "999";
        this.ElementDraggedClone.style["left"] = (item.offsetLeft || 0) + "px";
        this.ElementDraggedClone.style["top"] = (item.offsetTop || 0) - this.ScrollContainer.y + "px";
        this.ElementDraggedClone.style["width"] = (item.offsetWidth || 0) + "px";

        item.parentNode.appendChild(this.ElementDraggedClone);

        item.classList.add("active");
    }

    trashDragItem = () => {

        if (this.ElementDraggedClone && this.ElementDragged) {
            this.ElementDragged.closest(".ak-admiko-add-scroll-fix").style["height"] = 'auto';
            this.ElementDragged.closest(".ak-admiko-add-scroll-fix").style["overflow-y"] = 'unset';
            this.ElementDragged.classList.remove("active");
            this.ElementDraggedToClone.classList.remove("active");
            this.ElementDraggedClone.remove();
            this.ElementDragged = false;
            this.ElementDraggedClone = false;
            this.ElementDraggingActive = false;
            this.ElementDraggedIsRoot = false;
            this.DraggedItem = false;
            this.OverItem = false;

            for (let a = 0; a < this.SortableSubContainers.length; ++a) {
                if (this.SortableSubContainers[a].children.length === 1) {
                    this.SortableSubContainers[a].querySelector('.js-empty-folder').style['display'] = "block";
                } else if (this.SortableSubContainers[a].children.length > 1 && this.SortableSubContainers[a].querySelector('.empty-folder')) {
                    this.SortableSubContainers[a].querySelector('.js-empty-folder').style['display'] = "none";
                }
            }
        }
    }

    moveItem = (item, x, y) => {
        //item.style["-webkit-transform"] = "translateX( " + x + "px ) translateY( " + y + "px )";
        //item.style["-moz-transform"] = "translateX( " + x + "px ) translateY( " + y + "px )";
        //item.style["-ms-transform"] = "translateX( " + x + "px ) translateY( " + y + "px )";
        //item.style["transform"] = "translateX( " + x + "px ) translateY( " + y + "px )";
        item.style["-webkit-transform"] = "translateY( " + y + "px )";
        item.style["-moz-transform"] = "translateY( " + y + "px )";
        item.style["-ms-transform"] = "translateY( " + y + "px )";
        item.style["transform"] = "translateY( " + y + "px )";
    }

    onTop = (item, x, y) => {
        let box = item.getBoundingClientRect(),
            isx = (x > box.left && x < (box.left + box.width)),
            isy = (y > box.top && y < (box.top + box.height));
        return (isx && isy);
    }

    swapItems = (item1, item2) => {
        let parent1 = item1.parentNode,
            parent2 = item2.parentNode;

        if (parent1 !== parent2) {
            // move to new list
            // if(parent2.children.length === 0){
            //     parent2.append(item1);
            // } else {

            // }

            // this.SortableSubContainers = document.querySelectorAll(".js-droppable-folder");
            //
            // for (var a = 0; a < this.SortableSubContainers.length; ++a) {
            //
            //     if (this.SortableSubContainers[a].children.length === 0) {
            //         this.SortableSubContainers[a].appendChild(this.EmptyChild.cloneNode(true));
            //     } else if (this.SortableSubContainers[a].children.length > 1 && this.SortableSubContainers[a].querySelector('.empty-folder')) {
            //         this.SortableSubContainers[a].querySelector('.empty-folder').remove();
            //     }
            // }

            parent2.insertBefore(item1, item2);

            if (parent2.classList.contains("js-droppable-folder")) {
                if (parent2.children.length > 1) {
                    parent2.querySelector('.js-empty-folder').style['display'] = "none";
                } else {
                    parent2.querySelector('.js-empty-folder').style['display'] = "block";
                }
            }
            if (parent1.classList.contains("js-droppable-folder")) {
                if (parent1.children.length > 2) {
                    parent1.querySelector('.js-empty-folder').style['display'] = "none";
                } else {
                    parent1.querySelector('.js-empty-folder').style['display'] = "block";
                }
            }


        } else {
            // sort is same list
            let temp = document.createElement("div");
            parent1.insertBefore(temp, item1);
            parent2.insertBefore(item1, item2);
            parent1.insertBefore(item2, temp);
            parent1.removeChild(temp);
        }


        if (item1.closest('.js-sortable-folder')) {
            item1.dataset.folder = item1.closest('.js-sortable-folder').dataset.id;
        } else {
            item1.dataset.folder = '';
        }
        this.isSwaped = true;
    }


}

export default TabSortMenu;