import React, {useEffect} from "react";
import {FormTitle} from "./Partials/FormTitle";
import {FormColumn} from "./Partials/FormColumn";
import {FormDefault} from "./Partials/FormDefault";
import {FormPlaceholder} from "./Partials/FormPlaceholder";
import {FormDescription} from "./Partials/FormDescription";
import {FormRequired} from "./Partials/FormRequired";
import {FormSubmitButton} from "../../../../../Helpers/FormSubmitButton";
import {FormCloseButton} from "../../../../../Helpers/FormCloseButton";
import {FormCustomClass} from "./Partials/FormCustomClass";
import {FormWidth} from "./Partials/FormWidth";
import {AdvancedSettings} from "../../../../../Helpers/AdvancedSettings";
import {rootCUSTOM_CLASS_MAX_LENGTH, rootTABLE_COLUMN_MAX_LENGTH} from "../../../../../../Helper/RootVar/Contstant";
import {FormElementHeight} from "./Partials/FormElementHeight";
import {FormDisableAKJS} from "./Partials/FormDisableAKJS";

export const TextArea = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormData = props.onChangeFormData;
    const sendFormData = props.sendFormData;
    const submitButton = props.submitButton;
    const closeForm = props.closeForm;
    const checkSaveButtonOnChildStart = props.checkSaveButtonOnChildStart;
    useEffect(() => {
        checkSaveButtonOnChildStart(formFieldsRules);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formFieldsRules = {
        title: {required: true, type: "input", rules: {minLength: 1, maxLength: 64}},
        //elm_name: {required: true, type: "input", rules: {minLength: 1, maxLength: 64, allowedInput: "upper_lower_dash_numbers"}},
        table_column: {required: true, type: "input", rules: {minLength: 1, maxLength: rootTABLE_COLUMN_MAX_LENGTH, allowedInput: "upper_lower_dash_numbers_protect_start_end"}},
        required: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        custom_class: {required: false, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_CLASS_MAX_LENGTH, allowedInput: "upper_lower_dash_minus_numbers_space"}},
        element_height: {required: true, type: "input", rules: {minLength: 1, maxLength: 3, allowedInput: "numbers"}},
        disable_js: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        textarea_editor: {required: false, type: "select"},
        db_type: {required: false, type: "select"},
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules)
    };
    return (
        <>
            <form action="">
                <FormTitle formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                {/*<FormVariable formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>*/}
                <FormColumn formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormWidth formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormRequired formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                {formData.can_edit === 1 &&
                    <div className="form-group">
                        <label htmlFor="textarea_editor">Text Editor:</label>
                        <div className="form-group-input">
                            <select value={formData.textarea_editor} className="form-select" name="textarea_editor" id="textarea_editor" onChange={(e) => onChangeFormDataInput(e)}>
                                <option value="simple">Simple</option>
                                <option value="advanced">Advanced</option>
                                <option value="none">Disable</option>
                            </select>
                        </div>
                    </div>
                }
                <FormDefault formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                <AdvancedSettings>
                    <FormDescription formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormPlaceholder formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    {formData.can_edit === 1 &&
                        <div className="form-group">
                            <label htmlFor="db_type">Database Type:</label>
                            <div className="form-group-input">
                                <select value={formData.db_type} className="form-select" name="db_type" id="db_type" onChange={(e) => onChangeFormDataInput(e)}>
                                    <option value="text">TEXT</option>
                                    <option value="longText">LONGTEXT</option>
                                    <option value="mediumText">MEDIUMTEXT</option>
                                </select>
                            </div>
                        </div>
                    }
                    <FormElementHeight formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormCustomClass formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormDisableAKJS formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                </AdvancedSettings>
                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                <FormCloseButton closeForm={(e) => closeForm(e)}/>
            </form>
        </>
    );

}