import React from "react";

export const FormStep = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="default">Step:</label>
            <div className="form-group-input">
                <input className="form-input" autoComplete="off" name="step" id="step" placeholder="Enter step value" value={formData.step} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                <p className="ak-admiko-form-error">Required!</p>
            </div>
        </div>
    );

}