import React, {useEffect, useRef, useState} from "react";
import {useIsMountedRef} from "../../../../Helper/isMounted";
import {FormSubmitButton} from "../../../Helpers/FormSubmitButton";
import {FormCloseButton} from "../../../Helpers/FormCloseButton";
import {rootSetError} from "../../../../Helper/RootVar/Error/Error";
import {onChangeFormDataGlobal} from "../../../../Helper/Functions";
import {apiAddChildPage} from "../../../../Api/useTable";
import {useNavigate, useParams} from "react-router-dom";
import {rootBuilderLink} from "../../../../Helper/RootVar/Contstant";

/*ADD new elements to a FORM*/
export const ChildPage = (props) => {
    const {url_menu_id = false, url_page_id = false, url_table_id = false} = useParams();
    const tableLinkLoad = `${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}/t/${url_table_id}`;
    let navigate = useNavigate();
    /*set props*/
    const form_idu = props.form_idu;

    const title = props.title;

    const isMountedRef = useIsMountedRef();
    const inputFocus = useRef(null);
    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSlideIn(true);
        }, 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closeForm = () => {
        hideBack();
    };

    const hideBack = () => {
        setSlideIn(false);
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            navigate(`${tableLinkLoad}`);
        }, 200);
    }

    /*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");
    /*form js*/
    const formFields = {type: 'page_crud', title: "", table_idu: form_idu};
    const formFieldsRules = {
        title: {required: false, type: "input", rules: {minLength: 1, maxLength: 64}},
        type: {required: false, type: "select"}        
    }
    const [formData, setFormData] = useState(formFields);

    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };
    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiAddChildPage({...formData});
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };

    return (
        <>
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll ${slideIn ? "admiko-show" : ""}`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                            <div className="info">
                                <h2><i className={`${title.icon}`}></i>{title.title}</h2>
                                <p>{title.info}</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-action-menu-body">
                            <div className={`admiko-action-menu-body-action add-menu active`}>
                                <h3>Add a new page:</h3>
                                <div>
                                    <form action="">
                                        <div className="form-group">
                                            <label htmlFor="title">Name:</label>
                                            <div className="form-group-input">
                                                <input className="form-input" ref={inputFocus} autoComplete="off" name="title" id="title" placeholder="Enter name" value={formData.title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="type">Page type:</label>
                                            <div className="form-group-input">
                                                <select value={formData.type} className="form-select" name="type" id="type" onChange={(e) => onChangeFormData(e)}>
                                                    <option value="page_crud">Table/Form page</option>
                                                    <option value="page_empty">Empty page</option>
                                                </select>
                                            </div>
                                        </div>
                                        <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                        {formData.type === 'page_crud' &&
                                            <div className="form-group-helper">
                                                <h5>Table/Form page</h5>
                                                <p>Standard CRUD page with a table. Recommended in most cases.</p>
                                            </div>
                                        }
                                        {formData.type === 'page_empty' &&
                                            <div className="form-group-helper">
                                                <h5>Empty page</h5>
                                                <p>Create an empty page and add content.</p>
                                            </div>
                                        }
                                        <br/>
                                        <FormCloseButton closeForm={(e) => closeForm(e)}/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}