import React, {useEffect, useState} from "react";
import {FormSubmitButton} from "../../Helpers/FormSubmitButton";
import {apiAddNewProject, apiDeleteProject, apiEditProject, apiGetKey, apiGetProjects} from "../../Api/useCallApi";
import {useIsMountedRef} from "../../Helpers/isMounted";
import {CheckFormOnChange, CheckFormOnSubmit} from "../../Helpers/CheckFormFields";
import {Link, useNavigate, useParams} from "react-router-dom";
import {setRootPageInfoDirect} from "../../Global/PageInfo";
import {FormDeleteButton} from "../../Helpers/FormDeleteButton";
import {appGlobalSettings, appRootProjects, setAppGlobalSettingsDirect, setAppRootProjectsDirect} from "../../Global/Projects";
import {FormMessage} from "../LayoutParts/FormMessage";
import {appRootClients, setAppRootClientsDirect} from "../../Global/Clients";
import {appRootFrameworks, setAppRootFrameworksDirect} from "../../Global/Framework";
import {SettingsForm} from "./SettingsForm";

export const ProjectForm = () => {

    const {id = false, url_install = false} = useParams();
    let navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("project");
    const [installTab, setInstallTab] = useState("default");

    const isMountedRef = useIsMountedRef();
    /*send button*/
    const [submitButtonTimer, setSubmitButtonTimer] = useState(0);
    const [deleteProgress, setDeleteProgress] = useState(false);
    const [submitButton, setSubmitButton] = useState({disable: false, state: ""});
    /*end send button*/

    const formFields = {
        fields: {idu: "", name: "", platform: "", client: "none"}
    }
    const formErrorData = {
        error: {name: false}
    }
    const formFieldsRules = {
        name: {required: true, type: "input", rules: {minLength: 1, maxLength: 50}},
        client: {required: false, type: "select"},
        platform: {required: false, type: "select"},
    }

    const [formData, setFormData] = useState(formFields);
    const [fieldError, setFieldError] = useState(formErrorData);
    const [formErrorMessage, setFormErrorMessage] = useState(false);
    const [formInfoMessage, setFormInfoMessage] = useState(false);
    const [projectInfo, setProjectInfo] = useState(false);
    const [apiKey, setApiKey] = useState(false);

    const [formSettingsData, setFormSettingsData] = useState(false);


    useEffect(() => {
        if (id !== false) {
            setRootPageInfoDirect({title: "My Projects", subtitle: "Update project settings", activeMenu: "projects", breadcrumbs: [{link: "/", title: "Project"}, {link: "/", title: projectInfo.name}]});
        } else {
            setRootPageInfoDirect({title: "My Projects", subtitle: "Add new project.", activeMenu: "projects", breadcrumbs: [{link: "/", title: "Project"}, {link: "/project/add", title: "Add"}]});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectInfo])

    useEffect(() => {
        if (!Array.isArray(appRootProjects)) {
            (async () => {
                const response = await apiGetProjects();
                if (response.success === true) {
                    setAppRootProjectsDirect(response.return.projects);
                    setAppGlobalSettingsDirect(response.return.global_settings);
                    setAppRootClientsDirect(response.return.clients);
                    setAppRootFrameworksDirect(response.return.frameworks);
                    /*local*/
                    setProjectData(response.return.projects);
                } else {
                    navigate("/", {replace: true});
                }
            })()
        } else {
            setProjectData(appRootProjects);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (activeTab === "install" && id !== false) {
            (async () => {
                const response = await apiGetKey({idu: id});
                if (response.success === true) {
                    setApiKey(response.return.key);
                    setProjectData(appRootProjects);
                } else {
                    //navigate("/", {replace: true});
                }
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    useEffect(() => {
        if (url_install === "install") {
            setActiveTab('install');
        }
    }, [url_install])


    const setProjectData = (ProjectsList) => {
        if (id !== false && Array.isArray(ProjectsList) && ProjectsList.length > 0) {
            const filtered = ProjectsList.filter(obj => {
                return obj.idu === id;
            });
            if (filtered.length) {
                setFormData({
                    fields: {
                        idu: filtered[0]['idu'],
                        name: filtered[0]['name'],
                        platform: filtered[0]["platform"],
                        client: filtered[0]["client"],
                    }
                })
                setFormSettingsData({
                    soft_delete: filtered[0]["soft_delete"],
                    auditable_logs: filtered[0]["auditable_logs"],
                    multi_tenancy: filtered[0]["multi_tenancy"],
                    multi_tenancy_team: filtered[0]["multi_tenancy_team"],
                    use_language: filtered[0]["use_language"],
                    user_management: filtered[0]["user_management"],
                    image_width: filtered[0]["image_width"],
                    image_height: filtered[0]["image_height"],
                    image_upload_folder: filtered[0]["image_upload_folder"],
                    file_upload_folder: filtered[0]["file_upload_folder"],
                    checkbox_toggle: filtered[0]["checkbox_toggle"],
                    use_ajax: filtered[0]["use_ajax"],
                    hide_id: filtered[0]["hide_id"],
                    webp: filtered[0]["webp"],
                    page_width: filtered[0]["page_width"],
                    table_width: filtered[0]["table_width"],
                    show_width: filtered[0]["show_width"],
                    form_width: filtered[0]["form_width"],
                    disable_show: filtered[0]["disable_show"],
                })
                setProjectInfo(filtered[0]);
            } else {
                navigate("/", {replace: true});
            }
        } else {
            setFormData(prevFormData => ({
                fields: {...prevFormData.fields, "platform": appRootFrameworks[0].idu}
            }));
            setFormSettingsData(appGlobalSettings)

        }
    };


    const onChangeFormData = (e) => {
        let val;
        if (formFieldsRules.hasOwnProperty(e.target.name)) {
            // = CheckFormOnChange(e, formFieldsRules, submitButton, setSubmitButton);
            val = CheckFormOnChange(e, formFieldsRules, submitButton, setSubmitButton);
        } else {
            val = e.target.value;
        }
        setFormData(prevFormData => ({
            fields: {...prevFormData.fields, [e.target.name]: val}
        }));
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setFormErrorMessage(false);
        setFormInfoMessage(false);
        /*send button*/
        setSubmitButton({...submitButton, disable: true, state: "progress"});

        if (submitButtonTimer) {
            window.clearTimeout(submitButtonTimer);
            setSubmitButtonTimer(0);
        }

        /*end send button*/
        if (CheckFormOnSubmit(formData, fieldError, setFieldError, formFieldsRules, submitButton, setSubmitButton)) {
            setSubmitButton({...submitButton, disable: false, state: ""});
            return;
        }

        let sendFormData = {...formData.fields};
        let response;
        if (id !== false) {
            response = await apiEditProject(sendFormData);
        } else {
            response = await apiAddNewProject(sendFormData);
        }
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {

            setAppRootProjectsDirect(response.return.projects);
            if (id !== false) {
                setProjectData(response.return.projects);
                setSubmitButton({...submitButton, disable: false, state: "saved"});
            } else if (response.return.redirect_to) {
                setAppRootProjectsDirect(response.return.projects);
                // setProjectInfo(response.return.projects);
                //setApiKey(response.return.api_key);
                navigate(`/project/edit/${response.return.redirect_to}/install`, {replace: true});
            }
            // if (response.message === 'updated') {

            //     //setFormInfoMessage("Project updated.");
            // }
            // if (response.message === 'saved') {
            //     //navigate("/", {replace: true});
            // }
            // navigate("/", {replace: true});
        } else {
            /*todo: set error messages*/
            if (response.message === 'check_input') {
                setFormErrorMessage("Please check your input.");
            } else if (response.message === 'plan_limit') {
                setFormErrorMessage("Your account has reached the projects limit. Please upgrade your plan.");
            } else if (response.message === 'plan_expired') {
                navigate(`/plans`, {replace: true});
                // setFormErrorMessage("Your account has reached the projects limit. Please upgrade your plan.");
            } else {
                setFormErrorMessage("Server side error. Please contact support.");
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }

        /*send button*/
        setSubmitButtonTimer(setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }, 3000));
        /*end send button*/
    }

    const deleteRecord = async (e, idu) => {
        e.preventDefault();
        setSubmitButton({...submitButton, disable: true, state: ""});
        setDeleteProgress(true);
        let sendFormData = {idu: idu};
        let response = await apiDeleteProject(sendFormData);

        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setAppRootProjectsDirect(response.return.projects);
            navigate("/", {replace: true});
        } else {
            setDeleteProgress(false);
            setSubmitButton({...submitButton, disable: false, state: ""});
        }
    };

    const actionActiveTab = async (e, tab) => {
        e.preventDefault();
        setActiveTab(tab);
    }
    const updateFormData = (data) => {
        setFormData(prevFormData => ({
            fields: {...prevFormData.fields, ...data}
        }));
    };

    return appRootProjects !== false ? (
            <>
                <div className="ak-form-container">
                    <div className="ak-section">

                        <div className="tabs-group">
                            <div>
                                <ul className="">
                                    <li className="">
                                        <a href="/" draggable="false" className={(activeTab === "project" ? "active" : "")} onClick={(e) => actionActiveTab(e, "project")} aria-current="page">
                                            <svg aria-hidden="true" className="" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                                            </svg>
                                            Project settings
                                        </a>
                                    </li>
                                    <li className="mr-2">
                                        <a href="/" draggable="false" className={(activeTab === "default" ? "active" : "")} onClick={(e) => actionActiveTab(e, "default")}>
                                            <svg aria-hidden="true" className="" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path>
                                            </svg>
                                            Default values
                                        </a>
                                    </li>
                                    {id &&
                                        <li className="mr-2">
                                            <a href="/" draggable="false" className={(activeTab === "install" ? "active" : "")} onClick={(e) => actionActiveTab(e, "install")}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/>
                                                </svg>
                                                Install
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div>
                                {id &&
                                    <>
                                        <FormDeleteButton sendFormData={deleteRecord} id={id} progress={deleteProgress} link={"/"} info="Are you sure you want to delete this project? All of your data will be permanently removed. This action cannot be undone."/>
                                    </>
                                }
                            </div>
                        </div>

                        <div className="ak-section-content">
                            <form>
                                <div className={(activeTab === "project" ? "block" : "hidden")}>
                                    <div className="ak-section-form-content">
                                        <div className="form-group">
                                            <label htmlFor="name">Project name *</label>
                                            <input type="text" autoComplete="off" className={`form-input ${fieldError.error.name ? 'form-input-error' : ''}`} value={formData.fields.name} onChange={(e) => onChangeFormData(e)} name="name" id="name" placeholder="Enter project name"/>
                                            <p className="required-input">Project name is required.</p>
                                        </div>
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-4">
                                            <div className="form-group">
                                                <label htmlFor="platform">Platform</label>
                                                {(appRootFrameworks !== false || Array.isArray(appRootFrameworks)) &&
                                                    <>
                                                        {Array.isArray(appRootFrameworks) && appRootFrameworks.length > 0 ? (
                                                            <>
                                                                <select id="platform" name="platform" className="form-select" value={formData.fields.platform} onChange={(e) => onChangeFormData(e)}>
                                                                    {appRootFrameworks.map((item) => (
                                                                        <option value={item.idu} key={item.idu}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="pb-6 text-sm">
                                                                    Error, please contact support.
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                }

                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="client">Client</label>
                                                {appRootClients === false || !Array.isArray(appRootClients) ? (
                                                    <>
                                                        <Link to="/client/add"><span className="ak-menu-item-icon"><i className="fa-solid fa-plus"></i></span> <span className="ak-menu-item-title">Add a client</span></Link>
                                                    </>
                                                ) : (
                                                    <>
                                                        {Array.isArray(appRootClients) && appRootClients.length > 0 ? (
                                                            <>
                                                                <select id="client" name="client" className="form-select" value={formData.fields.client} onChange={(e) => onChangeFormData(e)}>
                                                                    <option value="none" key="0">Select client</option>
                                                                    {appRootClients.map((item) => (
                                                                        <option value={item.idu} key={item.idu}>{item.name}{item.company && <> - {item.company}</>}</option>
                                                                    ))}
                                                                </select>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="text-sm pt-2">
                                                                    <Link to="/client/add"><span className="ak-menu-item-icon"><i className="fa-solid fa-plus"></i></span> <span className="ak-menu-item-title">Add a client</span></Link>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={(activeTab === "default" ? "block" : "hidden")}>
                                    <div className="ak-section-form-content">
                                        <p className="text-sm text-gray-900 mb-6">
                                            This section allows you to manage the default values and configurations for new pages.
                                            These settings will not affect any previously created pages and will only apply to the pages created after the changes are made.
                                        </p>
                                        <SettingsForm formSettingsData={formSettingsData} updateFormData={updateFormData}/>

                                    </div>
                                </div>
                                {id &&
                                    <div className={(activeTab === "install" ? "block" : "hidden")}>
                                        <div className="ak-section-form-content">
                                            {/*<div className="border-l-4 border-blue-400 bg-blue-50 p-4">*/}
                                            {/*    <div className="flex">*/}
                                            {/*        <div className="flex-shrink-0">*/}
                                            {/*            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">*/}
                                            {/*                <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"/>*/}
                                            {/*            </svg>*/}
                                            {/*        </div>*/}
                                            {/*        <div className="ml-3">*/}
                                            {/*            <p className="text-sm text-blue-700">*/}
                                            {/*                Temporary page that contains detailed installation instructions for our software until the official documentation becomes available.*/}
                                            {/*            </p>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="py-4">
                                                <div className="text-sm font-medium mb-1.5 text-gray-700">Download:</div>
                                                <div className="text-sm text-gray-900">
                                                    <ul className="divide-y divide-gray-200 rounded-md border border-gray-200 overflow-hidden">
                                                        <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm bg-gray-50">
                                                            <div className="flex w-0 flex-1 items-center">
                                                                <svg className="h-5 w-5 flex-shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                    <path fillRule="evenodd" d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z" clipRule="evenodd"/>
                                                                </svg>
                                                                <span className="ml-2 w-0 flex-1 truncate">Admiko v.{projectInfo.admiko_version} - {projectInfo.version}</span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <a href={projectInfo.download_link} rel="noreferrer" className="font-medium text-blue-500 hover:text-blue-600" target="_blank">Download</a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="pb-4">
                                                <div className="text-sm font-medium mb-1.5 text-gray-700">Default admin login after installation:</div>
                                                <div className="text-sm text-gray-900">
                                                    admin@admin.com<br/>
                                                    password
                                                </div>
                                            </div>
                                            <div className="pb-6">
                                                <div className="text-sm font-medium mb-1.5 text-gray-700">Project API key:</div>
                                                <div className="text-sm text-gray-900 bg-gray-50 rounded-md p-4 pr-12 border border-gray-200 relative">
                                                    <div className="break-words w-full">
                                                        {apiKey}
                                                    </div>
                                                    <div className="w-10 absolute right-0 top-1/2 -translate-y-1/2 pr-2 select-none" onClick={() => {navigator.clipboard.writeText(apiKey)}}>
                                                        <div className="border border-gray-200 rounded-md cursor-pointer hover:bg-gray-100 text-gray-500 active:bg-green-50">
                                                            <svg className="m-2 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                <path d="M448 384H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V320c0 35.3-28.7 64-64 64zM64 128h96v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H256c8.8 0 16-7.2 16-16V416h48v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pb-0">
                                                <div className="text-sm font-medium mb-1.5 text-gray-700">
                                                    To install Admiko files, there are several methods available. <br/>Please choose your preferred method:
                                                </div>
                                                <div className="block">
                                                    <div className="border-b border-gray-200 pt-2">
                                                        <nav className="-mb-px flex space-x-4 select-none" aria-label="Tabs">
                                                            <div onClick={() => setInstallTab('default')} className={`${(installTab === "default" ? "border-blue-600 text-blue-700 hover:text-blue-700 hover:border-blue-600" : "text-gray-500 hover:text-gray-700 hover:border-gray-300 border-transparent")} cursor-pointer whitespace-nowrap py-2 px-2 border-b-2 font-medium text-sm`}>Install</div>
                                                            <div onClick={() => setInstallTab('install_page')} className={`${(installTab === "install_page" ? "border-blue-600 text-blue-700 hover:text-blue-700 hover:border-blue-600" : "text-gray-500 hover:text-gray-700 hover:border-gray-300 border-transparent")} cursor-pointer whitespace-nowrap py-2 px-2 border-b-2 font-medium text-sm`}>Use install page</div>
                                                            <div onClick={() => setInstallTab('artisan')} className={`${(installTab === "artisan" ? "border-blue-600 text-blue-700 hover:text-blue-700 hover:border-blue-600" : "text-gray-500 hover:text-gray-700 hover:border-gray-300 border-transparent")} cursor-pointer whitespace-nowrap py-2 px-2 border-b-2 font-medium text-sm`}>Artisan</div>
                                                            <div onClick={() => setInstallTab('composer')} className={`${(installTab === "composer" ? "border-blue-600 text-blue-700 hover:text-blue-700 hover:border-blue-600" : "text-gray-500 hover:text-gray-700 hover:border-gray-300 border-transparent")} cursor-pointer whitespace-nowrap py-2 px-2 border-b-2 font-medium text-sm`}>Composer</div>
                                                        </nav>
                                                    </div>
                                                </div>
                                                <div className={(installTab === "default" ? "block" : "hidden")}>
                                                    <div className="p-6">
                                                        <nav>
                                                            <ol className="overflow-hidden">
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-blue-500" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-500 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-blue-500"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Extract files to your server.</span>
                                                                          <span className="text-sm text-gray-500">Make sure your server meets Laravel requirements.</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <div className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                            <span className="text-sm font-medium text-gray-700">Edit .env file</span>
                                                                            <span className="text-sm text-gray-500">Open .env file, setup your database connection and add project key:</span>
                                                                            <div className="text-sm text-gray-900 bg-gray-50 rounded-md p-4 mt-2 pr-12 border border-gray-200 relative">
                                                                                <div className="break-words w-full">
                                                                                ADMIKO_APP_KEY={apiKey}
                                                                                </div>
                                                                                <div className="w-10 absolute right-0 top-1/2 -translate-y-1/2 pr-2 select-none" onClick={() => {navigator.clipboard.writeText('ADMIKO_APP_KEY='+apiKey)}}>
                                                                                    <div className="border border-gray-200 rounded-md cursor-pointer hover:bg-gray-100 text-gray-500 active:bg-green-50">
                                                                                        <svg className="m-2 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                                            <path d="M448 384H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V320c0 35.3-28.7 64-64 64zM64 128h96v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H256c8.8 0 16-7.2 16-16V416h48v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Create new APP key</span>
                                                                          <span className="text-sm text-gray-500">php artisan key:generate</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Open yourwebsite.test/admin/install</span>
                                                                          <span className="text-sm text-gray-500">Installation will run all migrations for you.</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Login</span>
                                                                          <span className="text-sm text-gray-500">Login to admin with default username and password provided above.</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </nav>
                                                    </div>
                                                </div>
                                                <div className={(installTab === "install_page" ? "block" : "hidden")}>
                                                    <div className="p-6">
                                                        <nav>
                                                            <ol className="overflow-hidden">
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-blue-500" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-500 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-blue-500"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Extract files to your server.</span>
                                                                          <span className="text-sm text-gray-500">Make sure your server meets Laravel requirements.</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Open yourwebsite.test/admin/install</span>
                                                                          <span className="text-sm text-gray-500">Fill form with required information.</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Login</span>
                                                                          <span className="text-sm text-gray-500">Login to admin with default username and password provided above.</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </nav>
                                                    </div>
                                                </div>
                                                <div className={(installTab === "artisan" ? "block" : "hidden")}>
                                                    <div className="p-6">
                                                        <nav>
                                                            <ol className="overflow-hidden">
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-blue-500" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-500 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-blue-500"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Extract files to your server.</span>
                                                                          <span className="text-sm text-gray-500">Make sure your server meets Laravel requirements.</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <div className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                            <span className="text-sm font-medium text-gray-700">Edit .env file</span>
                                                                            <span className="text-sm text-gray-500">Open .env file, setup your database connection and add project key:</span>
                                                                            <div className="text-sm text-gray-900 bg-gray-50 rounded-md p-4 mt-2 pr-12 border border-gray-200 relative">
                                                                                <div className="break-words w-full">
                                                                                ADMIKO_APP_KEY={apiKey}
                                                                                </div>
                                                                                <div className="w-10 absolute right-0 top-1/2 -translate-y-1/2 pr-2 select-none" onClick={() => {navigator.clipboard.writeText('ADMIKO_APP_KEY='+apiKey)}}>
                                                                                    <div className="border border-gray-200 rounded-md cursor-pointer hover:bg-gray-100 text-gray-500 active:bg-green-50">
                                                                                        <svg className="m-2 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                                            <path d="M448 384H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V320c0 35.3-28.7 64-64 64zM64 128h96v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H256c8.8 0 16-7.2 16-16V416h48v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Create new APP key</span>
                                                                          <span className="text-sm text-gray-500">php artisan key:generate</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Migrate and seed database</span>
                                                                          <span className="text-sm text-gray-500">php artisan migrate --path=database/migrations/admin --force</span>
                                                                          <span className="text-sm text-gray-500">php artisan db:seed --class="Database\Seeders\Admin\AdminDatabaseSeeder"</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Delete installation files</span>
                                                                          <span className="text-sm text-gray-500">App\Http\Controllers\Admin\InstallController.php</span>
                                                                          <span className="text-sm text-gray-500">resources/views/admin/install.blade.php</span>
                                                                          <span className="text-sm text-gray-500">routes/admin/routes_public/install.php</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Open yourwebsite.test/admin and login</span>
                                                                          <span className="text-sm text-gray-500">Login to admin with default username and password provided above.</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </nav>
                                                    </div>
                                                </div>
                                                <div className={(installTab === "composer" ? "block" : "hidden")}>
                                                    <div className="p-6">
                                                        <nav>
                                                            <ol className="overflow-hidden">
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-blue-500" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-500 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-blue-500"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Use composer to create new Laravel project</span>
                                                                          <span className="text-sm text-gray-500">composer create-project laravel/laravel ./my_project_name {projectInfo.laravel_version}.*</span>
                                                                          <span className="text-sm text-gray-500">cd my_project_name</span>
                                                                          <span className="text-sm text-gray-500">composer require intervention/image</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <div className="ml-4 flex min-w-0 flex-col pt-1.5 w-full">
                                                                            <span className="text-sm font-medium text-gray-700">Download Admiko files</span>
                                                                            <div className="text-sm text-gray-900">

                                                                                <ul className="divide-y divide-gray-200 rounded-md border border-gray-200 overflow-hidden">
                                                                                    <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm bg-gray-50">
                                                                                        <div className="flex w-0 flex-1 items-center">
                                                                                            <svg className="h-5 w-5 flex-shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                                                <path fillRule="evenodd" d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z" clipRule="evenodd"/>
                                                                                            </svg>
                                                                                            <span className="ml-2 w-0 flex-1 truncate">Admiko v.{projectInfo.admiko_version}</span>
                                                                                        </div>
                                                                                        <div className="ml-4 flex-shrink-0">
                                                                                            <a href={projectInfo.custom_download_link} target="_blank" rel="noreferrer" className="font-medium text-blue-500 hover:text-blue-600">Download</a>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Copy Admiko files to my_project_name</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <div className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                            <span className="text-sm font-medium text-gray-700">Edit .env file</span>
                                                                            <span className="text-sm text-gray-500">Open .env file, setup your database connection and add project key:</span>
                                                                            <div className="text-sm text-gray-900 bg-gray-50 rounded-md p-4 mt-2 pr-12 border border-gray-200 relative">
                                                                                <div className="break-words w-full">
                                                                                    ADMIKO_APP_KEY={apiKey}
                                                                                </div>
                                                                                <div className="w-10 absolute right-0 top-1/2 -translate-y-1/2 pr-2 select-none" onClick={() => {navigator.clipboard.writeText('ADMIKO_APP_KEY='+apiKey)}}>
                                                                                    <div className="border border-gray-200 rounded-md cursor-pointer hover:bg-gray-100 text-gray-500 active:bg-green-50">
                                                                                        <svg className="m-2 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                                            <path d="M448 384H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V320c0 35.3-28.7 64-64 64zM64 128h96v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H256c8.8 0 16-7.2 16-16V416h48v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z"/>
                                                                                        </svg>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Migrate and seed database</span>
                                                                          <span className="text-sm text-gray-500">php artisan migrate --path=database/migrations/admin --force</span>
                                                                          <span className="text-sm text-gray-500">php artisan db:seed --class="Database\Seeders\Admin\AdminDatabaseSeeder"</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Delete installation files</span>
                                                                          <span className="text-sm text-gray-500">App\Http\Controllers\Admin\InstallController.php</span>
                                                                          <span className="text-sm text-gray-500">resources/views/admin/install.blade.php</span>
                                                                          <span className="text-sm text-gray-500">routes/admin/routes_public/install.php</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="relative pb-10">
                                                                    <div className="group relative flex items-start">
                                                                        <span className="flex h-9 items-center" aria-hidden="true">
                                                                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                                                                            <span className="h-2.5 w-2.5 rounded-full bg-gray-300"></span>
                                                                          </span>
                                                                        </span>
                                                                        <span className="ml-4 flex min-w-0 flex-col pt-1.5">
                                                                          <span className="text-sm font-medium text-gray-700">Open yourwebsite.test/admin and login</span>
                                                                          <span className="text-sm text-gray-500">Login to admin with default username and password provided above.</span>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {activeTab === "install" ?
                                    <>
                                        <div className="form-buttons-group">
                                            <div className="builder">
                                                <Link to={`/builder/${id}/m/${projectInfo.home_page_id}/p/${projectInfo.home_page_id}`} draggable="false">
                                                    <i className="fa-solid fa-layer-group pr-2"></i> Start builder
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="px-6">
                                            <FormMessage formErrorMessage={formErrorMessage} formInfoMessage={formInfoMessage}/>
                                        </div>
                                        <div className="form-buttons-group">
                                            <FormSubmitButton buttonText={"Save"} submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                            <div className="form-action">
                                                <Link to="/" className="form-cancel-btn">cancel</Link>
                                            </div>
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </>
        ) :
        <>
            <div className="admiko-ak-loading">
                <i className="fa-solid fa-circle-notch"></i>
            </div>
        </>
        ;
};