import React from "react";

export const FormCustomClass = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="custom_class">Custom class:</label>
            <div className="form-group-input">
                <input className={`form-input`} autoComplete="off" name="custom_class" id="custom_class" placeholder="Enter your class name" value={formData.custom_class} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
            </div>
        </div>
    );

}