import React from "react";
import {Link} from "react-router-dom";
import {quotes} from "../Helpers/Quotes";
import {CreateAccountForm} from "./CreateAccountForm";
export const CreateAccount = () => {

    return (
        <>
            <div className="login-container flex flex-col flex-auto items-center justify-center p-8 min-h-screen bg-gray-100">
                <div className="flex w-full max-w-md rounded-2xl shadow overflow-hidden bg-white md:max-w-5xl">
                    <div className="w-full px-12 py-12 max-w-md">
                        <div className="text-3xl font-extrabold tracking-tight leading-tight">Create account</div>
                        <div className="flex items-baseline mt-1 text-sm w-96">
                            <div className="">Already have an account?</div>
                            <Link to="/login" draggable="false" className="link ml-1">Sign in</Link>
                        </div>
                        <CreateAccountForm/>
                        <div className="mt-4">
                            <a className="text-slate-600 text-sm hover:underline" target="_blank" href="https://admiko.com/terms_and_privacy" rel="noreferrer">By creating an account, you agree to the Terms and Privacy Policy.</a>
                        </div>
                    </div>

                    <div className="hidden md:flex items-center justify-center p-12 lg:px-20 overflow-hidden bg-gray-800 text-gray-400">
                        <div className="w-full">
                            <div className="text-2xl lg:text-4xl font-bold leading-none text-gray-100">
                                {quotes[Math.floor(Math.random() * quotes.length)]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
};