import React, {useEffect, useState} from "react";
import {apiUpdateAccount} from "../Api/useCallApi";
import {useIsMountedRef} from "../Helpers/isMounted";
import {CheckFormOnChange, CheckFormOnSubmit} from "../Helpers/CheckFormFields";
import {FormSubmitButton} from "../Helpers/FormSubmitButton";
import {UpdatePassword} from "./LayoutParts/UpdatePassword";
import {setRootPageInfoDirect} from "../Global/PageInfo";
import {FormMessage} from "./LayoutParts/FormMessage";
import {rootLoggedUser, updateAppRootUser} from "../Global/User";
export const Account = () => {

    const isMountedRef = useIsMountedRef();

    /*send button*/
    const [submitButtonTimer, setSubmitButtonTimer] = useState(0);
    const [submitButton, setSubmitButton] = useState({disable: false, state: ""});
    /*end send button*/

    const formFields = {
        fields: {name: rootLoggedUser.name,email:rootLoggedUser.email,current_password:""}
    }
    const formErrorData = {
        error: {name: false,email:false,current_password:false}
    }
    const formFieldsRules = {
        name: {required: true,type:"input",rules: {minLength:1,maxLength:50}},
        email: {required: true,type:"email",rules: {minLength:5,maxLength:150,allowedInput:"email"}},
        current_password: {required: true,type:"input",rules: {minLength:6,maxLength:100}},
    }

    const [formData, setFormData] = useState(formFields);
    const [fieldError, setFieldError] = useState(formErrorData);
    const [formErrorMessage, setFormErrorMessage] = useState(false);
    const [formInfoMessage, setFormInfoMessage] = useState(false);

    const onChangeFormData = (e) => {
        if (formFieldsRules.hasOwnProperty(e.target.name)) {
            e.target.value = CheckFormOnChange(e,formFieldsRules,submitButton,setSubmitButton);
        }
        setFormData(prevFormData => ({
            fields: {...prevFormData.fields, [e.target.name]: e.target.value}
        }));
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setFormErrorMessage(false);
        setFormInfoMessage(false);
        /*send button*/
        setSubmitButton({...submitButton, disable: true, state: "progress"});

        if (submitButtonTimer) {
            window.clearTimeout(submitButtonTimer);
            setSubmitButtonTimer(0);
        }
        /*end send button*/
        if(CheckFormOnSubmit(formData, fieldError, setFieldError, formFieldsRules, submitButton, setSubmitButton)){
            setSubmitButton({...submitButton, disable: false, state: ""});
            return;
        }
        let sendFormData = {...formData.fields};

        const response = await apiUpdateAccount(sendFormData);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            if(response.message==='updated'){
                setFormInfoMessage("Your account is updated.");
            }
            //updateUser({name:response.return.user.name,email:response.return.user.email,token:user.token})
            updateAppRootUser(response.return.user);
            setSubmitButton({...submitButton, disable: false, state: "saved"});
        } else {
            if(response.message==='check_input'){
                setFormErrorMessage("Please check your input.");
            }
            if(response.message==='invalid_password'){
                setFormErrorMessage("Please check your current password.");
            }
            if(response.message==='email_exist'){
                setFormErrorMessage("This email already exist.");
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }
        /*send button*/
        setSubmitButtonTimer(setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }, 3000));
        /*end send button*/
    }
    useEffect(() => {
        setRootPageInfoDirect({title:"My Account",subtitle:"Update your account.",activeMenu:"account",breadcrumbs:[{link:"/account",title:"My Account"}]})
        setFormData(formFields);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootLoggedUser])
    // useEffect(() => {
    //     setRootPageInfoDirect({title:"My Account",subtitle:"Update your account.",activeMenu:"account",breadcrumbs:[{link:"/account",title:"My Account"}]})
    // }, [])
    return (
        <>
            <div className="ak-form-container">
                <div className="ak-section">
                    <div className="ak-section-content">
                        <form>
                            <div className="ak-section-form-content">
                                <div className="form-group">
                                    <label htmlFor="name">Name *</label>
                                    <input type="text" className={`form-input ${fieldError.error.name ? 'form-input-error' : ''}`} value={formData.fields.name} onChange={(e) => onChangeFormData(e)} name="name" id="name" placeholder="Enter name"/>
                                    <p className="required-input">Name is required.</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email *</label>
                                    <input type="text" className={`form-input ${fieldError.error.email ? 'form-input-error' : ''}`} value={formData.fields.email} onChange={(e) => onChangeFormData(e)} name="email" id="email" placeholder="Enter email"/>
                                    <p className="required-input">Email is required.</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Confirm with current password *</label>
                                    <input type="password" className={`form-input ${fieldError.error.current_password ? 'form-input-error' : ''}`} value={formData.fields.current_password} onChange={(e) => onChangeFormData(e)} name="current_password" id="current_password" autoComplete="current-password" placeholder="Enter current password"/>
                                    <p className="required-input">Current password is required.</p>
                                </div>
                                <div>
                                    <FormMessage formErrorMessage={formErrorMessage} formInfoMessage={formInfoMessage}/>
                                </div>
                            </div>
                            <div className="form-buttons-group">
                                <FormSubmitButton buttonText={"Update"} submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <UpdatePassword/>
        </>

    )
};