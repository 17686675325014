import React from "react";

export const FormVarLength = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <>
            {formData.can_edit === 1 &&
                <div className="form-group">
                    <label htmlFor="var_length">Column length:</label>
                    <div className="form-group-input">
                        <input className="form-input" autoComplete="off" name="var_length" id="var_length" placeholder="DB table length" value={formData.var_length} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                        <p className="ak-admiko-form-error">Required!</p>
                    </div>
                </div>
            }
        </>
    );

}