import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useIsMountedRef} from "../../../Helper/isMounted";
import {FormSubmitButton} from "../../Helpers/FormSubmitButton";
import {FormCloseButton} from "../../Helpers/FormCloseButton";
import {onChangeFormDataGlobal} from "../../../Helper/Functions";
import {apiUpdateWidgetsSettings} from "../../../Api/useWidgets";
import {rootSetError} from "../../../Helper/RootVar/Error/Error";
import {rootPAGE_ID_MAX_LENGTH} from "../../../Helper/RootVar/Contstant";

export const WidgetCustomClass = (props) => {

    const [infoClick, setInfoClick] = useState({page_id: false, table_id: false});

    const LoadFormData = useCallback(() => {
        setWidgetIdu(props.widgetData.idu);
        let newProperty = {}
        newProperty.edit_page_title = props.widgetData.title;
        newProperty.edit_table_width = props.widgetData.table_width??"full";
        newProperty.edit_page_id = props.widgetData.page_id;
        newProperty.layout = props.widgetData.layout??"custom_class";
        newProperty.edit_path = props.widgetData.settings?.path??"";
        setFormData(newProperty);
    }, [props.widgetData]);


    useEffect(() => {
        LoadFormData();
    }, [props.widgetData,LoadFormData])

    const closeForm = () => {
        props.hideBack();
    };
    // const LoadFormData = () => {
    //     setWidgetIdu(props.widgetData.idu);
    //     let newProperty = {}
    //     newProperty.edit_page_title = props.widgetData.title;
    //     newProperty.edit_table_width = props.widgetData.table_width??"full";
    //     newProperty.edit_page_id = props.widgetData.page_id;
    //     newProperty.layout = props.widgetData.layout??"custom_class";
    //     newProperty.edit_path = props.widgetData.settings?.path??"";
    //     setFormData(newProperty);
    //     //onChangeFormData({target: {name: 'edit_path', value: "123"}});
    // }

    /*FORM*/
    const isMountedRef = useIsMountedRef();
    const [submitButton, setSubmitButton] = useState("ready");
    const [widgetIdu, setWidgetIdu] = useState(false);
    /*form js*/
    // Destructuring props to extract checkSaveButtonOnChildStart
    const { checkSaveButtonOnChildStart } = props;

    // const formFieldsRules = {
    //     edit_page_title: {required: false, type: "input", rules: {minLength: 1, maxLength: 64}},
    //     edit_table_width: {required: false, type: "select"},
    //     layout: {required: false, type: "select"},
    //     edit_page_id: {required: true, type: "input", rules: {minLength: 3, maxLength: rootPAGE_ID_MAX_LENGTH, allowedInput: "lower_dash_numbers_protect_start_end"}},
    //     edit_path: {required: true, type: "input", rules: {minLength: 1, maxLength: 250}},
    // }
    const formFieldsRules = useMemo(() => ({
        edit_page_title: {required: false, type: "input", rules: {minLength: 1, maxLength: 64}},
        edit_table_width: {required: false, type: "select"},
        layout: {required: false, type: "select"},
        edit_page_id: {required: true, type: "input", rules: {minLength: 3, maxLength: rootPAGE_ID_MAX_LENGTH, allowedInput: "lower_dash_numbers_protect_start_end"}},
        edit_path: {required: true, type: "input", rules: {minLength: 1, maxLength: 250}},
    }), []); // Empty dependency array

    const formFields = {
        edit_page_title: "",
        edit_table_width: "full",
        edit_page_id: "",
        layout: "custom_class",
        edit_path: "",
    };


    const [formData, setFormData] = useState(formFields);

    useEffect(() => {
        checkSaveButtonOnChildStart(formData, formFieldsRules, submitButton, setSubmitButton);
    }, [formData, formFieldsRules, submitButton, checkSaveButtonOnChildStart]);


    // const formFields = {
    //     edit_page_title: "",
    //     edit_table_width: "full",
    //     edit_page_id: "",
    //     layout: "custom_class",
    //     edit_path: "",
    // }
    // const [formData, setFormData] = useState(formFields);
    //
    // useEffect(() => {
    //     props.checkSaveButtonOnChildStart(formData, formFieldsRules, submitButton, setSubmitButton);
    // }, [formData])



    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiUpdateWidgetsSettings({...formData}, widgetIdu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };

    /*END FORM*/

    return (
        <>
            <div className="admiko-action-menu-body">
                <div className={`admiko-action-menu-body-action`}>
                    <div>
                        <form action="">
                            <div className="form-group">
                                <label htmlFor="edit_page_title">Title:</label>
                                <div className="form-group-input">
                                    <input className="form-input" autoComplete="off" name="edit_page_title" id="edit_page_title" placeholder="Enter title" value={formData.edit_page_title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                    <p className="ak-admiko-form-error">Required!</p>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="edit_page_id" className="mouse-pointer" onClick={() => setInfoClick({page_id: !infoClick.page_id})}>Widget ID: <i className="fa-solid fa-circle-info"></i></label>
                                <div className="form-group-input">
                                    <input className="form-input" autoComplete="off" name="edit_page_id" id="edit_page_id" placeholder="Enter page ID" value={formData.edit_page_id} type="text" onChange={(e) => onChangeFormData(e)}/>
                                    <p className="ak-admiko-form-error">Required min 3 characters!</p>
                                </div>
                            </div>
                            {infoClick.page_id &&
                                <p className="ak-admiko-form-info">Unique ID to use for filenames etc.., Max {rootPAGE_ID_MAX_LENGTH} characters. Can't start with _0-9 or ends with _ (Limited: a-z, 0-9 and _).
                                    Highly recommended to use only a-z and _</p>
                            }

                            {/*<div className="form-group">*/}
                            {/*    <label htmlFor="edit_page_description">Info:</label>*/}
                            {/*    <div className="form-group-input">*/}
                            {/*        <textarea value={formData.edit_page_description} className="form-textarea" name="edit_page_description" placeholder="Enter table info" id="edit_page_description" onChange={(e) => onChangeFormData(e)}></textarea>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="form-group">*/}
                            {/*    <label htmlFor="edit_table_width">Width:</label>*/}
                            {/*    <div className="form-group-input">*/}
                            {/*        <select value={formData.edit_table_width} className="form-select" name="edit_table_width" id="edit_table_width" onChange={(e) => onChangeFormData(e)}>*/}
                            {/*            <option value="full" key="full">Full</option>*/}
                            {/*            <option value="medium" key="medium">Medium</option>*/}
                            {/*            <option value="small" key="small">Small</option>*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="form-group">*/}
                            {/*    <label htmlFor="edit_table_width">Width:</label>*/}
                            {/*    <div className="form-group-input">*/}
                            {/*        <select value={formData.edit_table_width} className="form-select" name="edit_table_width" id="edit_table_width" onChange={(e) => onChangeFormData(e)}>*/}
                            {/*            <option value="1_1" key="1_1">100%</option>*/}
                            {/*            <option value="1_2" key="1_2">50%</option>*/}
                            {/*            <option value="1_3" key="1_3">33%</option>*/}
                            {/*            <option value="1_4" key="1_4">25%</option>*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="form-group">
                                <label htmlFor="edit_table_width">Width:</label>
                                <div className="form-group-input">
                                    <div className="form-group-input-radio-inline">
                                        {[{title:"100%",value:'1_1'},
                                            {title:"50%",value:'1_2'},
                                            {title:"33%",value:'1_3'},
                                            {title:"25%",value:'1_4'}
                                        ].map(item => (
                                            <div key={item.value} className={`${formData.edit_table_width === item.value ? 'active' : ''}`}>
                                                <input
                                                    type="radio"
                                                    id={`width_${item.value}`}
                                                    name="edit_table_width"
                                                    value={item.value}
                                                    checked={formData.edit_table_width === item.value}
                                                    onChange={(e) => onChangeFormData(e)}
                                                />
                                                <label
                                                    htmlFor={`width_${item.value}`}
                                                >
                                                    {item.title}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="layout">Type:</label>
                                <div className="form-group-input">
                                    <select value={formData.layout} className="form-select" name="layout" id="layout" onChange={(e) => onChangeFormData(e)}>
                                        <option value="custom_class" key="custom_class">Call controller</option>
                                        <option value="custom_html" key="custom_html">Include blade</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="edit_path">Path:</label>
                            </div>
                            <div className="form-group">
                                <div className="form-group-input">
                                    <textarea value={formData.edit_path} className={`form-textarea ${!formData.edit_path?'ak-admiko-form-input-error':''}`} name="edit_path" placeholder="Enter path" id="edit_path" onChange={(e) => onChangeFormData(e)}></textarea>
                                    <p className="ak-admiko-form-error">Required!</p>
                                </div>
                            </div>
                            {formData.layout === 'custom_class' &&
                            <p className="text-xs mb-2">
                                Call controller example:<br/>
                                App\Http\Controllers\Admin\ExampleController@index
                            </p>
                            }
                            {formData.layout === 'custom_html' &&
                                <p className="text-xs mb-2">
                                    Include blade example:<br/>
                                    admin.myfolder.index
                                </p>
                            }
                            <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                            <FormCloseButton closeForm={(e) => closeForm(e)}/>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
