import React, {useEffect, useState} from "react";
import {FormSubmitButton} from "../Helpers/FormSubmitButton";
import {useAuth} from "../Providers/AuthProvider";
import {useIsMountedRef} from "../Helpers/isMounted";
import {CheckFormOnChange, CheckFormOnSubmit} from "../Helpers/CheckFormFields";
import {apiLoginUser} from "../Api/useCallApi";
import {getReCaptchaToken, loadReCaptchaScript, unloadReCaptcha} from "../Helpers/ReCaptcha";
import {Link} from "react-router-dom";
import {updateAppRootUser} from "../Global/User";

export const LoginForm = () => {

    const {login} = useAuth();

    const isMountedRef = useIsMountedRef();
    /*send button*/
    const [submitButtonTimer, setSubmitButtonTimer] = useState(0);
    const [submitButton, setSubmitButton] = useState({disable: false, state: ""});
    /*end send button*/

    useEffect(() => {
        // Add reCaptcha
        loadReCaptchaScript();
        const queryParameters = new URLSearchParams(window.location.search)
        if(queryParameters.get("g") && queryParameters.get("n") && queryParameters.get("e")){
            unloadReCaptcha();
            updateAppRootUser({"name":queryParameters.get("n"),"email":queryParameters.get("e"),"hide_me":true});
            login(queryParameters.get("g"));
        }

        return () => {
            //remove reCaptcha
            unloadReCaptcha();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formFields = {
        fields: {email: "", password: "", remember: 0, token: ""}
    }
    const formErrorData = {
        error: {email: false, password: false, remember: false, token: false}
    }
    const formFieldsRules = {
        email: {required: true, type: "email", rules: {minLength: 5, maxLength: 150, allowedInput: "email"}},
        password: {required: true, type: "input", rules: {minLength: 6, maxLength: 100}},
        remember: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
    }

    const [formData, setFormData] = useState(formFields);
    const [fieldError, setFieldError] = useState(formErrorData);
    const [formError, setFormError] = useState(false);

    const onChangeFormData = (e) => {
        if (formFieldsRules.hasOwnProperty(e.target.name)) {
            e.target.value = CheckFormOnChange(e, formFieldsRules, submitButton, setSubmitButton);
        }
        setFormData(prevFormData => ({
            fields: {...prevFormData.fields, [e.target.name]: e.target.value}
        }));
    };

    /*SEND DATA*/
    const sendFormData = async (e) => {
        e.preventDefault();
        setFormError(false);
        /*send button*/
        setSubmitButton({...submitButton, disable: true, state: "progress"});
        /*send button*/
        if (submitButtonTimer) {
            window.clearTimeout(submitButtonTimer);
            setSubmitButtonTimer(0);
        }
        /*end send button*/

        if (CheckFormOnSubmit(formData, fieldError, setFieldError, formFieldsRules, submitButton, setSubmitButton)) {
            setSubmitButton({...submitButton, disable: false, state: ""});
            return;
        }
        let sendFormData = {...formData.fields};

        //get reCaptcha token
        let token = await getReCaptchaToken();

        if (!token) {
            setSubmitButton({...submitButton, disable: false, state: ""});
            return false;
        }
        sendFormData.token = token;

        const response = await apiLoginUser(sendFormData);

        if (!isMountedRef.current) {
            return false;
        }

        if (response.success === true) {
            login(response.return.user.token)
            updateAppRootUser(response.return.user);
        } else {
            if (typeof response.message === 'object') {
                let errorMessages = [];
                Object.keys(response.message).forEach(key => {
                    errorMessages.push(...response.message[key]);
                });
                setFormError(errorMessages);

            } else if (response.message === 'no_user' || response.message === 'invalid_user') {
                setFormError("Invalid username or password.");
            } else if (response.message === 'invalid_token') {
                setFormError("Invalid recaptcha token.");
            } else {
                setFormError(response.message);
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }
    };
    /*end form js*/

    return (
        <>
            <form className="mt-8">
                {formError !== false &&
                    <div className="error-message">
                        {(Array.isArray(formError) && formError.length > 0) ?
                            <>
                                {formError.map((item, i) => (
                                    <p key={i}>
                                        {item}
                                    </p>
                                ))}
                            </>
                            :
                            <>{formError}</>
                        }
                    </div>
                }
                <div className="form-group">
                    <label htmlFor="email">
                        Email address *
                    </label>
                    {/*value={formData.fields.email}*/}
                    <input className={`form-input ${fieldError.error.email ? 'form-input-error' : ''}`}  onBlur={(e) => onChangeFormData(e)} onChange={(e) => onChangeFormData(e)} id="email" name="email" type="email" required="" placeholder="Enter email" autoFocus="on" autoComplete="on"/>
                    <p className="required-input">Email is required.</p>
                </div>

                <div className="form-group">
                    <label htmlFor="password">
                        Password *
                    </label>
                    {/*value={formData.fields.password} */}
                    <input className={`form-input ${fieldError.error.password ? 'form-input-error' : ''}`} onBlur={(e) => onChangeFormData(e)} onChange={(e) => onChangeFormData(e)} id="password" name="password" type="password" required="" autoComplete="off" placeholder="Enter password"/>
                    <p className="required-input">Password is required.</p>
                </div>
                <div className="mt-6">
                    <FormSubmitButton buttonText={"Sign in"} submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                </div>
                <div className="flex items-center justify-between mt-6 flex-wrap">
                    {/*<div className="flex items-center">*/}
                    {/*    <input checked={formData.fields.remember === 1} name="remember" id="remember" type="checkbox" className="form-checkbox" value="1" onChange={(e) => onChangeFormData(e)}/>*/}
                    {/*    <label htmlFor="remember" className="block ml-2 text-sm text-gray-900 leading-5">*/}
                    {/*        Remember*/}
                    {/*    </label>*/}
                    {/*</div>*/}

                    <div className="text-sm leading-5">
                        <Link to="/reset" draggable="false" className="link ml-1">Forgot your password?</Link>
                    </div>
                </div>
            </form>
        </>

    )
};