import {Settings} from "./Section/Settings/Settings";
import React, {useEffect, useState} from "react";

import {DeleteSectionHeader} from "./Delete/DeleteSectionHeader";
import {Icons} from "../Icons/Icons";
import {DeleteSection} from "./Delete/DeleteSection";
import {AddNewPageSection} from "./Section/Add/ListElements";
import {ElementInfo} from "../Helpers/ElementInfo";
import {IconsSectionHeader} from "../Icons/IconsSectionHeader";
import {useNavigate} from "react-router-dom";
import {rootBuilderLink, rootHomePageID} from "../../Helper/RootVar/Contstant";
import {SystemFileInfo} from "../Helpers/SystemFileInfo";
import {setRootBuilderDocuArea} from "../../Global/AdmikoToolBox/BuilderDocumentation";


export const PageStart = (props) => {
    /*set props*/
    const { pageData, url_page_id, url_table_id, url_widget_id } = props;
    let navigate = useNavigate();

    const [selectedSection, setSelectedSection] = useState(false);

    useEffect(() => {
        setActionMenuTab('main');
        setRootBuilderDocuArea('page');
    }, [ url_page_id, url_table_id, url_widget_id]);


    //
    //
    useEffect(() => {
        setSelectedSection(pageData);
    }, [pageData])

    const deletedParentAction = (response) => {
        if(response.return.parent_idu !== rootHomePageID){
            return navigate(`${rootBuilderLink}/m/${response.return.parent_idu}/p/${response.return.parent_idu}`)
        } else {
            return navigate(`${rootBuilderLink}/m/${rootHomePageID}/p/${rootHomePageID}`)
        }
    };

    /*TOGGLE ACTION*/
    const [actionMenuTab, setActionMenuTab] = useState('main');
    const toggleActionMenuTab = (page) => {
        setActionMenuTab(page);
    };

    return (selectedSection ? (
                <>
                    <div className="admiko-section-editor">
                        <div className={`admiko-action admiko-action-menu admiko-show ak-admiko-pre-scroll`}>
                            <div className="ak-admiko-add-scroll">
                                <div className="ak-admiko-add-scroll-fix">
                                    <div className="admiko-action-header admiko-action-header-info">
                                        <div className="info">
                                            <h2><i className="fa-regular fa-file-lines"></i>Page builder</h2>
                                            {/*<p>Set up your form.</p>*/}
                                        </div>
                                        <div className="close">
                                            <div className="admiko-toggle-tools">

                                            </div>
                                        </div>
                                    </div>
                                    {(selectedSection.idu !== 0) &&
                                        <>
                                            {(selectedSection.can_delete === 1) &&
                                                <DeleteSectionHeader toggleActionMenuTab={(e) => toggleActionMenuTab(e)}/>
                                            }
                                        </>
                                    }

                                    {/*{(selectedSection.can_edit === 1) &&*/}
                                    <div className="admiko-action-header mouse-pointer" onClick={() => toggleActionMenuTab('page_properties')}>
                                        <div className="info">
                                            <h2><i className="fa-solid fa-gears"></i>Page settings</h2>
                                            <p>Edit page properties.</p>
                                        </div>
                                        <div className="close">
                                            <div className="admiko-toggle-tools">
                                                <div className="admiko-toggle-box">
                                                    <div>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*}*/}
                                    {(selectedSection.idu !== 0) &&
                                        <>
                                            <IconsSectionHeader toggleActionMenuTab={(e) => toggleActionMenuTab(e)}/>
                                        </>
                                    }
                                    {(selectedSection.can_edit === 1) &&
                                        <div className="admiko-action-header mouse-pointer" onClick={() => toggleActionMenuTab('page_elements')}>
                                            <div className="info">
                                                <h2><i className="fa-regular fa-square-plus"></i>Add section</h2>
                                                <p>Add new sections to a page.</p>
                                            </div>
                                            <div className="close">
                                                <div className="admiko-toggle-tools">
                                                    <div className="admiko-toggle-box">
                                                        <div>
                                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                                <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <ElementInfo section={selectedSection.info_details} header="Page information:"/>

                                    {(selectedSection.idu !== 0) &&
                                        <>
                                            {(selectedSection.can_edit === 0) &&
                                                <SystemFileInfo/>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        {/*add new section*/}
                        {(actionMenuTab === "page_elements" && selectedSection.can_edit === 1) &&
                            <AddNewPageSection
                                selectedSection={selectedSection} toggleActionMenuTab={toggleActionMenuTab}
                            />
                        }
                        {/*// && selectedSection.can_edit === 1*/}
                        {(actionMenuTab === "page_properties") &&
                            <Settings
                                idu={selectedSection.idu} toggleActionMenuTab={toggleActionMenuTab}
                                section={selectedSection} setSelectedSection={setSelectedSection}/>}
                        {actionMenuTab === "icon_manager" &&
                            <Icons toggleActionMenuTab={toggleActionMenuTab} section={selectedSection} setSelectedSection={setSelectedSection}/>
                            //
                        }
                        {actionMenuTab === "delete" &&
                            <DeleteSection toggleActionMenuTab={toggleActionMenuTab} section={selectedSection} deletedParentAction={deletedParentAction}/>
                        }
                    </div>
                </>)
            :
            (<>
                    <div className={`admiko-action admiko-action-menu admiko-show ak-admiko-pre-scroll`}>
                        <div className="ak-admiko-add-scroll">
                            <div className="ak-admiko-add-scroll-fix">
                                <div className="admiko-ak-loading">
                                    <i className="fa-solid fa-circle-notch"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
    )
}