import React, {PureComponent} from "react";
import ReactApexCharts from 'react-apexcharts';
import {ToolBoxEditButtons} from "./ToolBoxEditButtons";
import {getRootScrollToID} from "../../../Helper/RootVar/Contstant";

class WidgetProgress extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {title: ""};
        this.ApexChart = {
            series: [{
                name: "",
                data: [80, 41, 75, 51, 148, 12, 69, 91, 148]
            }],
            options: {
                chart: {
                    type: 'area',
                    sparkline: {
                        enabled: true,
                    },
                    toolbar: {
                        show: false,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 2,
                },
                title: {
                    text: undefined,
                },
                legend: {
                    show: false,
                },

                grid: {
                    show: false,
                    // row: {
                    //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    //     opacity: 0.5
                    // },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                    labels: {
                        show: false
                    },
                },
                yaxis: {
                    labels: {
                        show: false
                    },
                },
                markers: {
                    size: 0,
                },
                tooltip: {
                    theme: "light",
                    fixed: {
                        enabled: true,
                        position: "topLeft",
                    },
                    x: {
                        show: false,
                    },
                },
            },
        };
    }

    componentDidMount() {
        this.setState({title: this.props.info.idu});
    }

    //
    toolBoxNavigate(e) {
        e.preventDefault();
        this.props.navigateToToolboxArea(this.props.info.idu, 'layout')
    };

    componentDidUpdate() {
        let scrollToElement = getRootScrollToID();
        if (scrollToElement) {
            const element = document.querySelector(`[data-id="${scrollToElement}"]`);
            element.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }
    FormatNumber(number,decimals) {
        if(decimals === 2){
            const formattedCurrency = number.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
            return formattedCurrency.replace('$', '');
        } else {
            return number;
        }

    }


    render() {

        return this.props.info.idu ?
            <>
                <div className={`ak-section-container ak-widget-container widget-width-ak-w-${this.props.info.table_width ?? "1_1"} js-page-elements-container js-admiko-page-element-sort-me ${this.props.widget_id === this.props.url_widget_id && "selected"} ${this.props.info.widget_error === 1 && "widget-error"}`}
                     data-id={this.props.info.idu} data-folder={this.props.folder_idu}>
                    <ToolBoxEditButtons idu={this.props.info.idu}
                                        table_id={this.props.widget_id} url_table_id={this.props.url_widget_id} url_menu_id={this.props.url_menu_id} url_page_id={this.props.url_page_id}
                                        position={this.props.position} table_btn={1} totalItems={this.props.totalItems} sortMe={this.props.sortMe}
                                        folder_idu={this.props.folder_idu}
                    />
                    {this.props.info.disable_date_picker === 0 &&
                        <div className="ak-widget-date-picker" title="Date picker">
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/></svg>
                            </div>
                        </div>
                    }
                    <div className="ak-section-element-container">
                        <div className="ak-widget-small">
                            <div>
                                <h3>{this.props.info.title}</h3>
                                <div className="widget-numbers">{this.props.info.prefix}
                                    {this.FormatNumber(2420, this.props.info.decimals)}
                                    {this.props.info.suffix}</div>
                            </div>
                            <div className="overflow-hidden w-full h-[95px]">
                                <ReactApexCharts options={this.ApexChart.options} series={this.ApexChart.series} type="area" height={95}/>
                                {/*<ApexChart/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </>
            :
            <>
                <div className="ak-menu-item-loading">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </>;
    }

}

export default WidgetProgress;
