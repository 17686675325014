import React from "react";
import {FormInfo} from "./Partials/Partials";
import {EditBox} from "../FormContentStart";

export const Separator = (props) => {

    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const editElement = props.editElement;

    return (
        <div className={`ak-form-group ak-w-${ElementData.width} js-form-elements js-admiko-sort-me-container ${props.element_id === ElementData.idu ? "selected":""}`} data-id={ElementData.idu}>
            <div className={`ak-form-group-box`} onClick={()=> editElement(ElementData.idu)}>
                <div className="form-header">
                    <h3>{ElementData.title}</h3>
                    <FormInfo ElementData={ElementData}/>
                </div>
            </div>
            <EditBox position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement} idu={ElementData.idu}/>
        </div>
    );


}