import React, {useEffect, useState} from "react";
import {rootAdmikoToolBoxMenuContainer,updateRootAdmikoToolBoxMenuContainer} from "../../Helper/RootVar/ToolBox/Menu/MenuToolBox";
import {MenuManage} from "./Add/MenuManage";
import {MenuHeader} from "./Edit/MenuHeader/MenuHeader";
import {MenuLink} from "./Edit/MenuLink/MenuLink";
import {MenuContainer} from "./Edit/Container/MenuContainer";
import {useIsMountedRef} from "../../Helper/isMounted";
import {Link} from "react-router-dom";
import {rootBuilderSidebarDocuLink} from "../../Global/AdmikoToolBox/BuilderDocumentation";

export const MenuStart = (props) => {
    /*set props*/
    const rootLeftMenuAll = props.rootLeftMenuAll;
    const rootMenuToolBox = props.rootAdmikoToolBoxMenuContainer;

    const [fadeIn, setFadeIn] = useState(false);
    const [currentPart, setCurrentPart] = useState("");

    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        if(rootMenuToolBox.close){
            closeMe()
        } else {
            setCurrentPart("set_new")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootMenuToolBox])

    useEffect(() => {
        if(currentPart === "set_new"){
            setCurrentPart(rootMenuToolBox.part)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPart])

    const closeMe = () => {
        setFadeIn(false);
        setTimeout(() => {
            updateRootAdmikoToolBoxMenuContainer();
        }, 200)
    }

    const onParentClick =(e)=> {
        if(e.target === e.currentTarget) {
            closeMe();
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setFadeIn(true);
        }, 1);
        setCurrentPart(rootAdmikoToolBoxMenuContainer.part)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const handleTransitionEnd =(ev)=> {
    //     if(ev.target.classList.contains("js-admiko-fade-out")){
    //
    //         //updateRootAdmikoToolBoxMenuContainer();
    //                 }
    //     onTransitionEnd={(ev) => handleTransitionEnd(ev)}
    // }
    return (
        <>
            <div className={`admiko-tools-menu-container ${fadeIn === true ? " admiko-fade js-admiko-fade":"js-admiko-fade-out"}`} id="js-ak-admiko-menu-root">
                <div className="admiko-tools-menu-container-box" onClick={(e)=>onParentClick(e)}>
                    <div className={`admiko-tools-container`}>
                        <div className="admiko-tools">
                            <div className="admiko-header">
                                <div className="admiko-toggle-tools">

                                </div>
                                <div className="admiko-header-title">Sidebar Builder</div>
                                <div className="admiko-toggle-tools">
                                    <Link to={rootBuilderSidebarDocuLink} target="_blank">
                                        <div className="admiko-toggle-box">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="admiko-action-container">
                                <div className="admiko-page-menu-editor">

                                    {currentPart === 'menu_manage' &&
                                        <MenuManage rootLeftMenuAll={rootLeftMenuAll}/>
                                    }
                                    {currentPart === 'menu_header' &&
                                        <MenuHeader idu={rootMenuToolBox.idu} rootAdmikoToolBoxMenuContainer={rootMenuToolBox}/>
                                    }
                                    {(currentPart === 'menu_link') &&
                                        <MenuLink idu={rootMenuToolBox.idu} rootAdmikoToolBoxMenuContainer={rootMenuToolBox}/>
                                    }
                                    {currentPart === 'container' &&
                                        <MenuContainer idu={rootMenuToolBox.idu}
                                                       rootAdmikoToolBoxMenuContainer={rootMenuToolBox}
                                                       // rootHomePageID={rootHomePageID}
                                                       // rootActiveMenuPageID={rootActiveMenuPageID}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
