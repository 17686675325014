import React from "react";

export const FormMinValue = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="min_value">Min value:</label>
            <div className="form-group-input">
                <input className="form-input" autoComplete="off" name="min_value" id="min_value" placeholder="Enter required min value" value={formData.min_value} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                <p className="ak-admiko-form-error">Required!</p>
            </div>
        </div>
    );

}