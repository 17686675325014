import React, {useEffect} from "react";
import {appRootProjects, setAppGlobalSettingsDirect, setAppRootProjectsDirect} from "../../Global/Projects";
import {Link} from "react-router-dom";
import {setRootPageInfoDirect} from "../../Global/PageInfo";
import {apiGetProjects} from "../../Api/useCallApi";
import {setAppRootClientsDirect} from "../../Global/Clients";
import {setAppRootFrameworksDirect} from "../../Global/Framework";

export const Projects = () => {
    useEffect(() => {
        setRootPageInfoDirect({title: "My Projects", subtitle: "Projects list", activeMenu: "projects", breadcrumbs: [{link: "/", title: "My Projects"}]});
        (async () => {
            const response = await apiGetProjects();
            if (response.success === true) {
                setAppRootProjectsDirect(response.return.projects);
                setAppGlobalSettingsDirect(response.return.global_settings);
                setAppRootClientsDirect(response.return.clients);
                setAppRootFrameworksDirect(response.return.frameworks);
            } else {
                /*todo: else*/
            }
        })()

    }, [])

    return appRootProjects !== false ? (
            <>

                {Array.isArray(appRootProjects) && appRootProjects.length > 0 ? (
                    <>
                        <div>
                            <Link type="button" to="/project/add" draggable="false" className="add-new-btn">
                                <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"/>
                                </svg>
                                New project
                            </Link>
                        </div>
                        <div className="ak-project-container">
                            {appRootProjects.map((item) => (
                                <div className="ak-project-content" key={item.idu}>
                                    <div className="settings">
                                        <Link to={`/project/edit/${item.idu}`} draggable="false" className="">
                                            <i className="fa-solid fa-sliders"></i>
                                        </Link>
                                    </div>
                                    <div className="ak-project-info">
                                        <span>{item.created_at}</span>
                                        <h5>{item.name}</h5>
                                        {item.client_name &&
                                            <>
                                                <span>{item.client_name}</span>
                                            </>
                                        }
                                        {item.client_company &&
                                            <>
                                                <span>{item.client_company}</span>
                                            </>
                                        }
                                        <div className="builder">
                                            <Link to={`/builder/${item.idu}/m/${item.home_page_id}/p/${item.home_page_id}`} draggable="false">
                                                <i className="fa-solid fa-layer-group pr-2"></i> Builder
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="py-6">
                            <div className="text-center">
                                <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path vectorEffect="non-scaling-stroke" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"/>
                                </svg>
                                <h3 className="mt-2 text-sm font-medium text-gray-900">No projects</h3>
                                <p className="mt-1 text-sm text-gray-500">Get started by creating a new project.</p>
                                <div className="mt-6">
                                    <Link type="button" to="/project/add" draggable="false" className="add-new-btn">
                                        <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"/>
                                        </svg>
                                        New project
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        ) :
        <>
            <div className="admiko-ak-loading">
                <i className="fa-solid fa-circle-notch"></i>
            </div>
        </>;
};