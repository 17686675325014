export const checkInputAsYouType = (e, formFieldsRules) => {

    let key = e.target.name;
    let returnValue = "";
    if (formFieldsRules.hasOwnProperty(key)) {
        if (formFieldsRules[e.target.name].type === "input") {
            returnValue = e.target.value;
            if (formFieldsRules[e.target.name].rules.hasOwnProperty("maxLength")) {
                if (returnValue.length > formFieldsRules[e.target.name].rules.maxLength) {

                    return false;
                }
            }
            if (formFieldsRules[e.target.name].rules.hasOwnProperty("allowedInput")) {
                if (!preventInput(e, returnValue, formFieldsRules)) {
                    return false;
                }
            }
        }
    }
    return true;
}

export const preventInput = (event, returnValue, formFieldsRules) => {
    if (formFieldsRules[event.target.name].rules.allowedInput === "numbers_minus") {
        let regex = /^[0-9-]+$/;
        let key = event.nativeEvent.data;

        if (key === "-") {
            if ((returnValue.split("-").length-1) === 1) {
                if (returnValue.indexOf("-") > 0) {
                    return false;
                }
            } else if ((returnValue.split("-").length-1) > 1) {
                return false;
            }
        }
        if (returnValue !== "" && regex.test(returnValue) === false) return false;

    } else if (formFieldsRules[event.target.name].rules.allowedInput === "numbers") {
        let regex = /^[0-9]+$/;
        if (!regex.test(returnValue)) {
            return true;
        }
    } else if (formFieldsRules[event.target.name].rules.allowedInput === "numbers_dots") {
        let regex = /^[0-9.]+$/;
        let key = event.nativeEvent.data;
        if (key === ".") {
            if ((returnValue.split(".").length-1) === 1) {
                if (returnValue.indexOf(".") === 0) {
                    return false;
                }
                if (returnValue.indexOf("-") === 0 && returnValue.indexOf(".") === 1) {
                    return false;
                }
            } else if ((returnValue.split(".").length-1) > 1) {
                return false;
            }
        }
        if (!regex.test(returnValue)) {
            return true;
        }
    } else if (formFieldsRules[event.target.name].rules.allowedInput === "numbers_dots_minus") {
        let key = event.nativeEvent.data;
        let regex = /^[0-9.-]+$/;
        if (key === ".") {
            if ((returnValue.split(".").length-1) === 1) {
                if (returnValue.indexOf(".") === 0) {
                    return false;
                }
                if (returnValue.indexOf("-") === 0 && returnValue.indexOf(".") === 1) {
                    return false;
                }
            } else if ((returnValue.split(".").length-1) > 1) {
                return false;
            }
        }
        if (key === "-") {
            if ((returnValue.split("-").length-1) === 1) {
                if (returnValue.indexOf("-") > 0) {
                    return false;
                }
            } else if ((returnValue.split("-").length-1) > 1) {
                return false;
            }
        }
        if (!regex.test(returnValue)) {
            return true;
        }
    }
    return true;
}
export const CheckFormDataFields = (e, formFieldsRules, submitButton, setSubmitButton) => {
    let key = e.target.name;
    let returnValue = "";
    if (formFieldsRules.hasOwnProperty(key)) {
        if (formFieldsRules[e.target.name].type === "checkbox") {
            if (e.target.checked) {
                returnValue = formFieldsRules[e.target.name].rules.checked;
            } else {
                returnValue = formFieldsRules[e.target.name].rules.unchecked;
            }
        } else if (formFieldsRules[e.target.name].type === "select") {

            returnValue = e.target.value;
            if (formFieldsRules[e.target.name].required === true && e.target.value === "none") {

                if(e.target.hasOwnProperty("classList")){
                    e.target.classList.add("ak-admiko-form-input-error");
                }
                setSubmitButton("disabled");
            } else {
                if(e.target.hasOwnProperty("classList")){
                    e.target.classList.remove("ak-admiko-form-input-error");
                }
                setSubmitButton("ready");
            }
        } else if (formFieldsRules[e.target.name].type === "input") {

            returnValue = e.target.value;
            if (formFieldsRules[e.target.name].rules.hasOwnProperty("maxLength")) {
                returnValue = returnValue.substring(0, formFieldsRules[e.target.name].rules.maxLength);
            }
            if (formFieldsRules[e.target.name].rules.hasOwnProperty("allowedInput")) {
                returnValue = filterInput(e, returnValue, formFieldsRules);
            }
            if (formFieldsRules[e.target.name].required === true) {
                if (formFieldsRules[e.target.name].rules.hasOwnProperty("minLength")) {
                    if (formFieldsRules[e.target.name].rules.minLength > 0) {
                        if (returnValue.length < formFieldsRules[e.target.name].rules.minLength) {
                            e.target.classList.add("ak-admiko-form-input-error");
                           setSubmitButton("disabled");
                        } else {
                            e.target.classList.remove("ak-admiko-form-input-error");
                           setSubmitButton("ready");
                        }
                    }
                }
            }
        } else if (formFieldsRules[e.target.name].type === "array") {
            returnValue = e.target.value;
        }

        return returnValue;
    }
};

export const filterInput = (e, returnValue, formFieldsRules) => {
    let returnMe = returnValue;
    if (formFieldsRules[e.target.name].rules.allowedInput === "lower_dash") {
        const regex = /^[a-z_]+$/;
        if (regex.test(returnValue) === false) {
            returnMe = returnValue.replace(/[^a-z_.]/g, '')
        }
    } else if (formFieldsRules[e.target.name].rules.allowedInput === "lower_numbers_dash") {
        const regex = /^[a-z0-9_]+$/;
        if (regex.test(returnValue) === false) {
            returnMe = returnValue.replace(/[^a-z_.]/g, '')
        }
    } else if (formFieldsRules[e.target.name].rules.allowedInput === "lower_numbers_dash_minus") {
        const regex = /^[a-z0-9_-]+$/;
        if (regex.test(returnValue) === false) {
            returnMe = returnValue.replace(/[^a-z_.]/g, '')
        }
    } else if (formFieldsRules[e.target.name].rules.allowedInput === "upper_lower_dash_numbers_protect_start_end") {
        returnMe = replaceStartAndEnd(returnMe);

        const regex = /^_|^[0-9]|_$|[^A-Za-z0-9_]/;
        if (regex.test(returnMe) === true) {
            returnMe = returnMe.replace(/^[^A-Za-z]+|[^A-Za-z0-9_]+$/g, '')
        }
    } else if (formFieldsRules[e.target.name].rules.allowedInput === "upper_lower_dash_numbers_backslash_protect_start_end") {
        returnMe = replaceStartAndEndSpaceBackslash(returnMe);

        const regex = /^_|^[0-9]|_$|[^A-Za-z0-9_ \\]/;
        if (regex.test(returnMe) === true) {
            returnMe = returnMe.replace(/^[^A-Za-z]+|[^A-Za-z0-9_ \\]+$/g, '')
        }

    } else if (formFieldsRules[e.target.name].rules.allowedInput === "upper_lower_dash_minus_numbers_space") {
        const regex = /^[a-zA-Z0-9-_ ]+$/;
        if (regex.test(returnValue) === false) {
            returnMe = returnValue.replace(/[^a-zA-Z0-9-_ ]/g, '')
        }
    } else if (formFieldsRules[e.target.name].rules.allowedInput === "lower_dash_numbers_protect_start_end") {
        returnMe = replaceStartAndEnd(returnMe.toLowerCase());
        const regex = /^_|^[0-9]|_$|[^a-z0-9_]/;
        if (regex.test(returnMe) === true) {
            returnMe = returnMe.replace(/^[^a-z]+|[^a-z0-9_]+$/g, '')
        }
    } else if (formFieldsRules[e.target.name].rules.allowedInput === "upper_lower_dash_numbers") {

        const regex = /^[a-zA-Z0-9_]+$/;
        if (regex.test(returnValue) === false) {
            returnMe = returnValue.replace(/[^a-zA-Z0-9_]/g, '')
        }

    } else if (formFieldsRules[e.target.name].rules.allowedInput === "upper_lower_dash_numbers_dots_semi") {
        const regex = /^[a-zA-Z0-9_.,]+$/;
        if (regex.test(returnValue) === false) {
            returnMe = returnValue.replace(/[^a-zA-Z0-9_.,]/g, '')
        }
    } else if (formFieldsRules[e.target.name].rules.allowedInput === "numbers_dots") {
        const regex = /^[0-9.]+$/;
        if (regex.test(returnValue) === false) {
            returnMe = returnValue.replace(/[^0-9.]/g, '')
        }
    } else if (formFieldsRules[e.target.name].rules.allowedInput === "numbers_minus") {
        const regex = /^[0-9-]+$/;
        if (regex.test(returnValue) === false) {
            returnMe = returnValue.replace(/[^0-9-]/g, '')
        }
    } else if (formFieldsRules[e.target.name].rules.allowedInput === "numbers_dots_minus") {
        const regex = /^[0-9.-]+$/;
        if (regex.test(returnValue) === false) {
            returnMe = returnValue.replace(/[^0-9.-]/g, '')
        }
    } else if (formFieldsRules[e.target.name].rules.allowedInput === "lower_numbers_dash_backslash") {
        const regex = /^[a-z0-9_/]+$/;
        if (regex.test(returnValue) === false) {
            returnMe = returnValue.replace(/[^a-z0-9_/]/g, '')
        }
    } else if (formFieldsRules[e.target.name].rules.allowedInput === "numbers") {
        const regex = /^[0-9]+$/;
        if (regex.test(returnValue) === false) {
            returnMe = returnValue.replace(/[^0-9]/g, '')
        }
    } else if (formFieldsRules[e.target.name].rules.allowedInput === "lower_upper_numbers_dash_minus") {
        const regex = /^[a-zA-Z0-9_-]+$/;
        if (regex.test(returnValue) === false) {
            returnMe = returnValue.replace(/[^a-zA-Z0-9_-]/g, '')
        }
    }
    return returnMe;
}
export const replaceStartAndEnd = (string) => {
    /*replace 0-9 and _ on start and _ on end and keep only A-Za-z0-9_*/
    // return string.replace(/^[^A-Za-z]+|[^A-Za-z0-9_]+|[^A-Za-z0-9]+$/g, '');
    return string.replace(/^[^A-Za-z]+|[^A-Za-z0-9_]+$/g, '');
}
export const replaceStartAndEndSpaceBackslash = (string) => {
    /*replace 0-9 and _ on start and _ on end and keep only A-Za-z0-9_*/
    // return string.replace(/^[^A-Za-z]+|[^A-Za-z0-9_]+|[^A-Za-z0-9]+$/g, '');
    return string.replace(/^[^A-Za-z]+|[^A-Za-z0-9_ \\]+$/g, '');
}
export const checkSaveButton = (newFormData, formFieldsRules, submitButton, setSubmitButton) => {
    let submitEnable = true;
    Object.keys(newFormData).map(function (key) {
        if (formFieldsRules.hasOwnProperty(key)) {
            if (formFieldsRules[key].type === "select") {
                if (formFieldsRules[key].required === true && newFormData[key] === "none") {
                    submitEnable = false;
                }
            } else if (formFieldsRules[key].type === "input") {
                if (formFieldsRules[key].required === true) {
                    if (formFieldsRules[key].rules.hasOwnProperty("minLength")) {
                        if (formFieldsRules[key].rules.minLength > 0) {
                            if (newFormData[key].length < formFieldsRules[key].rules.minLength) {
                                submitEnable = false;
                            }
                        }
                    }
                }
            } else if (formFieldsRules[key].type === "array") {
                let arrayRequired = false;
                if (formFieldsRules[key].required === true) {

                    if (formFieldsRules[key].array_required === false) {
                        arrayRequired = Array.isArray(newFormData[key]) && newFormData[key].length > 0;
                    } else {
                        arrayRequired = true;
                    }
                    if (arrayRequired && !formFieldsRules[key].hasOwnProperty("fields")) {
                        if (!Array.isArray(newFormData[key]) || newFormData[key].length === 0) {
                            submitEnable = false;
                        }
                    }
                    if (arrayRequired && formFieldsRules[key].hasOwnProperty("fields")) {
                        Object.keys(formFieldsRules[key].fields).map(function (key2) {
                            if (formFieldsRules[key].fields[key2].rules?.hasOwnProperty("minLength")) {
                                if (formFieldsRules[key].fields[key2].rules.minLength > 0) {
                                    if (Array.isArray(newFormData[key]) && newFormData[key].length > 0) {
                                        for (let i = 0; i < newFormData[key].length; i++) {
                                            if (newFormData[key][i][key2].length < formFieldsRules[key].fields[key2].rules.minLength) {
                                                submitEnable = false;
                                            }
                                        }
                                    } else {
                                        submitEnable = false;
                                    }
                                }
                            }
                            return false;
                        })
                    }
                }
            }
        }
        return false;
    });

    if (submitEnable === true) {
        if(submitButton !== "saved") {
            setSubmitButton("ready");
        }
    } else {
        setSubmitButton("disabled");
    }
};

export const CheckCustomValuesFields = (e, formFieldsRules) => {

    let key = e.target.name;
    let returnValue = "";
    if (formFieldsRules.hasOwnProperty(key)) {

        returnValue = e.target.value;
        if (formFieldsRules[e.target.name].rules.hasOwnProperty("maxLength")) {
            returnValue = returnValue.substring(0, formFieldsRules[e.target.name].rules.maxLength);
        }
        if (formFieldsRules[e.target.name].rules.hasOwnProperty("allowedInput")) {
            returnValue = filterInput(e, returnValue, formFieldsRules);
        }
        if (formFieldsRules[e.target.name].required === true) {
            if (formFieldsRules[e.target.name].rules.hasOwnProperty("minLength")) {
                if (formFieldsRules[e.target.name].rules.minLength > 0) {
                    if (returnValue.length < formFieldsRules[e.target.name].rules.minLength) {
                        e.target.classList.add("ak-admiko-form-input-error");
                        //setSubmitButton({...submitButton, disable: true});
                    } else {
                        e.target.classList.remove("ak-admiko-form-input-error");
                        //setSubmitButton({...submitButton, disable: false});
                    }
                }
            }
        }

        return returnValue;
    }

};
