import React, {PureComponent} from "react";
import {ToolBoxEditButtons} from "./ToolBoxEditButtons";
import {LoadPageElement} from "../LoadPageElement";

class ElementContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {title: "",containerPageContent: this.props.pageContent.child_elements};
    }

    componentDidMount() {
        this.setState({title: this.props.info.idu});
        //this.setState({containerPageContent: this.filterPageElements()});
    }

    filterPageElements(){
        return this.props.pageContent.filter((item) => {
            return item.container_idu === this.props.widget_id && item.layout !== 'element_container';
        });
    }
    toolBoxNavigate(e) {
        e.preventDefault();
        this.props.navigateToToolboxArea(this.props.info.idu, 'layout')
    };
    componentDidUpdate(prevProps) {
        // let scrollToElement = getRootScrollToID();
        // if (scrollToElement) {
        //     const element = document.querySelector(`[data-id="${scrollToElement}"]`);
        //     element.scrollIntoView({
        //         behavior: "smooth",
        //         block: "center",
        //     });
        // }
        if (JSON.stringify(this.props.pageContent.child_elements) !== JSON.stringify(prevProps.pageContent)) {
            //this.setState({containerPageContent: this.filterPageElements()});
            this.setState({containerPageContent: this.props.pageContent.child_elements});
            //this.forceUpdate();
        }
    }


    render() {

        return this.props.info.idu ?
            <>
                <div className={`ak-section-container ak-table-container content-width-${this.props.info.table_width ?? "full"} js-page-elements-container js-admiko-page-element-sort-me-container js-admiko-page-element-sort-me-container-false js-sortable-folder ${this.props.widget_id === this.props.url_widget_id && "selected"}`} data-id={this.props.info.idu}>
                    <div className="ak-page-element-folder-header js-admiko-page-element-sort-me js-folder-header" data-id={this.props.info.idu}>
                        <ToolBoxEditButtons idu={this.props.info.idu}
                                            table_id={this.props.widget_id} url_table_id={this.props.url_widget_id} url_menu_id={this.props.url_menu_id} url_page_id={this.props.url_page_id}
                                            position={this.props.position} table_btn={1} totalItems={this.props.totalItems} sortMe={this.props.sortMe}
                        />
                    </div>
                    <div className={`ak-section-element-container ak-table-page-container js-droppable-folder pt-5 ${this.state.containerPageContent.length > 0 ?'pb0':'pb5'}`}>
                        {this.state.containerPageContent
                            // .filter(item => item.container_idu === this.props.widget_id && item.layout !== 'element_container')
                            .map((item,i) => {
                            return <LoadPageElement
                                key={`c-` + item.idu}
                                position={this.props.position+i}
                                item={item}
                                pageContent={this.props.pageContent}
                                totalItems={this.props.pageContent.length}
                                url_menu_id={this.props.url_menu_id}
                                url_page_id={this.props.url_page_id}
                                url_table_id={this.props.url_table_id}
                                url_widget_id={this.props.url_widget_id}
                                moveUp={this.props.sortMe.SortMoveUp}
                                moveDown={this.props.sortMe.SortMoveDown}
                                folder_idu={this.props.info.idu}
                            />
                        })}
                        <div data-id={this.props.info.idu} className="empty-folder js-empty-folder js-admiko-page-element-sort-me" style={{display: typeof this.state.containerPageContent === 'object' && Object.keys(this.state.containerPageContent).length > 0 ? 'none' : 'block' }}>Drag & drop widgets.</div>
                    </div>
                </div>
            </>
            :
            <>
                <div className="ak-menu-item-loading">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </>;
    }

}

export default ElementContainer;
