import React from "react";
import {FormInfo, Required} from "./Partials/Partials";
import {EditBox} from "../FormContentStart";
import {useEffect, useState} from "react";

export const RadioGroup = (props) => {
    /*set props*/
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const editElement = props.editElement;

    const [radioChecked, setRadioChecked] = useState("");


    useEffect(() => {
        if (ElementData.required === 1) {
            setRadioChecked(ElementData.idu + "-1");
        } else {
            setRadioChecked("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (ElementData.required === 1) {
            setRadioChecked(ElementData.idu + "-1");
        } else {
            setRadioChecked("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ElementData.required])

    const onValueChange = (idu) => {
        setRadioChecked(idu);
    }

    return (
        <>
            <div className={`ak-form-group ak-w-${ElementData.width} js-form-elements js-admiko-sort-me-container ak-element-direction-${ElementData.direction} ${props.element_id === ElementData.idu ? "selected" : ""} ${ElementData.error === 1 ? "ak-form-error" : ""}`} data-id={ElementData.idu}>
                <div className={`ak-form-group-box`} onClick={() => editElement(ElementData.idu)}>
                    <div className="ak-form-label">
                        <label>{ElementData.title}<Required ElementData={ElementData}/></label>
                    </div>
                    <div className="ak-form-input ak-form-input-checkbox">
                        <label className="label-checkbox">
                            <input type="radio" value={ElementData.idu + "-1"} name={ElementData.idu} checked={radioChecked === (ElementData.idu + "-1")} onChange={() => onValueChange(ElementData.idu + "-1")} className="form-radio"/> <span>{ElementData.form_display}</span>
                        </label>
                        <label className="label-checkbox">
                            <input type="radio" value={ElementData.idu + "-2"} name={ElementData.idu} checked={radioChecked === (ElementData.idu + "-2")} onChange={() => onValueChange(ElementData.idu + "-2")} className="form-radio"/> <span>{ElementData.form_display}</span>
                        </label>
                        <label className="label-checkbox">
                            <input type="radio" value={ElementData.idu + "-3"} name={ElementData.idu} checked={radioChecked === (ElementData.idu + "-3")} onChange={() => onValueChange(ElementData.idu + "-3")} className="form-radio"/> <span>{ElementData.form_display}</span>
                        </label>
                        <label className="label-checkbox">
                            <input type="radio" value={ElementData.idu + "-4"} name={ElementData.idu} checked={radioChecked === (ElementData.idu + "-4")} onChange={() => onValueChange(ElementData.idu + "-4")} className="form-radio"/> <span>{ElementData.form_display}</span>
                        </label>

                        <FormInfo ElementData={ElementData}/>
                    </div>
                </div>
                <EditBox position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement} idu={ElementData.idu}/>
            </div>
        </>
    );

}