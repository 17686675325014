import React from 'react';

export const Image = (props) => {
    const item = props.item;
    const counter = props.counter;

    const divStyle = {
        backgroundImage: `url("/images/example/${counter}.jpg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    const doNothing = (e) => {
        e.preventDefault();
    };

    if (item.settings.show_action === "none" && item.settings.show_as === "file_name") {
        return (
            <>
                <div className="p-2">image.jpg</div>
            </>
        );
    } else if (item.settings.show_as === "file_name") {
        return (
            <>
                <div className="p-2"><a href="/" onClick={(e) => doNothing(e)} className="table-image-link">image.jpg</a></div>
            </>
        );
    } else {
        return (
            <>
                {/*<img alt="" className="table-image" src={`/images/example/${counter}.jpg`}/>*/}
                <div onClick={(e) => doNothing(e)} className="table-image-link table-image" style={divStyle}></div>
            </>
        );
    }


}