import React, {useEffect, useState} from "react";
import {Text} from "./Elements/Text";
import {useIsMountedRef} from "../../../../../Helper/isMounted";
import {checkSaveButton} from "../../../../../Helper/RootVar/Error/CheckFormFields";
import {TextArea} from "./Elements/TextArea";
import {Image} from "./Elements/Image";
import {File} from "./Elements/File";
import {Select} from "./Elements/Select";
import {CheckBox} from "./Elements/CheckBox";
import {DateTime} from "./Elements/DateTime";
import {Time} from "./Elements/Time";
import {MoneyMeasurement} from "./Elements/MoneyMeasurement";
import {Numbers} from "./Elements/Numbers";
import {Float} from "./Elements/Float";
import {Slug} from "./Elements/Slug";
import {Separator} from "./Elements/Separator";
import {Email} from "./Elements/Email";
import {Password} from "./Elements/Password";
import {MultiCheckbox} from "./Elements/MultiCheckbox";
import {RadioGroup} from "./Elements/RadioGroup";
import {SelectCustom} from "./Elements/SelectCustom";
import {MultiCheckboxCustom} from "./Elements/MultiCheckboxCustom";
import {MultiSelectCustom} from "./Elements/MultiSelectCustom";
import {RadioGroupCustom} from "./Elements/RadioGroupCustom";
import {ParentChild} from "./Elements/ParentChild";
import {MapGoogle} from "./Elements/MapGoogle";
import {MapBing} from "./Elements/MapBing";
import {rootSetError} from "../../../../../Helper/RootVar/Error/Error";
import {apiEditFormElement} from "../../../../../Api/useForm";
import {onChangeFormDataGlobal} from "../../../../../Helper/Functions";
import {MultiSelect} from "./Elements/MultiSelect";
import {Range} from "./Elements/Range";
import {Color} from "./Elements/Color";
import {ImageBlobCrop} from "./Elements/ImageBlobCrop";
import {BigInteger} from "./Elements/BigInteger";
import {UserSelect} from "./Elements/UserSelect";
import {UserRadioGroup} from "./Elements/UserRadioGroup";
import {UserMultiSelect} from "./Elements/UserMultiSelect";
import {UserMultiCheckbox} from "./Elements/UserMultiCheckbox";
import {DateRange} from "./Elements/DateRange";

export const LoadElementSettings = (props) => {
    /*set props*/
    const elementData = props.elementData;
    const form_idu = props.form_idu;
    const isMountedRef = useIsMountedRef();
    const hideBack = props.hideBack;

    const [formData, setFormData] = useState(elementData);
    const [formFieldsRulesTemp, setFormFieldsRulesTemp] = useState(null);

    useEffect(() => {
        setFormData({...props.elementData});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(elementData)])

    useEffect(() => {
        if(formFieldsRulesTemp !== null){
            checkSaveButton(formData, formFieldsRulesTemp, submitButton, setSubmitButton);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(formData)])

    const checkSaveButtonOnChildStart = (formFieldsRules) => {
        setFormFieldsRulesTemp(formFieldsRules);
        if(formFieldsRules !== null){
            checkSaveButton(formData, formFieldsRules, submitButton, setSubmitButton);
        }
    }

    /*FORM*/
    /*send button*/
    const [submitButton, setSubmitButton] = useState("disabled");
    /*end send button*/

    const onChangeFormData = (e,formFieldsRules) => {
        setFormFieldsRulesTemp(formFieldsRules);
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };

    const closeForm = () => {
        hideBack();
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");

        let sendFormData = {...formData};

        delete sendFormData.idu;
        delete sendFormData.type;

        const response = await apiEditFormElement(sendFormData, form_idu, elementData.idu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/

    const renderElement = (type) => {
        switch (type) {
            case 'text':
                return (<><Text formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'textarea':
                return (<><TextArea formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'image':
                return (<><Image formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'image_blob_crop':
                return (<><ImageBlobCrop formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'file':
                return (<><File formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'select':
                return (<><Select section_id={form_idu} element_idu={elementData.idu} formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'checkbox':
                return (<><CheckBox formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'date_time':
                return (<><DateTime formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'time':
                return (<><Time formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'date_range':
                return (<><DateRange formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'money_measure':
                return (<><MoneyMeasurement formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'numbers':
                return (<><Numbers formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'biginteger':
                return (<><BigInteger formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'range':
                return (<><Range formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'color':
                return (<><Color formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'float':
                return (<><Float formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'slug':
                return (<><Slug formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'email':
                return (<><Email formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'password':
                return (<><Password formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'separator':
                return (<><Separator formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);

            case 'radio_group':
                return (<><RadioGroup section_id={form_idu} element_idu={elementData.idu} formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);

            case 'select_custom':
                return (<><SelectCustom formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);

            case 'radio_group_custom':
                return (<><RadioGroupCustom formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);

            case 'multi_checkbox':
                return (<><MultiCheckbox section_id={form_idu} element_idu={elementData.idu} formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);

            case 'multi_checkbox_custom':
                return (<><MultiCheckboxCustom formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);

            case 'multi_select':
                return (<><MultiSelect section_id={form_idu} element_idu={elementData.idu} formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);

            case 'multi_select_custom':
                return (<><MultiSelectCustom formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);


            case 'map_google':
                return (<><MapGoogle formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);

            case 'map_bing':
                return (<><MapBing formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);

            case 'parent_child':
                return (<><ParentChild formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'user_select':
                return (<><UserSelect section_id={form_idu} element_idu={elementData.idu} formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'user_radio_group':
                return (<><UserRadioGroup section_id={form_idu} element_idu={elementData.idu} formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'user_multi_checkbox':
                return (<><UserMultiCheckbox section_id={form_idu} element_idu={elementData.idu} formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'user_multi_select':
                return (<><UserMultiSelect section_id={form_idu} element_idu={elementData.idu} formData={formData} onChangeFormData={onChangeFormData} submitButton={submitButton} sendFormData={sendFormData} closeForm={closeForm} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);


            default:
                return (<></>);
        }
    }
    return (
        <>
            {renderElement(elementData.type)}
        </>
    )

}
