import React from 'react';
import {rootHighlightPageContentParts} from "../../../../Helper/RootVar/Contstant";

export const TableFooter = ({props}) => {

    return (
        <>
            {(props.info.pagination !== "drag_drop" && props.info.pagination !== "none") &&
                <div className="ak-table-bottom">
                    <div className="ak-table-per-page">
                        <select className="form-select">
                            <option>10</option>
                            <option>50</option>
                            <option>100</option>
                        </select>
                    </div>
                    <div className={`ak-table-pagination ${(props.table_id === props.url_table_id && rootHighlightPageContentParts === "pagination") && "selected"}`} onClick={()=>props.navigateToToolboxArea(props.info.idu,'pagination')}>
                        <div className="ak-table-pagination-info">1 to 5 of total: 5</div>
                        <div className="ak-table-pagination-nav">
                            <div>&laquo;</div>
                            <div className="selected">1</div>
                            <div>2</div>
                            <div>3</div>
                            <div>&raquo;</div>
                        </div>
                    </div>
                </div>
            }
            {/*{(props.info.pagination === "drag_drop" || props.info.pagination === "none") &&*/}
            {/*    <>*/}
            {/*        {props.info.multi_upload !== 0 &&*/}
            {/*            <div className="ak-multi-upload">*/}
            {/*                <a href="/" onClick={(e) => e.preventDefault()} className="ak-primary-button"><i className="fa-solid fa-file-arrow-up"></i> Upload</a>*/}
            {/*            </div>*/}
            {/*        }*/}
            {/*    </>*/}
            {/*}*/}

        </>
    );

}