import React, {Component} from 'react';

class SortMe extends Component {
    constructor(props) {
        super(props);
        this.state = {data: []};

        this._w = window;
        this._b = document.body;
        this._d = document.documentElement;

        this.ElementDragged = false;
        this.ElementDraggedIsRoot = false;

        this.ElementDraggingActive = false;
        this.ElementDraggedClick = {};
        this.ElementDraggedClone = null;
        this.SortableContainers = [];
        this.SortableSubContainers = [];
        this.Element = false;
        this.isSwaped = false;
        this.DraggedItem = false;
        this.OverItem = false;
        this.OverItemSwpped = false;
        this.startIndex = false;

    }
    //
    // //async componentDidMount() { }
    // componentDidMount() {
    //     //this.setState({data: this.props.rootLeftMenuAll});
    //     //this.shouldComponentUpdate(1);
    // }

    // shouldComponentUpdate(stopUp = 0) {
    //     // if (stopUp === 1) {
    //     //     return false;
    //     // } else {
    //     //     return true;
    //     // }
    // }

    saveMenu = () => {

        const myElements = document.getElementsByClassName(this.props.parentContainer + ' js-admiko-sort-me-container');
        let newList = {
            sortList: []
        };

        for (let i = 0; i < myElements.length; i++) {
            let newId = myElements[i].dataset.id;
            if (newId) {
                newList.sortList.push(newId)
            }
        }
        (async () => {
            await this.props.saveSortMe(newList);
        })()
    };

    render() {
        return this.props.children ?
            <>
                <div className="sort-me js-sortable" onMouseDown={this.dragMouseDown} onMouseMove={this.dragMouseMove} onMouseUp={this.dragMouseUp}>
                    {React.Children.map(this.props.children, child =>
                        React.cloneElement(child, {
                            sortMe: {
                                SortMoveUp: this.moveUp,
                                SortMoveDown: this.moveDown
                            }
                        })
                    )}
                </div>
            </>
            :
            <><p>Loading...</p></>;
    }

    moveUp = (e) => {
        e.preventDefault();
        let current = e.target.closest(".js-admiko-sort-me-container");
        let currentIndex = [...current.parentNode.children].indexOf(current);
        let prevEl = current.previousElementSibling;
        /*move normal*/
        if(prevEl !== null && currentIndex > 0){
            prevEl.before(current);
            this.saveMenu();
        }
    }

    moveDown = (e) => {
        e.preventDefault();
        let current = e.target.closest(".js-admiko-sort-me-container");
        let currentIndex = [...current.parentNode.children].indexOf(current);
        let totalChildsMain = e.target.closest(".js-sortable").childElementCount;

        let nextEl = current.nextElementSibling;
        /*move normal*/
        if(nextEl !== null && ((currentIndex+1) < totalChildsMain)){
            nextEl.after(current);
            this.saveMenu();
        }
    }

    dragMouseDown = (e) => {
        e.stopPropagation();
        if (e.target.closest('.js-admiko-sort-me-container') && e.target.closest('.js-admiko-sort-me-container').classList.contains("drag-clone-element")) {
            this.trashDragItem();
            return;
        }
        this.ElementDragged = e.target.closest('.js-admiko-sort-me-container');

        this.ElementDraggedToClone = e.target.closest('.js-admiko-sort-me-container');
        if (e && e.target && (e.target.closest('.js-admiko-move-me') || e.target.classList.contains('js-admiko-move-me')) && this.ElementDragged) {
            e.preventDefault();
            this.SortableContainers = document.querySelectorAll("." + this.props.parentContainer + ".js-admiko-sort-me-container");
            this.ElementDraggingActive = true;
            this.ElementDraggedClick = this.getPoint(e);
            this.makeDragItem(this.ElementDraggedToClone);
        } else {
            this.trashDragItem();
        }
    }

    dragMouseMove = (e) => {
        e.stopPropagation();
        if (this.ElementDraggedClone && this.ElementDraggingActive) {
            e.preventDefault();
            let point = this.getPoint(e);
            this.OverItem = false;
            this.DraggedItem = this.ElementDragged;
            this.moveItem(this.ElementDraggedClone, (point.x - this.ElementDraggedClick.x), (point.y - this.ElementDraggedClick.y));
            //let onTop = "UP";
            let direction = "UP";
            // keep an eye for other sortable lists and switch over to it on hover
            for (let a = 0; a < this.SortableContainers.length; ++a) {
                let subContainer = this.SortableContainers[a];
                let onTop = this.onTop(subContainer, point.x, point.y);

                if (onTop.onTop) {
                    direction = onTop.direction;
                    this.Element = subContainer;
                    this.OverItem = subContainer;
                }
            }
            if (this.Element === this.ElementDragged) {
                this.OverItemSwpped = false;
                return;
            }
            //
            // if (this.OverItem === this.OverItemSwpped) {
            //     return;
            // }

            if (this.DraggedItem && this.OverItem) {
                this.swapItems(this.DraggedItem, this.OverItem, direction);
            }
        }

    }

    dragMouseUp = (e) => {
        e.stopPropagation();
        if (this.ElementDraggedClone && this.ElementDraggingActive && this.isSwaped) {
            this.isSwaped = false;
            this.trashDragItem()
            this.saveMenu();
        }
        this.trashDragItem();
    }


    getPoint = (e) => {
        let scrollX = Math.max(0, this._w.pageXOffset || this._d.scrollLeft || this._b.scrollLeft || 0) - (this._d.clientLeft || 0),
            scrollY = Math.max(0, this._w.pageYOffset || this._d.scrollTop || this._b.scrollTop || 0) - (this._d.clientTop || 0),
            pointX = e ? (Math.max(0, e.pageX || e.clientX || 0) - scrollX) : 0,
            pointY = e ? (Math.max(0, e.pageY || e.clientY || 0) - scrollY) : 0;

        return {x: pointX, y: pointY};
    }

    makeDragItem = (item) => {

        this.trashDragItem();
        let containerScrollY = 0;
        if(item.closest('.ak-admiko-add-scroll')){
            containerScrollY = item.closest('.ak-admiko-add-scroll').scrollTop;
        }
        this.ElementDraggedClone = document.createElement(item.tagName);
        this.ElementDraggedClone.className = item.className;
        this.ElementDraggedClone.classList.add("drag-clone-element");
        this.ElementDraggedClone.innerHTML = item.innerHTML;
        this.ElementDraggedClone.style["position"] = "absolute";
        this.ElementDraggedClone.style["z-index"] = "40";
        this.ElementDraggedClone.style["left"] = (item.offsetLeft || 0) + "px";
        this.ElementDraggedClone.style["top"] = (item.offsetTop || 0) - containerScrollY + "px";
        this.ElementDraggedClone.style["width"] = (item.offsetWidth || 0) + "px";
        item.parentNode.appendChild(this.ElementDraggedClone);
        item.classList.add("active");
    }

    trashDragItem = () => {
        if (this.ElementDraggedClone && this.ElementDragged) {
            this.ElementDragged.classList.remove("active");
            this.ElementDraggedToClone.classList.remove("active");
            this.ElementDraggedClone.remove();
            this.ElementDragged = false;
            this.ElementDraggedClone = false;
            this.ElementDraggingActive = false;
            this.ElementDraggedIsRoot = false;
            this.DraggedItem = false;
            this.OverItem = false;
            this.OverItemSwpped = false;
            this.startIndex = false;
        }
    }

    moveItem = (item, x, y) => {
        if(this.props.moveXY === true){
            item.style["-webkit-transform"] = "translateX( " + x + "px ) translateY( " + y + "px )";
            item.style["-moz-transform"] = "translateX( " + x + "px ) translateY( " + y + "px )";
            item.style["-ms-transform"] = "translateX( " + x + "px ) translateY( " + y + "px )";
            item.style["transform"] = "translateX( " + x + "px ) translateY( " + y + "px )";
        } else {
            item.style["-webkit-transform"] = "translateY( " + y + "px )";
            item.style["-moz-transform"] = "translateY( " + y + "px )";
            item.style["-ms-transform"] = "translateY( " + y + "px )";
            item.style["transform"] = "translateY( " + y + "px )";
        }

    }

    onTop = (item, x, y) => {
        let box = item.getBoundingClientRect(),
            isx = (x > box.left && x < (box.left + box.width)),
            isy = (y > box.top && y < (box.top + box.height)),
            onTop = false,
            direction = "UP";

        if(isx && isy){
            onTop = true;
        }
        if(onTop && (y > box.top && y < (box.top + (box.height/2)))){
            direction = "UP";
        } else if(onTop && (y > (box.top + box.height/2) && y < (box.top + box.height))){
            direction = "DOWN";
        }

        return {onTop: onTop, direction: direction};
        //return (isx && isy);
    }

    swapItems = (item1, item2,direction) => {

        let current = item1;
        let currentIndex = [...current.parentNode.children].indexOf(current);
        if(this.startIndex === false){
            this.startIndex = currentIndex;
        }
        let over = item2;
        let overIndex = [...item2.parentNode.children].indexOf(item2);

        //let prevEl = current.previousElementSibling;

        // if (item2 === this.OverItemSwpped) {
        //     return;
        // }

        if(currentIndex > overIndex && direction === "UP"){
            if(currentIndex > 0){
                over.before(current);
                this.OverItemSwpped = item2;
            }
        } else if(currentIndex < overIndex && direction === "DOWN"){
            let totalChildsMain = item1.closest(".js-sortable").childElementCount;
            if (((currentIndex + 1) < totalChildsMain)) {
                over.after(current);
            }
        }



        //
        // let parent1 = item1.parentNode,
        //     parent2 = item2.parentNode;
        //
        // let temp = document.createElement("div");
        // parent1.insertBefore(temp, item1);
        // parent2.insertBefore(item1, item2);
        // parent1.insertBefore(item2, temp);
        // parent1.removeChild(temp);

        this.isSwaped = true;
    }

}

export default SortMe;