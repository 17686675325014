import React, {useEffect, useState} from "react";
import {CheckFormOnChange} from "../../Helpers/CheckFormFields";

export const SettingsForm = (props) => {

    const formSettingsData = props.formSettingsData;
    const updateFormData = props.updateFormData;
    const formFieldsRules = {
        soft_delete: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        use_ajax: {required: false, type: "checkbox", rules: {checked: "ajax", unchecked: 0}},
        auditable_logs: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        multi_tenancy: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        multi_tenancy_team: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        image_width: {required: true, type: "input", rules: {minLength: 1, maxLength: 100}},
        image_height: {required: true, type: "input", rules: {minLength: 1, maxLength: 100}},
        image_upload_folder: {required: false, type: "input", rules: {minLength: 1, maxLength: 100}},
        file_upload_folder: {required: false, type: "input", rules: {minLength: 1, maxLength: 100}},
        checkbox_toggle: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        hide_id: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        webp: {required: false, type: "select"},
        page_width: {required: false, type: "select"},
        table_width: {required: false, type: "select"},
        show_width: {required: false, type: "select"},
        form_width: {required: false, type: "select"},
        use_language: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        user_management: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        disable_show: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
    }

    const [formData, setFormData] = useState(false);

    const onChangeFormData = (e) => {
        let val;
        if (formFieldsRules.hasOwnProperty(e.target.name)) {
            val = CheckFormOnChange(e, formFieldsRules);
        } else {
            val = e.target.value;
        }
        setFormData(prevFormData => ({...prevFormData, [e.target.name]: val}));
        if(e.target.name === 'user_management' && val === 0 && formData.multi_tenancy === 1){
            setFormData(prevFormData => ({...prevFormData, 'multi_tenancy': 0}));
        }
        if(e.target.name === 'user_management' && val === 0 && formData.multi_tenancy_team === 1){
            setFormData(prevFormData => ({...prevFormData, 'multi_tenancy_team': 0}));
        }
    };

    useEffect(() => {
        updateFormData(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    useEffect(() => {
        setFormData(formSettingsData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSettingsData])

    return formData !== false ? (
            <>
                <div className="pb-1 mb-3 border-b border-gray-200">Table</div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-4">
                    <div className="form-group">
                        <label htmlFor="table_width">Table width:</label>
                        <select id="table_width" name="table_width" className="form-select" value={formData.table_width} onChange={(e) => onChangeFormData(e)}>
                            <option value="full" key="full">Full</option>
                            <option value="medium" key="medium">Medium</option>
                            <option value="small" key="small">Small</option>
                        </select>
                    </div>
                    {/*<div className="form-group">*/}
                    {/*    <label htmlFor="show_width">Show page width:</label>*/}
                    {/*    <select id="show_width" name="show_width" className="form-select" value={formData.show_width} onChange={(e) => onChangeFormData(e)}>*/}
                    {/*        <option value="full" key="full">Full</option>*/}
                    {/*        <option value="medium" key="medium">Medium</option>*/}
                    {/*        <option value="small" key="small">Small</option>*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-4">
                    <div className="mb-6">
                        <label htmlFor="hide_id" className="inline-flex relative items-center cursor-pointer select-none">
                            <input type="checkbox" value="1" id="hide_id" name="hide_id" className="sr-only peer" checked={formData.hide_id === 1} onChange={(e) => onChangeFormData(e)}/>
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-400 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-700"></div>
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Hide</span>
                        </label>
                        <p className="text-sm text-gray-900">Hide ID from table view.</p>
                    </div>
                    <div className="mb-6">
                        <label htmlFor="soft_delete" className="inline-flex relative items-center cursor-pointer select-none">
                            <input type="checkbox" id="soft_delete" name="soft_delete" className="sr-only peer" checked={formData.soft_delete === 1} onChange={(e) => onChangeFormData(e)}/>
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-400 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-700"></div>
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Soft delete</span>
                        </label>
                        <p className="text-sm text-gray-900">Use soft delete for your pages.</p>
                    </div>
                    <div className="mb-6">
                        <label htmlFor="use_ajax" className="inline-flex relative items-center cursor-pointer select-none">
                            <input type="checkbox" value="1" id="use_ajax" name="use_ajax" disabled={formData.use_ajax === "disabled"} className="sr-only peer" checked={formData.use_ajax === "ajax"} onChange={(e) => onChangeFormData(e)}/>
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-400 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-700"></div>
                            <span className={`ml-3 text-sm font-medium dark:text-gray-300 ${formData.use_ajax !== "disabled"?"text-gray-900":"text-gray-300"}`}>Ajax</span>
                        </label>
                        <p className={`text-sm ${formData.use_ajax !== "disabled"?"text-gray-900":"text-gray-300"}`}>Use Ajax for tables.</p>
                    </div>


                    <div className="mb-6">
                        <label htmlFor="auditable_logs" className="inline-flex relative items-center cursor-pointer select-none">
                            <input type="checkbox" value="1" id="auditable_logs" name="auditable_logs" disabled={formData.auditable_logs === "disabled"} className="sr-only peer" checked={formData.auditable_logs === 1} onChange={(e) => onChangeFormData(e)}/>
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-400 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-700"></div>
                            <span className={`ml-3 text-sm font-medium dark:text-gray-300 ${formData.auditable_logs !== "disabled"?"text-gray-900":"text-gray-300"}`}>Auditable logs</span>
                        </label>
                        <p className={`text-sm ${formData.auditable_logs !== "disabled"?"text-gray-900":"text-gray-300"}`}>Use auditable logs and track all changes.</p>
                    </div>

                    <div className="mb-6">
                        <label htmlFor="disable_show" className="inline-flex relative items-center cursor-pointer select-none">
                            <input type="checkbox" value="1" id="disable_show" name="disable_show" disabled={formData.disable_show === "disabled"} className="sr-only peer" checked={formData.disable_show === 1} onChange={(e) => onChangeFormData(e)}/>
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-400 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-700"></div>
                            <span className={`ml-3 text-sm font-medium dark:text-gray-300 ${formData.disable_show !== "disabled"?"text-gray-900":"text-gray-300"}`}>Disable show page</span>
                        </label>
                        <p className={`text-sm ${formData.disable_show !== "disabled"?"text-gray-900":"text-gray-300"}`}>Disable show page on table.</p>
                    </div>

                </div>

                <div className="pb-1 mb-3 border-b border-gray-200">Form</div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-4">

                    <div className="form-group">
                        <label htmlFor="form_width">Form width:</label>
                        <select id="form_width" name="form_width" className="form-select" value={formData.form_width} onChange={(e) => onChangeFormData(e)}>
                            <option value="full" key="full">Full</option>
                            <option value="medium" key="medium">Medium</option>
                            <option value="small" key="small">Small</option>
                        </select>
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-4">
                    <div className="form-group">
                        <label htmlFor="image_width">Image width in pixels:</label>
                        <input type="text" className={`form-input`} value={formData.image_width} onChange={(e) => onChangeFormData(e)} name="image_width" id="image_width" placeholder="Enter default image width"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="image_height">Image height in pixels:</label>
                        <input type="text" className={`form-input`} value={formData.image_height} onChange={(e) => onChangeFormData(e)} name="image_height" id="image_height" placeholder="Enter default image height"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="webp" className={`${formData.webp !== "disabled"?"text-gray-900":"text-gray-300"}`}>WebP support</label>
                        <select  disabled={formData.webp === "disabled"} id="webp" name="webp" className="form-select" value={formData.webp} onChange={(e) => onChangeFormData(e)}>
                            <option value="none" key="none">Disabled</option>
                            <option value="convert" key="convert">Convert to WebP</option>
                            <option value="add" key="add">Add WebP</option>
                        </select>
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-4">

                    <div className="form-group">
                        <label htmlFor="image_upload_folder">Image folder:</label>
                        <input type="text" className={`form-input`} value={formData.image_upload_folder} onChange={(e) => onChangeFormData(e)} name="image_upload_folder" id="image_upload_folder" placeholder="Enter default image upload folder"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="file_upload_folder">File folder:</label>
                        <input type="text" className={`form-input`} value={formData.file_upload_folder} onChange={(e) => onChangeFormData(e)} name="file_upload_folder" id="file_upload_folder" placeholder="Enter default file upload folder"/>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-4">

                    <div className="mb-6">
                        <label htmlFor="checkbox_toggle" className="inline-flex relative items-center cursor-pointer select-none">
                            <input type="checkbox" value="1" id="checkbox_toggle" name="checkbox_toggle" className="sr-only peer" checked={formData.checkbox_toggle === 1} onChange={(e) => onChangeFormData(e)}/>
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-400 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-700"></div>
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Switch</span>
                        </label>
                        <p className="text-sm text-gray-900">Display checkbox as switch.</p>
                    </div>
                </div>
                <div className="pb-1 mb-3 border-b border-gray-200">Project</div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-4">
                    <div className="mb-6">
                        <label htmlFor="user_management" className="inline-flex relative items-center cursor-pointer select-none">
                            <input type="checkbox" value="1" id="user_management" name="user_management" disabled={formData.user_management === "disabled"} className="sr-only peer" checked={formData.user_management === 1} onChange={(e) => onChangeFormData(e)}/>
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-400 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-700"></div>
                            <span className={`ml-3 text-sm font-medium dark:text-gray-300 ${formData.user_management !== "disabled"?"text-gray-900":"text-gray-300"}`}>User management</span>
                        </label>
                        <p className={`text-sm ${formData.user_management !== "disabled"?"text-gray-900":"text-gray-300"}`}>Add user, roles, permissions.</p>
                    </div>
                    <div className="mb-6">
                        <label htmlFor="multi_tenancy" className="inline-flex relative items-center cursor-pointer select-none">
                            <input type="checkbox" value="1" id="multi_tenancy" name="multi_tenancy" className="sr-only peer" disabled={(formData.user_management === "disabled" || formData.user_management === 0 || formData.multi_tenancy === "disabled")} checked={formData.multi_tenancy === 1} onChange={(e) => onChangeFormData(e)}/>
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-400 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-700"></div>
                            <span className={`ml-3 text-sm font-medium dark:text-gray-300 ${(formData.user_management !== "disabled" && formData.user_management !== 0 && formData.multi_tenancy !== "disabled")?"text-gray-900":"text-gray-300"}`}>Multi-tenancy users</span>
                        </label>
                        <p className={`text-sm ${(formData.user_management !== "disabled" && formData.user_management !== 0 && formData.multi_tenancy !== "disabled")?"text-gray-900":"text-gray-300"}`}>Allow users to see the records of other users</p>
                    </div>
                    <div className="mb-6">
                        <label htmlFor="multi_tenancy_team" className="inline-flex relative items-center cursor-pointer select-none">
                            <input type="checkbox" value="1" id="multi_tenancy_team" name="multi_tenancy_team" className="sr-only peer" disabled={(formData.user_management === "disabled" || formData.user_management === 0 || formData.multi_tenancy_team === "disabled")} checked={formData.multi_tenancy_team === 1} onChange={(e) => onChangeFormData(e)}/>
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-400 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-700"></div>
                            <span className={`ml-3 text-sm font-medium dark:text-gray-300 ${(formData.user_management !== "disabled" && formData.user_management !== 0 && formData.multi_tenancy_team !== "disabled")?"text-gray-900":"text-gray-300"}`}>Multi-tenancy team</span>
                        </label>
                        <p className={`text-sm ${(formData.user_management !== "disabled" && formData.user_management !== 0 && formData.multi_tenancy_team !== "disabled")?"text-gray-900":"text-gray-300"}`}>Group users into teams.</p>
                    </div>

                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-4">
                    <div className="mb-6">
                        <label htmlFor="use_language" className="inline-flex relative items-center cursor-pointer select-none">
                            <input type="checkbox" value="1" id="use_language" name="use_language" disabled={formData.use_language === "disabled"} className="sr-only peer" checked={formData.use_language === 1} onChange={(e) => onChangeFormData(e)}/>
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-400 dark:peer-focus:ring-blue-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-700"></div>
                            <span className={`ml-3 text-sm font-medium dark:text-gray-300 ${formData.use_language !== "disabled"?"text-gray-900":"text-gray-300"}`}>Multilingual support</span>
                        </label>
                        <p className={`text-sm ${formData.use_language !== "disabled"?"text-gray-900":"text-gray-300"}`}>Create language files for each page.</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-4">
                    <div className="form-group">
                        <label htmlFor="page_width">Page width:</label>
                        <select id="table_width" name="page_width" className="form-select" value={formData.page_width} onChange={(e) => onChangeFormData(e)}>
                            <option value="full" key="full">Full</option>
                            <option value="medium" key="medium">Medium</option>
                            <option value="small" key="small">Small</option>
                        </select>
                    </div>
                </div>
            </>
        ) :
        <>
            <div className="admiko-ak-loading">
                <i className="fa-solid fa-circle-notch"></i>
            </div>
        </>
        ;
};