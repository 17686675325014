import React, {useEffect, useRef, useState} from 'react';
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import {rootHideChat, rootLoggedUser} from "./User";
import {useLocation} from "react-router-dom";
import {apiInvalidEmail} from "../Api/useCallApi";

export const LoadTawkChat = () => {
    let tawkMessengerRef = useRef();
    const [tawkStatus, setTawkStatus] = useState(false);

    const location = useLocation();
    const isBuilderPath = location.pathname.includes('/builder');
    useEffect(() => {
        if (tawkStatus) {
            if (rootLoggedUser.email !== "" && isBuilderPath === false) {
                tawkMessengerRef.current.showWidget();
            } else if (isBuilderPath) {
                tawkMessengerRef.current.hideWidget();
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tawkStatus, rootLoggedUser, isBuilderPath])


    const onTawkLoad = () => {
        setTawkStatus(true);
        tawkMessengerRef.current.setAttributes({
            name: (rootLoggedUser.name !== "" ? rootLoggedUser.name : rootLoggedUser.email),
            email: rootLoggedUser.email,
            hash: rootLoggedUser.hash
        }, function (error) {
            if (error === 'INVALID_EMAIL') {
                (async () => {
                    await apiInvalidEmail({invalid_email: rootLoggedUser.email});
                })()
            }
        });
    }

    return (
        <>
            {(rootLoggedUser.email !== "" && !rootHideChat) &&
                <TawkMessengerReact
                    propertyId="60dca35c7f4b000ac03a6262"
                    widgetId="1f9ets8p1"
                    onLoad={onTawkLoad}
                    ref={tawkMessengerRef}
                />
            }
        </>
    )
}
