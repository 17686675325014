import React from "react";

import './vendors/fontawesome-free-6.4.0-web/css/all.min.css';
import {Route, Routes} from "react-router-dom";
import {PublicLayout} from "./Account/Providers/PublicLayout";
import {LoginPage} from "./Account/Login/Login";
import {NotFound} from "./Account/Dashboard/NotFound";
import {ProtectedLayout} from "./Account/Providers/ProtectedLayout";
import {Projects} from "./Account/Dashboard/Projects/Projects";
import {ProjectForm} from "./Account/Dashboard/Projects/ProjectForm";
import {Account} from "./Account/Dashboard/Account";
import {Clients} from "./Account/Dashboard/Clients/Clients";
import {ClientForm} from "./Account/Dashboard/Clients/ClientForm";
import {Contact} from "./Account/Dashboard/Contact";
import {BuilderProtectedLayout} from "./Account/Providers/BuilderProtectedLayout";
import {useAppRootError} from "./Account/Global/Errors";
import {GlobalSettingsForm} from "./Account/Dashboard/Projects/GlobalSettingsForm";
import Container from "./Builder/Container";
import {CreateAccount} from "./Account/Login/CreateAccount";
import {ResetPassword} from "./Account/Login/ResetPassword";
import {SetNewPassword} from "./Account/Login/SetNewPassword";
import {Plans} from "./Account/Dashboard/Plans/Plans";
import {Statements} from "./Account/Dashboard/Plans/Statements";
import {useAppUpdateNotification} from "./Account/Global/AppVersion";
import {News} from "./Account/Dashboard/News/News";
import {rootLoggedUser, useAppRootUser} from "./Account/Global/User";
import {useAppRootPageInfo} from "./Account/Global/PageInfo";
import {useAppRootProjects} from "./Account/Global/Projects";
import {useAppRootClients} from "./Account/Global/Clients";
import {useAppRootFramework} from "./Account/Global/Framework";
import {LoadTawkChat} from "./Account/Global/LoadTawkChat";
import {Affiliate} from "./Account/Dashboard/Affiliate/Affiliate";

function App() {
    useAppRootError();

    useAppRootUser();
    useAppRootPageInfo();
    useAppRootProjects();
    useAppRootClients();
    useAppRootFramework();

    useAppUpdateNotification();

    return (
        <><Routes>
            <Route element={<PublicLayout/>}>
                <Route path="/login" exact={true} element={<LoginPage/>}/>
                <Route path="/sign-up" exact={true} element={<CreateAccount/>}/>
                <Route path="/reset" exact={true} element={<ResetPassword/>}/>
                <Route path="/reset-password" exact={true} element={<SetNewPassword/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
            <Route element={<ProtectedLayout/>}>
                <Route path="/" exact={true} element={<Projects/>}/>
                <Route path="project/add" exact={true} element={<ProjectForm/>}/>
                <Route path="project/edit/:id" exact={true} element={<ProjectForm/>}/>
                <Route path="project/edit/:id/:url_install" exact={true} element={<ProjectForm/>}/>
                <Route path="global-settings" exact={true} element={<GlobalSettingsForm/>}/>

                <Route path="news" exact={true} element={<News/>}/>

                <Route path="account" exact={true} element={<Account/>}/>

                <Route path="clients" exact={true} element={<Clients/>}/>
                <Route path="client/add" exact={true} element={<ClientForm/>}/>
                <Route path="client/edit/:id" exact={true} element={<ClientForm/>}/>

                <Route path="users" exact={true} element={<ProjectForm/>}/>
                <Route path="user/add" exact={true} element={<ProjectForm/>}/>
                <Route path="user/edit/:id" exact={true} element={<ProjectForm/>}/>

                <Route path="contact" exact={true} element={<Contact/>}/>

                {/*<Route path="plans" exact={true} element={<Plans/>}/>*/}
                <Route path="plans/:thank_you?" exact={true} element={<Plans/>}/>
                <Route path="statements" exact={true} element={<Statements/>}/>
                <Route path="affiliate" exact={true} element={<Affiliate/>}/>



                <Route path="*" element={<NotFound/>}/>
            </Route>
            <Route element={<BuilderProtectedLayout/>}>
                <Route path="builder/:url_project_id" exact={true} element={<Container/>}/>
                <Route path="builder/:url_project_id/m/:url_menu_id/p/:url_page_id" exact={true} element={<Container/>}/>

                <Route path="builder/:url_project_id/m/:url_menu_id/p/:url_page_id/t/:url_table_id" exact={true} element={<Container/>}/>
                <Route path="builder/:url_project_id/m/:url_menu_id/p/:url_page_id/t/:url_table_id/l/:url_load_action" exact={true} element={<Container/>}/>

                <Route path="builder/:url_project_id/m/:url_menu_id/p/:url_page_id/w/:url_widget_id" exact={true} element={<Container/>}/>

                {/*<Route path="builder/:url_project_id/m/:url_menu_id/p/:url_page_id/f/:url_form_id" exact={true} element={<Container />}/>*/}
                <Route path="builder/:url_project_id/m/:url_menu_id/p/:url_page_id/f/:url_form_id/edit" exact={true} element={<Container/>}/>
                <Route path="builder/:url_project_id/m/:url_menu_id/p/:url_page_id/f/:url_form_id/e/:url_element_id" exact={true} element={<Container/>}/>
                <Route path="builder/:url_project_id/m/:url_menu_id/p/:url_page_id/f/:url_form_id/pe/:url_form_page_element" exact={true} element={<Container/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
        </Routes>
            <LoadTawkChat/>
        </>
    )
}

export default App;