import {CheckFormDataFields, checkInputAsYouType} from "./RootVar/Error/CheckFormFields";

export const arrayFindObjectByProp = (arr, prop, val) => {
    return arr.find((obj) => obj[prop] === val);
};
export const debounceFunction = (func, delay) => {
    let timer;
    return function() {
        let self = this;
        let args= arguments;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(self, args)
        }, delay)
    }
}
export const onChangeFormDataGlobal = (e,formFieldsRules,setFormData,submitButton,setSubmitButton) => {
    let value;

    if(checkInputAsYouType(e, formFieldsRules, submitButton, setSubmitButton)){
        if (formFieldsRules.hasOwnProperty(e.target.name)) {
            value = CheckFormDataFields(e, formFieldsRules, submitButton, setSubmitButton);
        } else {
            value = e.target.value;
        }
        setFormData(prevFormData => ({...prevFormData, [e.target.name]: value}));
    }
};

export const getRandomId = (data) => {

    let indexKey = 0;
    let randomKey = makeRandomId(8);
    for (let i = 0; i < data.length; i++) {
        indexKey = data.findIndex(p => p.key === randomKey);
        if (indexKey !== -1) {
            return getRandomId(data);
        }
    }
    return randomKey;
}
export const makeRandomId = (length) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}