import React from "react";

export const FormDecimal = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="decimal_limit">Decimal:</label>
            <div className="form-group-input">
                <input className="form-input" autoComplete="off" name="decimal_limit" id="decimal_limit" placeholder="Number of digits after the decimal point" value={formData.decimal_limit} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                <p className="ak-admiko-form-error">Required!</p>
            </div>
        </div>
    );

}