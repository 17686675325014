import React from "react";
import {FormInfo, Required} from "./Partials/Partials";
import {EditBox} from "../FormContentStart";

export const TextArea = (props) => {
    /*set props*/
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const editElement = props.editElement;

    const renderTextArea = (ElementData) => {
        switch (ElementData.editor) {
            case 'simple':
                return (
                    <>
                        <div className="ak-text-area">
                            <div className="ak-editor-toolbox">
                                <div>
                                    <i className="fa-solid fa-bold"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-italic"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-underline"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-align-justify"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-align-left"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-align-center"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-align-right"></i>
                                </div>

                                <div>
                                    <i className="fa-solid fa-list"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-list-ol"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-link"></i>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case 'advanced':
                return (
                    <>
                        <div className="ak-text-area">
                            <div className="ak-editor-toolbox">
                                <div>
                                    <i className="fa-solid fa-bold"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-italic"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-underline"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-align-justify"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-align-left"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-align-center"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-align-right"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-list"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-list-ol"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-link"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-code"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-paintbrush"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-fill-drip"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-font"></i>
                                </div>
                                <div>
                                    <i className="fa-solid fa-arrow-up-1-9"></i>
                                </div>

                            </div>
                        </div>
                    </>
                );
            default:
                return (<></>);
        }
    }

    return (
        <>
            <div className={`ak-form-group ak-w-${ElementData.width} js-form-elements js-admiko-sort-me-container ${props.element_id === ElementData.idu ? "selected" : ""}`} data-id={ElementData.idu}>
                <div className={`ak-form-group-box`} onClick={()=> editElement(ElementData.idu)}>
                    <div className="ak-form-label">
                        <label>{ElementData.title}<Required ElementData={ElementData}/></label>
                    </div>
                    <div className="ak-form-input">
                        {renderTextArea(ElementData)}
                        <textarea className="form-textarea" placeholder={ElementData.placeholder}></textarea>
                        <FormInfo ElementData={ElementData}/>
                    </div>
                </div>
                <EditBox position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement} idu={ElementData.idu}/>
            </div>
        </>
    );


}