import {useState} from 'react';

export let appRootProjects = [];
export let setAppRootProjects;

export let appGlobalSettings = false;
export let setAppGlobalSettings;

export const useAppRootProjects = () => {
    [appRootProjects, setAppRootProjects] = useState(false);
    [appGlobalSettings, setAppGlobalSettings] = useState(false);


}
export const setAppRootProjectsDirect = (data) => {
    setAppRootProjects(data);
};
export const setAppGlobalSettingsDirect = (data) => {
    setAppGlobalSettings(data);
};
export const glOpenLeftMenuContainer = () => {
    let elements = document.querySelectorAll(".ak-menu-item-link");
    for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        if (element.classList.contains('active')) {
            let totalHeight = element.closest(".ak-menu-dropdown").querySelector('.ak-menu-dropdown-container').scrollHeight
            element.closest(".ak-menu-dropdown").querySelector('.ak-menu-dropdown-container').style["height"] = totalHeight + 'px';
            element.closest(".ak-menu-dropdown").classList.toggle('active');
        }
    }
}