import React from "react";

export const FormDescription = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <>
            <div className="form-group">
                <label htmlFor="info">Description:</label>
                <div className="form-group-input">
                    <textarea value={formData.info} className="form-textarea" name="info" placeholder="Enter description" id="info" onChange={(e) => onChangeFormDataInput(e)}></textarea>
                </div>
            </div>
        </>
    );

}