import React from "react";
import {Text} from "./Elements/Text";
import {TextArea} from "./Elements/TextArea";
import {Image} from "./Elements/Image";
import {ImageBlobCrop} from "./Elements/ImageBlobCrop";
import {File} from "./Elements/File";
import {Select} from "./Elements/Select";
import {CheckBox} from "./Elements/CheckBox";
import {DateTime} from "./Elements/DateTime";
import {Time} from "./Elements/Time";
import {MoneyMeasurement} from "./Elements/MoneyMeasurement";
import {Float} from "./Elements/Float";
import {Numbers} from "./Elements/Numbers";
import {Email} from "./Elements/Email";
import {Password} from "./Elements/Password";
import {Separator} from "./Elements/Separator";
import {MultiCheckbox} from "./Elements/MultiCheckbox";
import {RadioGroup} from "./Elements/RadioGroup";
import {MultiSelect} from "./Elements/MultiSelect";
import {SelectCustom} from "./Elements/SelectCustom";
import {MultiCheckboxCustom} from "./Elements/MultiCheckboxCustom";
import {MultiSelectCustom} from "./Elements/MultiSelectCustom";
import {RadioGroupCustom} from "./Elements/RadioGroupCustom";
import {ParentChild} from "./Elements/ParentChild";
import {Slug} from "./Elements/Slug";
import {MapGoogle} from "./Elements/MapGoogle";
import {MapBing} from "./Elements/MapBing";
import {Range} from "./Elements/Range";
import {Color} from "./Elements/Color";
import {BigInteger} from "./Elements/BigInteger";
import {UserSelect} from "./Elements/UserSelect";
import {UserRadioGroup} from "./Elements/UserRadioGroup";
import {UserMultiCheckbox} from "./Elements/UserMultiCheckbox";
import {UserMultiSelect} from "./Elements/UserMultiSelect";
import {DateRange} from "./Elements/DateRange";

export const LoadFormElements = (props) => {
    /*set props*/
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const editElement = props.editElement;
    const url_element_id = props.url_element_id;

    switch (ElementData.type) {
        case 'text':
            return (<><Text element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'textarea':
            return (<><TextArea element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'image':
            return (<><Image element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'image_blob_crop':
            return (<><ImageBlobCrop element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'file':
            return (<><File element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'checkbox':
            return (<><CheckBox element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'date_time':
            return (<><DateTime element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'date_range':
            return (<><DateRange element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'time':
            return (<><Time element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'money_measure':
            return (<><MoneyMeasurement element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'numbers':
            return (<><Numbers element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'biginteger':
            return (<><BigInteger element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'range':
            return (<><Range element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'color':
            return (<><Color element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'float':
            return (<><Float element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'email':
            return (<><Email element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);

        case 'password':
            return (<><Password element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'separator':
            return (<><Separator element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);


        case 'slug':
            return (<><Slug element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);

        case 'select':
            return (<><Select element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'multi_select':
            return (<><MultiSelect element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'multi_checkbox':
            return (<><MultiCheckbox element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'radio_group':
            return (<><RadioGroup element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);


        case 'parent_child':
            return (<><ParentChild element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);


        case 'select_custom':
            return (<><SelectCustom element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'radio_group_custom':
            return (<><RadioGroupCustom element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'multi_checkbox_custom':
            return (<><MultiCheckboxCustom element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'multi_select_custom':
            return (<><MultiSelectCustom element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);

        case 'map_google':
            return (<><MapGoogle element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'map_bing':
            return (<><MapBing element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);

        case 'user_select':
            return (<><UserSelect element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'user_radio_group':
            return (<><UserRadioGroup element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'user_multi_select':
            return (<><UserMultiSelect element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);
        case 'user_multi_checkbox':
            return (<><UserMultiCheckbox element_id={url_element_id} ElementData={ElementData} position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement}/></>);

        default:
            return (<></>);
    }
}

