import React from "react";


export const FormWidth = (props) => {
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="width">Width:</label>
            <div className="form-group-input">
                <div className="form-group-input-radio-inline">
                    {[{title:"100%",value:'1_1'}, {title:"75%",value:'3_4'}, {title:"66%",value:'2_3'}, {title:"50%",value:'1_2'}, {title:"33%",value:'1_3'}, {title:"25%",value:'1_4'}].map(item => (
                        <div key={item.value} className={`${formData.width === item.value ? 'active' : ''}`}>
                            <input
                                type="radio"
                                id={`width_${item.value}`}
                                name="width"
                                value={item.value}
                                checked={formData.width === item.value}
                                onChange={(e) => onChangeFormDataInput(e)}
                            />
                            <label
                                htmlFor={`width_${item.value}`}
                            >
                                {item.title}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
//
// export const FormWidth = (props) => {
//     /*set props*/
//     const formData = props.formData;
//     const onChangeFormDataInput = props.onChangeFormDataInput;
//
//     return (
//         <div className="form-group">
//             <label htmlFor="width">Width:</label>
//             <div className="form-group-input">
//                 <select value={formData.width} className="form-select" name="width" id="width" onChange={(e) => onChangeFormDataInput(e)}>
//                     <option value="1_1">100%</option>
//                     {/*<option value="4_5">80%</option>*/}
//                     <option value="3_4">75%</option>
//                     <option value="2_3">66%</option>
//                     {/*<option value="3_5">60%</option>*/}
//                     <option value="1_2">50%</option>
//                     <option value="1_3">33%</option>
//                     <option value="1_4">25%</option>
//                     {/*<option value="1_5">20%</option>*/}
//                     {/*<option value="1_10">10%</option>*/}
//                 </select>
//             </div>
//         </div>
//     );
//
// }