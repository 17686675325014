import React, {useEffect, useState} from "react";
import {FormDeleteButton} from "../../Helpers/FormDeleteButton";
import {useIsMountedRef} from "../../../Helper/isMounted";
import {rootSetError} from "../../../Helper/RootVar/Error/Error";
import {apiDeletePage} from "../../../Api/usePage";
import {arrayFindObjectByProp} from "../../../Helper/Functions";
import {rootAllPages} from "../../../Helper/RootVar/Contstant";

export const DeleteSection = (props) => {
    /*set props*/
    const toggleActionMenuTab = props.toggleActionMenuTab;
    const section = props.section;
    const area = props.area;
    const url_page_id = props.url_page_id??false;
    const deletedParentAction = props.deletedParentAction;

    const isMountedRef = useIsMountedRef();
    const [slideIn, setSlideIn] = useState(false);
    const [containerDelete, setContainerDelete] = useState(true);

    useEffect(() => {
        setContainerDelete(true);
        if(url_page_id){
            const item = arrayFindObjectByProp(rootAllPages[url_page_id]?.content, "idu", section.idu)
            if (item && item.type === "element_container" && item.child_elements.length > 0) {
                setContainerDelete(false);
            }
        }

        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSlideIn(true);
        }, 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section])

    const hideBack = () => {
        setSlideIn(false);
        setTimeout(() => {
            toggleActionMenuTab("main");
        }, 200)
    }


    /*send button*/
    const [submitButtonTimer, setSubmitButtonTimer] = useState(0);
    const [submitButton, setSubmitButton] = useState({disable: false, state: ""});
    /*end send button*/

    const deleteMe = async () => {
        /*send button*/
        setSubmitButton({...submitButton, disable: true, state: "progress"});

        if (submitButtonTimer) {
            window.clearTimeout(submitButtonTimer);
            setSubmitButtonTimer(0);
        }
        /*end send button*/
        const response = await apiDeletePage({}, section.idu, area);

        if (!isMountedRef.current) {
            return false;
        }

        if (response.success === true) {
            deletedParentAction(response);
        } else {
            rootSetError(response);
            /*send button*/
            setSubmitButton({...submitButton, disable: false, state: "saved"});
            setSubmitButtonTimer(setTimeout(() => {
                if (!isMountedRef.current) {
                    return false;
                }
                setSubmitButton({...submitButton, disable: false, state: ""});
            }, 3000));
            /*end send button*/
        }

    }


    return (
        <div className={`admiko-action admiko-action-menu ${slideIn ? "admiko-show" : ""}`}>
            <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                <div className="info">
                    <h2><i className="fa-solid fa-trash"></i>Delete</h2>
                </div>
                <div className="close">
                    <div className="admiko-toggle-tools">
                        <div className="admiko-toggle-box">
                            <div>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="admiko-action-menu-body">
                <div className={`admiko-action-menu-body-action`}>
                    {containerDelete ? <>
                            <p>Delete forever?</p>
                            <FormDeleteButton submitButton={submitButton} deleteMe={deleteMe}/>
                        </>
                        :
                        <>
                            <p>Please remove all elements from container before deleting.</p>
                        </>
                    }
                    {/*<p>Delete forever?</p>*/}
                    {/*<FormDeleteButton submitButton={submitButton} deleteMe={deleteMe}/>*/}
                </div>
            </div>
        </div>
    );
}
