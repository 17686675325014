import React from "react";

export const FormPrefix = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="prefix">Prefix:</label>
            <div className="form-group-input">
                <input className="form-input" autoComplete="off" name="prefix" id="prefix" placeholder="Enter prefix" value={formData.prefix} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                <p className="ak-admiko-form-error">Required!</p>
            </div>
        </div>
    );

}