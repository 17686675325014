import {useEffect} from 'react';
import {rootSetError} from "./Error/Error";
import {apiFirstLoad} from "../../Api/useFirstLoad";
import {setRootAllPages, setRootLeftMenuAll, setRootFormElementsAll, setRootHomePageID, LoadPageSections, rootBuilderLink} from "./Contstant";
import {useNavigate, useParams} from "react-router-dom";

export const useBuilderRootFirstLoad = () => {

    let navigate = useNavigate();
    const {url_project_id = false, url_menu_id = false, url_page_id = false} = useParams();
    //, url_table_id = false, url_form_id = false, url_element_id = false
    /*loading menu pages on start*/
    useEffect(() => {

        (async () => {
            const response = await apiFirstLoad();
            if (response.success === true) {
                await setRootHomePageID(response.return.home);
                await setRootFormElementsAll(response.return.all_form_elements);
                await setRootLeftMenuAll(response.return.menu);
                await setRootAllPages(response.return.pages);

                let buildURL = "";
                if (url_project_id) {
                    buildURL += rootBuilderLink;
                } else {
                    return navigate("/")
                }
                if (url_menu_id) {
                    buildURL += '/m/' + url_menu_id
                } else {
                    buildURL += '/m/' + response.return.home
                }
                if (url_page_id) {
                    buildURL += '/p/' + url_page_id;
                    LoadPageSections(url_page_id);
                } else {
                    buildURL += '/p/' + response.return.home
                    return navigate(buildURL);
                }
                //
                // if (url_table_id) {
                //     buildURL += '/t/' + url_table_id
                // }
                // if (url_form_id) {
                //     if (url_element_id) {
                //         buildURL += '/f/' + url_form_id
                //     } else {
                //         buildURL += '/f/' + url_form_id + '/edit'
                //     }
                //
                // }
                // if (url_element_id) {
                //     buildURL += '/e/' + url_element_id
                // }
                //return navigate(buildURL);

            } else {
                rootSetError(response);
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

};
