import React from "react";

export const SortMeHelperTools = (props) => {
    /*set props*/
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;

    const showSettings = props.showSettings;
    const idu = props.idu;
    return (
        <>
            <div className="admiko-form-settings">
                {totalItems > 1 &&
                    <div className="move-section tft-move js-admiko-sort-me">
                        {position > 0 &&
                            <div className="move-up mouse-pointer js-admiko-move-me-up" onClick={(e) => sortMe.SortMoveUp(e)}><i className="fa-solid fa-chevron-up"></i></div>
                        }
                        <div className="mouse-move js-admiko-move-me"><i className="fa-solid fa-up-down-left-right"></i></div>
                        {position + 1 < totalItems &&
                            <div className="move-down mouse-pointer js-admiko-move-me-down" onClick={(e) => sortMe.SortMoveDown(e)}><i className="fa-solid fa-chevron-down"></i></div>
                        }
                    </div>
                }
                <div className="mouse-pointer element-edit" onClick={() => showSettings(idu)}><i className="fa-solid fa-gear"></i></div>

            </div>
        </>
    );
}