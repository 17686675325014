import React from 'react';
export const Text = (props) => {
    const item = props.item;

    const doNothing = (e) => {
        e.preventDefault();
    };

    if(item.settings.show_as === "1"){
        return (
            <>
                <a href="/" onClick={(e) => doNothing(e)}>{item.settings.prefix}{item.title}{item.settings.suffix}</a>
            </>
        );
    } else {
        return (
            <>
                {item.settings.prefix}{item.title}{item.settings.suffix}
            </>
        );
    }


}