import React, {useState} from "react";
import {rootTABLE_COLUMN_MAX_LENGTH} from "../../../../../../../Helper/RootVar/Contstant";

export const FormColumnTwo = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;
    const [infoClick, setInfoClick] = useState({table_column_two: false});

    return (
        <>
            <div className="form-group">
                <label htmlFor="table_column_two" className="mouse-pointer"  onClick={() => setInfoClick({table_column_two: !infoClick.table_column_two})}>Column name: <i className="fa-solid fa-circle-info"></i></label>
                <div className="form-group-input">
                    <input className={`form-input ${formData.table_column_two === "" && "ak-admiko-form-input-error"}`} autoComplete="off" name="table_column_two" id="table_column_two" placeholder="Enter column name" value={formData.table_column_two} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
            {infoClick.table_column_two &&
                <p className="ak-admiko-form-info">Unique field name in database table for current form. Max {rootTABLE_COLUMN_MAX_LENGTH} characters. Can't start with _0-9 or ends with _ (Limited: A-Z, a-z, 0-9 and _).
                    Highly recommended to use only a-z and _</p>
            }
        </>
    );

}