import React, {useEffect, useState} from "react";
import {FormSubmitButton} from "../Helpers/FormSubmitButton";
import {useAuth} from "../Providers/AuthProvider";
import {useIsMountedRef} from "../Helpers/isMounted";
import {CheckFormOnChange, CheckFormOnSubmit} from "../Helpers/CheckFormFields";
import {apiCreateUser} from "../Api/useCallApi";
import {getReCaptchaToken, loadReCaptchaScript, unloadReCaptcha} from "../Helpers/ReCaptcha";


export const CreateAccountForm = () => {

    const {login} = useAuth();
    const [referrer, setReferrer] = useState("");
    const isMountedRef = useIsMountedRef();
    /*send button*/

    const [submitButtonTimer, setSubmitButtonTimer] = useState(0);
    const [submitButton, setSubmitButton] = useState({disable: false, state: ""});
    /*end send button*/

    useEffect(() => {
        const refCookie = getCookie('referral');
        if (refCookie) {
            setReferrer(refCookie)
            // console.log('Referral value:', refCookie);
        }
        // Add reCaptcha
        loadReCaptchaScript();
        return () => {
            //remove reCaptcha
            unloadReCaptcha();
        };
    }, [])

    const formFields = {
        fields: {email: "", password: "", token: ""}
    }
    const formErrorData = {
        error: {email: false, password: false, token: false}
    }
    const formFieldsRules = {
        email: {required: true, type: "email", rules: {minLength: 5, maxLength: 150, allowedInput: "email"}},
        password: {required: true, type: "input", rules: {minLength: 6, maxLength: 100}}
    }

    const [formData, setFormData] = useState(formFields);
    const [fieldError, setFieldError] = useState(formErrorData);
    const [formError, setFormError] = useState(false);

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const onChangeFormData = (e) => {
        if (e.target.name === 'new-password') {
            e.target.name = 'password';
        }
        if (formFieldsRules.hasOwnProperty(e.target.name)) {
            e.target.value = CheckFormOnChange(e, formFieldsRules, submitButton, setSubmitButton);
        }
        setFormData(prevFormData => ({
            fields: {...prevFormData.fields, [e.target.name]: e.target.value}
        }));
    };

    /*SEND DATA*/
    const sendFormData = async (e) => {

        e.preventDefault();
        setFormError(false);
        /*send button*/
        setSubmitButton({...submitButton, disable: true, state: "progress"});
        /*send button*/
        if (submitButtonTimer) {
            window.clearTimeout(submitButtonTimer);
            setSubmitButtonTimer(0);
        }
        /*end send button*/

        if (CheckFormOnSubmit(formData, fieldError, setFieldError, formFieldsRules, submitButton, setSubmitButton)) {
            setSubmitButton({...submitButton, disable: false, state: ""});
            return;
        }
        let sendFormData = {...formData.fields};

        //get reCaptcha token
        let token = await getReCaptchaToken();
        if (!token) {
            setSubmitButton({...submitButton, disable: false, state: ""});
            return false;
        }
        sendFormData.referrer = referrer;
        sendFormData.token = token;
        const response = await apiCreateUser(sendFormData);

        if (!isMountedRef.current) {
            return false;
        }

        if (response.success === true) {
            login(response.return.user.token)
        } else {
            if (typeof response.message === 'object') {
                let errorMessages = [];
                Object.keys(response.message).forEach(key => {
                    errorMessages.push(...response.message[key]);
                });
                setFormError(errorMessages);
            } else if (response.message === 'invalid_email') {
                setFormError("Invalid email.");
            } else if (response.message === 'invalid_token') {
                setFormError("Invalid recaptcha token.");
            } else {
                setFormError(response.message);
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }
    };
    /*end form js*/

    return (
        <>
            <form className="mt-8">
                {formError !== false &&
                    <div className="error-message">
                        {(Array.isArray(formError) && formError.length > 0) ?
                            <>
                                {formError.map((item, i) => (
                                    <p key={i}>
                                        {item}
                                    </p>
                                ))}
                            </>
                            :
                            <>{formError}</>
                        }
                    </div>
                }
                <div className="form-group">
                    <label htmlFor="email">
                        Email address *
                    </label>
                    {/*value={formData.fields.email} */}
                    <input className={`form-input ${fieldError.error.email ? 'form-input-error' : ''}`} onChange={(e) => onChangeFormData(e)} id="email" name="email" type="email" required="" placeholder="Enter email" autoFocus="on" autoComplete="on"/>
                    <p className="required-input">Email is required.</p>
                </div>

                <div className="form-group">
                    <label htmlFor="password">
                        Password *
                    </label>
                    {/*value={formData.fields.password} */}
                    <input className={`form-input ${fieldError.error.password ? 'form-input-error' : ''}`} onChange={(e) => onChangeFormData(e)} id="new-password" name="new-password" type="password" required="" autoComplete="new-password" placeholder="Enter password"/>
                    <p className="required-input">Password is required. Min 6 characters.</p>
                </div>

                <div className="mt-6">
                    <FormSubmitButton buttonText={"Create"} submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                </div>
            </form>
        </>

    )
};