import React, {useEffect, useState} from "react";
import SortMe from "../../Helper/SortMe";
import {LoadFormElements} from "./LoadFormElements";
import {useNavigate} from "react-router-dom";
import {rootAllPageContent, rootBuilderLink, rootSinglePageForm, setSinglePageForm} from "../../Helper/RootVar/Contstant";
import {apiSortFormElements} from "../../Api/useForm";
import {ToolBoxFormEditButtons} from "./ToolBoxFormEditButtons";

export const FormContentStart = (props) => {
    /*set props*/
    const url_menu_id = props.url_menu_id;
    const url_page_id = props.url_page_id;
    const url_form_id = props.url_form_id;
    const url_element_id = props.url_element_id;
    const url_form_page_element = props.url_form_page_element;

    const prependLink = `${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}`;
    let navigate = useNavigate();

    const [fadeIn, setFadeIn] = useState(false);
    const [pageContent, setPageContent] = useState(false);
    const [formFieldManager, setFormFieldManager] = useState(0);

    useEffect(() => {
        if (rootAllPageContent.hasOwnProperty(url_form_id)) {
            if (rootAllPageContent[url_form_id].hasOwnProperty('form_content') && rootAllPageContent[url_form_id].form_content.hasOwnProperty('content')) {
                setPageContent(rootAllPageContent[url_form_id].form_content);
                setFormFieldManager(rootAllPageContent[url_form_id].form_content.settings.dynamic_fields);
                setSinglePageForm(url_page_id);
            } else {
                setPageContent(false);
                setFormFieldManager(0);
            }
        } else {
            /*todo: else*/
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rootAllPageContent[url_form_id])])

    const saveSortMe = (newList) => {
        (async () => {
            await apiSortFormElements(newList, url_form_id);
        })()
    }

    const editElement = (element_id) => {
        navigate(`${prependLink}/f/${url_form_id}/e/${element_id}`);
    }

    useEffect(() => {
        setTimeout(() => {
            setFadeIn(true);
        }, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className={`ak-form-container content-width-${pageContent !== false ? (pageContent.settings.form_width ?? "medium") : "medium"} ak-fade-in ${fadeIn === true ? "fade-in-start" : ""}`}>

                        {!Object.keys(rootSinglePageForm).length &&
                            <ToolBoxFormEditButtons table_btn={1} form_btn={1}
                                                    url_menu_id={url_menu_id} url_page_id={url_page_id} url_form_id={url_form_id} url_element_id={url_element_id} url_form_page_element={url_form_page_element}
                            />
                        }

                <div className="ak-section">
                    <div className="ak-section-header">
                        <div className="ak-section-header-left">
                            <h2 className="ak-section-title">Add new:</h2>
                        </div>
                    </div>
                    <div className="ak-section-content">
                        <form autoComplete="off">
                            <div className="ak-section-form-content">
                                {pageContent !== false ?
                                    <>
                                        {Array.isArray(pageContent.content) && pageContent.content.length > 0 ?
                                            <>
                                                <SortMe saveSortMe={saveSortMe} parentContainer="js-form-elements" moveXY={true}>
                                                    {pageContent.content.map((item, i) => (
                                                        <LoadFormElements key={`fe-` + item.idu} url_element_id={url_element_id} ElementData={item} position={i} totalItems={pageContent.content.length} editElement={editElement}/>
                                                    ))}
                                                </SortMe>
                                            </>
                                            :
                                            <>
                                                <div className="ak-form-empty" role="alert">
                                                    <span>Form is empty.</span> Add form elements from the toolbox on the right side <i className="fa-solid fa-arrow-right"></i>
                                                </div>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <div className="ak-form-loading" role="alert">
                                            <span><i className="fa-solid fa-circle-notch"></i></span> Loading, please wait...
                                        </div>
                                    </>}
                            </div>

                            <div className="ak-section-form-buttons">
                                <div className="ak-form-button-group ak-w100">
                                    <div className="ak-form-input">
                                        <button type="submit" onClick={(e) => e.preventDefault()} className="from-button-submit">Save</button>
                                        <button type="submit" onClick={(e) => e.preventDefault()} className="from-button-cancel">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {formFieldManager === 1 &&
                <div className={`ak-form-container ak-form-manager ak-fade-in ${fadeIn === true ? "fade-in-start" : ""}`}>
                    <div className="ak-section">
                        <div className="ak-section-header">
                            <div className="ak-section-header-left">
                                <div className="ak-form-info">
                                    Define visibility of each field for the form above.
                                </div>
                            </div>
                        </div>
                        <div className="ak-section-content">
                            <form autoComplete="off">
                                <div className="ak-section-form-content">
                                    {pageContent !== false ?
                                        <>
                                            {Array.isArray(pageContent.content) && pageContent.content.length > 0 ?
                                                <>
                                                    {pageContent.content.map((item, i) => (
                                                        <div key={i} className="ak-form-group">
                                                            <div className="ak-form-input ak-form-input-checkbox-toggle">
                                                                <div>
                                                                    <label>
                                                                        <input type="checkbox" value="" className="sr-only peer"/>
                                                                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                                        <span>{item.title}</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                                :
                                                <>
                                                    <div className="ak-form-empty" role="alert">
                                                        <span>Form is empty.</span> Add form elements from the toolbox on the right side <i className="fa-solid fa-arrow-right"></i>
                                                    </div>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <div className="ak-form-loading" role="alert">
                                                <span><i className="fa-solid fa-circle-notch"></i></span> Loading, please wait...
                                            </div>
                                        </>}
                                </div>
                                <div className="ak-section-form-buttons">
                                    <div className="ak-form-button-group ak-w100">
                                        <div className="ak-form-input">
                                            <button type="submit" onClick={(e) => e.preventDefault()} className="from-button-submit">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}


export const EditBox = (props) => {
    /*set props*/
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;

    const editElement = props.editElement;
    const idu = props.idu;
    return (
        <>
            <div className="admiko-form-settings">
                <div className="mouse-pointer element-edit" onClick={(e) => editElement(idu)}><i className="fa-solid fa-gear"></i></div>
                {totalItems > 1 &&
                    <div className="move-section tft-move js-admiko-sort-me">
                        {position > 0 &&
                            <div className="move-up mouse-pointer js-admiko-move-me-up" onClick={(e) => sortMe.SortMoveUp(e)}><i className="fa-solid fa-chevron-up"></i></div>
                        }
                        <div className="mouse-move js-admiko-move-me"><i className="fa-solid fa-up-down-left-right"></i></div>
                        {position + 1 < totalItems &&
                            <div className="move-down mouse-pointer js-admiko-move-me-down" onClick={(e) => sortMe.SortMoveDown(e)}><i className="fa-solid fa-chevron-down"></i></div>
                        }
                    </div>
                }

            </div>
        </>
    );
}
// export const EditBoxWidth = (props) => {
//     /*set props*/
//     const ElementData = props.ElementData;
//     return (
//         <>
//             <div className="admiko-form-settings-width">
//                 <div className="form-group-input-radio-inline">
//                     {[{title:"100%",value:'1_1'}, {title:"75%",value:'3_4'}, {title:"66%",value:'2_3'}, {title:"50%",value:'1_2'}, {title:"33%",value:'1_3'}, {title:"25%",value:'1_4'}].map(item => (
//                         <div className={`${ElementData.width === item.value ? 'active' : ''}`}>
//                             <input
//                                 type="radio"
//                                 id={`width_${item.value}`}
//                                 name="width"
//                                 value={item.value}
//                                 checked={ElementData.width === item.value}
//                                 // onChange={(e) => editElementWidth(e)}
//                             />
//                             <label
//                                 htmlFor={`width_${item.value}`}
//                             >
//                                 {item.title}
//                             </label>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </>
//     );
// }