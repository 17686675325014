import React from "react";
import {Link} from "react-router-dom";
import {appRootPageBreadcrumbs} from "../../Global/PageInfo";

export const Breadcrumbs = () => {
    return (
        <>
            <div className="ak-breadcrums-container">
                <div className="ak-breadcrums">
                    <nav className="flex" aria-label="Breadcrumb">
                        <ol className="flex items-center space-x-2">
                            <li>
                                <div>
                                    <Link to="/" draggable="false" className="text-gray-400 hover:text-gray-500">
                                        <svg className="h-5 w-5 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z" clipRule="evenodd"/>
                                        </svg>
                                        <span className="sr-only">Home</span>
                                    </Link>
                                </div>
                            </li>
                            {appRootPageBreadcrumbs.map((item, i) => (
                                <li key={i}>
                                    <div className="flex items-center">
                                        <svg className="h-5 w-5 flex-shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd"/>
                                        </svg>
                                        {(i + 1 === appRootPageBreadcrumbs.length) ?
                                            <>
                                                <span className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700">{item.title}</span>
                                            </>
                                            :
                                            <>
                                                <Link to={item.link} draggable="false" className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700">{item.title}</Link>
                                            </>
                                        }
                                    </div>
                                </li>
                            ))}

                        </ol>
                    </nav>
                </div>
            </div>
        </>

    )
};