export const quotes = [
    "”Spread love everywhere you go.” — Mother Teresa",
    "”Believe you can and you're halfway there.” — Theodore Roosevelt",
    "”In a gentle way, you can shake the world.” — Mahatma Gandhi",
    "”When it comes to luck, you make your own.” — Bruce Springsteen",
    "”You have to be where you are to get where you need to go.” — Amy Poehler",
    "“The only limit to our realization of tomorrow will be our doubts today.” — Franklin D. Roosevelt ",
    "“You do not find the happy life. You make it.” — Camilla Eyring Kimball",
    "“You Must Find A Place Inside Yourself Where Nothing Is Impossible.” — Brian Weiner",
    "“Learn as if you will live forever, live like you will die tomorrow.” — Mahatma Gandhi",
    "“There are three ways to ultimate success: The first way is to be kind. The second way is to be kind. The third way is to be kind.” —Mister Rogers",
    "“The pessimist sees difficulty in every opportunity. The optimist sees opportunity in every difficulty.“ — Winston Churchill",
    "“Experience is a hard teacher because she gives the test first, the lesson afterwards.” ―Vernon Sanders Law",
    "“Setting goals is the first step in turning the invisible into the visible.” — Tony Robbins",
    "“Either you run the day or the day runs you.” — Jim Rohn",
    "“When we strive to become better than we are, everything around us becomes better too.” — Paulo Coelho",
    "“The elevator to success is out of order. You’ll have to use the stairs, one step at a time.” — Joe Girard",
    "“Work until your bank account looks like a phone number.”",
    "“Talent wins games, but teamwork and intelligence win championships.” — Michael Jordan",
    "“Coming together is a beginning. Keeping together is progress. Working together is success.” — Henry Ford",
    "“Alone we can do so little, together we can do so much.” — Helen Keller",
    "“Just one small positive thought in the morning can change your whole day.” — Dalai Lama",
    "“Mondays are the start of the work week which offer new beginnings 52 times a year!“ – David Dweck",
    "“Friday sees more smiles than any other day of the workweek!”—Kate Summers",
    "“Life is like riding a bicycle. To keep your balance you must keep moving.” —Albert Einstein",
    "“If you really want to do something, you'll find a way. If you don't, you'll find an excuse.” ―Jim Rohn",
    "“Our greatest glory is not in never falling, but in rising every time we fall.” — Confucius ",
    "“When everything seems to be going against you, remember that the airplane takes off against the wind, not with it.” — Henry Ford",
    "“Make sure your worst enemy doesn’t live between your own two ears.” —Laird Hamilton",
    "“The two most important days in your life are the day you’re born and the day you find out why.” – Mark Twain",
    "“Celebrate each day as new beginning.” – The ritual of Sakura",
]