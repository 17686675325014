import React from "react";
import {LogoutUserToken} from "../Api/useCallApi";
import {useAuth} from "../Providers/AuthProvider";

export const LogoutLink = () => {

    const { logout } = useAuth();

    const LogoutUser = async (e) => {
        e.preventDefault();
        const response = await LogoutUserToken();
        if(response.success === true){
            logout();
        } else {
            logout();
        }
    };

    return (
        <>
            <a href="/" draggable="false" onClick={LogoutUser} className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-cloud-arrow-up"></i></span> <span className="ak-menu-item-title">Logout</span></a>
        </>
    )
};