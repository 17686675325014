import React, {useEffect, useState} from "react";
import {useIsMountedRef} from "../../../../../Helper/isMounted";
import {onChangeFormDataGlobal} from "../../../../../Helper/Functions";
import {rootSetError} from "../../../../../Helper/RootVar/Error/Error";
import {FormSubmitButton} from "../../../../Helpers/FormSubmitButton";
import {FormCloseButton} from "../../../../Helpers/FormCloseButton";
import {apiEditSinglePageForm} from "../../../../../Api/useSinglePageForm";
import {rootPAGE_ID_MAX_LENGTH, rootTABLE_NAME_MAX_LENGTH, rootTITLE_NAME_MAX_LENGTH} from "../../../../../Helper/RootVar/Contstant";

/*ADD new elements to a FORM*/
export const SingleFormPageSettings = (props) => {

    const idu = props.idu;
    const toggleActionMenuTab = props.toggleActionMenuTab;
    const section = props.section;

    const isMountedRef = useIsMountedRef();
    const [infoClick, setInfoClick] = useState({page_id: false});

    /*GET DATA for form from API server*/
    useEffect(() => {
        LoadFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        LoadFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(section)])

    const LoadFormData = () => {
        // let newProperty = {...formData}
        let newProperty = {}
        newProperty.edit_menu_title = section.title;
        newProperty.edit_page_title = section.page_title;
        newProperty.edit_page_description = section.description;
        newProperty.edit_page_id = section.page_id;
        newProperty.edit_page_hide_from_import = section.hide_from_import;

        newProperty.edit_table_name = section.database;

        newProperty.page_form_id = section.single_page_form.page_form_id;
        newProperty.auditable_logs = section.single_page_form.auditable_logs;
        newProperty.extend_model = section.single_page_form.extend_model;
        newProperty.extend_controller = section.single_page_form.extend_controller;
        newProperty.extend_request = section.single_page_form.extend_request;
        newProperty.form_width = section.single_page_form.form_width;
        setFormData(newProperty);

    }

    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        setSlideIn(true);
    }, [])

    const closeForm = () => {
        hideBack();
    };

    const hideBack = () => {
        setSlideIn(false);

        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            toggleActionMenuTab("main");
        }, 200);
    }

    /*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");
    /*form js*/
    const formFields = {
        edit_menu_title: "",
        edit_page_title: "",
        edit_page_description: "",
        edit_page_id: 0,
        edit_page_hide_from_import: 0,
        idu: 0,
        edit_table_name: "",
        auditable_logs: 0,
        extend_model: 0,
        extend_controller: 0,
        extend_request: 0,
        form_width: "medium",
    }
    const [formData, setFormData] = useState(formFields);

    const formFieldsRules = {
        edit_menu_title: {required: true, type: "input", rules: {minLength: 1, maxLength: rootTITLE_NAME_MAX_LENGTH}},
        edit_page_title: {required: true, type: "input", rules: {minLength: 1, maxLength: rootTITLE_NAME_MAX_LENGTH}},
        edit_page_description: {required: false, type: "input", rules: {minLength: 0, maxLength: 250}},
        edit_page_id: {required: true, type: "input", rules: {minLength: 1, maxLength: rootPAGE_ID_MAX_LENGTH, allowedInput: "lower_dash_numbers_protect_start_end"}},
        edit_page_hide_from_import: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        edit_table_name: {required: true, type: "input", rules: {minLength: 1, maxLength: rootTABLE_NAME_MAX_LENGTH, allowedInput: "lower_dash_numbers_protect_start_end"}},

        auditable_logs: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        extend_model: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        extend_controller: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        extend_request: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},

        form_width: {required: false, type: "select"},
    }

    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiEditSinglePageForm({...formData}, idu,formData.page_form_id);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/

    return (
        <>
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll ${slideIn ? "admiko-show" : ""}`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                            <div className="info">
                                <h2><i className="fa-solid fa-gears"></i>Page settings:</h2>
                                <p>Edit page properties.</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-action-menu-body">
                            <div className={`admiko-action-menu-body-action add-menu active`}>
                                <div>
                                    <form action="">
                                        <div className="form-group">
                                            <label htmlFor="edit_menu_title">Menu title:</label>
                                            <div className="form-group-input">
                                                <input className="form-input" autoComplete="off" name="edit_menu_title" id="edit_menu_title" placeholder="Enter menu title" value={formData.edit_menu_title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                <p className="ak-admiko-form-error">Required!</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="edit_page_title">Page title:</label>
                                            <div className="form-group-input">
                                                <input className="form-input" autoComplete="off" name="edit_page_title" id="edit_page_title" placeholder="Enter page title" value={formData.edit_page_title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                <p className="ak-admiko-form-error">Required!</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="edit_page_description">Description:</label>
                                            <div className="form-group-input">
                                                <textarea name="edit_page_description" id="edit_page_description" rows="4" placeholder="Enter page description" className="form-textarea" value={formData.edit_page_description} onChange={(e) => onChangeFormData(e)}></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="edit_page_id" className="mouse-pointer" onClick={() => setInfoClick({page_id: !infoClick.page_id})}>Page ID: <i className="fa-solid fa-circle-info"></i></label>
                                            <div className="form-group-input">
                                                <input className="form-input" autoComplete="off" name="edit_page_id" id="edit_page_id" placeholder="Enter unique ID" value={formData.edit_page_id} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                <p className="ak-admiko-form-error">Required min 3 characters!</p>
                                            </div>
                                        </div>
                                        {infoClick.page_id &&
                                            <p className="ak-admiko-form-info">Unique ID to use in url, routes, filenames etc.., Max {rootPAGE_ID_MAX_LENGTH} characters. Can't start with _0-9 or ends with _ (Limited: a-z, 0-9 and _).
                                                Highly recommended to use only a-z and _</p>
                                        }

                                        <div className="form-group">
                                            <label htmlFor="edit_table_name" className="mouse-pointer" onClick={() => setInfoClick({table_id: !infoClick.table_id})}>Table name: <i className="fa-solid fa-circle-info"></i></label>
                                            <div className="form-group-input">
                                                <input className="form-input" autoComplete="off" name="edit_table_name" id="edit_table_name" placeholder="Enter table name" value={formData.edit_table_name} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                <p className="ak-admiko-form-error">Required!</p>
                                            </div>
                                        </div>
                                        {infoClick.table_id &&
                                            <p className="ak-admiko-form-info">Unique database table name. Max {rootTABLE_NAME_MAX_LENGTH} characters. Can't start with 0-9 and _ or ends with _ (Limited: a-z, 0-9 and _).
                                                Highly recommended to use only a-z and _</p>
                                        }
                                        <div className="form-group">
                                            <label htmlFor="form_width">Form width:</label>
                                            <div className="form-group-input">
                                                <select value={formData.form_width} className="form-select" name="form_width" id="form_width" onChange={(e) => onChangeFormData(e)}>
                                                    <option value="full" key="full">Full</option>
                                                    <option value="medium" key="medium">Medium</option>
                                                    <option value="small" key="small">Small</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="ak-table-settings">
                                            <div className="form-group">
                                                <label htmlFor="edit_page_hide_from_import">Hide from import:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="edit_page_hide_from_import" id="edit_page_hide_from_import" value="1" checked={formData.edit_page_hide_from_import === 1} onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="auditable_logs">Auditable Logs:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="auditable_logs" id="auditable_logs" value="1" checked={formData.auditable_logs === 1} onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="extend_model">Extend Model:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="extend_model" id="extend_model" value="1" checked={formData.extend_model === 1} onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="extend_controller">Extend Controller:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="extend_controller" id="extend_controller" value="1" checked={formData.extend_controller === 1} onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="extend_request">Extend Request:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="extend_request" id="extend_request" value="1" checked={formData.extend_request === 1} onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                        <FormCloseButton closeForm={(e) => closeForm(e)}/>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
