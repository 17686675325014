import {fetchData} from "./useCallApi";

export const apiGetIcons = async (dataToSend,sec_id) => {
    const dataFetch = {action:"icons",dataPrepare:dataToSend,"sec_id":sec_id};
    return await fetchData(dataFetch);
}
export const apiEditIcon = async (dataToSend,sec_id) => {

    const dataFetch = {action:"icon_edit",dataPrepare:dataToSend,"sec_id":sec_id};
    return await fetchData(dataFetch);
}