import React from "react";
import TableForm from "./Table/TableForm";
import GalleryForm from "./Table/GalleryForm";
import CardsHorizontalForm from "./Table/CardsHorizontalForm";
import {makeRootHighlightPageContentParts, rootBuilderLink} from "../../Helper/RootVar/Contstant";
import {useNavigate} from "react-router-dom";
import ElementContainer from "./Widgets/ElementContainer";
import WidgetCustomClass from "./Widgets/WidgetCustomClass";
import WidgetCounter from "./Widgets/WidgetCounter";
import WidgetProgress from "./Widgets/WidgetProgress";
import WidgetLine from "./Widgets/WidgetLine";
//import {WidgetPie} from "./Widgets/WidgetPie";
import WidgetPie from "./Widgets/WidgetPie";
import WidgetBar from "./Widgets/WidgetBar";
import WidgetRadar from "./Widgets/WidgetRadar";
import WidgetRadialBar from "./Widgets/WidgetRadialBar";
import WidgetPolar from "./Widgets/WidgetPolar";
import WidgetArea from "./Widgets/WidgetArea";
import WidgetColumn from "./Widgets/WidgetColumn";
import WidgetDonut from "./Widgets/WidgetDonut";

export const LoadPageElement = (props) => {
    const item = props.item;
    const totalItems = props.totalItems;
    // const sortMe = props.sortMe;
    const sortMe = {'SortMoveUp': props.moveUp, 'SortMoveDown': props.moveDown};
    const position = props.position;
    const url_menu_id = props.url_menu_id;
    const url_page_id = props.url_page_id;
    const url_table_id = props.url_table_id;
    const url_widget_id = props.url_widget_id;
    const folder_idu = props.folder_idu ?? false;


    let navigate = useNavigate();
    const navigateToToolboxArea = (table_id, section) => {
        makeRootHighlightPageContentParts(false);
        navigate(`${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}/t/${table_id}/l/${section}`, {replace: true});
    }

    switch (item.layout) {
        case "table":
            return (
                <>
                    <TableForm key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                               table_id={item.idu} url_table_id={url_table_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                               navigateToToolboxArea={navigateToToolboxArea}/>
                </>
            );
        case "table_gallery":
            return (
                <>
                    <GalleryForm key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                                 table_id={item.idu} url_table_id={url_table_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                                 navigateToToolboxArea={navigateToToolboxArea}/>
                </>
            );
        case "table_cards":
            return (
                <>
                    <CardsHorizontalForm key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                                         table_id={item.idu} url_table_id={url_table_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                                         navigateToToolboxArea={navigateToToolboxArea}/>
                </>
            );
        case "element_container":
            return (
                <>
                    <ElementContainer key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                                      widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                                      navigateToToolboxArea={navigateToToolboxArea}
                                      in_container={true}
                        // pageContent={props.pageContent}
                                      pageContent={item}
                    />
                </>
            );
        case "custom_class":
        case "custom_html":
            return (
                <>
                    <WidgetCustomClass key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                                       widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                                       navigateToToolboxArea={navigateToToolboxArea}
                                       folder_idu={folder_idu}/>
                </>
            );
        case "widget_counter":
            return (
                <>
                    <WidgetCounter key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                                   widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                                   navigateToToolboxArea={navigateToToolboxArea}
                                   folder_idu={folder_idu}/>
                </>
            );
        case "widget_progress":
            return (
                <>
                    <WidgetProgress key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                                    widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                                    navigateToToolboxArea={navigateToToolboxArea}
                                    folder_idu={folder_idu}/>
                </>
            );
        case "widget_line":
            return (
                <>
                    <WidgetLine key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                                widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                                navigateToToolboxArea={navigateToToolboxArea}
                                folder_idu={folder_idu}/>
                </>
            );
        case "widget_area":
            return (
                <>
                    <WidgetArea key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                                widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                                navigateToToolboxArea={navigateToToolboxArea}
                                folder_idu={folder_idu}/>
                </>
            );
        case "widget_bar":
            return (
                <>
                    <WidgetBar key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                               widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                               navigateToToolboxArea={navigateToToolboxArea}
                               folder_idu={folder_idu}/>
                </>
            );
        case "widget_column":
            return (
                <>
                    <WidgetColumn key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                                  widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                                  navigateToToolboxArea={navigateToToolboxArea}
                                  folder_idu={folder_idu}/>
                </>
            );
        case "widget_radar":
            return (
                <>
                    <WidgetRadar key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                                 widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                                 navigateToToolboxArea={navigateToToolboxArea}
                                 folder_idu={folder_idu}/>
                </>
            );
        case "widget_pie":
            return (
                <>
                    <WidgetPie key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                               widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                               navigateToToolboxArea={navigateToToolboxArea}
                               folder_idu={folder_idu}/>
                </>
            );
        case "widget_donut":
            return (
                <>
                    <WidgetDonut key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                               widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                               navigateToToolboxArea={navigateToToolboxArea}
                               folder_idu={folder_idu}/>
                </>
            );
        case "widget_polar":
            return (
                <>
                    <WidgetPolar key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                                 widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                                 navigateToToolboxArea={navigateToToolboxArea}
                                 folder_idu={folder_idu}/>
                </>
            );
        case "widget_radial_bar":
            return (
                <>
                    <WidgetRadialBar key={`tf-` + item.idu} position={position} info={item} totalItems={totalItems} sortMe={sortMe}
                                     widget_id={item.idu} url_widget_id={url_widget_id} url_menu_id={url_menu_id} url_page_id={url_page_id}
                                     navigateToToolboxArea={navigateToToolboxArea}
                                     folder_idu={folder_idu}/>
                </>
            );
        default:
            return <></>;
    }


}