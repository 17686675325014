import React, {useEffect, useState} from "react";
import {FormSubmitButton} from "../Helpers/FormSubmitButton";
import {useIsMountedRef} from "../Helpers/isMounted";
import {CheckFormOnChange, CheckFormOnSubmit} from "../Helpers/CheckFormFields";
import {apiResetPassword} from "../Api/useCallApi";
import {getReCaptchaToken, loadReCaptchaScript, unloadReCaptcha} from "../Helpers/ReCaptcha";


export const ResetPasswordForm = () => {


    const isMountedRef = useIsMountedRef();
    /*send button*/
    const [submitButtonTimer, setSubmitButtonTimer] = useState(0);
    const [submitButton, setSubmitButton] = useState({disable: false, state: ""});
    /*end send button*/

    useEffect(() => {
        // Add reCaptcha
        loadReCaptchaScript();
        return () => {
            //remove reCaptcha
            unloadReCaptcha();
        };
    }, [])

    const formFields = {
        fields: {email: "", token: ""}
    }
    const formErrorData = {
        error: {email: false, token: false}
    }
    const formFieldsRules = {
        email: {required: true, type: "email", rules: {minLength: 5, maxLength: 150, allowedInput: "email"}}
    }

    const [formData, setFormData] = useState(formFields);
    const [fieldError, setFieldError] = useState(formErrorData);
    const [formError, setFormError] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);

    const onChangeFormData = (e) => {
        if (formFieldsRules.hasOwnProperty(e.target.name)) {
            e.target.value = CheckFormOnChange(e, formFieldsRules, submitButton, setSubmitButton);
        }
        setFormData(prevFormData => ({
            fields: {...prevFormData.fields, [e.target.name]: e.target.value}
        }));
    };

    /*SEND DATA*/
    const sendFormData = async (e) => {
        setFormSuccess(false);
        e.preventDefault();
        setFormError(false);
        /*send button*/
        setSubmitButton({...submitButton, disable: true, state: "progress"});
        /*send button*/
        if (submitButtonTimer) {
            window.clearTimeout(submitButtonTimer);
            setSubmitButtonTimer(0);
        }
        /*end send button*/

        if (CheckFormOnSubmit(formData, fieldError, setFieldError, formFieldsRules, submitButton, setSubmitButton)) {
            setSubmitButton({...submitButton, disable: false, state: ""});
            return;
        }
        let sendFormData = {...formData.fields};

        //get reCaptcha token
        let token = await getReCaptchaToken();
        if (!token) {
            setSubmitButton({...submitButton, disable: false, state: ""});
            return false;
        }
        sendFormData.token = token;
        const response = await apiResetPassword(sendFormData);

        if (!isMountedRef.current) {
            return false;
        }

        if (response.success === true) {
            setFormError(false);
            setFormSuccess(true);
        } else {
            if (typeof response.message === 'object') {
                let errorMessages = [];
                Object.keys(response.message).forEach(key => {
                    errorMessages.push(...response.message[key]);
                });
                setFormError(errorMessages);

            } else if (response.message === 'email_reset') {
                setFormError(false);
                setFormSuccess(true);
            } else {
                setFormError(response.message);
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }
    };
    /*end form js*/

    return (
        <>
            <form className="mt-8">
                {formError !== false &&
                    <div className="error-message">
                        {(Array.isArray(formError) && formError.length > 0) ?
                            <>
                                {formError.map((item, i) => (
                                    <p key={i}>
                                        {item}
                                    </p>
                                ))}
                            </>
                            :
                            <>{formError}</>
                        }
                    </div>
                }

                {formSuccess !== false ?
                    <div className="info-message">
                        If we have an account matching your email address, you will receive an email with a link to reset your password.
                    </div>
                    :
                    <>
                        <div className="form-group">
                            <label htmlFor="email">
                                Email address *
                            </label>
                            <input className={`form-input ${fieldError.error.email ? 'form-input-error' : ''}`} onBlur={(e) => onChangeFormData(e)} onChange={(e) => onChangeFormData(e)} id="email" name="email" type="email" required="" placeholder="Enter email" autoFocus="on" autoComplete="on"/>
                            <p className="required-input">Email is required.</p>
                        </div>
                        <div className="mt-6">
                            <FormSubmitButton buttonText={"Reset"} submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                        </div>
                    </>
                }

            </form>
        </>

    )
};