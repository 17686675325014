import React, {useEffect} from "react";
import {FormTitle} from "./Partials/FormTitle";
import {FormColumn} from "./Partials/FormColumn";
import {FormDescription} from "./Partials/FormDescription";
import {FormRequired} from "./Partials/FormRequired";
import {FormSubmitButton} from "../../../../../Helpers/FormSubmitButton";
import {FormWidth} from "./Partials/FormWidth";
import {FormSearch} from "./Partials/FormSearch";
import {FormCloseButton} from "../../../../../Helpers/FormCloseButton";
import {FormCustomClass} from "./Partials/FormCustomClass";
import {AdvancedSettings} from "../../../../../Helpers/AdvancedSettings";
import {FormPrefix} from "./Partials/FormPrefix";
import {FormSuffix} from "./Partials/FormSufix";
import {FormDisableAKJS} from "./Partials/FormDisableAKJS";
import {rootCUSTOM_CLASS_MAX_LENGTH, rootCUSTOM_PATH_LENGTH, rootCUSTOM_VALUE_MAX_LENGTH, rootTABLE_COLUMN_MAX_LENGTH} from "../../../../../../Helper/RootVar/Contstant";
import {FormUserConnectedSource} from "./Partials/FormUserConnectedSource";
import {FormUserConnectedSortSource} from "./Partials/FormUserConnectedSortSource";
import {FormUserCustomPath} from "./Partials/FormUserCustomPath";
import {FormUseAjaxSelect} from "./Partials/FormUseAjaxSelect";

export const UserSelect = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormData = props.onChangeFormData;
    const sendFormData = props.sendFormData;
    const closeForm = props.closeForm;
    const submitButton = props.submitButton;
    const section_id = props.section_id;
    const element_idu = props.element_idu;
    const checkSaveButtonOnChildStart = props.checkSaveButtonOnChildStart;
    useEffect(() => {
        checkSaveButtonOnChildStart(formFieldsRules);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formFieldsRules = {
        title: {required: true, type: "input", rules: {minLength: 1, maxLength: 64}},
        //elm_name: {required: true, type: "input", rules: {minLength: 1, maxLength: 64, allowedInput: "upper_lower_dash_numbers"}},
        table_column: {required: true, type: "input", rules: {minLength: 1, maxLength: rootTABLE_COLUMN_MAX_LENGTH, allowedInput: "upper_lower_dash_numbers_protect_start_end"}},
        required: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        search: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        use_ajax: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        // source: {required: true, type: "select"},
        prefix: {required: false, type: "input", rules: {minLength: 1, maxLength: 20}},
        suffix: {required: false, type: "input", rules: {minLength: 1, maxLength: 20}},
        custom_class: {required: false, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_CLASS_MAX_LENGTH, allowedInput: "upper_lower_dash_minus_numbers_space"}},

        custom_path: {required: true, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_PATH_LENGTH, allowedInput: "upper_lower_dash_numbers_backslash_protect_start_end"}},
        custom_data: {
            required: true, array_required: true, type: "array", fields: {
                value: {required: true, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_VALUE_MAX_LENGTH, allowedInput: "lower_upper_numbers_dash_minus"}}
            }
        },
        custom_sort_data: {
            required: true, array_required: true, type: "array", fields: {
                value: {required: true, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_VALUE_MAX_LENGTH, allowedInput: "lower_upper_numbers_dash_minus"}},
                order: {required: false, type: "select", rules: {}}
            }
        },
        disable_js: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
    }
    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules);
    };
    return (
        <>
            <form action="">
                <FormTitle formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormColumn formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormWidth formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormRequired formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormSearch formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                {formData.can_edit === 1 &&
                    <>
                        <FormUseAjaxSelect formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                        <FormUserCustomPath formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                        <FormUserConnectedSource section_id={section_id} element_idu={element_idu} formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                        <FormUserConnectedSortSource section_id={section_id} element_idu={element_idu} formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    </>
                }

                <AdvancedSettings>
                    <FormDescription formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormPrefix formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormSuffix formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormCustomClass formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormDisableAKJS formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                </AdvancedSettings>
                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                <FormCloseButton closeForm={(e) => closeForm(e)}/>
            </form>
        </>
    );

}