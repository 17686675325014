import React, {useEffect, useState} from "react";
import {useIsMountedRef} from "../../Helper/isMounted";

export const FormSubmitButton = (props) => {
     const {submitButton, sendFormData} = props;

    const isMountedRef = useIsMountedRef();

    /*send button*/
    const [submitButtonTimer, setSubmitButtonTimer] = useState(0);
    const [buttonState, setButtonState] = useState({disable: false, state: ""});
    /*end send button*/

    useEffect(() => {
        if (!isMountedRef.current) {
            return false;
        }
        if (submitButtonTimer) {
            window.clearTimeout(submitButtonTimer);
            setSubmitButtonTimer(0);
        }

        if(submitButton === "progress"){
            setButtonState({...buttonState, disable: true, state: "progress"});
        } else if(submitButton === "ready"){
            setButtonState({...buttonState, disable: false, state: ""});
        } else if(submitButton === "saved"){
            setButtonState({...buttonState, disable: false, state: "saved"});
            setSubmitButtonTimer(setTimeout(() => {
                if (!isMountedRef.current) {
                    return false;
                }
                setButtonState({...buttonState, disable: false, state: ""});
            }, 3000));
        } else {
            // setButtonState({...buttonState, disable: false, state: ""});
            setButtonState({...buttonState, disable: true, state: ""});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitButton])

    return (
        <>
            <div className="form-group">
                <div className="form-group-input">
                    <button type="submit" className="from-button button-submit" disabled={buttonState.disable} onClick={(e) => sendFormData(e)}>
                        <span className="button-state">
                            <span className={`button-state-progress ${buttonState.state === "progress" ? 'show-me' : ''}`}>
                                <i className="fa-solid fa-circle-notch"></i>
                            </span>
                            <span className={`button-state-saved ${buttonState.state === "saved" ? 'show-me' : ''}`}>
                                <i className="fa-solid fa-circle-check"></i>
                            </span>
                        </span>
                        <span className="button-text">Save</span>
                    </button>
                </div>
            </div>
        </>
    );
}
