import React, {useEffect, useState} from "react";
import {AddPageElement} from "./AddPageElement";
import {useIsMountedRef} from "../../../../Helper/isMounted";

export const AddNewPageSection = (props) => {
    /*set props*/
    const selectedSection = props.selectedSection;
    const toggleActionMenuTab = props.toggleActionMenuTab;

    const isMountedRef = useIsMountedRef();

    const [slideIn, setSlideIn] = useState(false);
    useEffect(() => {
        setSlideIn(true);
    }, [])

    const hideBack = () => {
        setSlideIn(false);
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            toggleActionMenuTab("main");
        }, 200)
    }

    /*TOGGLE ACTION*/
    const [actionElementTab, setActionElementTab] = useState({page: 'main', icon: "", header: "", info: ""});
    const toggleActionElementTab = (page, icon, header, info) => {
        setActionElementTab({page: page, icon: icon, header: header, info: info});
    };

    /*end form js*/
    return (
        <>
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll ${slideIn ? "admiko-show" : ""}`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                            <div className="info">
                                <h2><i className="fa-solid fa-plus"></i>Add section</h2>
                                <p>Add new sections to a page.</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-action-header">
                            <div className="info">
                                <h2><i className="fa-solid fa-database"></i>Data section</h2>
                                <p>Sections with the form builder.</p>
                            </div>
                        </div>
                        <div className="admiko-action-menu-body">
                            <div className={`admiko-action-menu-body-action`}>
                                <div className="add-page-element-container">
                                    <div className="add-page-element" onClick={() => toggleActionElementTab('tf', 'fa-solid fa-table', "Table/Form", "")}>
                                        <i className="fa-solid fa-table"></i>
                                        <h2>Table/Form</h2>
                                    </div>
                                    <div className="add-page-element" onClick={() => toggleActionElementTab('tf_gallery', 'fa-solid fa-images', "Gallery", "")}>
                                        <i className="fa-solid fa-images"></i><h2>Gallery</h2>
                                    </div>
                                    <div className="add-page-element" onClick={() => toggleActionElementTab('tf_cards', 'fa-regular fa-square', "Cards", "")}>
                                        <i className="fa-regular fa-square"></i><h2>Cards</h2>
                                    </div>
                                    {/*<div className="add-page-element" onClick={() => toggleActionElementTab('tf_people','fa-solid fa-users',"People","")}>*/}
                                    {/*    <i className="fa-solid fa-users"></i><h2>People</h2>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>

                        <>
                            <div className="admiko-action-header">
                                <div className="info">
                                    <h2><i className="fa-solid fa-square-poll-vertical"></i>Widgets</h2>
                                    <p>Insert charts or custom content.</p>
                                </div>
                            </div>
                            <div className="admiko-action-menu-body">
                                <div className={`admiko-action-menu-body-action`}>
                                    <div className="add-page-element-container">
                                        {selectedSection.is_on_root_page === 1 &&
                                            <>
                                                <div className="add-page-element" onClick={() => toggleActionElementTab('widget_counter', 'fa-solid fa-stopwatch', "Counter", "")}>
                                                    <i className="fa-solid fa-stopwatch"></i>
                                                    <h2>Counter</h2>
                                                </div>
                                                <div className="add-page-element" onClick={() => toggleActionElementTab('widget_progress', 'fa-solid fa-chart-simple', "Progress", "")}>
                                                    <i className="fa-solid fa-chart-simple"></i>
                                                    <h2>Progress</h2>
                                                </div>
                                                <div className="add-page-element" onClick={() => toggleActionElementTab('widget_line', 'fa-solid fa-chart-line', "Line chart", "")}>
                                                    <i className="fa-solid fa-chart-line"></i>
                                                    <h2>Line</h2>
                                                </div>
                                                <div className="add-page-element" onClick={() => toggleActionElementTab('widget_area', 'fa-solid fa-chart-area', "Area chart", "")}>
                                                    <i className="fa-solid fa-chart-area"></i>
                                                    <h2>Area</h2>
                                                </div>
                                                <div className="add-page-element" onClick={() => toggleActionElementTab('widget_pie', 'fa-solid fa-chart-pie', "Pie chart", "")}>
                                                    <i className="fa-solid fa-chart-pie"></i>
                                                    <h2>Pie</h2>
                                                </div>
                                                <div className="add-page-element" onClick={() => toggleActionElementTab('widget_bar', 'fa-solid fa-chart-bar', "Bar chart", "")}>
                                                    <i className="fa-solid fa-chart-bar"></i>
                                                    <h2>Bar</h2>
                                                </div>
                                                <div className="add-page-element" onClick={() => toggleActionElementTab('widget_column', 'fa-solid fa-chart-column', "Column chart", "")}>
                                                    <i className="fa-solid fa-chart-column"></i>
                                                    <h2>Column</h2>
                                                </div>
                                                <div className="add-page-element" onClick={() => toggleActionElementTab('widget_radar', 'fa-solid fa-circle-nodes', "Radar chart", "")}>
                                                    <i className="fa-solid fa-circle-nodes"></i>
                                                    <h2>Radar</h2>
                                                </div>
                                                <div className="add-page-element" onClick={() => toggleActionElementTab('widget_radial_bar', 'fa-solid fa-spinner', "Radial bar chart", "")}>
                                                    <i className="fa-solid fa-spinner"></i>
                                                    <h2>Radial</h2>
                                                </div>
                                                <div className="add-page-element" onClick={() => toggleActionElementTab('widget_polar', 'fa-solid fa-chart-pie', "Polar chart", "")}>
                                                    <i className="fa-solid fa-chart-pie"></i>
                                                    <h2>Polar</h2>
                                                </div>
                                                <div className="add-page-element" onClick={() => toggleActionElementTab('widget_donut', 'fa-solid fa-rainbow', "Donut chart", "")}>
                                                    <i className="fa-solid fa-rainbow"></i>
                                                    <h2>Donut</h2>
                                                </div>
                                                <div className="add-page-element" onClick={() => toggleActionElementTab('element_container', 'fa-regular fa-file-code', "Container", "")}>
                                                    <i className="fa-regular fa-file-code"></i>
                                                    <h2>Container</h2>
                                                </div>
                                            </>
                                        }
                                        <div className="add-page-element" onClick={() => toggleActionElementTab('widget_custom_element', 'fa-solid fa-code', "Custom widget", "")}>
                                            <i className="fa-solid fa-code"></i>
                                            <h2>Custom</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                    </div>
                </div>
            </div>
            {actionElementTab.page !== "main" && <AddPageElement idu={selectedSection.idu} actionElementTab={actionElementTab}
                                                                 toggleActionElementTab={toggleActionElementTab}

            />}
        </>
    );
}
