import React from 'react';
export const DateRange = (props) => {
    const createNiceDate = () => {
        let splitDate = new Date().toUTCString().split(" ");
        return splitDate[1]+' '+splitDate[2]+', '+splitDate[3];
    };
    return (
        <>
            {createNiceDate()}
        </>
    );
}