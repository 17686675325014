import React from "react";
import {FormInfo, Required} from "./Partials/Partials";
import {EditBox} from "../FormContentStart";
import {useEffect, useState} from "react";

export const RadioGroupCustom = (props) => {
    /*set props*/
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const editElement = props.editElement;

    const [radioChecked, setRadioChecked] = useState("");


    useEffect(() => {
        if (ElementData.required === 1) {
            setRadioChecked(ElementData.idu + "-0");
        } else {
            setRadioChecked("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (ElementData.required === 1) {
            setRadioChecked(ElementData.idu + "-0");
        } else {
            setRadioChecked("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ElementData.required])

    const onValueChange = (idu) => {
        setRadioChecked(idu);
    }
    return (
        <>
            <div className={`ak-form-group ak-w-${ElementData.width} js-form-elements js-admiko-sort-me-container ak-element-direction-${ElementData.direction} ${props.element_id === ElementData.idu ? "selected" : ""} ${ElementData.error === 1 ? "ak-form-error" : ""}`} data-id={ElementData.idu}>
                <div className={`ak-form-group-box`} onClick={() => editElement(ElementData.idu)}>
                    <div className="ak-form-label">
                        <label>{ElementData.title}<Required ElementData={ElementData}/></label>
                    </div>
                    <div className="ak-form-input ak-form-input-checkbox">
                        {Array.isArray(ElementData.form_display) && ElementData.form_display.length > 0 ?
                            <>
                                {ElementData.element_as_button === 1 ?
                                    <>
                                        {ElementData.form_display.map((item, i) => (
                                            <label className="label-checkbox" key={i}>
                                                <input type="radio" value={ElementData.idu + "-" + i} checked={radioChecked === (ElementData.idu + "-" + i)} onChange={() => onValueChange(ElementData.idu + "-" + i)} className="form-radio"/> <span>{item}</span>
                                            </label>
                                        ))}
                                    </>
                                    :
                                    <>
                                        {ElementData.form_display.map((item, i) => (
                                            <label className="label-checkbox" key={i}>
                                                <input type="radio" value={ElementData.idu + "-" + i} checked={radioChecked === (ElementData.idu + "-" + i)} onChange={() => onValueChange(ElementData.idu + "-" + i)} className="form-radio"/> <span>{item}</span>
                                            </label>
                                        ))}
                                    </>
                                }
                            </>
                            :
                            <>
                                <label className="label-checkbox">
                                    Empty, add data.
                                </label>
                            </>
                        }
                        <FormInfo ElementData={ElementData}/>
                    </div>
                </div>
                <EditBox position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement} idu={ElementData.idu}/>
            </div>
        </>
    );

}