import React from "react";

export const FormDisableAKJS = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <>
            {formData.can_edit === 1 &&
                <div className="form-group">
                    <label htmlFor="disable_js">Disable default JS:</label>
                    <div className="form-group-input">
                        <div className="form-group-input">
                            <input className="form-checkbox" type="checkbox" name="disable_js" value="1" checked={formData.disable_js === 1} id="disable_js" onChange={(e) => onChangeFormDataInput(e)}/>
                        </div>
                    </div>
                </div>
            }
        </>
    );

}