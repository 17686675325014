import React, {useCallback, useEffect, useState} from "react";
import {useIsMountedRef} from "../../../Helper/isMounted";
import {FormSubmitButton} from "../../Helpers/FormSubmitButton";
import {FormCloseButton} from "../../Helpers/FormCloseButton";
import {onChangeFormDataGlobal} from "../../../Helper/Functions";
import {apiUpdateWidgetsSettings} from "../../../Api/useWidgets";
import {rootSetError} from "../../../Helper/RootVar/Error/Error";
import {rootCUSTOM_CLASS_MAX_LENGTH, rootPAGE_ID_MAX_LENGTH} from "../../../Helper/RootVar/Contstant";
import {WidgetConnectedSource} from "./Partials/WidgetConnectedSource";
import {checkSaveButton} from "../../../Helper/RootVar/Error/CheckFormFields";
import {AdvancedSettings} from "../../Helpers/AdvancedSettings";

export const WidgetGlobal = (props) => {

    const [infoClick, setInfoClick] = useState({page_id: false, table_id: false});

    const LoadFormData = useCallback(() => {
        setWidgetIdu(props.widgetData.idu);
        let newProperty = {}
        newProperty.edit_page_title = props.widgetData.title;
        newProperty.edit_table_width = props.widgetData.table_width ?? "1_1";
        newProperty.edit_page_id = props.widgetData.page_id;
        newProperty.source = props.widgetData.widget_data?.source ?? "none";
        newProperty.filter_data = props.widgetData.widget_data?.filter_data ?? "all";
        newProperty.filter_days = props.widgetData.widget_data?.filter_days ?? 7;
        newProperty.filter_data_end = props.widgetData.widget_data?.filter_data_end ?? "all";
        newProperty.filter_days_end = props.widgetData.widget_data?.filter_days_end ?? 7;
        newProperty.aggregate_user_list = props.widgetData.settings ?? [];
        newProperty.group_by_idu = props.widgetData.widget_data?.group_by_idu ?? "none";
        newProperty.group_by_time = props.widgetData.widget_data?.group_by_time ?? "day";
        newProperty.edit_prefix = props.widgetData.widget_data.prefix;
        newProperty.edit_suffix = props.widgetData.widget_data.suffix;
        newProperty.edit_decimals = props.widgetData.widget_data.decimals ?? 0;
        newProperty.edit_missing_dates = props.widgetData.widget_data.fill_missing_dates ?? 0;
        newProperty.edit_disable_js = props.widgetData.disable_js ?? 0;
        newProperty.edit_date_picker = props.widgetData.date_picker ?? 0;
        newProperty.edit_custom_class = props.widgetData.custom_class ?? "";
        newProperty.edit_stacked = props.widgetData.widget_data?.edit_stacked ?? "none";
        // newProperty.edit_number_format = props.widgetData.widget_data.number_format ?? "number";
        setFormData(newProperty);
    }, [props.widgetData]);

    const widgetDataString = JSON.stringify(props.widgetData);

    useEffect(() => {
        LoadFormData();
    }, [widgetDataString,LoadFormData])

    const closeForm = () => {
        props.hideBack();
    };
    // const LoadFormData = () => {
    //     setWidgetIdu(props.widgetData.idu);
    //     let newProperty = {}
    //     newProperty.edit_page_title = props.widgetData.title;
    //     newProperty.edit_table_width = props.widgetData.table_width ?? "1_1";
    //     newProperty.edit_page_id = props.widgetData.page_id;
    //     newProperty.source = props.widgetData.widget_data?.source ?? "none";
    //     newProperty.filter_data = props.widgetData.widget_data?.filter_data ?? "all";
    //     newProperty.filter_days = props.widgetData.widget_data?.filter_days ?? 7;
    //     newProperty.filter_data_end = props.widgetData.widget_data?.filter_data_end ?? "all";
    //     newProperty.filter_days_end = props.widgetData.widget_data?.filter_days_end ?? 7;
    //     newProperty.aggregate_user_list = props.widgetData.settings ?? [];
    //     newProperty.group_by_idu = props.widgetData.widget_data?.group_by_idu ?? "none";
    //     newProperty.group_by_time = props.widgetData.widget_data?.group_by_time ?? "day";
    //     newProperty.edit_prefix = props.widgetData.widget_data.prefix;
    //     newProperty.edit_suffix = props.widgetData.widget_data.suffix;
    //     newProperty.edit_decimals = props.widgetData.widget_data.decimals ?? 0;
    //     newProperty.edit_missing_dates = props.widgetData.widget_data.fill_missing_dates ?? 0;
    //     newProperty.edit_disable_js = props.widgetData.disable_js ?? 0;
    //     newProperty.edit_date_picker = props.widgetData.date_picker ?? 0;
    //     newProperty.edit_custom_class = props.widgetData.custom_class ?? "";
    //     newProperty.edit_stacked = props.widgetData.widget_data?.edit_stacked ?? "none";
    //     // newProperty.edit_number_format = props.widgetData.widget_data.number_format ?? "number";
    //     setFormData(newProperty);
    // }

    /*FORM*/
    const isMountedRef = useIsMountedRef();
    const [submitButton, setSubmitButton] = useState("ready");
    const [widgetIdu, setWidgetIdu] = useState(false);
    /*form js*/
    // const formFields = {
    //     edit_page_title: "",
    //     edit_table_width: "1_1",
    //     edit_page_id: "",
    //     source: "none",
    //     filter_data: "all",
    //     filter_days: 7,
    //     aggregate_user_list: [],
    //     group_by_idu: "none",
    //     group_by_time: "day",
    // }
    const [formData, setFormData] = useState();
    // const [formData, setFormData] = useState(formFields);

    const formFieldsRules = {
        edit_page_title: {required: true, type: "input", rules: {minLength: 1, maxLength: 64}},
        edit_table_width: {required: false, type: "select"},
        // layout: {required: false, type: "select"},
        edit_page_id: {required: true, type: "input", rules: {minLength: 3, maxLength: rootPAGE_ID_MAX_LENGTH, allowedInput: "lower_dash_numbers_protect_start_end"}},
        // edit_number_format: {required: false, type: "select"},

        edit_prefix: {required: false, type: "input", rules: {minLength: 1, maxLength: 10}},
        edit_suffix: {required: false, type: "input", rules: {minLength: 1, maxLength: 10}},
        edit_decimals: {required: false, type: "checkbox", rules: {checked: 2, unchecked: 0}},
        edit_missing_dates: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},

        source: {required: true, type: "select"},
        filter_data: {required: false, type: "select"},
        filter_days: {required: true, type: "input", rules: {minLength: 1, maxLength: 4, allowedInput: "numbers"}},
        filter_data_end: {required: false, type: "select"},

        filter_days_end: {required: true, type: "input", rules: {minLength: 1, maxLength: 4, allowedInput: "numbers"}},
        aggregate_user_list: {
            required: true, array_required: true, type: "array", fields: {
                idu: {required: true, type: "select"},
                action: {required: true, type: "select"},
                title: {required: false, type: "input", rules: {maxLength: 20}},
                edit_number_format: {required: true, type: "select"},
            }
        },
        group_by_idu: {required: true, type: "select"},
        group_by_time: {required: false, type: "select"},
        edit_custom_class: {required: false, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_CLASS_MAX_LENGTH, allowedInput: "upper_lower_dash_minus_numbers_space"}},
        edit_disable_js: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        edit_date_picker: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        edit_stacked: {required: false, type: "select"},

    }

    useEffect(() => {
        if (formData !== undefined) {
            checkSaveButton(formData, formFieldsRules, submitButton, setSubmitButton);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(formData)])
    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
        if (e.target.name === "filter_data" && e.target.value !== "days") {
            if (formData.filter_days === "") {
                formData.filter_days = 7;
            }
        }
        if (e.target.name === "filter_data_end" && e.target.value !== "days") {
            if (formData.filter_days_end === "") {
                formData.filter_days_end = 7;
            }
        }
    };
    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules);
    };
    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiUpdateWidgetsSettings({...formData}, widgetIdu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };

    /*END FORM*/

    return (
        <>
            {formData ?
                <>
                    <div className="admiko-action-menu-body">
                        <div className={`admiko-action-menu-body-action`}>
                            <div>
                                <form action="">
                                    <div className="form-group">
                                        <label htmlFor="edit_page_title">Title:</label>
                                        <div className="form-group-input">
                                            <input className="form-input" autoComplete="off" name="edit_page_title" id="edit_page_title" placeholder="Enter title" value={formData.edit_page_title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                            <p className="ak-admiko-form-error">Required!</p>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="edit_page_id" className="mouse-pointer" onClick={() => setInfoClick({page_id: !infoClick.page_id})}>Widget ID: <i className="fa-solid fa-circle-info"></i></label>
                                        <div className="form-group-input">
                                            <input className="form-input" autoComplete="off" name="edit_page_id" id="edit_page_id" placeholder="Enter page ID" value={formData.edit_page_id} type="text" onChange={(e) => onChangeFormData(e)}/>
                                            <p className="ak-admiko-form-error">Required min 3 characters!</p>
                                        </div>
                                    </div>
                                    {infoClick.page_id &&
                                        <p className="ak-admiko-form-info">Unique ID to use for filenames etc.., Max {rootPAGE_ID_MAX_LENGTH} characters. Can't start with _0-9 or ends with _ (Limited: a-z, 0-9 and _).
                                            Highly recommended to use only a-z and _</p>
                                    }

                                    {/*<div className="form-group">*/}
                                    {/*    <label htmlFor="edit_page_description">Info:</label>*/}
                                    {/*    <div className="form-group-input">*/}
                                    {/*        <textarea value={formData.edit_page_description} className="form-textarea" name="edit_page_description" placeholder="Enter table info" id="edit_page_description" onChange={(e) => onChangeFormData(e)}></textarea>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="form-group">*/}
                                    {/*    <label htmlFor="edit_table_width">Width:</label>*/}
                                    {/*    <div className="form-group-input">*/}
                                    {/*        <select value={formData.edit_table_width} className="form-select" name="edit_table_width" id="edit_table_width" onChange={(e) => onChangeFormData(e)}>*/}
                                    {/*            <option value="1_1" key="1_1">100%</option>*/}
                                    {/*            <option value="1_2" key="1_2">50%</option>*/}
                                    {/*            <option value="1_3" key="1_3">33%</option>*/}
                                    {/*            <option value="1_4" key="1_4">25%</option>*/}
                                    {/*        </select>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="form-group">
                                        <label htmlFor="edit_table_width">Width:</label>
                                        <div className="form-group-input">
                                            <div className="form-group-input-radio-inline">
                                                {[{title: "100%", value: '1_1'},
                                                    {title: "50%", value: '1_2'},
                                                    {title: "33%", value: '1_3'},
                                                    {title: "25%", value: '1_4'}
                                                ].map(item => (
                                                    <div key={item.value} className={`${formData.edit_table_width === item.value ? 'active' : ''}`}>
                                                        <input
                                                            type="radio"
                                                            id={`width_${item.value}`}
                                                            name="edit_table_width"
                                                            value={item.value}
                                                            checked={formData.edit_table_width === item.value}
                                                            onChange={(e) => onChangeFormData(e)}
                                                        />
                                                        <label
                                                            htmlFor={`width_${item.value}`}
                                                        >
                                                            {item.title}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {(props.widgetData.layout === 'widget_line' || props.widgetData.layout === 'widget_area' || props.widgetData.layout === 'widget_bar' || props.widgetData.layout === 'widget_column') &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="edit_prefix">Prefix:</label>
                                                <div className="form-group-input">
                                                    <input className="form-input" autoComplete="off" name="edit_prefix" id="edit_prefix" placeholder="Enter prefix" value={formData.edit_prefix} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="edit_suffix">Suffix:</label>
                                                <div className="form-group-input">
                                                    <input className="form-input" autoComplete="off" name="edit_suffix" id="edit_suffix" placeholder="Enter suffix" value={formData.edit_suffix} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="decimals">Decimals:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="edit_decimals" value="2" checked={formData.edit_decimals === 2} id="decimals" onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="edit_missing_dates">Fill missing dates:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="edit_missing_dates" value="1" checked={formData.edit_missing_dates === 1} id="edit_missing_dates" onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="filter_data">Date start:</label>
                                        <div className="form-group-input flex flex-nowrap">
                                            <select value={formData.filter_data} className="form-select" name="filter_data" id="filter_data" onChange={(e) => onChangeFormData(e)}>
                                                <option value="all" key="all">All</option>
                                                <option value="days" key="days">Last</option>
                                                <option value="week" key="week">This week</option>
                                                <option value="month" key="month">This month</option>
                                                <option value="year" key="year">This year</option>
                                            </select>
                                            {formData.filter_data === 'days' &&
                                                <div className="flex flex-nowrap items-center pl-2">
                                                    <input type="text" className="form-input" autoComplete="off" value={formData.filter_days} name="filter_days" id="filter_days" onChange={(e) => onChangeFormData(e)}/>
                                                    <div className=" pl-1"> days.</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="filter_data_end">Date end:</label>
                                        <div className="form-group-input flex flex-nowrap">
                                            <select value={formData.filter_data_end} className="form-select" name="filter_data_end" id="filter_data_end" onChange={(e) => onChangeFormData(e)}>
                                                <option value="all" key="all">All</option>
                                                <option value="days" key="days">Next</option>
                                                <option value="week" key="week">This week</option>
                                                <option value="month" key="month">This month</option>
                                                <option value="year" key="year">This year</option>
                                            </select>
                                            {formData.filter_data_end === 'days' &&
                                                <div className="flex flex-nowrap items-center pl-2">
                                                    <input type="text" className="form-input" autoComplete="off" value={formData.filter_days_end} name="filter_days_end" id="filter_days_end" onChange={(e) => onChangeFormData(e)}/>
                                                    <div className=" pl-1"> days.</div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <WidgetConnectedSource key={widgetIdu}
                                                           activateGroup={props.activateGroup}
                                                           activateAggregateAddMore={props.activateAggregateAddMore}
                                                           formData={formData}
                                                           layout={props.widgetData.layout}
                                                           widgetIdu={widgetIdu}
                                                           setSubmitButton={setSubmitButton}
                                                           onChangeFormDataInput={onChangeFormDataInput}/>
                                    {/*<div>*/}
                                    {/*    source:*/}
                                    {/*    {JSON.stringify(formData.source, null, 2)}*/}
                                    {/*</div>*/}
                                    {/*<div>*/}
                                    {/*    group_by_idu:*/}
                                    {/*    {formData.group_by_idu}*/}
                                    {/*</div>*/}
                                    {/*<div>*/}
                                    {/*    group_by_time:*/}
                                    {/*    {formData.group_by_time}*/}
                                    {/*</div>*/}
                                    {/*<div>*/}
                                    {/*    aggregate_user_list:*/}
                                    {/*    {JSON.stringify(formData.aggregate_user_list, null, 2)}*/}
                                    {/*</div>*/}
                                    <AdvancedSettings>
                                        {(props.widgetData.layout === 'widget_bar' || props.widgetData.layout === 'widget_column') &&
                                            <div className="form-group">
                                                <label htmlFor="edit_stacked">Stacked:</label>
                                                <div className="form-group-input flex flex-nowrap">
                                                    <select value={formData.edit_stacked} className="form-select" name="edit_stacked" id="edit_stacked" onChange={(e) => onChangeFormData(e)}>
                                                        <option value="none" key="none">Disable</option>
                                                        <option value="stacked" key="stacked">Stacked</option>
                                                        <option value="stacked_full" key="stacked_full">Stacked 100%</option>
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                        <div className="form-group">
                                            <label htmlFor="edit_custom_class">Custom class:</label>
                                            <div className="form-group-input">
                                                <input className={`form-input`} autoComplete="off" name="edit_custom_class" id="edit_custom_class" placeholder="Enter your class name" value={formData.edit_custom_class} type="text" onChange={(e) => onChangeFormData(e)}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="edit_disable_js">Disable default JS:</label>
                                            <div className="form-group-input">
                                                <div className="form-group-input">
                                                    <input className="form-checkbox" type="checkbox" name="edit_disable_js" value="1" checked={formData.edit_disable_js === 1} id="edit_disable_js" onChange={(e) => onChangeFormData(e)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="edit_date_picker">Disable date picker:</label>
                                            <div className="form-group-input">
                                                <div className="form-group-input">
                                                    <input className="form-checkbox" type="checkbox" name="edit_date_picker" value="1" checked={formData.edit_date_picker === 1} id="edit_date_picker" onChange={(e) => onChangeFormData(e)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </AdvancedSettings>
                                    <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                    <FormCloseButton closeForm={(e) => closeForm(e)}/>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>Loading</>
            }
        </>
    );
}
