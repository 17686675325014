
import React from "react";

export const FormInfo = (props) => {
    /*set props*/
    const ElementData = props.ElementData;
    return (
        <>
            {ElementData.info && <p className="ak-form-info">{ElementData.info}</p>}
        </>
    );
}
export const Required = (props) => {
    /*set props*/
    const ElementData = props.ElementData;
    return (
        <>
            {ElementData.required === 1 && "*"}
        </>
    );
}
