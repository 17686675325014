import React, {PureComponent} from "react";
import ReactApexCharts from 'react-apexcharts';
import {ToolBoxEditButtons} from "./ToolBoxEditButtons";
class WidgetBar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {title: ""};
        this.drawChart();
    }

    drawChart() {
        this.numberOfLines = [];
        if (this.props.info.settings.length === 0) {
            this.numberOfLines.push({
                name: "Title",
                data: [85, 84, 42, 31, 35, 38, 43, 46, 78]
            })
        }
        if (this.props.info.settings.length >= 1) {
            if (this.props.info.settings[0]['edit_number_format'] === "currency") {
                this.numberOfLines.push({
                    name: (this.props.info.settings[0]['title'] ?? "Title"),
                    data: [80.02, 21.65, 85.78, 12.01, 69.58, 51.55, 148.89, 91.93, 88.99]
                })
            } else {
                this.numberOfLines.push({
                    name: (this.props.info.settings[0]['title'] ?? "Title"),
                    data: [80, 21, 85, 12, 69, 51, 148, 91, 88]
                })
            }

        }
        if (this.props.info.settings.length >= 2) {
            if (this.props.info.settings[1]['edit_number_format'] === "currency") {
                this.numberOfLines.push({
                    name: (this.props.info.settings[1]['title'] ?? "Title"),
                    data: [12.78, 55.05, 29.11, 37.62, 95.68, 25.05, 56.54, 87.77, 91.99]
                })
            } else {
                this.numberOfLines.push({
                    name: (this.props.info.settings[1]['title'] ?? "Title"),
                    data: [12, 55, 29, 37, 95, 25, 56, 87, 91]
                })
            }

        }
        if (this.props.info.settings.length >= 3) {
            if (this.props.info.settings[2]['edit_number_format'] === "currency") {
                this.numberOfLines.push({
                    name: (this.props.info.settings[2]['title'] ?? "Title"),
                    data: [11, 25.71, 86.46, 57.99, 35.78, 1.04, 57.57, 49.05, 86.01]
                })
            } else {
                this.numberOfLines.push({
                    name: (this.props.info.settings[2]['title'] ?? "Title"),
                    data: [11, 25, 86, 57, 35, 1, 57, 49, 86]
                })
            }

        }
        if (this.props.info.settings.length >= 4) {
            if (this.props.info.settings[3]['edit_number_format'] === "currency") {
                this.numberOfLines.push({
                    name: (this.props.info.settings[3]['title'] ?? "Title"),
                    data: [70.01, 11.17, 33.13, 46.89, 78.47, 4.88, 66.07, 73.08, 25.05]
                })
            } else {
                this.numberOfLines.push({
                    name: (this.props.info.settings[3]['title'] ?? "Title"),
                    data: [70, 11, 33, 46, 78, 4, 66, 73, 25]
                })
            }

        }
        if (this.props.info.settings.length >= 5) {
            if (this.props.info.settings[4]['edit_number_format'] === "currency") {
                this.numberOfLines.push({
                    name: (this.props.info.settings[4]['title'] ?? "Title"),
                    data: [20.01, 30.85, 20.77, 10.07, 0, 20.40, 30.99, 55.05, 56.07]
                })
            } else {
                this.numberOfLines.push({
                    name: (this.props.info.settings[4]['title'] ?? "Title"),
                    data: [20, 30, 20, 10, 0, 20, 30, 55, 56]
                })
            }

        }
        if (this.props.info.settings.length >= 6) {
            if (this.props.info.settings[5]['edit_number_format'] === "currency") {
                this.numberOfLines.push({
                    name: (this.props.info.settings[5]['title'] ?? "Title"),
                    data: [36.78, 55.99, 78.87, 21.18, 54.12, 10.13, 8, 35.08, 86.01]
                })
            } else {
                this.numberOfLines.push({
                    name: (this.props.info.settings[5]['title'] ?? "Title"),
                    data: [36, 55, 78, 21, 54, 10, 8, 35, 86],
                    formatter: function (value) {
                        return value + "$";
                    }
                })
            }

        }
        let stacked = false
        let stacked_type = "normal";
        if(this.props.info.stacked === "stacked"){
            stacked = true;
            stacked_type = "normal";
        } else if(this.props.info.stacked === "stacked_full"){
            stacked = true;
            stacked_type = "100%";
        }
        this.ApexChart = {
            series: this.numberOfLines,
            options: {
                chart: {
                    type: 'bar',
                    sparkline: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    stacked: stacked,
                    stackType: stacked_type,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 2,
                },
                title: {
                    text: undefined,
                },
                legend: {
                    show: true,
                },

                grid: {
                    show: true,
                    // row: {
                    //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    //     opacity: 0.5
                    // },
                },
                xaxis: {
                    categories: [this.props.info.group_by_title, this.props.info.group_by_title, this.props.info.group_by_title, this.props.info.group_by_title, this.props.info.group_by_title, this.props.info.group_by_title, this.props.info.group_by_title, this.props.info.group_by_title,],
                    labels: {
                        show: true
                    }
                },
                yaxis: {
                    labels: {
                        show: true
                    }
                },
                markers: {
                    size: 0,
                },
                tooltip: {
                    x: {
                        show: true,
                    },
                },
            },
        };

    }

    componentDidMount() {
        this.setState({title: this.props.info.idu});

    }

    componentDidUpdate(prevProps) {
        if (this.props.info.settings !== prevProps.info.settings) {
            this.drawChart();
            this.forceUpdate();
        }
    }

    // FormatNumber(number, number_format) {
    //     if (number_format == "currency") {
    //         const formattedCurrency = number.toLocaleString('en-US', {
    //             style: 'currency',
    //             currency: 'USD',
    //         });
    //         return formattedCurrency.replace('$', '');
    //     } else {
    //         return number;
    //     }
    //
    // }


    render() {

        return this.props.info.idu ?
            <>
                <div className={`ak-section-container ak-widget-container widget-width-ak-w-${this.props.info.table_width ?? "1_1"} js-page-elements-container js-admiko-page-element-sort-me ${this.props.widget_id === this.props.url_widget_id && "selected"} ${this.props.info.widget_error === 1 && "widget-error"}`}
                     data-id={this.props.info.idu} data-folder={this.props.folder_idu}>
                    <ToolBoxEditButtons idu={this.props.info.idu}
                                        table_id={this.props.widget_id} url_table_id={this.props.url_widget_id} url_menu_id={this.props.url_menu_id} url_page_id={this.props.url_page_id}
                                        position={this.props.position} table_btn={1} totalItems={this.props.totalItems} sortMe={this.props.sortMe}
                                        folder_idu={this.props.folder_idu}
                    />
                    {this.props.info.disable_date_picker === 0 &&
                        <div className="ak-widget-date-picker" title="Date picker">
                            <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/></svg>
                            </div>
                        </div>
                    }
                    <div className="ak-section-element-container">
                        <div className="ak-widget-small ak-widget-tall">
                            <div>
                                <h3>{this.props.info.title}</h3>
                            </div>
                            <div className="overflow-hidden w-full h-[300px]">
                                <ReactApexCharts options={this.ApexChart.options} series={this.ApexChart.series} type="bar" height={300}/>
                                {/*<ApexChart/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </>
            :
            <>
                <div className="ak-menu-item-loading">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </>;
    }

}

export default WidgetBar;
