import {useState} from 'react';
import {apiGetIcons} from "../../../Api/useIcons";
import {rootSetError} from "../Error/Error";

export let rootIcons = [];
export let setRootIcons;

export const useBuilderRootIcons  = () => {
    [rootIcons, setRootIcons] = useState([]);
};
export const LoadIcons = (vendor) => {
    (async () => {
        const response = await apiGetIcons({"vendor": vendor});
        if (response.success === true) {
            setRootIcons(response.return.icons)
        } else {
            rootSetError(response);
        }
    })()
}