import React from "react";
export const FormCloseButton = ({closeForm}) => {


    return (
        <>
            <div className="form-group">
                <div className="form-group-input">
                    <div className="from-button-close" onClick={(e) => closeForm(e)}><span className="button-text" >Close</span></div>
                </div>
            </div>
        </>
    );
}
