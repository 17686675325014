import React, {useEffect} from "react";

import {PageContentStart} from "./Page/PageContentStart";
import {FormContentStart} from "./Form/FormContentStart";
import {useNavigate, useParams} from "react-router-dom";
export const Content = () => {
    /*set props*/
    const {url_project_id = false, url_menu_id = false, url_page_id = false, url_table_id = false, url_widget_id = false, url_form_id = false, url_element_id = false, url_form_page_element = false} = useParams();

    let navigate = useNavigate();

    useEffect(() => {
        if(!url_menu_id || !url_page_id){
            return navigate('/')
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(!url_menu_id || !url_page_id){
            return navigate('/')
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_menu_id,url_page_id])

    return (
        <>
            {(url_page_id !== false) && (
                <div className={`ak-content`} id={`ak-content`}>
                    <>
                        {url_form_id ?
                            <>
                                <FormContentStart
                                    url_project_id={url_project_id}
                                    url_menu_id={url_menu_id}
                                    url_page_id={url_page_id}
                                    url_table_id={url_table_id}
                                    url_form_id={url_form_id}
                                    url_element_id={url_element_id}
                                    url_form_page_element={url_form_page_element}
                                />
                            </>
                            :
                            <>
                                {/*<PageContentStartDrag/>*/}
                                <PageContentStart
                                    url_project_id={url_project_id}
                                    url_menu_id={url_menu_id}
                                    url_page_id={url_page_id}
                                    url_table_id={url_table_id}
                                    url_form_id={url_form_id}
                                    url_element_id={url_element_id}
                                    url_widget_id={url_widget_id}
                                />
                            </>
                        }
                    </>
                </div>
            )}
        </>
    )
}

export default Content;