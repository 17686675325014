import React from 'react';

export const File = (props) => {
    const item = props.item;

    const doNothing = (e) => {
        e.preventDefault();
    };

    if(item.settings.show_action === "none"){
        return (
            <>
                file.pdf
            </>
        );
    } else {
        return (
            <>
                <a href="/" onClick={(e) => doNothing(e)}>file.pdf</a>
            </>
        );
    }
}