import React from "react";

export const FormElementDatabaseCustom = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <>
            <div className="form-group">
                <label htmlFor="element_database">Database name:</label>
                <div className="form-group-input">
                    <input className="form-input" autoComplete="off" name="element_database" id="element_database" placeholder="Enter database name" value={formData.element_database} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
        </>
    );

}