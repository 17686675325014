import {MenuLink} from "./MenuLink";
import React, {PureComponent} from "react";
import {openRootToolBox, rootLeftMenuAll} from "../../Helper/RootVar/Contstant";
import {closeRootAdmikoToolBoxMenuContainer, updateRootAdmikoToolBoxMenuContainer} from "../../Helper/RootVar/ToolBox/Menu/MenuToolBox";


class MenuList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {menuList: [], rootActiveMenuPageID: 0};
    }

    componentDidMount() {
        this.setState({menuList: rootLeftMenuAll, rootActiveMenuPageID: 0});
    }

    // componentWillReceiveProps(newProps) {
    //     const {menuPagesList, selectedPage} = newProps;
    //     this.setState({menuList: menuPagesList, selectedPage: selectedPage});
    // }
    static getDerivedStateFromProps(newProps, state) {
        //const {menuPagesList, selectedPage} = newProps;
        let newReturnProps = {menuList: state.menuList, rootActiveMenuPageID: state.rootActiveMenuPageID};
        if (newProps.rootLeftMenuAll !== state.menuList) {
            newReturnProps.menuList = newProps.rootLeftMenuAll;
        }
        if (newProps.rootActiveMenuPageID !== state.rootActiveMenuPageID) {
            newReturnProps.rootActiveMenuPageID = newProps.rootActiveMenuPageID;
        }

        return newReturnProps
    }
    // static getDerivedStateFromProps(props, state) {
    //     // // Any time the current user changes,
    //     // // Reset any parts of state that are tied to that user.
    //     // // In this simple example, that's just the email.
    //     // if (props.userID !== state.prevPropsUserID) {
    //     //     return {
    //     //         prevPropsUserID: props.userID,
    //     //         email: props.defaultEmail
    //     //     };
    //     // }
    //     // return null;
    // }

    componentDidUpdate(prevProps) {
        if (prevProps.rootLeftMenuAll !== this.props.rootLeftMenuAll) {
            this.fixOpenMenuDropDowns();
        }
    }

    fixOpenMenuDropDowns = () => {
        const ak_menu = document.getElementById("js-ak-menu");
        if (ak_menu.children.length > 0) {
            for (let b = 0; b < ak_menu.children.length; ++b) {
                if (ak_menu.children[b].classList.contains("ak-menu-dropdown") && ak_menu.children[b].classList.contains("active")) {
                    let ttlChildren = ak_menu.children[b].querySelector('.ak-menu-dropdown-container').children.length;
                    ak_menu.children[b].querySelector('.ak-menu-dropdown-container').style["height"] = (ttlChildren * 44) + 'px';
                }
            }
        }
    }

    /*click events*/
    setPage = (e, idu, type, isPage, isToolbox) => {

        if (isToolbox === 1) {
            e.preventDefault();
            updateRootAdmikoToolBoxMenuContainer(false,true,type,idu);
        }
        if (isPage === 1) {
            closeRootAdmikoToolBoxMenuContainer();
            openRootToolBox();
        }

    }

    toggleContainer = (e) => {
        e.preventDefault();
        if (e.target.closest(".ak-menu-dropdown").classList.contains('active')) {
            e.target.closest(".ak-menu-dropdown").querySelector('.ak-menu-dropdown-container').style["height"] = '0px';
        } else {
            let totalHeight = e.target.closest(".ak-menu-dropdown").querySelector('.ak-menu-dropdown-container').scrollHeight
            e.target.closest(".ak-menu-dropdown").querySelector('.ak-menu-dropdown-container').style["height"] = totalHeight + 'px';
        }
        e.target.closest(".ak-menu-dropdown").classList.toggle('active');
    }


    render() {
        return Array.isArray(this.state.menuList) && this.state.menuList.length > 0 ?
            <>
                {this.state.menuList.map((item) => (
                    <MenuLink key={item.idu} item={item}
                              // rootActiveMenuPageID={this.state.rootActiveMenuPageID}
                              setPage={this.setPage} toggleContainer={this.toggleContainer}/>
                ))}
            </>
            :
            <>
                <div className="ak-menu-item-loading">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </>;
    }


}

export default MenuList;