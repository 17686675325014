import React from 'react';

export function TabSortMenuItem({item,detect=0,folder='',moveUp,moveDown}) {


    return (
        <div className={`ak-admiko-menu-item js-admiko-menu-item-detect ${item.type === "menu_header" ? 'menu-header' : ''} ${detect === 1 ? 'js-folder-header' : ''}`} data-id={item.idu} data-folder={folder.idu}>
                <div className="ak-admiko-item-box">
                    {item.icon ? (
                        <>
                            <span className="ak-admiko-menu-item-icon" dangerouslySetInnerHTML={{__html: item.icon}}></span>
                        </>
                    ) : ''}
                    <span className="ak-admiko-menu-item-title">{item.title}</span>
                </div>
                <div className="ak-admiko-menu-action">
                    <div className="ak-admiko-menu-action-up mouse-pointer" onClick={(e) => moveUp(e)}><i className="fa-solid fa-angle-up"></i></div>
                    <div className="ak-admiko-menu-action-down mouse-pointer" onClick={(e) => moveDown(e)}><i className="fa-solid fa-angle-down"></i></div>
                    <div className="ak-admiko-menu-action-move"><i className="fa-solid fa-up-down"></i></div>
                </div>
        </div>
    );
}