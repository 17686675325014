import { useState,useEffect } from 'react';
import {useAuth} from "../Providers/AuthProvider";

export let appRootError = [];
export let setAppRootError;

export let appRootLogout = false;
export let setAppRootLogout;

export const useAppRootError = () => {
    [appRootError, setAppRootError] = useState(false);
    [appRootLogout, setAppRootLogout] = useState(false);

    const {logout} = useAuth();

    useEffect(() => {
        if(appRootLogout === true){
            logout();
            setAppRootLogout(false);
        }

        // if (rootERROR.hasOwnProperty('return') && rootERROR.return.hasOwnProperty('logout')) {
        //     if (rootERROR.return.logout === 1) {
        //         logout();
        //     }
        // }
        // if(appRootLogout === true){
        //     logout();
        //
        //     //setAppRootLogout(false);
        //     setAppRootLogout(false);
        // }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appRootLogout])

}
export const setRootCheckLogout = (response) => {

    if (response.hasOwnProperty('return') && response.return.hasOwnProperty('logout')) {

        if (response.return.logout === 1) {
            setAppRootLogout(true);
        }
    }
};
export const setAppRootCheckErrorDirect = (response) => {
    // if (response.return.hasOwnProperty('logout')) {
    //     if (response.return.logout === 1) {
    //         setAppRootLogout(true);
    //     }
    // }
};