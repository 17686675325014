import {fetchData} from "./useCallApi";

export const apiAddPageElement = async (dataToSend,sec_id) => {
    const dataFetch = {action:"add_page_element",dataPrepare:dataToSend,"sec_id":sec_id};
    return await fetchData(dataFetch);
}

export const apiEditPageSettings = async (dataToSend,sec_id) => {

    const dataFetch = {action:"edit_page_settings",dataPrepare:dataToSend,"sec_id":sec_id};
    return await fetchData(dataFetch);
}