import {useState, useEffect} from 'react';
import {apiGetPageElements} from "../../Api/usePage";
import {rootSetError} from "./Error/Error";
import {useParams} from "react-router-dom";

/**CONST VARIABLES THROUGH THE PROJECT**/
/*PROJECT ID*/
export let rootProjectID = "";
export let setRootProjectID;

/*BUILDER URL*/
export let rootBuilderLink = "";
export let setRootBuilderLink;

/*HOME PAGE ID*/
export let rootHomePageID = "";
export let setRootHomePageID;

/*MENU TOGGLE*/
export let rootLeftMenuVisible = true;
export let setRootLeftMenuVisible;

/*TOOLBOX TOGGLE*/
export let rootToolBoxVisible = true;
export let setRootToolBoxVisible;

/*TOOLBOX AREA START*/
/*TOOLBOX ELEMENT OPEN AREA AFTER TOOLBOX IS OPEN*/
export let rootOpenToolBoxArea = false;
export let setRootOpenToolBoxArea;

/*TOOLBOX ELEMENT OPEN AREA IF TABLE IS SELECTED */
export let rootSelectedTableOpenArea = false;
export let setRootSelectedTableOpenArea;

/*TOOLBOX ELEMENT OPEN AREA IF TABLE IS SELECTED */
export let rootHighlightPageContentParts = false;
export let setRootHighlightPageContentParts;
/*TOOLBOX AREA END*/

/*ELEMENTS DATA FOR A FORM*/
export let rootFormElementsAll = [];
export let setRootFormElementsAll;

/**END CONST VARIABLES THROUGH THE PROJECT**/

/**VARIABLES TO KEEP AND UPDATE DATA**/
export let rootActiveMenuID = 0;
export let setRootActiveMenuID;

/*VARIABLE TO KEEP MENU DATA*/
export let rootLeftMenuAll = [];
export let setRootLeftMenuAll;

/*ALL PAGES DATA*/
export let rootAllPages = [];
export let setRootAllPages;

/*ALL PAGES SECTIONS DATA*/
export let rootAllPageContent = [];
export let setRootAllPageContent;

/*ALL PAGES SECTIONS DATA*/
export let rootSinglePageForm = {};
export let setRootSinglePageForm;

/*SCROLL PAGE CONTENT TO ELEMENT DATA-ID*/
export let rootScrollTo = false;
export let setRootScrollTo;


/*DB and VAR_NAME LIMITS*/
export let rootTITLE_NAME_MAX_LENGTH = 64;/*used on table,widget name*/
export let rootDB_MAX_LENGTH = 55; /*used on form elements element_database*/
export let rootTABLE_NAME_MAX_LENGTH = 55;/*used on table database name*/
export let rootPAGE_ID_MAX_LENGTH = 25;
export let rootELEMENT_NAME_MAX_LENGTH = 25;
export let rootTABLE_COLUMN_MAX_LENGTH = 50;
export let rootCUSTOM_TITLE_MAX_LENGTH = 50;
export let rootCUSTOM_VALUE_MAX_LENGTH = 50;
export let rootCUSTOM_CLASS_MAX_LENGTH = 50;
export let rootCUSTOM_PATH_LENGTH = 100;
/*ALL FORM ELEMENTS*/
// export let rootAllFormElements = [];
// export let setRootAllFormElements;
// export let rootFormElementID = "";
// export let setRootFormElementID;

/**END VARIABLES TO KEEP AND UPDATE DATA**/

export const useBuilderRootConstants  = (project_id) => {

    [rootProjectID, setRootProjectID] = useState(project_id);
    [rootBuilderLink, setRootBuilderLink] = useState(`/builder/${project_id}`);
    [rootHomePageID, setRootHomePageID] = useState("");
    [rootLeftMenuVisible, setRootLeftMenuVisible] = useState(true);
    [rootToolBoxVisible, setRootToolBoxVisible] = useState(true);

    /*start ToolBox open Area*/
    [rootHighlightPageContentParts, setRootHighlightPageContentParts] = useState(false);
    /*end ToolBox open Area*/
    /*scroll to element after returning from a form*/
    [rootSinglePageForm, setRootSinglePageForm] = useState({});
    [rootScrollTo, setRootScrollTo] = useState(false);

    [rootFormElementsAll, setRootFormElementsAll] = useState([]);
    [rootActiveMenuID, setRootActiveMenuID] = useState(false);
    [rootAllPages, setRootAllPages] = useState(false);
    [rootLeftMenuAll, setRootLeftMenuAll] = useState([]);
    [rootAllPageContent, setRootAllPageContent] = useState([]);

    const {url_page_id = false,} = useParams();
    useEffect(() => {
        if (rootAllPages.hasOwnProperty(url_page_id)) {
            if (rootAllPages[url_page_id].hasOwnProperty('content')) {
                LoadPageSections(url_page_id);
            } else {
                /*todo: else*/
            }
        } else {
            /*todo: else*/
        }
    }, [url_page_id])


};
/*GET DATA BY ID FROM ALL PAGES*/
// export const getFromAllPages = (idu) => {
//     if(idu && rootAllPages.hasOwnProperty(idu)){
//         return rootAllPages[idu]
//     }
// }
/*GET PAGE-FORM (single form page) DATA FROM ALL PAGES*/
// export const setSinglePageForm = (page_id) => {
//     const page = rootAllPages[page_id]?.page?.single_page_form || {};
//     const formId = page.page_form_id || null;
//
//     if (formId) {
//         setRootSinglePageForm(page);
//         return page;
//     } else if (rootSinglePageForm) {
//         setRootSinglePageForm([]);
//     }
// }

export const setSinglePageForm = (page_id) => {
    if (!rootAllPages[page_id]?.page?.single_page_form?.page_form_id) {
        if (Object.keys(rootSinglePageForm).length > 0) {
            setRootSinglePageForm([]);
        }
        return;
    }

    setRootSinglePageForm(rootAllPages[page_id].page.single_page_form);
    return rootAllPages[page_id].page.single_page_form;
};
// export const setSinglePageForm = (page_id) => {
//     if (rootAllPages[page_id].hasOwnProperty('page')) {
//         if (rootAllPages[page_id].page.hasOwnProperty('single_page_form')) {
//             if (Object.keys(rootAllPages[page_id]).length > 0) {
//                 if (rootAllPages[page_id].page.single_page_form.hasOwnProperty('page_form_id')) {
//                     setRootSinglePageForm(rootAllPages[page_id].page.single_page_form);
//                     return rootAllPages[page_id].page.single_page_form;
//                 } else if(rootSinglePageForm){
//                     setRootSinglePageForm([]);
//                 }
//             }
//         }
//     }
// }

/*GO HOME*/
// export const rootGoHome = (e) => {
//     e.preventDefault();
//     // setRootActiveMenuPageID(rootHomePageID)
// }
/*OPEN CONTAINER IF ACTIVE PAGE IS INSIDE*/
export const rootOpenLeftMenuContainer = () => {
    let element = document.querySelector(".ak-menu-item.active");
    if (element && element.closest(".ak-menu-dropdown")) {
        if (!element.closest(".ak-menu-dropdown").classList.contains('active')) {
            element.closest(".ak-menu-dropdown").querySelector(".js-admiko-menu-dropdown-click").click();
        }
    }
}

/*TOGGLE TOOLBOX ON THE RIGHT SIDE*/
export const openRootToolBox = () => {
    if (!rootToolBoxVisible) {
        /*if right side TOOL BOX is hidde OPEN IT*/
        setRootToolBoxVisible(true);
    }
}
/*OPEN TOOLBOX AREA*/
// export const setRootOpenToolBoxArea = (area) => {
//     setRootOpenToolBoxArea(area);
// }
export const makeRootHighlightPageContentParts = (area) => {
    setRootHighlightPageContentParts(area);
}

// export const getRootHighlightPageContentParts = () => {
//     let value = rootHighlightPageContentParts;
//     setRootHighlightPageContentParts(false);
//     if (value !== false) {
//         return value;
//     } else {
//         return false;
//     }
// }
export const makeRootSelectedTableOpenArea = (area) => {
    setRootSelectedTableOpenArea(area);
}
// export const getRootSelectedTableOpenArea = () => {
//     let value = rootSelectedTableOpenArea;
//     setRootSelectedTableOpenArea(false);
//     if (value !== false) {
//         return value;
//     } else {
//         return false;
//     }
// }
// export const getRootOpenToolBoxArea = () => {
//     let value = rootOpenToolBoxArea;
//     setRootOpenToolBoxArea(false);
//     if (value !== false) {
//         return value;
//     } else {
//         return false;
//     }
// }

/*SET SCROLL TO ROOT VARIABLE*/
export const setRootScrollToID = (elementId) => {
    //if (!rootScrollTo) {
    setRootScrollTo(elementId);
    //}
}
export const getRootScrollToID = () => {
    let value = rootScrollTo;
    setRootScrollTo(false);
    if (value !== false) {
        return value;
    } else {
        return false;
    }
}
// export const resetToolBoxArea = (area) => {
//     setRootOpenToolBoxArea(false);
// }
/*ON PAGE SELECT LOAD SECTION TABLE AND FORM DATA*/
export const LoadPageSections = (page_id) => {
    // setRootAllPageContent([]);
    (async () => {
        const response = await apiGetPageElements(page_id);
        if (response.success === true) {
            /*todo: else*/
        } else {
            rootSetError(response);
        }
    })()
}
// /*ON PAGE SELECT LOAD SECTION TABLE AND FORM DATA*/
// export const LoadFormSections = (sec_id,table_form_idu) => {
//
//     (async () => {
//         const response = await apiGetAllFormElements(sec_id,table_form_idu);
//         if (response.success === true) {
//
//         } else {
//             rootSetError(response);
//         }
//     })()
// }