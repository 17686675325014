import React from "react";
import {rootAppUpdate} from "../Global/AppVersion";
export const AppVersionInfoBox = () => {

    return (
        <>
            {rootAppUpdate === true &&
                <>
                    <div className="border-l-4 border-blue-400 bg-blue-50 p-2 fixed top-0 left-1/2 -translate-x-1/2 mt-1 z-50">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"/>
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-blue-700">
                                    Update is available. Please refresh the page to use the latest version.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}
