import '../assets/css/index.css';

import React, {useState} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useAuth} from "./AuthProvider";
import {Menu} from "../Dashboard/Menu";
import {PageHeader} from "../Dashboard/LayoutParts/PageHeader";
import {Breadcrumbs} from "../Dashboard/LayoutParts/Breadcrumbs";
import {AppVersionInfoBox} from "../Helpers/AppVersionInfoBox";
export const ProtectedLayout = () => {

    const {user} = useAuth();
    let location = useLocation();
    const [leftMenuVisible, setLeftMenuVisible] = useState(true);

    if (!user) {
        return <Navigate to="/login" state={{from: location}} replace={true}/>;
    }

    return (
        <div className="ak-app">
            <div className="ak-container">
                <div className={`ak-sidebar-container ${!leftMenuVisible ? 'hide-me' : ''}`}>
                    <div className="ak-sidebar">
                        {/*<div className="inline-block mt-2 m-auto w-32 rounded-full bg-orange-500 p-1 text-xs font-bold text-center tracking-widest text-white leading-none">BETA v.1.0</div>*/}
                        <div className="mt-3 text-3xl font-black leading-none tracking-tight text-center">
                          <span className="animate-logo bg-clip-text text-transparent bg-gradient-to-r from-white via-blue-300 to-white">
                            ADMIKO
                          </span>
                        </div>
                        <div className="text-sm font-bold text-center tracking-widest text-white leading-none">SKIP THE LINE</div>
                        {/*<div className={`ak-logo ${rootLoggedUser.name === "a.s.c.s.s" ? 'ak-logo-in' : ''}`}>*/}
                        {/*    <div>ADMIKO</div>*/}
                        {/*    <div className="tagline">SKIP THE LINE</div>*/}
                        {/*</div>*/}
                        <nav className="ak-menu">
                            <Menu/>
                        </nav>
                    </div>
                </div>
                <div className="ak-content-container">
                    <div className="ak-header-container">
                        <div className="ak-header-left">
                            <div className="ak-toggle-menu" onClick={() => setLeftMenuVisible(!leftMenuVisible)}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="ak-header-right">

                        </div>
                    </div>
                    <div className="ak-page-info-container">
                        <Breadcrumbs/>
                        <PageHeader/>
                    </div>
                    <div className="ak-content">
                        <Outlet/>
                    </div>
                </div>
            </div>
            <AppVersionInfoBox/>
        </div>
    )
};