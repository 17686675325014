import React from "react";
export const FormDeleteButton = ({submitButton, deleteMe}) => {


    return (
        <>
            <button className="from-button button-submit delete-btn" disabled={submitButton.disable} onClick={deleteMe}>
                <span className="button-state">
                    <span className={`button-state-progress ${submitButton.state === "progress" ? 'show-me' : ''}`}>
                        <i className="fa-solid fa-circle-notch"></i>
                    </span>
                    <span className={`button-state-saved ${submitButton.state === "saved" ? 'show-me' : ''}`}>
                        <i className="fa-solid fa-circle-check"></i>
                    </span>
                </span>
                <span className="button-text">Delete</span>
            </button>
        </>
    );
}
