import React, {useEffect, useRef, useState} from "react";

import {FormSubmitButton} from "../../Helpers/FormSubmitButton";
import {useIsMountedRef} from "../../../Helper/isMounted";
import {apiAddPage} from "../../../Api/usePage";
import {rootSetError} from "../../../Helper/RootVar/Error/Error";
import {onChangeFormDataGlobal} from "../../../Helper/Functions";


export const TabAddPage = () => {
    const isMountedRef = useIsMountedRef();
    /*set focus on input element*/
    const inputFocus = useRef(null);
    useEffect(() => {
        let timeoutId = 0;
        timeoutId = setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            inputFocus.current.focus();
        }, 0)
        return () => clearTimeout(timeoutId);
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    /*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");

    const formFields = {title: "", type: 'page_crud'};
    const formFieldsRules = {
        title: {required: false, type: "input", rules: {minLength: 1, maxLength: 64}},
        type: {required: false, type: "select"},
    }
    const [formData, setFormData] = useState(formFields);
    const resetFormData = () => {
        setFormData(formFields);
        setFormData(prevFormData => ({...prevFormData, type: formData.type}));
    }
    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e,formFieldsRules,setFormData,submitButton,setSubmitButton);
    };
    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiAddPage({...formData});
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            resetFormData();
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/


    return (
        <div className={`admiko-action-menu-body-action add-page active`}>
            <h3>Add a new page:</h3>
            <div>
                <form action="">
                    <div className="form-group">
                        <label htmlFor="title">Name:</label>
                        <div className="form-group-input">
                            <input className="form-input" ref={inputFocus} autoComplete="off" name="title" id="title" placeholder="Enter name" value={formData.title} type="text" onChange={(e) => onChangeFormData(e)}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="type">Page type:</label>
                        <div className="form-group-input">
                            <select value={formData.type} className="form-select" name="type" id="type" onChange={(e) => onChangeFormData(e)}>
                                <option value="page_crud">Table/Form page</option>
                                <option value="page_empty">Empty page</option>
                                <option value="page_form">Form page</option>
                            </select>
                        </div>
                    </div>
                    <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                    {formData.type === 'page_crud' &&
                        <div className="form-group-helper">
                            <h5>Table/Form page</h5>
                            <p>Standard CRUD page with a table. Recommended in most cases.</p>
                        </div>
                    }
                    {formData.type === 'page_empty' &&
                        <div className="form-group-helper">
                            <h5>Empty page</h5>
                            <p>Create an empty page and add content.</p>
                        </div>
                    }
                    {formData.type === 'page_form' &&
                        <div className="form-group-helper">
                            <h5>Form page</h5>
                            <p>Single row form page.</p>
                        </div>
                    }
                </form>
            </div>
        </div>
    );
}
