import React from "react";
import {FormInfo, Required} from "./Partials/Partials";
import {EditBox} from "../FormContentStart";

export const SelectCustom = (props) => {
    /*set props*/
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const editElement = props.editElement;

    return (
        <>
            <div className={`ak-form-group ak-w-${ElementData.width} js-form-elements js-admiko-sort-me-container ${props.element_id === ElementData.idu ? "selected":""} ${ElementData.error && "ak-form-error"}`} data-id={ElementData.idu}>
                <div className={`ak-form-group-box`} onClick={()=> editElement(ElementData.idu)}>
                    <div className="ak-form-label">
                        <label>{ElementData.title}<Required ElementData={ElementData}/></label>
                    </div>
                    <div className="ak-form-input">
                        <div className={`${ElementData.prefix !== "" ? "ak-form-prefix" : ""} ${ElementData.suffix !== "" ? "ak-form-suffix" : ""}`}>
                            {ElementData.prefix !== "" && <span className="ak-prefix">{ElementData.prefix}</span>}
                        <select className="form-select">
                            {ElementData.required === 0 && (<option>Select</option>)}
                            {Array.isArray(ElementData.form_display) && ElementData.form_display.length > 0 ?
                                <>
                                    {ElementData.form_display.map((item, i) => (
                                        <option key={i}>
                                            {item}
                                        </option>
                                    ))}
                                </>
                                :
                                <>
                                </>
                            }
                        </select>
                            {ElementData.suffix !== "" && <span className="ak-suffix">{ElementData.suffix}</span>}
                        </div>
                        <FormInfo ElementData={ElementData}/>
                    </div>
                </div>
                <EditBox position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement} idu={ElementData.idu}/>
            </div>
        </>
    );
}