import React from "react";

export const FormElementHeight = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <>
            {formData.can_edit === 1 &&
                <div className="form-group">
                    <label htmlFor="element_height">Height:</label>
                    <div className="form-group-input">
                        <input className="form-input" autoComplete="off" name="element_height" id="element_height" placeholder="Height in pixels" value={formData.element_height} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                        <p className="ak-admiko-form-error">Required!</p>
                    </div>
                </div>
            }
        </>
    );

}