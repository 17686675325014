import React, {useEffect, useState} from "react";
import {FormSubmitButton} from "../../Helpers/FormSubmitButton";

import {apiAddNewClient, apiDeleteClient, apiEditClient, apiGetClients} from "../../Api/useCallApi";
import {useIsMountedRef} from "../../Helpers/isMounted";
import {CheckFormOnChange, CheckFormOnSubmit} from "../../Helpers/CheckFormFields";
import {Link, useNavigate, useParams} from "react-router-dom";

import {setRootPageInfoDirect} from "../../Global/PageInfo";
import {appRootClients, setAppRootClientsDirect} from "../../Global/Clients";
import {FormDeleteButton} from "../../Helpers/FormDeleteButton";
import {FormMessage} from "../LayoutParts/FormMessage";

export const ClientForm = () => {
    const {id = false} = useParams();
    let navigate = useNavigate();

    const isMountedRef = useIsMountedRef();
    /*send button*/
    const [submitButtonTimer, setSubmitButtonTimer] = useState(0);
    const [deleteProgress, setDeleteProgress] = useState(false);
    const [submitButton, setSubmitButton] = useState({disable: false, state: ""});
    /*end send button*/

    const formFields = {
        fields: {idu: "", name: "", company: "", website: "", info: ""}
    }
    const formErrorData = {
        error: {name: false}
    }
    const formFieldsRules = {
        name: {required: true, type: "input", rules: {minLength: 1, maxLength: 50}},
    }

    const [formData, setFormData] = useState(formFields);
    const [fieldError, setFieldError] = useState(formErrorData);
    const [formErrorMessage, setFormErrorMessage] = useState(false);
    const [formInfoMessage, setFormInfoMessage] = useState(false);

    const onChangeFormData = (e) => {
        if (formFieldsRules.hasOwnProperty(e.target.name)) {
            e.target.value = CheckFormOnChange(e, formFieldsRules, submitButton, setSubmitButton);
        }
        setFormData(prevFormData => ({
            fields: {...prevFormData.fields, [e.target.name]: e.target.value}
        }));
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setFormErrorMessage(false);
        setFormInfoMessage(false);
        /*send button*/
        setSubmitButton({...submitButton, disable: true, state: "progress"});

        if (submitButtonTimer) {
            window.clearTimeout(submitButtonTimer);
            setSubmitButtonTimer(0);
        }
        /*end send button*/
        if (CheckFormOnSubmit(formData, fieldError, setFieldError, formFieldsRules, submitButton, setSubmitButton)) {
            setSubmitButton({...submitButton, disable: false, state: ""});
            return;
        }
        let sendFormData = {...formData.fields};
        let response;
        if (id !== false) {
            response = await apiEditClient(sendFormData);
        } else {
            response = await apiAddNewClient(sendFormData);
        }
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setAppRootClientsDirect(response.return.clients);
            if (id !== false) {
                setSubmitButton({...submitButton, disable: false, state: "saved"});
            } else {
                navigate("/clients", {replace: true});
            }

        } else {
            if (response.message === 'check_input') {
                setFormErrorMessage("Please check your input.");
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }

        /*send button*/
        setSubmitButtonTimer(setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }, 3000));

        /*end send button*/
    }

    useEffect(() => {
        if (!Array.isArray(appRootClients)) {
            (async () => {
                const response = await apiGetClients();
                if (response.success === true) {
                    setAppRootClientsDirect(response.return.clients);
                    setClientData(response.return.clients);
                } else {
                    navigate("/clients", {replace: true});
                }
            })()
        } else {
            setClientData(appRootClients);
        }
        if (id !== false) {
            setRootPageInfoDirect({title: "Clients", subtitle: "Manage your clients.", activeMenu: "clients", breadcrumbs: [{link: "/clients", title: "Clients"}]});
        } else {
            setRootPageInfoDirect({title: "Clients", subtitle: "Manage your clients.", activeMenu: "clients", breadcrumbs: [{link: "/clients", title: "Clients"}, {link: "/client/add", title: "Add"}]});
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setClientData = (ClientsList) => {
        if (id !== false && Array.isArray(ClientsList) && ClientsList.length > 0) {
            const filtered = ClientsList.filter(obj => {
                return obj.idu === id;
            });
            if (filtered.length) {
                setFormData({fields: {idu: filtered[0]['idu'], name: filtered[0]["name"], company: filtered[0]["company"], website: filtered[0]["website"], info: filtered[0]["info"]}})
            } else {
                navigate("/clients", {replace: true});
            }
        }
    };

    const deleteRecord = async (e, idu) => {
        e.preventDefault();
        setSubmitButton({...submitButton, disable: true, state: ""});
        setDeleteProgress(true);
        let sendFormData = {idu: idu};
        let response = await apiDeleteClient(sendFormData);

        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setAppRootClientsDirect(response.return.clients);
            navigate("/clients", {replace: true});
        } else {
            if (response.message === 'check_input') {
                setFormErrorMessage("Please check your input.");
            }
            setDeleteProgress(false);
            setSubmitButton({...submitButton, disable: false, state: ""});
        }
    };

    return appRootClients !== false ? (
            <>
                <div className="ak-form-container">
                    <div className="ak-section">
                        {id &&
                            <>
                                <div className="tabs-group">
                                    <div>Edit:</div>
                                    <div>
                                        <FormDeleteButton sendFormData={deleteRecord} id={id} progress={deleteProgress} link={"/"} info="Are you sure you want to delete? All of your data will be permanently removed. This action cannot be undone."/>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="ak-section-content">
                            <form>
                                <div className="ak-section-form-content">
                                    <div className="form-group">
                                        <label htmlFor="name">Name *</label>
                                        <input type="text" className={`form-input ${fieldError.error.name ? 'form-input-error' : ''}`} value={formData.fields.name} onChange={(e) => onChangeFormData(e)} name="name" id="name" placeholder="Enter name"/>
                                        <p className="required-input">Name is required.</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="company">Company</label>
                                        <input type="text" className={`form-input ${fieldError.error.company ? 'form-input-error' : ''}`} value={formData.fields.company} onChange={(e) => onChangeFormData(e)} name="company" id="company" placeholder="Enter company"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="website">Website</label>
                                        <input type="text" className={`form-input ${fieldError.error.website ? 'form-input-error' : ''}`} value={formData.fields.website} onChange={(e) => onChangeFormData(e)} name="website" id="website" placeholder="Enter website"/>
                                    </div>

                                    <div>
                                        <FormMessage formErrorMessage={formErrorMessage} formInfoMessage={formInfoMessage}/>
                                    </div>
                                </div>
                                <div className="form-buttons-group">
                                    <FormSubmitButton buttonText={"Save"} submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                    <div className="form-action">
                                        <Link to="/clients" className="form-cancel-btn">cancel</Link>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        ) :
        <>
            <div className="admiko-ak-loading">
                <i className="fa-solid fa-circle-notch"></i>
            </div>
        </>;
};