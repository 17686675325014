import React, {PureComponent} from "react";

import {ToolBoxEditButtons} from "./ToolBoxEditButtons";
import {getRootScrollToID} from "../../../Helper/RootVar/Contstant";

class WidgetCustomClass extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {title: ""};
    }

    componentDidMount() {
        this.setState({title: this.props.info.idu});
    }
    //
    toolBoxNavigate(e) {
        e.preventDefault();
        this.props.navigateToToolboxArea(this.props.info.idu, 'layout')
    };

    componentDidUpdate() {
        let scrollToElement = getRootScrollToID();
        if (scrollToElement) {
            const element = document.querySelector(`[data-id="${scrollToElement}"]`);
            element.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }


    render() {

        return this.props.info.idu ?
            <>
                <div className={`ak-section-container ak-widget-container ak-widget-container-custom widget-width-ak-w-${this.props.info.table_width ?? "1_1"} js-page-elements-container js-admiko-page-element-sort-me ${this.props.widget_id === this.props.url_widget_id && "selected"}`}
                     data-id={this.props.info.idu} data-folder={this.props.folder_idu}>
                    <ToolBoxEditButtons idu={this.props.info.idu}
                                        table_id={this.props.widget_id} url_table_id={this.props.url_widget_id} url_menu_id={this.props.url_menu_id} url_page_id={this.props.url_page_id}
                                        position={this.props.position} table_btn={1} totalItems={this.props.totalItems} sortMe={this.props.sortMe}
                                        folder_idu={this.props.folder_idu}
                    />
                    <div className="ak-section-element-container">
                        <div>{this.props.info?.settings?.path??""}</div>
                    </div>
                </div>
            </>
            :
            <>
                <div className="ak-menu-item-loading">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </>;
    }

}

export default WidgetCustomClass;
