import React, {useState} from "react";
import {rootTABLE_COLUMN_MAX_LENGTH} from "../../../../../../../Helper/RootVar/Contstant";

export const FormColumn = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;
    const [infoClick, setInfoClick] = useState({table_column: false});

    return (
        <>
            {formData.can_edit === 1 &&
                <>
                    <div className="form-group">
                        <label htmlFor="table_column" className="mouse-pointer" onClick={() => setInfoClick({table_column: !infoClick.table_column})}>Column name: <i className="fa-solid fa-circle-info"></i></label>
                        <div className="form-group-input">
                            <input className={`form-input ${formData.table_column === "" && "ak-admiko-form-input-error"}`} autoComplete="off" name="table_column" id="table_column" placeholder="Enter column name" value={formData.table_column} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                            <p className="ak-admiko-form-error">Required!</p>
                        </div>
                    </div>
                    {infoClick.table_column &&
                        <p className="ak-admiko-form-info">Unique field name in database table for current form. Max {rootTABLE_COLUMN_MAX_LENGTH} characters. Can't start with _0-9 or ends with _ (Limited: A-Z, a-z, 0-9 and _).
                            Highly recommended to use only a-z and _</p>
                    }
                </>
            }
        </>
    );

}