import React, {useEffect} from "react";
import {FormTitle} from "./Partials/FormTitle";
import {FormDescription} from "./Partials/FormDescription";
import {FormSubmitButton} from "../../../../../Helpers/FormSubmitButton";
import {FormWidth} from "./Partials/FormWidth";
import {FormCloseButton} from "../../../../../Helpers/FormCloseButton";
import {FormSelectTitle} from "./Partials/FormSelectTitle";
import {FormCustomClass} from "./Partials/FormCustomClass";
import {AdvancedSettings} from "../../../../../Helpers/AdvancedSettings";
import {rootCUSTOM_CLASS_MAX_LENGTH, rootELEMENT_NAME_MAX_LENGTH} from "../../../../../../Helper/RootVar/Contstant";
// import {FormPrefix} from "./Partials/FormPrefix";
// import {FormSuffix} from "./Partials/FormSufix";
import {FormDisableAKJS} from "./Partials/FormDisableAKJS";
import {FormVariable} from "./Partials/FormVariable";

export const ParentChild = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormData = props.onChangeFormData;
    const sendFormData = props.sendFormData;
    const submitButton = props.submitButton;
    const closeForm = props.closeForm;

    const checkSaveButtonOnChildStart = props.checkSaveButtonOnChildStart;

    useEffect(() => {
        checkSaveButtonOnChildStart(formFieldsRules);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formFieldsRules = {
        title: {required: true, type: "input", rules: {minLength: 1, maxLength: 64}},
        elm_name: {required: true, type: "input", rules: {minLength: 1, maxLength: rootELEMENT_NAME_MAX_LENGTH, allowedInput: "upper_lower_dash_numbers_protect_start_end"}},
        title_source_id: {required: true, type: "select"},
        // prefix: {required: false, type: "input", rules: {minLength: 1, maxLength: 20}},
        // suffix: {required: false, type: "input", rules: {minLength: 1, maxLength: 20}},
        custom_class: {required: false, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_CLASS_MAX_LENGTH, allowedInput: "upper_lower_dash_minus_numbers_space"}},
        disable_js: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
    }
    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules)
    };
    return (
        <>
            <form action="">
                <FormTitle formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                {formData.can_edit === 1 &&
                    <FormVariable formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                }
                <FormWidth formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormSelectTitle formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <AdvancedSettings>
                    <FormDescription formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                    {/*<FormPrefix formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>*/}
                    {/*<FormSuffix formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>*/}
                    <FormCustomClass formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormDisableAKJS formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                </AdvancedSettings>
                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                <FormCloseButton closeForm={(e) => closeForm(e)}/>
            </form>
        </>
    );

}