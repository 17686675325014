import React, {useEffect, useState} from "react";
import {FormSubmitButton} from "../../Helpers/FormSubmitButton";

import {apiEditGlobalSettings, apiGetProjects} from "../../Api/useCallApi";
import {useIsMountedRef} from "../../Helpers/isMounted";
import {useNavigate} from "react-router-dom";

import {setRootPageInfoDirect} from "../../Global/PageInfo";
import {appGlobalSettings, setAppGlobalSettingsDirect, setAppRootProjectsDirect} from "../../Global/Projects";
import {setAppRootClientsDirect} from "../../Global/Clients";
import {setAppRootFrameworksDirect} from "../../Global/Framework";
import {SettingsForm} from "./SettingsForm";

export const GlobalSettingsForm = () => {


    let navigate = useNavigate();
    const isMountedRef = useIsMountedRef();
    /*send button*/
    const [submitButtonTimer, setSubmitButtonTimer] = useState(0);
    const [submitButton, setSubmitButton] = useState({disable: false, state: ""});
    /*end send button*/
    const [formData, setFormData] = useState(false);
    const [formGlobalSettingsData, setFormGlobalSettingsData] = useState(false);
    const updateFormData = (data) => {
        setFormData(data);
    };
    const sendFormData = async (e) => {
        e.preventDefault();
        /*send button*/
        setSubmitButton({...submitButton, disable: true, state: "progress"});
        if (submitButtonTimer) {
            window.clearTimeout(submitButtonTimer);
            setSubmitButtonTimer(0);
        }
        let sendFormData = {...formData};
        let response = await apiEditGlobalSettings(sendFormData);

        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setAppGlobalSettingsDirect(response.return.global_settings);
            setGlobalSettingsData(response.return.global_settings);
            setSubmitButton({...submitButton, disable: false, state: "saved"});
        } else {
            setSubmitButton({...submitButton, disable: false, state: ""});
        }
        /*send button*/
        setSubmitButtonTimer(setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }, 3000));

        /*end send button*/
    }

    useEffect(() => {
        if (appGlobalSettings === false) {
            (async () => {
                const response = await apiGetProjects();
                if (response.success === true) {
                    setAppRootProjectsDirect(response.return.projects);
                    setAppGlobalSettingsDirect(response.return.global_settings);
                    setAppRootClientsDirect(response.return.clients);
                    setAppRootFrameworksDirect(response.return.frameworks);
                    /*local*/
                    setGlobalSettingsData(response.return.global_settings);
                } else {
                    navigate("/", {replace: true});
                }
            })()
        } else {
            setGlobalSettingsData(appGlobalSettings);
        }
        setRootPageInfoDirect({title: "Global Settings", subtitle: "Manage global projects settings", activeMenu: "global-settings", breadcrumbs: [{link: "/", title: "Global settings"}]});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setGlobalSettingsData = (data) => {
        setFormGlobalSettingsData(data);
    };

    return setFormGlobalSettingsData !== false ? (
            <>
                <div className="ak-form-container">
                    <div className="ak-section">
                        <div className="tabs-group">
                            <ul className="">
                                <li className="mr-2">
                                    <a href="/" draggable="false" className="active">
                                        <svg aria-hidden="true" className="" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path>
                                        </svg>
                                        Global values
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="ak-section-content">
                            <form>
                                <div className="block">
                                    <div className="ak-section-form-content">
                                        <p className="text-sm text-gray-900 mb-6">
                                            This page allows you to manage the default values and configurations for new projects. These settings will not affect any previously created projects and will only apply to the projects created after the changes are made.
                                        </p>
                                        <SettingsForm formSettingsData={formGlobalSettingsData} updateFormData={updateFormData}/>
                                    </div>
                                </div>
                                <div className="form-buttons-group">
                                    <FormSubmitButton buttonText={"Save"} submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                    {/*<div className="form-action">*/}
                                    {/*    <Link to="/" className="form-cancel-btn">cancel</Link>*/}
                                    {/*</div>*/}
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </>
        ) :
        <>
            <div className="admiko-ak-loading">
                <i className="fa-solid fa-circle-notch"></i>
            </div>
        </>
        ;
};