
import React,{useEffect, useState} from "react";
import {rootAllPageContent, rootAllPages, rootBuilderLink} from "../../Helper/RootVar/Contstant";
import {Link} from "react-router-dom";

export const Breadcrumbs = (props) => {
    /*set props*/
    const url_menu_id = props.url_menu_id;
    const url_page_id = props.url_page_id;
    const url_form_id = props.url_form_id;

    const prependLink = `${rootBuilderLink}/m/${url_menu_id}/p`;


    const [breadCrumbs, setBreadCrumbs] = useState(false);
    useEffect(() => {
        loadData(url_form_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        loadData(url_form_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rootAllPages[url_page_id]),JSON.stringify(rootAllPageContent[url_form_id]),url_form_id])

    const loadData = (form_id = false) => {
        if(form_id){
            if (rootAllPageContent.hasOwnProperty(url_form_id)) {
                if (rootAllPageContent[url_form_id].hasOwnProperty('form_content') && rootAllPageContent[url_form_id].form_content.hasOwnProperty('breadcrumbs')) {
                    setBreadCrumbs(rootAllPageContent[url_form_id].form_content.breadcrumbs.parents);
                } else {
                    setBreadCrumbs(false);
                }
            }
        } else {
            if (rootAllPages.hasOwnProperty(url_page_id)) {
                if (rootAllPages[url_page_id].hasOwnProperty('breadcrumbs') && rootAllPages[url_page_id].breadcrumbs.hasOwnProperty('parents')) {
                    setBreadCrumbs(rootAllPages[url_page_id].breadcrumbs.parents);
                } else {
                    setBreadCrumbs(false);
                }
            }
        }
    }

    return breadCrumbs ? (
            <>
                {breadCrumbs.map((item, index) => (
                    (item.link === 1 ? (
                            <li key={index}><Link to={`${prependLink}/${item.idu}`}>{item.title}</Link></li>
                        ) :
                        (
                            <li key={index}>{item.title}</li>
                        ))

                ))}
            </>
        ) :
        <>
        </>
        ;
}
