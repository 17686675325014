import React, {useCallback, useEffect, useState} from "react";
import {useIsMountedRef} from "../../../Helper/isMounted";
import {FormSubmitButton} from "../../Helpers/FormSubmitButton";
import {FormCloseButton} from "../../Helpers/FormCloseButton";
import {onChangeFormDataGlobal} from "../../../Helper/Functions";
import {apiUpdateWidgetsSettings} from "../../../Api/useWidgets";
import {rootSetError} from "../../../Helper/RootVar/Error/Error";
import {rootCUSTOM_CLASS_MAX_LENGTH, rootPAGE_ID_MAX_LENGTH} from "../../../Helper/RootVar/Contstant";

export const ElementContainer = (props) => {


    const [infoClick, setInfoClick] = useState({page_id: false, table_id: false});

    const LoadFormData = useCallback(() => {
        setWidgetIdu(props.widgetData.idu);
        let newProperty = {...props.widgetData};
        newProperty.edit_page_title = props.widgetData.title;
        newProperty.edit_table_width = props.widgetData.table_width;
        newProperty.edit_page_id = props.widgetData.page_id;
        newProperty.edit_custom_class = props.widgetData.custom_class;
        setFormData(newProperty);
    }, [props.widgetData]); // Include all external dependencies

    useEffect(() => {
        LoadFormData();
    }, [props.widgetData, LoadFormData]); // Now both dependencies are included


    // useEffect(() => {
    //     LoadFormData();
    // }, [props.widgetData])

    const closeForm = () => {
        props.hideBack();
    };
    // const LoadFormData = () => {
    //     setWidgetIdu(props.widgetData.idu);
    //     let newProperty = {...props.widgetData}
    //     newProperty.edit_page_title = props.widgetData.title;
    //     newProperty.edit_table_width = props.widgetData.table_width;
    //     newProperty.edit_page_id = props.widgetData.page_id;
    //     newProperty.edit_custom_class = props.widgetData.custom_class;
    //     setFormData(newProperty);
    // }

    /*FORM*/
    const isMountedRef = useIsMountedRef();
    const [submitButton, setSubmitButton] = useState("ready");
    const [widgetIdu, setWidgetIdu] = useState(false);
    /*form js*/
    const formFields = {
        edit_page_title: "",
        edit_table_width: "full",
        edit_page_id: "",
        edit_custom_class: "",
    }
    const [formData, setFormData] = useState(formFields);

    const formFieldsRules = {
        edit_page_title: {required: false, type: "input", rules: {minLength: 1, maxLength: 64}},
        edit_table_width: {required: false, type: "select"},
        edit_page_id: {required: true, type: "input", rules: {minLength: 3, maxLength: rootPAGE_ID_MAX_LENGTH, allowedInput: "lower_dash_numbers_protect_start_end"}},
        edit_custom_class: {required: false, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_CLASS_MAX_LENGTH, allowedInput: "upper_lower_dash_minus_numbers_space"}},
    }

    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiUpdateWidgetsSettings({...formData}, widgetIdu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/

    return (
        <>
            <div className="admiko-action-menu-body">
                <div className={`admiko-action-menu-body-action`}>
                    <div>
                        <form action="">
                            <div className="form-group">
                                <label htmlFor="edit_page_title">Title:</label>
                                <div className="form-group-input">
                                    <input className="form-input" autoComplete="off" name="edit_page_title" id="edit_page_title" placeholder="Enter title" value={formData.edit_page_title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                    <p className="ak-admiko-form-error">Required!</p>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="edit_page_id" className="mouse-pointer" onClick={() => setInfoClick({page_id: !infoClick.page_id})}>Widget ID: <i className="fa-solid fa-circle-info"></i></label>
                                <div className="form-group-input">
                                    <input className="form-input" autoComplete="off" name="edit_page_id" id="edit_page_id" placeholder="Enter page ID" value={formData.edit_page_id} type="text" onChange={(e) => onChangeFormData(e)}/>
                                    <p className="ak-admiko-form-error">Required min 3 characters!</p>
                                </div>
                            </div>
                            {infoClick.page_id &&
                                <p className="ak-admiko-form-info">Unique ID to use for filenames etc.., Max {rootPAGE_ID_MAX_LENGTH} characters. Can't start with _0-9 or ends with _ (Limited: a-z, 0-9 and _).
                                    Highly recommended to use only a-z and _</p>
                            }

                            {/*<div className="form-group">*/}
                            {/*    <label htmlFor="edit_page_description">Info:</label>*/}
                            {/*    <div className="form-group-input">*/}
                            {/*        <textarea value={formData.edit_page_description} className="form-textarea" name="edit_page_description" placeholder="Enter table info" id="edit_page_description" onChange={(e) => onChangeFormData(e)}></textarea>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="form-group">*/}
                            {/*    <label htmlFor="edit_table_width">Width:</label>*/}
                            {/*    <div className="form-group-input">*/}
                            {/*        <select value={formData.edit_table_width} className="form-select" name="edit_table_width" id="edit_table_width" onChange={(e) => onChangeFormData(e)}>*/}
                            {/*            <option value="full" key="full">Full</option>*/}
                            {/*            <option value="medium" key="medium">Medium</option>*/}
                            {/*            <option value="small" key="small">Small</option>*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="form-group">
                                <label htmlFor="table_width">Table width:</label>
                                <div className="form-group-input">
                                    <div className="form-group-input-radio-inline">
                                        {[{title:"Full",value:'full'}, {title:"Medium",value:'medium'}, {title:"Small",value:'small'}].map(item => (
                                            <div key={item.value} className={`${formData.edit_table_width === item.value ? 'active' : ''}`}>
                                                <input
                                                    type="radio"
                                                    id={`width_${item.value}`}
                                                    name="edit_table_width"
                                                    value={item.value}
                                                    checked={formData.edit_table_width === item.value}
                                                    onChange={(e) => onChangeFormData(e)}
                                                />
                                                <label
                                                    htmlFor={`width_${item.value}`}
                                                >
                                                    {item.title}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="edit_custom_class">Custom class:</label>
                                <div className="form-group-input">
                                    <input className={`form-input`} autoComplete="off" name="edit_custom_class" id="edit_custom_class" placeholder="Enter your class name" value={formData.edit_custom_class} type="text" onChange={(e) => onChangeFormData(e)}/>
                                </div>
                            </div>
                            <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                            <FormCloseButton closeForm={(e) => closeForm(e)}/>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
