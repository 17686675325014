import React from "react";
import {Link} from "react-router-dom";
import {quotes} from "../Helpers/Quotes";
import {ResetPasswordForm} from "./ResetPasswordForm";
export const ResetPassword = () => {


    return (
        <>
            <div className="login-container flex flex-col flex-auto items-center justify-center p-8 min-h-screen bg-gray-100">
                <div className="flex w-full max-w-md rounded-2xl shadow overflow-hidden bg-white md:max-w-5xl">
                    <div className="w-full px-12 py-12 max-w-md">
                        <div className="text-3xl font-extrabold tracking-tight leading-tight">Reset password</div>
                        <div className="flex items-baseline mt-1 text-sm w-96">
                            <div className="">Return to </div>
                            <Link to="/login" draggable="false" className="link ml-1">sign in.</Link>
                        </div>
                        <ResetPasswordForm/>
                    </div>

                    <div className="hidden md:flex items-center justify-center p-12 lg:px-20 overflow-hidden bg-gray-800 text-gray-400">
                        <div className="w-full">
                            <div className="text-2xl lg:text-4xl font-bold leading-none text-gray-100">
                                {quotes[Math.floor(Math.random() * quotes.length)]}
                            </div>
                            {/*<div className="mt-6 text-lg tracking-tight leading-6">*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
};