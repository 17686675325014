import axios from "axios";
import {setAppRootCheckErrorDirect, setRootCheckLogout} from "../Global/Errors";
import {setRootAppUpdate} from "../Global/AppVersion";

const clientAxios = axios.create({
    baseURL: "/api/app/v1",
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
});

// let cancelToken;
export const fetchData = async ({action, dataPrepare= "", sec_id = "", table_form_idu = "", elm_id = ""}) => {

    //let start = +new Date();

    // //Check if there are any previous pending requests
    // if (typeof cancelToken !== typeof undefined) {
    //     cancelToken.cancel("canceled")
    // }
    // //Save the cancel token for the current request
    // cancelToken = axios.CancelToken.source()
    let config = {
        // cancelToken: cancelToken.token
    };

    if(action !== "login" && action !== "register" && action !== "reset" && action !== "check_password_token"  && action !== "reset_password" ){
        const user = JSON.parse(localStorage.getItem("user"));
        if(user){
            config.headers = {Authorization: `Bearer ${user.token}`};
        } else {
            setRootCheckLogout({'return': {"logout":1}})
        }
    }
    let dataToSend = {};
    if (sec_id !== "") {
        dataToSend["ak_sec_idu"] = sec_id;
    }
    if (table_form_idu !== "") {
        dataToSend["ak_tf_idu"] = table_form_idu;
    }
    if (elm_id !== "") {
        dataToSend["ak_e_idu"] = elm_id;
    }
    dataToSend["ak_action"] = action;
    if (dataToSend && dataPrepare) {
        dataToSend["ak_data"] = dataPrepare;
    }

    dataToSend = JSON.stringify(dataToSend);

    if (process.env.NODE_ENV === 'production') {
        // dataToSend = btoa(dataToSend);
        // dataToSend = encodeURIComponent(btoa(dataToSend));
    } else {
        console.log("SENT");
        console.log(dataToSend);
        console.log(encodeURIComponent(btoa(encodeURIComponent(dataToSend))));
        // dataToSend = encodeURIComponent(btoa(dataToSend));
    }
    dataToSend = encodeURIComponent(btoa(encodeURIComponent(dataToSend)));

    return await clientAxios.post("", {send: dataToSend},config)
        .then((response) => {
            let message = "";
            if (response.data.hasOwnProperty("message")) {
                message = response.data.message;
            }

            if (response.data.hasOwnProperty("app_v")) {
                if(response.data.app_v !== "" && response.data.app_v !== process.env.REACT_APP_VERSION){
                    setRootAppUpdate(true);
                }
            }
            let returnData = {};
            if (response.data.hasOwnProperty("success") && response.data.success === true) {
                if (response.data.hasOwnProperty("return")) {
                    returnData = JSON.parse(atob(decodeURIComponent(response.data.return)));

                    if (process.env.NODE_ENV === 'production') {
                        // returnData = JSON.parse(atob(response.data.return));
                        // returnData = JSON.parse(atob(decodeURIComponent(response.data.return)));
                    } else {
                        // returnData = JSON.parse(response.data.return);
                        // returnData = JSON.parse(atob(decodeURIComponent(response.data.return)));
                        console.log("Return:");
                        console.log(returnData);
                    }
                } else {
                    message = "Error RAR03";
                }
            } else {
                // message = "Error RA103";
                if (response.data.hasOwnProperty("return")) {
                    returnData = JSON.parse(atob(decodeURIComponent(response.data.return)));

                    if (process.env.NODE_ENV === 'production') {
                        // returnData = JSON.parse(atob(response.data.return));
                        // returnData = JSON.parse(atob(decodeURIComponent(response.data.return)));
                    } else {
                        // returnData = JSON.parse(atob(decodeURIComponent(response.data.return)));
                        console.log("Return error:");
                        console.log(returnData);
                        // returnData = JSON.parse(decodeURIComponent(response.data.return));

                        // returnData = JSON.parse(response.data.return);
                    }
                } else {
                    message = "Error RAR33";
                }
            }
            setRootCheckLogout({'return':returnData})
            setAppRootCheckErrorDirect({'success': response.data.success, 'message': message,'return':returnData});
            return {'success': response.data.success, 'message': message,'return':returnData};
        })
        .catch((error) => {
            console.log('ERROR');
            if (axios.isCancel(error)) {
                console.log('ERROR CANCEL');
            } else {
                /*todo: else*/
            }
            return {message: "Server busy!", error: error};
        });
}
/*CREATE ACCOUNT*/
export const apiCreateUser = async (dataToSend) => {

    const dataFetch = {action: "register", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
/*RESET PASSWORD*/
export const apiResetPassword = async (dataToSend) => {

    const dataFetch = {action: "reset", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const apiSetNewPassword = async (dataToSend) => {

    const dataFetch = {action: "reset_password", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
/*LOGIN*/
export const apiLoginUser = async (dataToSend) => {

    const dataFetch = {action: "login", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const LogoutUserToken = async () => {
    const dataFetch = {action: "logout"};
    return await fetchData(dataFetch);
}
/*CONTACT US*/
export const apiContactUs = async (dataToSend) => {
    const dataFetch = {action: "contact_us", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
/*NEWS*/
export const apiGetNews = async (dataToSend) => {
    const dataFetch = {action: "get_news", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}


/*GET USER EMAIL AND NAME*/
export const apiGetUserInfo = async () => {
    const dataFetch = {action: "get_user_info"};
    return await fetchData(dataFetch);
}
/*SET INVALID EMAIL*/
export const apiInvalidEmail = async (dataToSend) => {
    const dataFetch = {action: "invalid_email", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}

/*MY ACCOUNT*/
export const apiUpdateAccount = async (dataToSend) => {
    const dataFetch = {action: "update_account", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const apiUpdatePassword = async (dataToSend) => {
    const dataFetch = {action: "update_password", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const apiGetPlans = async (dataToSend) => {
    const dataFetch = {action: "get_plans", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const apiGetPlanLink = async (dataToSend) => {
    const dataFetch = {action: "get_plan_link", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const apiGetStatements = async (dataToSend) => {
    const dataFetch = {action: "get_statements", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const apiGetAffiliate = async () => {
    const dataFetch = {action: "get_affiliate"};
    return await fetchData(dataFetch);
}

/*CLIENTS*/
export const apiGetClients = async () => {
    const dataFetch = {action: "get_clients"};
    return await fetchData(dataFetch);
}
export const apiAddNewClient = async (dataToSend) => {
    const dataFetch = {action: "add_client", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const apiEditClient = async (dataToSend) => {
    const dataFetch = {action: "edit_client", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const apiDeleteClient = async (dataToSend) => {
    const dataFetch = {action: "delete_client", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
/*PROJECTS*/
export const apiGetProjects = async () => {
    const dataFetch = {action: "get_projects"};
    return await fetchData(dataFetch);
}
export const apiGetKey = async (dataToSend) => {
    const dataFetch = {action: "get_api_key", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const apiAddNewProject = async (dataToSend) => {
    const dataFetch = {action: "add_project", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const apiEditProject = async (dataToSend) => {
    const dataFetch = {action: "edit_project", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const apiDeleteProject = async (dataToSend) => {
    const dataFetch = {action: "delete_project", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}
export const apiEditGlobalSettings = async (dataToSend) => {
    const dataFetch = {action: "edit_global_settings", dataPrepare: dataToSend};
    return await fetchData(dataFetch);
}


