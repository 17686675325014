import {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {rootBuilderLink} from "../Contstant";

export let rootERROR = [];
export let setRootERROR;

export const useBuilderRootERROR = () => {
    [rootERROR, setRootERROR] = useState(false);
    let navigate = useNavigate();
    useEffect(() => {
        if (rootERROR !== false) {
            if (rootERROR.success === false) {
                if (rootERROR.return.hasOwnProperty('action')) {
                    if (rootERROR.return.action === 'redirect_to_builder') {
                        return navigate(rootBuilderLink);
                    }
                    if (rootERROR.return.action === 'redirect_to_app') {
                        return navigate("/");
                    }
                    if (rootERROR.return.action === 'redirect_to_plans') {
                        return navigate("/plans");
                    }
                }
            }
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootERROR])
};
export const rootSetError = (response) => {
    setRootERROR(response);
}