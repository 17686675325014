import {fetchData} from "./useCallApi";

export const apiAddPage = async (dataToSend) => {
    const dataFetch = {action:"page_add",dataPrepare:dataToSend};
    return await fetchData(dataFetch);
}

export const apiGetPageElements = async (page_id) => {
    /*GET ALL INFO FOR TABLE AND FORM SECTIONS*/
    const dataFetch = {action:"all_page_elements","sec_id":page_id};
    return await fetchData(dataFetch);
}

export const apiDeletePage = async (dataToSend,sec_id,area) => {

    if(area === "PageElement"){
        /*DELETE ONE ELEMENT TABLE, CHART...*/
        const dataFetch = {action:"page_element_delete",dataPrepare:dataToSend,"sec_id":sec_id};
        return await fetchData(dataFetch);
    } else {
        /*DELETE PAGE WITH MULTI ELEMENTS TABLE, CHART...*/
        const dataFetch = {action:"page_delete",dataPrepare:dataToSend,"sec_id":sec_id};
        return await fetchData(dataFetch);
    }

}

export const apiSortPageElements = async (dataToSend,sec_id) => {
    const dataFetch = {action:"sort_page_elements",dataPrepare:dataToSend,"sec_id":sec_id,use_cancel:true};
    return await fetchData(dataFetch);
}


/*child pages*/

export const apiSortTableLayoutChildPages = async (dataToSend,table_form_idu) => {

    const dataFetch = {action:"sort_table_layout_child_pages",dataPrepare:dataToSend,"table_form_idu":table_form_idu,use_cancel:true};
    return await fetchData(dataFetch);
}
export const apiEditTableLayoutChildPages = async (dataToSend,table_form_idu,elm_idu) => {

    const dataFetch = {action:"edit_table_layout_child_pages",dataPrepare:dataToSend,"table_form_idu":table_form_idu,"elm_id":elm_idu};
    return await fetchData(dataFetch);
}
