import React from "react";
export const FormSubmitButton = ({submitButton, sendFormData,buttonText}) => {

    return (
        <>
            <div className="form-group">
                <div className="form-group-input">
                    <button type="submit" className="form-button button-submit" disabled={submitButton.disable} onClick={(e) => sendFormData(e)}>
                        <span className="button-state">
                            <span className={`button-state-progress ${submitButton.state === "progress" ? 'show-me' : ''}`}>
                                <i className="fa-solid fa-circle-notch"></i>
                            </span>
                            <span className={`button-state-saved ${submitButton.state === "saved" ? 'show-me' : ''}`}>
                                <i className="fa-solid fa-circle-check"></i>
                            </span>
                        </span>
                        <span className="button-text">{buttonText}</span>
                    </button>
                </div>
            </div>
        </>
    );
}
