import React, {useEffect, useRef, useState} from "react";
import SortMe from "../../../../Helper/SortMe";
import {rootSetError} from "../../../../Helper/RootVar/Error/Error";
import {useIsMountedRef} from "../../../../Helper/isMounted";
import {apiGetWidgetConnectedSourceList, apiGetWidgetSourceLabels} from "../../../../Api/useWidgets";

export const WidgetConnectedSource = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;
    const layout = props.layout;

    const isMountedRef = useIsMountedRef();

    /*source*/
    const sourceSelectElement = useRef(null);
    const [connectedUserSourceID, setConnectedUserSourceID] = useState(formData.source);
    const [sourceList, setSourceList] = useState([]);

    /*aggregate*/
    const [aggregateUserSelectedData, setAggregateUserSelectedData] = useState([]);
    const [aggregateLabels, setAggregateLabels] = useState([]);

    const [showSettings, setShowSettings] = useState(0)

    /*group*/
    const [groupUserSelectedIdu, setGroupUserSelectedIdu] = useState("none");
    const [groupUserSelectedDay, setGroupUserSelectedDay] = useState("day");
    const [groupLabels, setGroupLabels] = useState([]);
    const [isDate, setIsDate] = useState(false);

    useEffect(() => {
        LoadSourceList();

        // setConnectedUserSourceID(formData.source)
        let elm = {target: {name: "source", value: formData.source}};
        onChangeFormDataInput(elm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     //setConnectedUserSourceID(formData.source);
    //     let elm = {target: {name:"source",value:formData.source}};
    //     onChangeFormDataInput(elm);
    // }, [formData.source])

    // useEffect(() => {
    //     //setSourceList([]);
    //     //LoadSourceList();
    //
    //
    //     // if (formData.source === connectedUserSourceID && formData.aggregate_user_list?.length > 0) {
    //     //     setUserAggregate(formData.aggregate_user_list);
    //     // } else {
    //     //     setUserAggregateAddFirst([aggregateLabels[0]]);
    //     // }
    //     //
    //     // if (formData.source === connectedUserSourceID && formData.group_user_list?.length > 0) {
    //     //     setGroupUserSelectedIdu(formData.group_user_list?.group_by_idu??groupLabels[0].idu);
    //     //     setGroupUserSelectedDay(formData.group_user_list?.group_by_time??"day");
    //     // } else {
    //     //     setUserAggregateAddFirst([aggregateLabels[0]]);
    //     // }
    //
    //
    //
    // }, [props.widgetIdu])
    const LoadSourceList = async () => {
        props.setSubmitButton('disabled')
        const response = await apiGetWidgetConnectedSourceList();
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {

            setSourceList(response.return.source_list);
            if (formData.source !== "none" && formData.source !== 0) {
                //setConnectedUserSourceID(formData.source)
                // LoadSourceLabels(formData.source);

            }
        } else {
            rootSetError(response);
        }
    }
    const LoadSourceLabels = async (element_idu) => {
        const response = await apiGetWidgetSourceLabels({'element_idu': element_idu});
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {

            setAggregateLabels(response.return.aggregate_fields);
            setGroupLabels(response.return.group_fields);

            if (formData.source === connectedUserSourceID && formData.aggregate_user_list?.length > 0) {
                setUserAggregate([...formData.aggregate_user_list]);
            } else {
                setUserAggregateAddFirst([response.return.aggregate_fields[0]]);
            }

            if(props.activateGroup === 1){
                if (formData.source === connectedUserSourceID && formData.group_by_idu !== "none") {
                    groupCheckDate(response.return.group_fields.find((item) => item.idu === formData.group_by_idu))
                    setGroupUserSelectedIdu(response.return.group_fields.find((item) => item.idu === formData.group_by_idu)?.idu)
                    setGroupUserSelectedDay(formData.group_by_time ?? "day");
                } else {
                    if (response.return.group_fields.length > 0) {
                        groupCheckDate(response.return.group_fields[0])
                    }
                }
            }

        } else {
            rootSetError(response);
        }
    }

    const resetAll = () => {
        setShowSettings(0);
        setIsDate(false);
        setGroupLabels([]);
        setAggregateUserSelectedData([]);
        setGroupUserSelectedIdu("none");
        setGroupUserSelectedDay("day");
        setAggregateLabels([]);
    }

    // const generateUniqueKey2 = () => {
    //     let key = Math.random().toString(36).substring(2, 9);
    //     while (aggregateUserSelectedData.some(item => item.key === key)) {
    //         key = Math.random().toString(36).substring(2, 9);
    //     }
    //     return key;
    // };

    const doesKeyExist = (key) => aggregateUserSelectedData.some(item => item.key === key);

    const generateUniqueKey = () => {
        let key;
        do {
            key = Math.random().toString(36).substring(2, 9);
        } while (doesKeyExist(key));
        return key;
    };




    /*source*/
    /*source*/
    /*source*/
    const onChangeSource = (e) => {
        resetAll();
        // setShowSettings(0);
        // setGroupLabels([]);
        // setAggregateLabels([]);

        setConnectedUserSourceID(e.target.value);
        // if (e.target.value !== "none") {
        //     LoadSourceLabels(e.target.value);
        // }
    };

    useEffect(() => {
        let elm = {target: {name: 'source', value: connectedUserSourceID}};
        onChangeFormDataInput(elm);

        if (connectedUserSourceID !== "none" && connectedUserSourceID !== 0) {
            LoadSourceLabels(connectedUserSourceID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectedUserSourceID])

    /*group*/
    /*group*/
    /*group*/
    // useEffect(() => {
    //     if (groupLabels.length > 0) {
    //         groupCheckDate(groupLabels[0])
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [groupLabels])

    useEffect(() => {
        saveGroupToParent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupUserSelectedDay, groupUserSelectedIdu])
    const groupCheckDate = (selectedGroup) => {
        setGroupUserSelectedIdu(selectedGroup.idu);
        if (selectedGroup.is_date === 1) {
            setIsDate(true);
            if (groupUserSelectedDay === 0) {
                setGroupUserSelectedDay("day");
            }
        } else {
            setIsDate(false);
            setGroupUserSelectedDay(0);
        }
        //
        // if (groupUserSelectedIdu === "none") {
        //     //setGroupUserSelectedIdu(response.return.group_fields[0].idu);
        // }
    }
    const onChangeGroupSource = (e) => {
        groupCheckDate(groupLabels.find((item) => item.idu === e.target.value))
        setGroupUserSelectedIdu(groupLabels.find((item) => item.idu === e.target.value)?.idu)
    };
    const onChangeGroupDay = (e) => {
        setGroupUserSelectedDay(e.target.value);
    };

    const saveGroupToParent = () => {
        let group_by_idu = {target: {name: 'group_by_idu', value: groupUserSelectedIdu}};
        onChangeFormDataInput(group_by_idu);
        let group_by_time = {target: {name: 'group_by_time', value: groupUserSelectedDay}};
        onChangeFormDataInput(group_by_time);
    };

    /*aggregate*/
    /*aggregate*/
    /*aggregate*/

    const setUserAggregateAddFirst = (data) => {
        let action = "sum";
        if (data[0].title === "ID") {
            action = "count";
        }
        setUserAggregate([{idu: data[0].idu, action: action, title: "", edit_number_format: "number"}])
    }
    const setUserAggregate = (data) => {
        let newValues = [];
        newValues = data.map((item) => {
            let key = item.key || generateUniqueKey();
            return {...item, key};
        });
        setAggregateUserSelectedData(newValues);
    }
    const onChangeAggregateSource = (e, index) => {
        let newFormValues = [...aggregateUserSelectedData];

        let aggregateSelect = aggregateLabels.find((item) => item.idu === e.target.value);

        let action = "sum";
        if (aggregateSelect.title === "ID") {
            action = "count";
        }

        newFormValues[index] = {idu: e.target.value, action: action, title: newFormValues[index]?.title, edit_number_format:newFormValues[index]?.edit_number_format, key: newFormValues[index]?.key ?? generateUniqueKey()}
        setAggregateUserSelectedData([...newFormValues])
        setShowSettings(0);
    };
    const onChangeAggregate = (e, index) => {
        let newFormValues = [...aggregateUserSelectedData];
        newFormValues[index] = {idu: newFormValues[index]?.idu, action: e.target.value, title: newFormValues[index]?.title, edit_number_format:newFormValues[index]?.edit_number_format, key: newFormValues[index]?.key ?? generateUniqueKey()}
        setAggregateUserSelectedData([...newFormValues])
        setShowSettings(0);
    };
    const onChangeAggregateTitle = (e, index) => {
        let newFormValues = [...aggregateUserSelectedData];
        newFormValues[index] = {idu: newFormValues[index]?.idu, action: newFormValues[index]?.action, title: e.target.value, edit_number_format:newFormValues[index]?.edit_number_format, key: newFormValues[index]?.key ?? generateUniqueKey()}
        setAggregateUserSelectedData([...newFormValues])
    };
    const onChangeAggregateFormat = (e, index) => {
        let newFormValues = [...aggregateUserSelectedData];
        newFormValues[index] = {idu: newFormValues[index]?.idu, action: newFormValues[index]?.action, title: newFormValues[index]?.title, edit_number_format:e.target.value, key: newFormValues[index]?.key ?? generateUniqueKey()}
        setAggregateUserSelectedData([...newFormValues])
    };
    const addLabelGroup = () => {
        let action = "sum";
        if (aggregateLabels[0].title === "ID") {
            action = "count";
        }
        setUserAggregate([...aggregateUserSelectedData, {idu: aggregateLabels[0].idu, action: action,title: "", edit_number_format:"number"}])
        setShowSettings(0);
    }

    const removeUserLabel = (i) => {
        let newLabelGroup = [...aggregateUserSelectedData];
        newLabelGroup.splice(i, 1);
        setShowSettings(0);
        if (newLabelGroup.length < 1) {
            setUserAggregateAddFirst([aggregateLabels[0]]);
        } else {
            setUserAggregate(newLabelGroup);
        }


    }
    const editAggregateLabel = (key) => {
        if (showSettings === key) {
            setShowSettings(0);
        } else {
            setShowSettings(key);
        }
    }
    useEffect(() => {
        saveAggregateToParent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aggregateUserSelectedData])
    const saveAggregateToParent = () => {

        let newFormValues = aggregateUserSelectedData.map((item) => {
            const {key, ...rest} = item; // Destructure the title property and get the remaining properties
            return rest; // Return the modified object without the title property
        });

        let elm = {target: {name: 'aggregate_user_list', value: [...newFormValues]}};
        onChangeFormDataInput(elm);
    };
    /*SORTING*/
    /*SORTING*/
    /*SORTING*/
    const saveSortSortMe = (newList) => {
        let sortedArray = newList.sortList.map(index => aggregateUserSelectedData[index]);
        setUserAggregate(sortedArray)
        setShowSettings(0);
    }


    /*save to parent*/
    /*save to parent*/
    /*save to parent*/
    // const saveDataToParent = (labelGroupData) => {
    //     let newLabelGroup = [];
    //     for (let i = 0; i < labelGroupData.length; i++) {
    //         newLabelGroup = [...newLabelGroup, {idu: labelGroupData[i].idu, separator: labelGroupData[i].separator}];
    //     }
    //     let elm = {target: {name: 'selected_labels_list', value: newLabelGroup}};
    //     onChangeFormDataInput(elm);
    // }
    //
    // const saveSortDataToParent = (labelGroupData) => {
    //     let newLabelGroup = [];
    //     for (let i = 0; i < labelGroupData.length; i++) {
    //         newLabelGroup = [...newLabelGroup, {idu: labelGroupData[i].idu, action: labelGroupData[i].action??"sum"}];
    //     }
    //     let elm = {target: {name: 'aggregate_user_list', value: newLabelGroup}};
    //     onChangeFormDataInput(elm);
    // }


    // useEffect(() => {
    //     saveDataToParent(labelGroup);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [labelGroup])


    /*SORTING*/
    /*SORTING*/
    /*SORTING*/
    //
    // useEffect(() => {
    //     saveSortDataToParent(sortLabelGroup);
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [sortLabelGroup])
    //


    return (
        <>
            {sourceList.length > 0 ?
                <>
                    <div className="form-group">
                        <label htmlFor="source">Source table:</label>
                        <div className="form-group-input">
                            <select value={formData.source} ref={sourceSelectElement} className="form-select" name="source" id="source" onChange={(e) => onChangeSource(e)}>
                                <option key="none" value="none">Select source</option>
                                {sourceList.map((e) => {
                                    return <option key={e.idu} value={e.idu}>{e.title}</option>;
                                })}
                            </select>
                            <p className="ak-admiko-form-error">Required!</p>
                        </div>
                    </div>
                    {(connectedUserSourceID !== "none" && connectedUserSourceID !== 0) &&
                        <>
                            {aggregateLabels.length > 0 ?
                                <>
                                    {(groupLabels.length > 0 && props.activateGroup === 1) &&
                                        <>
                                            <div className="add-more-btn-box">
                                                <div>
                                                    Group by:
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group-input flex flex-nowrap space-x-2">
                                                    <select value={formData.group_by_idu} className="form-select" name="group_by_idu" id="group_by_idu" onChange={(e) => onChangeGroupSource(e)}>
                                                        {groupLabels.map((e) => {
                                                            return <option key={e.idu} value={e.idu}>{e.title}</option>;
                                                        })}
                                                    </select>
                                                    {isDate &&
                                                        <div className="w-[95%]">
                                                            <select value={formData.group_by_time} className="form-select" name="group_action" id="group_action" onChange={(e) => onChangeGroupDay(e)}>
                                                                <option value="day_full">by DAY (Y-m-d)</option>
                                                                <option value="month_full">by MONTH (Y-m)</option>
                                                                <option value="year">by YEAR (Y)</option>
                                                                <option value="day_single">by DAY (d)</option>
                                                                <option value="month_single">by MONTH (m)</option>
                                                                <option value="week_single">by WEEK (W)</option>
                                                                <option value="week_full">by WEEK (Y, W)</option>
                                                                <option value="hour_full">by HOUR (Y-m-d H)</option>
                                                                <option value="hour_single">by HOUR (H)</option>
                                                            </select>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="add-more-btn-box">
                                        <div>
                                            Aggregate:
                                        </div>
                                        <div>
                                            {props.activateAggregateAddMore === 1 &&
                                                <>
                                                    {(aggregateUserSelectedData.length >= 0 && aggregateUserSelectedData.length < 6) &&
                                                        <div className="add-more-btn mouse-pointer" onClick={() => addLabelGroup()}><i className="fa-solid fa-plus"></i> Add more</div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <SortMe saveSortMe={saveSortSortMe} parentContainer="js-ak-source-sort-label-group">
                                        {aggregateUserSelectedData.map((element, index) => (
                                            <AggregateGroup key={element.key}
                                                            position={index}
                                                            layout={layout}
                                                            ElementData={element}
                                                            aggregateLabels={aggregateLabels}
                                                            onChangeAggregateSource={onChangeAggregateSource}
                                                            onChangeAggregate={onChangeAggregate}
                                                            onChangeAggregateTitle={onChangeAggregateTitle}
                                                            onChangeAggregateFormat={onChangeAggregateFormat}
                                                            editAggregateLabel={editAggregateLabel}
                                                            totalItems={aggregateUserSelectedData.length}
                                                            showSettings={showSettings}
                                                            removeUserLabel={removeUserLabel}
                                            />
                                        ))}
                                    </SortMe>
                                </>
                                :
                                <>
                                    <div className="admiko-ak-loading">
                                        <i className="fa-solid fa-circle-notch"></i> Loading...
                                    </div>
                                </>
                            }
                        </>
                    }

                </>
                :
                <>
                    <div className="admiko-ak-loading">
                        <i className="fa-solid fa-circle-notch"></i> Loading...
                    </div>
                </>
            }
        </>
    );

}
export const AggregateGroup = (props) => {

    const ElementData = props.ElementData;
    const aggregateLabels = props.aggregateLabels;
    const onChangeAggregateSource = props.onChangeAggregateSource;
    const onChangeAggregate = props.onChangeAggregate;
    const onChangeAggregateTitle = props.onChangeAggregateTitle;
    // const onChangeAggregateFormat = props.onChangeAggregateFormat;
    const layout = props.layout;
    const [isCount, setIsCount] = useState(false);


    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const editAggregateLabel = props.editAggregateLabel;
    const showSettings = props.showSettings;
    const removeUserLabel = props.removeUserLabel;

    useEffect(() => {
        let findElement = aggregateLabels.find((item) => item.idu === ElementData.idu);
        if (findElement && findElement.title === "ID") {
            setIsCount(true);
        } else {
            setIsCount(false);
        }
    }, [ElementData, aggregateLabels])

    return (
        <div className="form-group ak-source-label-group js-ak-source-sort-label-group js-admiko-sort-me-container" data-id={position}>
            <div className="form-group-input form-group-input-widget flex flex-nowrap space-x-2">
                <select value={ElementData.idu} className="form-select" name="aggregate_idu" id="aggregate_idu" onChange={(e) => onChangeAggregateSource(e, position)}>
                    {aggregateLabels.map((e) => {
                        if (e.title === "ID") {
                            return <option key={e.idu} value={e.idu}>Count</option>;
                        } else {
                            return <option key={e.idu} value={e.idu}>{e.title}</option>;
                        }
                    })}
                </select>
                {!isCount &&
                    <div className="w-[95%]">
                        <select value={ElementData.action} className="form-select" name="aggregate_action" id="aggregate_action" onChange={(e) => onChangeAggregate(e, position)}>
                            <option value="sum">SUM</option>
                            <option value="average">AVERAGE</option>
                            <option value="min">MIN</option>
                            <option value="max">MAX</option>
                        </select>
                    </div>
                }
                {/*{totalItems > 1 &&*/}
                {(layout === "widget_line" || layout === "widget_area" || layout === "widget_bar" || layout === "widget_column" || layout === "widget_radar") &&
                    <AdmikoToolboxEditBox position={position} sortMe={sortMe} totalItems={totalItems} editLabelGroup={editAggregateLabel} idu={ElementData.key}/>
                }
            </div>
            <div className={`ak-source-widget-group-edit ${showSettings === ElementData.key && "show"}`}>
                <div className="ak-source-widget-group">

                    <div className="ak-group-widget-settings-edit-input">
                        <label htmlFor={`title-${ElementData.idu}`}>Title:</label>
                        <div className="form-group-input">
                            <input className="form-input" value={ElementData.title} autoComplete="off" name="title" id={`title-${ElementData.idu}`} onChange={(e) => onChangeAggregateTitle(e, position)} placeholder="Enter title" type="text" />
                        </div>
                    </div>

                    {/*<div className="ak-group-widget-settings-edit-input">*/}
                    {/*    <label htmlFor={`format-${ElementData.idu}`}>Format:</label>*/}
                    {/*    <div className="form-group-input">*/}
                    {/*        <select value={ElementData.edit_number_format} className="form-select" name="edit_number_format" id={`format-${ElementData.idu}`} onChange={(e) => onChangeAggregateFormat(e, position)}>*/}
                    {/*            <option value="number" key="number">Number</option>*/}
                    {/*            <option value="currency" key="currency">Currency</option>*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className="admiko-form-settings">
                    {totalItems > 1 &&
                        <div className="mouse-pointer delete-label-group" onClick={() => removeUserLabel(position)}><i className="fa-solid fa-trash"></i></div>
                    }
                </div>
            </div>
        </div>
    )
}


export const AdmikoToolboxEditBox = (props) => {
    /*set props*/
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;

    const editLabelGroup = props.editLabelGroup;
    const idu = props.idu;
    return (
        <>
            <div className="admiko-form-settings">
                {totalItems > 1 &&
                    <div className="move-section tft-move js-admiko-sort-me">
                        {position > 0 &&
                            <div className="move-up mouse-pointer js-admiko-move-me-up" onClick={(e) => sortMe.SortMoveUp(e)}><i className="fa-solid fa-chevron-up"></i></div>
                        }
                        <div className="mouse-move js-admiko-move-me"><i className="fa-solid fa-up-down-left-right"></i></div>
                        {position + 1 < totalItems &&
                            <div className="move-down mouse-pointer js-admiko-move-me-down" onClick={(e) => sortMe.SortMoveDown(e)}><i className="fa-solid fa-chevron-down"></i></div>
                        }
                    </div>
                }
                {/*{totalItems > 1 &&*/}
                    <div className="mouse-pointer element-edit" onClick={() => editLabelGroup(idu)}><i className="fa-solid fa-gear"></i></div>
                {/*}*/}
            </div>
        </>
    );
}