import React, {useEffect, useRef} from "react";

export const FormSelectTitle = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;
    const textSelect = props.textSelect;
    const textTitle = props.textTitle;

    useEffect(() => {
        let elm = {target: sourceSelectElement.current};
        onChangeFormDataInput(elm);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sourceSelectElement = useRef(null);

    const onChangeSource = () => {
        /*UPDATE SOURCE ID*/
        let elm = {target: sourceSelectElement.current};
        onChangeFormDataInput(elm);
    };

    return (
        <>
            <div className="form-group">
                <label htmlFor="title_source_id">{textTitle ?? "Label source"}:</label>
                <div className="form-group-input">
                    <select value={formData.title_source_id} ref={sourceSelectElement} className="form-select" name="title_source_id" id="title_source_id" onChange={(e) => onChangeSource(e)}>
                        <option key="none" value="none">{textSelect ?? "Select source"}</option>
                        {formData.source_list.map((e) => {
                            return <option key={e.idu} value={e.idu}>{e.title}</option>;
                        })}
                    </select>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
        </>
    );

}