import React, {useEffect, useRef} from "react";

export const FormUserCustomPath = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;
    const inputTarget = useRef();
    useEffect(() => {
         onChangeFormDataInput({target: inputTarget.current});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <>
            {/*{formData.connected_source_element_id === "none" &&*/}
                <div className="form-group">
                    <label htmlFor="custom_path">Model path:</label>
                    <div className="form-group-input">
                        <input ref={inputTarget} className="form-input" autoComplete="off" name="custom_path" id="custom_path" placeholder="Enter full model path" value={formData.custom_path} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                        <p className="ak-admiko-form-error">Required!</p>
                    </div>
                </div>
            {/*}*/}
        </>
    );

}