import React, {Component} from 'react';

import {rootSetError} from "./RootVar/Error/Error";
import {apiSortPageElements} from "../Api/usePage";
import {LoadPageElement} from "../Content/Page/LoadPageElement";

class SortPageElements extends Component {
    constructor(props) {
        super(props);
        this.state = {pageContent: this.props.pageContent, rootPageContent: []};
        this.isScrollingUp = false;
        this.isScrollingDown = false;
        this.scrollInterval = "";
        this.direction = "UP";
        this.e = false;
        this.fixedHeightElement = document.getElementById('ak-content');

        this.ElementDragged = false;
        this.ElementDraggedID = 0;
        this.ElementDraggedIsRoot = false;

        this.ElementDraggingActive = false;
        this.ElementDraggedClick = {};
        this.ElementDraggedClone = null;
        this.SortableContainers = [];
        this.SortableSubContainers = [];
        this.Container = null;
        this.Element = false;
        this.isSwaped = false;
        this.DraggedItem = false;
        this.OverItem = false;
        this.OverItemIsDiferent = false;
        this.EmptyChild = document.createElement('div');
        this.EmptyChild.className = "empty-folder js-empty-folder js-admiko-menu-item-detect";
        this.EmptyChild.innerHTML = 'Drag & drop page.';

    }

    //async componentDidMount() { }
    componentDidMount() {
        this.shouldComponentUpdate(1);
    }

    shouldComponentUpdate(stopUp = 0) {
        if (stopUp === 1) {
            return false;
        } else {
            return true;
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.pageContent) !== JSON.stringify(prevProps.pageContent)) {
            this.setState({pageContent: this.props.pageContent});
            //this.forceUpdate();
        }

    }

    saveMenu = (pageContent) => {
        const filteredData = [];
        // pageContent.map(item => {
        //     filteredData.push(item.idu);
        //     if (item.type === "element_container") {
        //         // let childElements = [];
        //         if (item.child_elements && item.child_elements.length > 0) {
        //             item.child_elements.map(child_item => {
        //                 filteredData.push(`${child_item.idu}-ak-${item.idu}`);
        //             });
        //         }
        //     }
        // });
        pageContent.forEach(item => {
            filteredData.push(item.idu);
            if (item.type === "element_container") {
                if (item.child_elements && item.child_elements.length > 0) {
                    item.child_elements.forEach(child_item => {
                        filteredData.push(`${child_item.idu}-ak-${item.idu}`);
                    });
                }
            }
        });
        let newList = {
            sortList: filteredData
        };

        (async () => {
            const response = await apiSortPageElements(newList, this.props.url_page_id);
            if (response.success === true) {

            } else {
                rootSetError(response);
            }
        })()
    }

    render() {
        return (<>
            {this.state.rootPageContent ?
                <>
                    <div className="sort-me js-sortable" id="js-sortable"
                         onMouseDown={this.dragMouseDown}
                         onMouseMove={this.dragMouseMove}
                         onMouseUp={this.dragMouseUp}
                    >
                        {this.state.pageContent
                            .map((item, i) => {
                                return <LoadPageElement
                                    key={`c-` + item.idu}
                                    position={i}
                                    item={item}
                                    pageContent={this.props.pageContent}
                                    totalItems={this.props.pageContent.length}
                                    url_menu_id={this.props.url_menu_id}
                                    url_page_id={this.props.url_page_id}
                                    url_table_id={this.props.url_table_id}
                                    url_widget_id={this.props.url_widget_id}
                                    moveUp={this.moveUp}
                                    moveDown={this.moveDown}
                                    folder_idu={false}
                                />
                            })}
                    </div>
                </>
                :
                <><p>Loading...</p></>
            }

        </>)


    }


    swapMeUp(currentElement = false, containerElement = false) {

        const targetId = currentElement.getAttribute('data-id');
        let pageContentNew = [];
        if (containerElement !== false) {
            const containerId = containerElement.getAttribute('data-id'); // The ID you want to find
            const containerIndex = this.state.pageContent.findIndex(item => item.idu === containerId);
            const containerContent = this.state.pageContent.find(item => item.idu === containerId);

            const itemInContainerIndex = containerContent.child_elements.findIndex(item => item.idu === targetId);
            const pageContentCopy = [...this.state.pageContent];

            if (containerIndex !== -1 && itemInContainerIndex > 0) {
                /*move in container*/
                pageContentCopy[containerIndex].child_elements = this.swapContainerElements("up", targetId, pageContentCopy[containerIndex].child_elements);
                pageContentNew = pageContentCopy;
            } else if (itemInContainerIndex === 0) {
                /*move outside container*/
                let newContent = this.swapContainerElements("up", targetId, pageContentCopy[containerIndex].child_elements, true, false);
                pageContentCopy[containerIndex].child_elements = newContent.pageContentCopy;
                newContent.removedItem.container_idu = 0;
                pageContentCopy.splice(containerIndex, 0, newContent.removedItem);
                pageContentNew = pageContentCopy;
            } else if (containerIndex !== -1) {
                /*move to container*/
                const index = pageContentCopy.findIndex(item => item.idu === targetId);
                if (index !== -1 && index >= 0) {
                    const removedItem = pageContentCopy.splice(index, 1)[0];
                    removedItem.container_idu = containerContent.idu;
                    pageContentCopy[containerIndex].child_elements = [...pageContentCopy[containerIndex].child_elements, removedItem];
                    pageContentNew = pageContentCopy;
                }
            }
        } else {
            pageContentNew = this.swapRootElements("up", targetId);
        }

        this.setState({pageContent: pageContentNew});
        this.saveMenu(pageContentNew)

    }

    swapRootElements(direction = "down", targetId) {
        //move root elements
        const index = this.state.pageContent.findIndex(item => item.idu === targetId);

        if (index !== -1 && index >= 0) {
            let newIndex = false;
            if (direction === "up" && index > 0) {
                newIndex = index - 1;
            } else if (direction === "down" && index < this.state.pageContent.length) {
                newIndex = index + 1;
            }
            if (newIndex !== false) {
                const pageContentCopy = [...this.state.pageContent];
                const removedItem = pageContentCopy.splice(index, 1)[0];
                pageContentCopy.splice(newIndex, 0, removedItem);
                return pageContentCopy;
            }
        }
    }

    swapContainerElements(direction = "down", targetId, pageContent, remove = false, add = false) {
        //move root elements
        const index = pageContent.findIndex(item => item.idu === targetId);
        if (index !== -1 && index >= 0) {
            let newIndex = false;
            if (direction === "up" && index > 0) {
                newIndex = index - 1;
            } else if (direction === "down" && index < pageContent.length) {
                newIndex = index + 1;
            }
            if (newIndex !== false && !remove && !add) {
                const pageContentCopy = [...pageContent];
                const removedItem = pageContentCopy.splice(index, 1)[0];
                pageContentCopy.splice(newIndex, 0, removedItem);
                return pageContentCopy;

            } else if (remove === true) {
                const pageContentCopy = [...pageContent];
                const removedItem = pageContentCopy.splice(index, 1)[0];
                return {pageContentCopy: pageContentCopy, removedItem: removedItem};

            }
        }
    }


    swapMeDown(currentElement = false, containerElement = false) {
        const targetId = currentElement.getAttribute('data-id');

        let pageContentNew = [];
        if (containerElement !== false) {
            const containerId = containerElement.getAttribute('data-id');
            const containerIndex = this.state.pageContent.findIndex(item => item.idu === containerId);
            const containerContent = this.state.pageContent.find(item => item.idu === containerId);
            const itemInContainerIndex = containerContent.child_elements.findIndex(item => item.idu === targetId);
            const pageContentCopy = [...this.state.pageContent];

            if (containerIndex !== -1 && itemInContainerIndex >= 0 && itemInContainerIndex !== containerContent.child_elements.length - 1) {
                /*move in container*/
                pageContentCopy[containerIndex].child_elements = this.swapContainerElements("down", targetId, pageContentCopy[containerIndex].child_elements);
                pageContentNew = pageContentCopy;
            } else if (containerIndex !== -1 && itemInContainerIndex !== -1 && itemInContainerIndex === containerContent.child_elements.length - 1) {
                /*move outside container*/
                let newContent = this.swapContainerElements("down", targetId, pageContentCopy[containerIndex].child_elements, true, false);
                pageContentCopy[containerIndex].child_elements = newContent.pageContentCopy;
                newContent.removedItem.container_idu = 0;
                pageContentCopy.splice(containerIndex + 1, 0, newContent.removedItem);
                pageContentNew = pageContentCopy;
            } else if (containerIndex !== -1 && itemInContainerIndex === -1) {
                /*move to container*/
                const index = pageContentCopy.findIndex(item => item.idu === targetId);
                if (index !== -1 && index >= 0) {
                    const removedItem = pageContentCopy.splice(index, 1)[0];
                    removedItem.container_idu = containerContent.idu;
                    const updatedItems = pageContentCopy.map((item) => {
                        if (item.idu === containerContent.idu) {
                            return {
                                ...item,
                                child_elements: [removedItem, ...item.child_elements],
                            };
                        }
                        return item;
                    });
                    pageContentNew = updatedItems;
                }
            }
        } else {
            pageContentNew = this.swapRootElements("down", targetId);
        }
        this.setState({pageContent: pageContentNew});
        this.saveMenu(pageContentNew)
    }

    moveUp = (e) => {
        e.preventDefault();
        let currentItem = e.target.closest(".js-admiko-page-element-sort-me");
        let current = e.target.closest(".js-admiko-page-element-sort-me");
        let currentIndex = [...current.parentNode.children].indexOf(current);
        let prevEl = current.previousElementSibling;
        let allowInFolder = true;

        if (current.classList.contains('js-admiko-page-element-sort-me-container-false')) {
            /*prevent moving inside folder*/
            allowInFolder = false;
        }
        if (current.closest(".js-admiko-page-element-sort-me-container")
            && current.closest(".js-folder-header")) {
            /*move folder*/
            current = current.closest(".js-sortable-folder");
            prevEl = current.previousElementSibling;
            currentIndex = [...current.parentNode.children].indexOf(current);
            if (prevEl !== null && currentIndex > 0) {
                this.swapMeUp(current);
            }
        } else if (allowInFolder && prevEl !== null
            && prevEl.classList.contains('js-sortable-folder')
            && !current.closest(".js-sortable-folder")) {
            /*move inside folder*/

            if (currentIndex > 0) {
                this.swapMeUp(current, prevEl ?? false);
            }
        } else if (current.closest(".js-sortable-folder") && currentIndex === 0) {
            /*move outside folder*/
            this.swapMeUp(current, current.closest(".js-sortable-folder") ?? false);
        } else {
            /*move normal*/
            if (prevEl !== null && currentIndex > 0) {
                this.swapMeUp(current, current.closest(".js-sortable-folder") ?? false);
            }
        }

        this.emptyFolderAction(e, currentItem);
    }

    moveDown = (e) => {
        e.preventDefault();
        let currentItem = e.target.closest(".js-admiko-page-element-sort-me");
        let current = e.target.closest(".js-admiko-page-element-sort-me");
        let currentIndex = [...current.parentNode.children].indexOf(current);
        let totalChildsMain = e.target.closest(".js-sortable").childElementCount;
        let nextEl = current.nextElementSibling;
        let allowInFolder = true;

        if (current.classList.contains('js-admiko-page-element-sort-me-container-false')) {
            /*prevent moving inside folder*/
            allowInFolder = false;
        }

        if (current.closest(".js-admiko-page-element-sort-me-container")
            && current.closest(".js-folder-header")) {
            /*move folder*/
            current = current.closest(".js-sortable-folder");
            nextEl = current.nextElementSibling;
            currentIndex = [...current.parentNode.children].indexOf(current);
            if (nextEl !== null && ((currentIndex + 1) < totalChildsMain)) {
                this.swapMeDown(current);
            }
        } else if (allowInFolder && nextEl !== null
            && nextEl.classList.contains('js-sortable-folder')
            && !current.closest(".js-sortable-folder")) {
            /*move inside folder*/
            if ((currentIndex + 1) < totalChildsMain) {
                this.swapMeDown(current, nextEl ?? false)
            }
        } else if (current.closest(".js-sortable-folder")) {
            /*move outside folder*/
            this.swapMeDown(current, current.closest(".js-sortable-folder") ?? false)
        } else {
            /*move normal*/
            if (nextEl !== null && ((currentIndex + 1) < totalChildsMain)) {
                this.swapMeDown(current, current.closest(".js-sortable-folder") ?? false)
            }
        }
        this.emptyFolderAction(e, currentItem);
    }

    emptyFolderAction = (e, currentItem) => {
        if (currentItem.closest('.js-sortable-folder') && !currentItem.classList.contains('js-folder-header')) {
            currentItem.dataset.folder = currentItem.closest('.js-sortable-folder').dataset.id;
        } else {
            currentItem.dataset.folder = '';
        }
        let folders = e.target.closest(".js-sortable").querySelectorAll(".js-droppable-folder");
        for (let a = 0; a < folders.length; ++a) {
            if (folders[a].children.length === 1) {
                folders[a].querySelector('.js-empty-folder').style['display'] = "block";
            } else {
                folders[a].querySelector('.js-empty-folder').style['display'] = "none";
            }
        }
    }


    dragMouseDown = (e) => {
        e.stopPropagation();
        this.OverItemIsDiferent = false;
        if (e.target.closest(".drag-clone-element")) {
            this.trashDragItem();
            return;
        }
        if (e.target.closest(".js-admiko-page-element-sort-me-container") && e.target.closest(".js-folder-header")) {
            /*drag folder*/
            this.ElementDragged = e.target.closest('.js-admiko-page-element-sort-me-container');
            this.ElementDraggedToClone = e.target.closest('.js-admiko-page-element-sort-me-container');
        } else {
            /*drag element*/
            this.ElementDragged = e.target.closest('.js-admiko-page-element-sort-me');
            this.ElementDraggedToClone = e.target.closest('.js-admiko-page-element-sort-me');
        }

        if (e && e.target && (e.target.classList.contains('fa-up-down-left-right') || e.target.classList.contains('js-admiko-move-me')) && this.ElementDragged) {
            /*check if mouse down is on move element*/
            e.preventDefault();
            this.fixedHeightElement.style["height"] = this.fixedHeightElement.offsetHeight + 'px';

            this.ElementDragged.closest(".ak-admiko-add-scroll-fix")
            this.ElementDraggedID = this.ElementDragged.getAttribute('data-id');

            this.SortableContainers = document.querySelectorAll(".js-admiko-page-element-sort-me");
            this.SortableSubContainers = document.querySelectorAll(".js-droppable-folder");

            if (e.target.closest('.js-droppable-folder') === null && e.target.closest('.js-sortable-folder')) {
                /*move folder*/
                this.ElementDraggedIsRoot = true;
                this.ElementDraggedToClone = e.target.closest('.js-sortable-folder');
            } else if (e.target.closest('.js-droppable-folder') && e.target.closest('.js-admiko-page-element-sort-me-container-false')) {
                //move element inside folder

            } else if (e.target.closest('.js-admiko-page-element-sort-me-container-false')) {
                /* move only root elements*/
                this.ElementDraggedIsRoot = true;
            }
            this.ElementDraggingActive = true;
            let point = this.getPoint(e);
            this.ElementDraggedClick = {x: point.x_scroll, y: point.y_scroll};
            this.makeDragItem(this.ElementDraggedToClone, e);
        } else {
            this.trashDragItem();
        }
    }

    dragMouseMove = (e) => {
        e.stopPropagation();
        if (this.ElementDraggingActive === true) {
            this.scrollPage(e);
        }
        if (this.ElementDraggedClone && this.ElementDraggingActive) {
            e.preventDefault();
            // this.e = e;
            let point = this.getPoint(e);
            let skip = 0;
            this.OverItem = false;
            this.Element = false;
            this.DraggedItem = this.ElementDragged;

            this.moveItem(e, this.ElementDraggedClone, (point.x_scroll - this.ElementDraggedClick.x), (point.y_scroll - this.ElementDraggedClick.y));

            // keep an eye for other sortable lists and switch over to it on hover
            for (let a = 0; a < this.SortableContainers.length; ++a) {
                let subContainer = this.SortableContainers[a];
                let onTop = this.onTop(subContainer, point.x, point.y);
                if (onTop.onTop) {
                    this.direction = onTop.direction;
                    this.Element = subContainer;
                    this.OverItem = subContainer;
                }
            }
            if (!this.Element) {
                this.OverItemIsDiferent = false;
                return;
            }
            if (this.Element.dataset.id === this.ElementDragged.dataset.id) {
                return;
            }
            /*root elements*/
            if (this.ElementDraggedIsRoot
                && this.Element.closest(".js-sortable-folder")
                && this.Element.closest('.js-droppable-folder') === null) {
                this.OverItem = this.Element.closest(".js-sortable-folder");
            }

            if (this.ElementDraggedIsRoot
                && this.Element.closest('.js-droppable-folder')) {
                skip = 1;
            }
            /*set drag for whole folder*/
            if (this.ElementDragged.closest(".js-sortable-folder")
                && this.ElementDragged.closest('.js-droppable-folder') === null) {
                this.DraggedItem = this.ElementDragged.closest(".js-sortable-folder");
            }
            /*end root elements*/

            /*over folder title*/
            if (this.Element.closest(".js-sortable-folder")
                && this.Element.closest('.js-droppable-folder') === null) {
                this.OverItem = this.Element.closest(".js-sortable-folder");
            }

            if (skip === 0 && this.DraggedItem && this.OverItem && this.OverItem.getAttribute('data-id') !== this.OverItemIsDiferent) {
                this.OverItemIsDiferent = this.OverItem.getAttribute('data-id');

                this.swapItems(this.DraggedItem, this.OverItem, this.OverItem.classList.contains("empty-folder"));
            }
        }
    }

    scrollPage = (e) => {
        let myDiv = document.getElementById('root');
        let mouseY = e.clientY;
        let position = myDiv.getBoundingClientRect();
        const scrollSpeed = 5;

        if (mouseY < 100 && window.scrollY > 100) {
            this.isScrollingUp = true;
            // let self = this;
            clearInterval(this.scrollInterval);
            this.scrollInterval = setInterval(function () {
                if (window.scrollY > 100) {
                    window.scroll({
                        top: (window.scrollY - scrollSpeed),
                        left: 0
                    });
                }

            }, 10);

        } else if (mouseY > (window.innerHeight - 100) && position.bottom > window.innerHeight) {
            this.isScrollingDown = true;
            clearInterval(this.scrollInterval);
            this.scrollInterval = setInterval(function () {
                // myDiv.scrollTop += scrollSpeed;
                window.scroll({
                    top: (window.scrollY + scrollSpeed),
                    left: 0
                });
            }, 10);
        } else {
            clearInterval(this.scrollInterval);
            this.isScrollingUp = false;
            this.isScrollingDown = false;
        }

    }
    dragMouseUp = (e) => {
        e.stopPropagation();
        this.fixedHeightElement.style["height"] = 'auto';
        clearInterval(this.scrollInterval);
        this.isScrollingUp = false;
        this.isScrollingDown = false;
        if (this.ElementDraggedClone && this.ElementDraggingActive) {
            this.isSwaped = false;
            this.trashDragItem()
            this.ElementDraggedID = 0;
            this.saveMenu(this.state.pageContent)
        }
        this.trashDragItem();
    }

    getPoint = (e) => {
        let scrollX = Math.max(0, window.scrollX || document.documentElement.scrollLeft || document.body.scrollLeft || 0) - (document.documentElement.clientLeft || 0),
            scrollY = Math.max(0, window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0) - (document.documentElement.clientTop || 0),
            pointX = e ? (Math.max(0, e.pageX || e.clientX || 0) - scrollX) : 0,
            pointY = e ? (Math.max(0, e.pageY || e.clientY || 0) - scrollY) : 0;

        let pointXScroll = pointX + window.pageXOffset;
        let pointYScroll = pointY + window.pageYOffset;
        return {x: pointX, y: pointY, x_scroll: pointXScroll, y_scroll: pointYScroll};
    }

    makeDragItem = (item, e) => {

        this.trashDragItem();
        let containerScrollY = {x: 0, y: 0};

        this.ElementDraggedClone = document.createElement(item.tagName);
        this.ElementDraggedClone.id = "drag-clone-element";
        this.ElementDraggedClone.className = item.className;
        this.ElementDraggedClone.classList.add("drag-clone-element");
        this.ElementDraggedClone.innerHTML = item.innerHTML;
        this.ElementDraggedClone.style["position"] = "absolute";
        this.ElementDraggedClone.style["z-index"] = "999";

        let folder = item.closest(".js-droppable-folder") ?? false;

        if (folder !== false) {
            folder = item.closest(".js-sortable-folder")
            this.ElementDraggedClone.style["left"] = (item.offsetLeft || 0) + 40 + "px";
            this.ElementDraggedClone.style["top"] = (item.offsetTop || 0) + folder.offsetTop - containerScrollY.y + "px";
        } else {
            this.ElementDraggedClone.style["left"] = (item.offsetLeft || 0) + "px";
            this.ElementDraggedClone.style["top"] = (item.offsetTop || 0) - containerScrollY.y + "px";
        }
        this.ElementDraggedClone.style["width"] = (item.offsetWidth || 0) + "px";
        item.closest(".js-sortable").appendChild(this.ElementDraggedClone);
        item.classList.add("active");
    }

    trashDragItem = () => {

        if (this.ElementDraggedClone && this.ElementDragged) {
            this.ElementDragged.classList.remove("active");
            this.ElementDraggedToClone.classList.remove("active");
            this.ElementDraggedClone.remove();
            this.ElementDragged = false;
            this.ElementDraggedClone = false;
            this.ElementDraggingActive = false;
            this.ElementDraggedIsRoot = false;
            this.DraggedItem = false;
            this.OverItem = false;

            for (let a = 0; a < this.SortableSubContainers.length; ++a) {
                if (this.SortableSubContainers[a].children.length === 1) {
                    this.SortableSubContainers[a].querySelector('.js-empty-folder').style['display'] = "block";
                } else if (this.SortableSubContainers[a].children.length > 1 && this.SortableSubContainers[a].querySelector('.empty-folder')) {
                    this.SortableSubContainers[a].querySelector('.js-empty-folder').style['display'] = "none";
                }
            }
        }
    }

    moveItem = (e, item, x, y) => {
        if (this.props.moveXY === true) {
            item.style["-webkit-transform"] = "translateX( " + x + "px ) translateY( " + y + "px )";
            item.style["-moz-transform"] = "translateX( " + x + "px ) translateY( " + y + "px )";
            item.style["-ms-transform"] = "translateX( " + x + "px ) translateY( " + y + "px )";
            item.style["transform"] = "translateX( " + x + "px ) translateY( " + y + "px )";
        } else {
            item.style["top"] = e.clientY + window.pageYOffset + "px";
        }
    }

    onTop = (item, x, y) => {

        let box = item.getBoundingClientRect(),
            isx = (x > box.left && x < (box.left + box.width)),
            isy = (y > box.top && y < (box.top + box.height)),
            onTop = false,
            direction = "UP";

        if (isx && isy) {
            onTop = true;
        }
        if (onTop && (y > box.top && y < (box.top + (box.height / 2)))) {
            direction = "UP";
        } else if (onTop && (y > (box.top + box.height / 2) && y < (box.top + box.height))) {
            direction = "DOWN";
        }
        return {onTop: onTop, direction: direction};
    }

    swapItems = (current, over, empty_folder) => {

        const updatedNestedArray = this.moveItemInNestedArray(
            this.state.pageContent, // Your initial nested array
            this.ElementDraggedID,   // ID of the item you want to move
            over.getAttribute('data-id'),    // ID of the target item where you want to place the source item
            empty_folder
        );
        this.isSwapped = true;
        this.setState({pageContent: updatedNestedArray});

    }

    moveItemInNestedArray(nestedArray, sourceId, targetId, empty_folder = false) {
        function findItemAndParent(arr, itemId) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].idu === itemId) {
                    return {item: arr[i], parent: arr};
                }
                if (arr[i].hasOwnProperty('child_elements') && arr[i].child_elements) {
                    const result = findItemAndParent(arr[i].child_elements, itemId);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        }

        const sourceInfo = findItemAndParent(nestedArray, sourceId);
        if (!sourceInfo) {
            return nestedArray; // Return the original array if source item is not found
        }
        const sourceItem = sourceInfo.item;
        const sourceParent = sourceInfo.parent;

        const targetInfo = findItemAndParent(nestedArray, targetId);
        if (!targetInfo) {
            return nestedArray; // Return the original array if target item is not found
        }

        const targetParent = targetInfo.parent;
        const targetIndex = targetParent.findIndex((item) => item.idu === targetId);

        if (targetIndex === -1) {
            return nestedArray; // Return the original array if target item is not found in its parent
        }

        // Remove the source item from its parent
        sourceParent.splice(sourceParent.indexOf(sourceItem), 1);

        if (empty_folder) {
            if(targetParent[targetIndex] && targetParent[targetIndex].hasOwnProperty('child_elements')){
                targetParent[targetIndex].child_elements.push(sourceItem);
            }
        } else {
            // Insert the source item at the target position
            targetParent.splice(targetIndex, 0, sourceItem);
        }
        return [...nestedArray]; // Return a new array to trigger React to update
    }

}

export default SortPageElements;