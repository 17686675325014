import React, {useEffect, useState} from "react";
import {IconsByVendor} from "./IconsByVendor";
import {LoadIcons, rootIcons} from "../../Helper/RootVar/Icons/Icons";

const MemoizedIconsByVendor = React.memo(IconsByVendor);
export const Icons = (props) => {
    /*set props*/
    const toggleActionMenuTab = props.toggleActionMenuTab;
    const section = props.section;
    const setSelectedSection = props.setSelectedSection;


    const [slideIn, setSlideIn] = useState(false);
    const [transitionEnded, setTransitionEnded] = useState(false);
    const actionLocalTab = 'font-awesome';

    useEffect(() => {
        setSlideIn(true);
        (async () => {
            if (rootIcons.length === 0) {
                await LoadIcons(actionLocalTab);
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section])

    const hideBack = () => {
        setSlideIn(false);
    }
    const handleTransitionEnd = (ev) => {
        if (ev.target.classList.contains("admiko-show")) {
            setTransitionEnded(true);
        } else if (ev.target.classList.contains("admiko-hide")) {
            toggleActionMenuTab("main");
        }
    }
    return (
        <div className={`admiko-action admiko-action-menu ${slideIn ? "admiko-show" : "admiko-hide"}`} onTransitionEnd={(ev) => handleTransitionEnd(ev)}>

            <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                <div className="info">
                    <h2><i className="fa-solid fa-icons"></i>Icons</h2>
                    <p>Select icon.</p>
                </div>
                <div className="close">
                    <div className="admiko-toggle-tools">
                        <div className="admiko-toggle-box">
                            <div>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="icon-sub-page-info">
                {section.is_on_root_page === 0 &&
                <>
                    <span><i className="fa-solid fa-triangle-exclamation"></i></span> The sub-page icon is visible in the parent table.
                </>
                }
            </div>
            {/*<div>*/}
            {/*    <div className="admiko-action-menu-tabs">*/}
            {/*        <div className={`${actionLocalTab === "font-awesome" ? 'active' : ''}`} onClick={() => toggleLocalTab('font-awesome')}><span><i className="fa-brands fa-font-awesome"></i></span>Font Awesome</div>*/}
            {/*        /!*<div className={`${actionLocalTab === "hero-icons" ? 'active' : ''}`} onClick={() => toggleLocalTab('hero-icons')}><span><i className="fa-solid fa-shield-blank"></i></span>Hero Icons</div>*!/*/}
            {/*        /!*<div className={`${actionLocalTab === "custom-icon" ? 'active' : ''}`} onClick={() => toggleLocalTab('custom-icon')}><span><i className="fa-solid fa-code"></i></span>Custom</div>*!/*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*{(actionLocalTab === "font-awesome" || actionLocalTab === "hero-icons") && <IconsByVendor section={section} setSelectedSection={setSelectedSection} vendor={actionLocalTab} setSelectIcon={setSelectIcon} />}*/}
            {/*{actionLocalTab === "custom-icon" && <IconCustom section={section} />}*/}
            {/*<MemoizedIconsByVendor section={section} setSelectedSection={setSelectedSection} vendor={actionLocalTab} rootIcons={rootIcons}/>*/}
            {transitionEnded ?
                <>
                    <MemoizedIconsByVendor section={section} setSelectedSection={setSelectedSection} vendor={actionLocalTab} rootIcons={rootIcons}/>
                </>
                :
                <>
                    <div className="admiko-ak-loading">
                        <i className="fa-solid fa-circle-notch"></i>
                    </div>
                </>
            }


        </div>
    );
}
