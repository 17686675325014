import React from "react";

export const DeleteSectionHeader = ({toggleActionMenuTab}) => {

    return (
        <>
            <div className="absolute bg-gray-800 mouse-pointer z-30 top-0 right-0 w-[64px] h-[64px] text-lg hover:bg-gray-700" onClick={(e) => toggleActionMenuTab('delete')}>
                <div className="w-full h-full border-l border-l-gray-500 border-dotted hover:text-red-500 flex items-center justify-center align-items-stretch transition-colors"><i className="fa-solid fa-trash"></i></div>
            </div>
        </>
    );
}
