import React from 'react';
export const DateTime = (props) => {
    const item = props.item;

    const createNiceDate = () => {
        let splitDate = new Date().toUTCString().split(" ");
        if(item.format === "date_time"){
            return splitDate[1]+' '+splitDate[2]+', '+splitDate[3]+' '+splitDate[4];
        } else {
            return splitDate[1]+' '+splitDate[2]+', '+splitDate[3];
        }
    };

    if(item.format === "date_time"){
        return (
            <>
                {createNiceDate()}
            </>
        );
    } else {
        return (
            <>
                {createNiceDate()}
            </>
        );
    }


}