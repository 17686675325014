import React from "react";
export const LoaderCircle = () => {

    return (
        <>
            <div className="admiko-ak-loading">
                <i className="fa-solid fa-circle-notch"></i>
            </div>
        </>
    );
}
