import React, {useEffect, useState} from "react";
import {useIsMountedRef} from "../../../../../../../Helper/isMounted";
import {FormDeleteButton} from "../../../../../../Helpers/FormDeleteButton";
import {rootSetError} from "../../../../../../../Helper/RootVar/Error/Error";
import {apiDeleteFormElement} from "../../../../../../../Api/useForm";

export const DeleteElementConfirm = (props) => {
    /*set props*/
    const toggleActionMenuTab = props.toggleActionMenuTab;
    const elementData = props.elementData;
    const form_idu = props.form_idu;
    const deletedParentAction = props.deletedParentAction;

    const isMountedRef = useIsMountedRef();
    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        setSlideIn(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(elementData)])

    const hideBack = () => {
        setSlideIn(false);
        setTimeout(() => {
            toggleActionMenuTab("main");
        }, 200)
    }


    /*send button*/
    const [submitButtonTimer, setSubmitButtonTimer] = useState(0);
    const [submitButton, setSubmitButton] = useState({disable: false, state: ""});
    /*end send button*/

    const deleteMe = async () => {
        /*send button*/
        setSubmitButton({...submitButton, disable: true, state: "progress"});
        /*end send button*/
        /*send button*/
        if (submitButtonTimer) {
            window.clearTimeout(submitButtonTimer);
            setSubmitButtonTimer(0);
        }
        /*end send button*/
        const response = await apiDeleteFormElement({}, form_idu,elementData.idu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            deletedParentAction(response);
        } else {
            rootSetError(response);
        }
    }


    return (
        <div className={`admiko-action admiko-action-menu ${slideIn ? "admiko-show" : ""}`}>
            <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                <div className="info">
                    <h2><i className="fa-solid fa-trash"></i>Delete</h2>
                </div>
                <div className="close">
                    <div className="admiko-toggle-tools">
                        <div className="admiko-toggle-box">
                            <div>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="admiko-action-menu-body">
                <div className={`admiko-action-menu-body-action`}>
                    <p>Delete forever?</p>
                    <FormDeleteButton submitButton={submitButton} deleteMe={deleteMe}/>
                </div>
            </div>
        </div>
    );
}
