import React, {useEffect} from "react";
import {FormTitle} from "./Partials/FormTitle";
import {FormVariable} from "./Partials/FormVariable";
import {FormDescription} from "./Partials/FormDescription";
import {FormRequired} from "./Partials/FormRequired";
import {FormSubmitButton} from "../../../../../Helpers/FormSubmitButton";
import {FormWidth} from "./Partials/FormWidth";
import {FormConnectedSource} from "./Partials/FormConnectedSource";
import {FormCloseButton} from "../../../../../Helpers/FormCloseButton";
import {FormSortingEnable} from "./Partials/FormSortingEnable";
import {FormElementDatabase} from "./Partials/FormElementDatabase";
import {FormCustomClass} from "./Partials/FormCustomClass";
import {AdvancedSettings} from "../../../../../Helpers/AdvancedSettings";
import {FormPrefix} from "./Partials/FormPrefix";
import {FormSuffix} from "./Partials/FormSufix";
import {FormDisableAKJS} from "./Partials/FormDisableAKJS";
import {rootCUSTOM_CLASS_MAX_LENGTH, rootDB_MAX_LENGTH, rootELEMENT_NAME_MAX_LENGTH} from "../../../../../../Helper/RootVar/Contstant";
import {FormUseAjaxSelect} from "./Partials/FormUseAjaxSelect";

export const MultiSelect = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormData = props.onChangeFormData;
    const sendFormData = props.sendFormData;
    const closeForm = props.closeForm;
    const submitButton = props.submitButton;
    const section_id = props.section_id;
    const element_idu = props.element_idu;
    const checkSaveButtonOnChildStart = props.checkSaveButtonOnChildStart;
    useEffect(() => {
        checkSaveButtonOnChildStart(formFieldsRules);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const formFieldsRules = {
        title: {required: true, type: "input", rules: {minLength: 1, maxLength: 64}},
        elm_name: {required: true, type: "input", rules: {minLength: 1, maxLength: rootELEMENT_NAME_MAX_LENGTH, allowedInput: "upper_lower_dash_numbers_protect_start_end"}},
        required: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        element_sorting: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        use_ajax: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        element_database: {required: true, type: "input", rules: {minLength: 1, maxLength: rootDB_MAX_LENGTH, allowedInput: "lower_dash_numbers_protect_start_end"}},
        source: {required: true, type: "select"},
        connected_source_element_id: {required: false, type: "select"},
        custom_class: {required: false, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_CLASS_MAX_LENGTH, allowedInput: "upper_lower_dash_minus_numbers_space"}},
        selected_labels_list: {
            required: true, array_required: true, type: "array",
        },
        selected_sort_list: {
            required: true, array_required: true, type: "array",
        },
        prefix: {required: false, type: "input", rules: {minLength: 1, maxLength: 20}},
        suffix: {required: false, type: "input", rules: {minLength: 1, maxLength: 20}},
        disable_js: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
    }
    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules)
    };
    return (
        <>
            <form action="">
                <FormTitle formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                {formData.can_edit === 1 &&
                    <FormVariable formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                }
                <FormWidth formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormRequired formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                {formData.can_edit === 1 &&
                    <>
                        <FormSortingEnable formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                        <FormUseAjaxSelect formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                        <FormElementDatabase formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                        <FormConnectedSource section_id={section_id} element_idu={element_idu} formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    </>
                }
                <AdvancedSettings>
                    <FormDescription formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                    <FormPrefix formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormSuffix formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormCustomClass formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormDisableAKJS formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                </AdvancedSettings>
                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                <FormCloseButton closeForm={(e) => closeForm(e)}/>
            </form>
        </>
    );

}