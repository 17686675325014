import React, {useEffect} from "react";
import {FormTitle} from "./Partials/FormTitle";
import {FormColumn} from "./Partials/FormColumn";
import {FormPlaceholder} from "./Partials/FormPlaceholder";
import {FormDescription} from "./Partials/FormDescription";
import {FormRequired} from "./Partials/FormRequired";
import {FormSubmitButton} from "../../../../../Helpers/FormSubmitButton";
import {FormWidth} from "./Partials/FormWidth";
import {FormCloseButton} from "../../../../../Helpers/FormCloseButton";
import {FormCustomClass} from "./Partials/FormCustomClass";
import {FormDisableAKJS} from "./Partials/FormDisableAKJS";
import {AdvancedSettings} from "../../../../../Helpers/AdvancedSettings";
import {rootCUSTOM_CLASS_MAX_LENGTH, rootTABLE_COLUMN_MAX_LENGTH} from "../../../../../../Helper/RootVar/Contstant";

export const DateTime = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormData = props.onChangeFormData;
    const sendFormData = props.sendFormData;
    const submitButton = props.submitButton;
    const closeForm = props.closeForm;
    const checkSaveButtonOnChildStart = props.checkSaveButtonOnChildStart;
    useEffect(() => {
        checkSaveButtonOnChildStart(formFieldsRules);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const formFieldsRules = {
        title: {required: true, type: "input", rules: {minLength: 1, maxLength: 64}},
        //elm_name: {required: true, type: "input", rules: {minLength: 1, maxLength: 64, allowedInput: "upper_lower_dash_numbers"}},
        table_column: {required: true, type: "input", rules: {minLength: 1, maxLength: rootTABLE_COLUMN_MAX_LENGTH, allowedInput: "upper_lower_dash_numbers_protect_start_end"}},
        required: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        custom_class: {required: false, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_CLASS_MAX_LENGTH, allowedInput: "upper_lower_dash_minus_numbers_space"}},
        disable_js: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
    }
    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules)
    };
    return (
        <>
            <form action="">
                <FormTitle formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                {/*<FormVariable formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>*/}
                <FormColumn formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormWidth formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormRequired formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                <div className="form-group">
                    <label htmlFor="date_time_type">Type:</label>
                    <div className="form-group-input">
                        <select value={formData.date_time_type} className="form-select" name="date_time_type" id="date_time_type" onChange={(e) => onChangeFormDataInput(e)}>
                            <option value="date">Date</option>
                            <option value="date_time">Date & Time</option>
                        </select>
                    </div>
                </div>
                <AdvancedSettings>
                    <FormDescription formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormPlaceholder formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                    <FormCustomClass formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormDisableAKJS formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                </AdvancedSettings>

                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                <FormCloseButton closeForm={(e) => closeForm(e)}/>
            </form>
        </>
    );

}