import {fetchData} from "./useCallApi";

export const apiAddMenu = async (dataToSend) => {
    const dataFetch = {action:"menu_add",dataPrepare:dataToSend};
    return await fetchData(dataFetch);
}
export const apiSortMenu = async (dataToSend) => {
    const dataFetch = {action:"menu_sort",dataPrepare:dataToSend,use_cancel:true};
    return await fetchData(dataFetch);
}

export const apiEditMenu = async (dataToSend,sec_id) => {
    const dataFetch = {action:"menu_edit",dataPrepare:dataToSend,"sec_id":sec_id};
    return await fetchData(dataFetch);
}
export const apiDeleteMenu = async (dataToSend,sec_id) => {
    const dataFetch = {action:"menu_delete",dataPrepare:dataToSend,"sec_id":sec_id};
    return await fetchData(dataFetch);
}
