import {useEffect, useState} from 'react';
export let rootBuilderSidebarDocuLink = 'https://admiko.com/docs/sidebar/';
export let rootBuilderHowToStartDocuLink = 'https://admiko.com/docs/start/';
export let rootBuilderDocuArea = 'index';
export let setRootBuilderDocuArea;

export let rootBuilderDocuLink = "https://admiko.com/docs/";
export let setRootBuilderDocuLink;
export const useBuilderDocumentation = () => {
    [rootBuilderDocuArea, setRootBuilderDocuArea] = useState('index');
    [rootBuilderDocuLink, setRootBuilderDocuLink] = useState("https://admiko.com/docs/");
    // [rootBuilderSidebarDocuLink] = useState('https://admiko.com/docs/sidebar/');

    useEffect(() => {
        let setLink = "";
        switch (rootBuilderDocuArea) {
            case "form":
                setLink = "form/fields/";
                break;
            case "table":
                setLink = "table/";
                break;
            case "page":
                setLink = "page/";
                break;
            case "widgets":
                setLink = "widgets/";
                break;
            default:
                setLink = "";
                break;
        }
        setRootBuilderDocuLink("https://admiko.com/docs/" + setLink);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootBuilderDocuArea])
};
