import React, {useEffect, useState} from "react";

import {rootAllPages, rootBuilderLink, setRootSinglePageForm, setSinglePageForm} from "../../Helper/RootVar/Contstant";
import {useNavigate} from "react-router-dom";
import SortPageElements from "../../Helper/SortPageElements";

export const PageContentStart = (props) => {
    /*set props*/
    const url_menu_id = props.url_menu_id;
    const url_page_id = props.url_page_id;
    const url_table_id = props.url_table_id;
    const url_widget_id = props.url_widget_id;

    const [fadeIn, setFadeIn] = useState(false);
    const [pageContent, setPageContent] = useState(false);
    const [pageWidth, setPageWidth] = useState('full');

    let navigate = useNavigate();

    useEffect(() => {
        if (rootAllPages.hasOwnProperty(url_page_id)) {
            if (rootAllPages[url_page_id].hasOwnProperty('content')) {
                // setPageContent(rootAllPages[url_page_id].content);
                groupByContainer(rootAllPages[url_page_id].content)
                setPageWidth(rootAllPages[url_page_id].page.page_width);
                pageFormRedirect(url_page_id);
            } else {
                setPageContent(false);
                setRootSinglePageForm({});
            }
        } else {
            /*todo: else*/
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rootAllPages[url_page_id])])

    const groupByContainer = (content) => {

        let rootElements = content.filter((item) => {
            return item.container_idu === 0;
        });
        let returnData = [];

        if(rootElements.length > 0) {
            returnData = rootElements.map((rootItem, i) =>{
                if(rootItem.layout === 'element_container'){
                    let childElements = content.filter((item) => {
                        return item.container_idu === rootItem.idu && item.layout !== 'element_container';
                    });
                    rootItem['child_elements'] = childElements
                    return rootItem;
                } else {
                    rootItem['child_elements'] = [];
                    return rootItem;
                }
            })
        }
        setPageContent(returnData);
    }


    const pageFormRedirect = (page_id) => {
        let data = setSinglePageForm(page_id);
        if(data){
            navigate(`${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}/f/${data.page_form_id}/edit`);
        } else {
            /*todo: else*/
        }
    }

    // const saveSortMe = (newList) => {
    //     (async () => {
    //         await apiSortPageElements(newList, url_page_id);
    //     })()
    // }
    useEffect(() => {
        setTimeout(() => {
            setFadeIn(true);
        }, 0);
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`ak-fade-in ${fadeIn === true ? "fade-in-start":""} content-width-${pageWidth}`}>
            {pageContent !== false ? (
                        <>
                            {Array.isArray(pageContent) && pageContent.length > 0 ? (
                                <>
                                    <SortPageElements pageContent={pageContent}
                                                      url_menu_id={url_menu_id}
                                                      url_page_id={url_page_id}
                                                      url_table_id={url_table_id}
                                                      url_widget_id={url_widget_id}
                                                      moveXY={true}
                                    />
                                    {/*<SortMePageElements saveSortMe={saveSortMe} parentContainer="js-page-elements-container">*/}
                                    {/*    {pageContent.map((item, i) => {*/}
                                    {/*        if (item.container_idu === 0) {*/}
                                    {/*            return (*/}
                                    {/*                <LoadPageElement*/}
                                    {/*                    key={`c-` + item.idu}*/}
                                    {/*                    position={i}*/}
                                    {/*                    item={item}*/}
                                    {/*                    pageContent={pageContent}*/}
                                    {/*                    totalItems={pageContent.length}*/}
                                    {/*                    url_menu_id={url_menu_id}*/}
                                    {/*                    url_page_id={url_page_id}*/}
                                    {/*                    url_table_id={url_table_id}*/}
                                    {/*                    url_widget_id={url_widget_id}*/}
                                    {/*                />*/}
                                    {/*            );*/}
                                    {/*        }*/}
                                    {/*        return null; // Render null or another component if the condition is not met*/}
                                    {/*    })}*/}
                                    {/*</SortMePageElements>*/}
                                </>
                            ) : (
                                <>
                                    <div className="ak-page-empty text-sm" role="alert">
                                        <div>
                                            <span>Don't worry if the page looks empty right now; we've got some exciting tools for you.</span>
                                        </div>
                                        <div>
                                            1. <i className="fa-solid fa-cog text-blue-500"></i> Let's have some fun with the sidebar builder on the left!<br/>
                                            <p className="text-gray-700">- Add pages, rearrange the menu, and unleash your website's magic with a touch of your own creativity.</p>
                                        </div>
                                        <div>
                                            2. Toolbox on the right: It's like a magical kit filled with awesome elements. <br/>
                                            <p className="text-gray-700">- Add new sections, manage forms and edit table to bring your page to life!</p>
                                        </div>
                                        <div className="font-medium">
                                            Let's get creative!
                                        </div>
                                       {/*<div className="flex items-center gap-2 text-sm pt-4 border-t border-gray-100">*/}
                                       {/*    <div className="w-4 h-4">*/}
                                       {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z"/></svg></div>*/}
                                       {/*    <div><Link to={rootBuilderHowToStartDocuLink} target="_blank">How to start</Link></div>*/}
                                       {/*</div>*/}
                                        {/*Add new page elements from the toolbox on the right side <i className="fa-solid fa-arrow-right"></i><br/>*/}
                                        {/*or use <i className="fa-solid fa-cog"></i> on the left side to add new pages and edit menu.<br/>*/}
                                    </div>
                                </>
                            )}
                        </>
                ) :
                <>
                    <div className="admiko-ak-loading">
                        <i className="fa-solid fa-circle-notch"></i>
                    </div>
                </>
            }
        </div>
    )
}
