import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {appRootClients, setAppRootClientsDirect} from "../../Global/Clients";
import {apiGetClients} from "../../Api/useCallApi";
import {setRootPageInfoDirect} from "../../Global/PageInfo";

export const Clients = () => {

    useEffect(() => {
        setRootPageInfoDirect({title: "Clients", subtitle: "Manage your clients.",activeMenu:"clients", breadcrumbs: [{link: "/clients", title: "Clients"}]});
        (async () => {
            const response = await apiGetClients();
            if (response.success === true) {
                setAppRootClientsDirect(response.return.clients)
            } else {

            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return appRootClients !== false ? (
            <>
                {Array.isArray(appRootClients) && appRootClients.length > 0 ? (
                    <>
                        <div className="mb-4">
                            <Link type="button" to="/client/add" draggable="false" className="add-new-btn">
                                <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"/>
                                </svg>
                                New client
                            </Link>
                        </div>
                        <div className="ak-table-content">
                            <table>
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Company</th>
                                    <th>Website</th>
                                    <th className="edit-btn">Edit</th>
                                </tr>
                                </thead>
                                <tbody>
                                {appRootClients.map((item) => (
                                    <tr key={item.idu}>
                                        <td className="nowrap">{item.name}</td>
                                        <td className="nowrap">{item.company}</td>
                                        <td className="nowrap">{item.website}</td>
                                        <td className="edit-btn">
                                            <Link to={`/client/edit/${item.idu}`} draggable="false" className="ak-menu-item-link">
                                                <i className="fa-solid fa-user-pen"></i>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="pb-6">
                            <div className="text-center">
                                <svg className="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"/>
                                </svg>
                                <h3 className="mt-2 text-sm font-medium text-gray-900">No clients</h3>
                                <p className="mt-1 text-sm text-gray-500">Start by creating a new client.</p>
                                <div className="mt-6">
                                    <Link type="button" to="/client/add" draggable="false" className="add-new-btn">
                                        <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"/>
                                        </svg>
                                        New client
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {/*<div>*/}
                {/*    <Link to="/client/add" type="button" draggable="false" className="add-new-btn">*/}
                {/*        <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">*/}
                {/*            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"/>*/}
                {/*        </svg>*/}
                {/*        New Client*/}
                {/*    </Link>*/}
                {/*</div>*/}
            </>
        ) :
        <>
            <div className="admiko-ak-loading">
                <i className="fa-solid fa-circle-notch"></i>
            </div>
        </>;
};