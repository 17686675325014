import React from "react";

export const AdvancedSettings = ({children}) => {

    function toggleContainer(e) {
        e.preventDefault();
        if (e.target.closest(".ak-toggle-settings-box").classList.contains('active')) {
            e.target.closest(".ak-toggle-settings-box").querySelector('.ak-more-settings').style["height"] = '0px';
        } else {
            let totalHeight = e.target.closest(".ak-toggle-settings-box").querySelector('.ak-more-settings').scrollHeight
            e.target.closest(".ak-toggle-settings-box").querySelector('.ak-more-settings').style["height"] = totalHeight + 'px';
        }
        e.target.closest(".ak-toggle-settings-box").classList.toggle('active');
    }
    return (
        <>
            <div className={`ak-toggle-settings-box`}>
                <div className={`ak-toggle-settings`} onClick={(e) => toggleContainer(e)}>
                    <div>
                        <i className="fa-solid fa-angle-down"></i>
                    </div>
                </div>
                <div className={`ak-more-settings`}>
                    {children}
                </div>
            </div>
        </>
    );
}