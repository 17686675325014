import React, {useEffect, useState} from "react";
import {useIsMountedRef} from "../../../../Helper/isMounted";
import SortMe from "../../../../Helper/SortMe";
import {SortMeHelperTools} from "../../../Helpers/SortMeHelperTools";
import {CheckFormDataFields} from "../../../../Helper/RootVar/Error/CheckFormFields";
import {FormSubmitButton} from "../../../Helpers/FormSubmitButton";
import {rootSetError} from "../../../../Helper/RootVar/Error/Error";
import {apiEditTableLayoutChildPages, apiSortTableLayoutChildPages} from "../../../../Api/usePage";

/*ADD new elements to a FORM*/
export const LayoutChildPages = (props) => {
    /*set props*/
    const form_idu = props.form_idu;
    const child_pages = props.elements.child_pages;

    const [visibleSettings, setVisibleSettings] = useState(0)

    const showSettings = (idu) => {
        if (visibleSettings === idu) {
            setVisibleSettings(0);
        } else {
            setVisibleSettings(idu);
        }
    }
    const saveSortMe = (newList) => {
        (async () => {
            await apiSortTableLayoutChildPages(newList,form_idu);
        })()
    }

    /*end form js*/
    return (
        <>

                <>
                    <br/>
                    <h4>
                        Sort sub-pages:
                    </h4>
                    {child_pages.length > 0 ?
                    <SortMe saveSortMe={saveSortMe} parentContainer="js-ak-source-table-child-pages">
                        {child_pages.map((element, index) => (
                            <LabelElements key={element.idu} ElementData={element} position={index} totalItems={child_pages.length}
                                           idu={element.idu}
                                           showSettings={showSettings}
                                           visibleSettings={visibleSettings}
                                           form_idu={form_idu}
                            />
                        ))}
                    </SortMe>
                    :
                    <p>No child pages</p>
                    }
                </>

        </>
    );
}


export const LabelElements = (props) => {
    /*set props*/
    const idu = props.idu;
    const form_idu = props.form_idu;
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const showSettings = props.showSettings;
    const visibleSettings = props.visibleSettings;
    const reloadContent = props.reloadContent;

    return (
        <>
            <div className="form-group ak-source-label-group ak-layout-group js-ak-source-table-child-pages js-admiko-sort-me-container" data-id={ElementData.idu}>
                <div className="form-group-input">
                    <label htmlFor={`layout-${ElementData.idu}`}>
                        {ElementData.title}
                    </label>
                    <SortMeHelperTools position={position} sortMe={sortMe} totalItems={totalItems} showSettings={showSettings} idu={ElementData.idu}/>
                </div>
                <div className={`ak-group-element-settings-edit ${visibleSettings === idu && "show"} show-1`}>
                    {visibleSettings === idu &&
                        <>
                            <LabelSettings ElementData={ElementData} position={position} form_idu={form_idu} reloadContent={reloadContent}/>

                        </>
                    }
                </div>
            </div>
        </>
    );
}

export const LabelSettings = (props) => {
    const ElementData = props.ElementData;
    const position = props.position;
    const form_idu = props.form_idu;

    const isMountedRef = useIsMountedRef();

    const [elementSettings, setElementSettings] = useState(props.ElementData.settings);

    useEffect(() => {
        setElementSettings(props.ElementData.settings);
    }, [props.ElementData.settings])

    //*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");

    const onChangeFormData = (e, formFieldsChildRules, position) => {
        if (formFieldsChildRules.hasOwnProperty(e.target.name)) {
            e.target.value = CheckFormDataFields(e, formFieldsChildRules, submitButton, setSubmitButton);
        }
        setElementSettings({[e.target.name]: e.target.value});
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiEditTableLayoutChildPages(elementSettings, form_idu, ElementData.idu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/

    return (
        <>
            <ChildPages ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
            <div className="layoutSaveBtn">
                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
            </div>

        </>
    );
}

export const ChildPages = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        data: {required: true, type: "select"},
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-${ElementData.idu}`}>Table link:</label>
                <div className="form-group-input">
                    <select defaultValue={elementSettings} className="form-select" name="data" id={`data-${ElementData.idu}`} onChange={(e) => onChangeFormDataInput(e)}>
                        <option value="icon">Icon</option>
                        <option value="icon_title">Icon and title</option>
                        <option value="title">Title</option>
                    </select>
                </div>
            </div>
        </>
    );

}