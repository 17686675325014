import React, {useEffect, useState} from "react";
import {useIsMountedRef} from "../../../../Helper/isMounted";
import {FormCloseButton} from "../../../Helpers/FormCloseButton";
import {PaginationTableOrder} from "./Partials/PaginationTableOrder";
import {FormSubmitButton} from "../../../Helpers/FormSubmitButton";
import {rootSetError} from "../../../../Helper/RootVar/Error/Error";
import {apiUpdateTablePagination} from "../../../../Api/useTable";
import {onChangeFormDataGlobal} from "../../../../Helper/Functions";
import {useNavigate, useParams} from "react-router-dom";
import {rootBuilderLink} from "../../../../Helper/RootVar/Contstant";

/*ADD new elements to a FORM*/
export const Pagination = (props) => {
    const {url_menu_id = false, url_page_id = false, url_table_id = false} = useParams();
    const tableLinkLoad = `${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}/t/${url_table_id}`;
    let navigate = useNavigate();
    /*set props*/
    const form_idu = props.form_idu;
    const pagination = props.elements.pagination.pagination;
    const elements = props.elements.pagination.elements;
    const selected_elements = props.elements.pagination.selected_elements;
    const title = props.title;

    const isMountedRef = useIsMountedRef();

    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSlideIn(true);
        }, 1);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closeForm = () => {
        hideBack();
    };
    const hideBack = () => {
        setSlideIn(false);
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            navigate(`${tableLinkLoad}`);
        }, 200)
    }


    /*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");
    /*form js*/
    const formFields = {pagination: pagination, order: []};
    const formFieldsRules = {
        pagination: {required: false, type: "select"},
    }
    const [formData, setFormData] = useState(formFields);

    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };
    const onChangeFormDataInput = (e) => {
        setFormData(prevFormData => ({...prevFormData, [e.name]: e.value}));
    }
    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await  apiUpdateTablePagination({...formData}, form_idu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            // resetFormData();
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/
    //

    /*end form js*/
    return (
        <>
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll ${slideIn ? "admiko-show" : ""}`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                            <div className="info">
                                <h2><i className={`${title.icon}`}></i>{title.title}</h2>
                                <p>{title.info}</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-action-menu-body">
                            <div className={`admiko-action-menu-body-action`}>
                                <div className="form-group ak-group-element-settings-edit-input">
                                    <label htmlFor={`pagination-${form_idu}`}>Pagination:</label>
                                    <div className="form-group-input">
                                        <select defaultValue={pagination} className="form-select" name="pagination" id={`pagination-${form_idu}`} onChange={(e) => onChangeFormData(e)}>
                                            <option value="pagination_js">JavaScript search & pagination</option>
                                            <option value="drag_drop">Drag & drop sorting</option>
                                            <option value="pagination">Classic search & pagination</option>
                                            <option value="none">No pagination</option>
                                        </select>
                                    </div>
                                </div>

                                {formData.pagination !== "drag_drop" &&
                                    <>
                                        <PaginationTableOrder form_idu={form_idu} elements={elements} selected_elements={selected_elements} onChangeFormDataInput={onChangeFormDataInput}/>
                                    </>
                                }
                                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                <FormCloseButton closeForm={(e) => closeForm(e)}/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
