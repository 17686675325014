import React, {useEffect, useRef, useState} from "react";
import SortMe from "../../../../../../../Helper/SortMe";
import {useIsMountedRef} from "../../../../../../../Helper/isMounted";
import {rootSetError} from "../../../../../../../Helper/RootVar/Error/Error";
import {apiGetConnectedSourceLabels} from "../../../../../../../Api/useForm";

export const FormConnectedSource = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;
    const section_id = props.section_id;
    const element_idu = props.element_idu;

    const isMountedRef = useIsMountedRef();

    const [connectedSourceID, setConnectedSourceID] = useState("none");
    const [connectedSourceElementID, setConnectedSourceElementID] = useState("none");
    const [sourceLabels, setSourceLabels] = useState([]);
    const [sourceConnectedElementList, setSourceConnectedElementList] = useState([]);
    const sourceSelectElement = useRef(null);

    /*LABEL*/
    const [connectedSourceLabels, setConnectedSourceLabels] = useState([]);
    const [labelGroup, setLabelGroup] = useState([])
    const [showSeparator, setShowSeparator] = useState(0)

    /*SORT*/
    const [connectedSourceSortLabels, setConnectedSourceSortLabels] = useState([]);
    const [sortLabelGroup, setSortLabelGroup] = useState([])

    useEffect(() => {
        setConnectedSourceID(formData.source);
        setConnectedSourceLabels(formData.selected_labels_list);
        setConnectedSourceSortLabels(formData.selected_sort_list);

        let elm = {target: sourceSelectElement.current};
        onChangeFormDataInput(elm);
        setConnectedSourceElementID(formData.connected_source_element_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (Array.isArray(connectedSourceLabels) && connectedSourceLabels.length > 0) {
            generateLabelGroup(connectedSourceLabels);
            generateSortLabelGroup(connectedSourceSortLabels);
        } else if (sourceLabels.length > 0) {
            let newLabelGroup = [{idu: sourceLabels[0].idu, separator: " ", labelList: []}];
            generateLabelGroup(newLabelGroup);

            let newSortLabelGroup = [{idu: sourceLabels[0].idu, order: "asc", labelList: []}];
            generateSortLabelGroup(newSortLabelGroup);
        } else {
            generateLabelGroup([]);
            generateSortLabelGroup([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceLabels])

    const LoadSourceLabels = async () => {
        const response = await apiGetConnectedSourceLabels({source_idu: connectedSourceID, uid_form_element: element_idu}, section_id );
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSourceLabels(response.return.source_labels);
            setSourceConnectedElementList(response.return.source_connected_element_list);
        } else {
            rootSetError(response);
        }
    }

    /*SOURCE ID*/
    /*SOURCE ID*/
    /*SOURCE ID*/

    useEffect(() => {
        setShowSeparator(0);
        setLabelGroup([]);
        setSortLabelGroup([]);
        if (connectedSourceID !== "none") {
            LoadSourceLabels();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectedSourceID])

    const onChangeSource = () => {
        /*UPDATE SOURCE ID*/
        let elm = {target: sourceSelectElement.current};
        onChangeFormDataInput(elm);

        setConnectedSourceElementID("none");
        onChangeFormDataInput({target: {name: 'connected_source_element_id', value: "none"}});

        setConnectedSourceLabels([]);
        setConnectedSourceSortLabels([]);
        setConnectedSourceID(sourceSelectElement.current.value);
    };
    const onChangeElementSource = (elm) => {
        /*UPDATE SOURCE ELEMENT ID*/
        setConnectedSourceElementID(elm.target.value);
        onChangeFormDataInput(elm);
    };

    /*label group*/
    /*label group*/
    /*label group*/

    useEffect(() => {
        saveDataToParent(labelGroup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [labelGroup])

    const saveDataToParent = (labelGroupData) => {
        let newLabelGroup = [];
        for (let i = 0; i < labelGroupData.length; i++) {
            newLabelGroup = [...newLabelGroup, {idu: labelGroupData[i].idu, separator: labelGroupData[i].separator}];
        }
        let elm = {target: {name: 'selected_labels_list', value: newLabelGroup}};
        onChangeFormDataInput(elm);
    }


    const addLabelGroup = () => {
        let newLabelGroup = [...labelGroup];
        let newSourceLabels = [...sourceLabels];

        const newLabelGroup2IDs = new Set(newLabelGroup.map(({idu}) => idu));
        const combined = [...newSourceLabels.filter(({idu}) => !newLabelGroup2IDs.has(idu))];

        newLabelGroup = [...newLabelGroup, {idu: combined[0].idu, separator: " ", labelList: []}];
        generateLabelGroup(newLabelGroup);
        setShowSeparator(0);
    }


    const generateLabelGroup = (labelGroupData) => {

        let newLabelGroup = [];
        let newSourceLabels = [...sourceLabels];

        for (let i = 0; i < labelGroupData.length; i++) {

            let newLabelGroupIDs = new Set(labelGroupData.map(({idu}) => idu));

            let index = newLabelGroupIDs.has(labelGroupData[i].idu);
            if (index) {
                newLabelGroupIDs.delete(labelGroupData[i].idu)
            }
            let combined = [...newSourceLabels.filter(({idu}) => !newLabelGroupIDs.has(idu))];
            let indexKey = combined.findIndex(p => p.idu === labelGroupData[i].idu);
            newLabelGroup = [...newLabelGroup, {idu: combined[indexKey].idu, separator: labelGroupData[i].separator, labelList: combined}];
        }

        setLabelGroup(newLabelGroup);
    }


    const removeLabelGroup = (i) => {
        let newLabelGroup = [...labelGroup];
        newLabelGroup.splice(i, 1);
        setShowSeparator(0);
        if (newLabelGroup.length < 1) {
            newLabelGroup = [{idu: sourceLabels[0].idu, separator: "", labelList: []}];
        }
        generateLabelGroup(newLabelGroup);
    }
    const editLabelGroup = (idu) => {
        if (showSeparator === idu) {
            setShowSeparator(0);
        } else {
            setShowSeparator(idu);
        }
    }

    const onChangeSeparatorInput = (e, idu) => {

        let newLabelGroup = [...labelGroup];
        let indexKey = newLabelGroup.findIndex(p => p.idu === idu);

        if (indexKey !== -1) {
            newLabelGroup[indexKey] = {idu: newLabelGroup[indexKey].idu, separator: e.target.value.substring(0, 10), labelList: newLabelGroup[indexKey].labelList};
            generateLabelGroup(newLabelGroup);
        }

    }
    const onChangeSourceLabel = (e, idu) => {

        let newFormValues = [...labelGroup];
        newFormValues.find((o, i) => {
            if (o.idu === idu) {
                newFormValues[i] = {idu: e.target.value, separator: newFormValues[i].separator, labelList: []};
                generateLabelGroup(newFormValues);
                return true;
            }
            return false;
        });
    };

    const saveLabelSortMe = (newList) => {
        let newLabelGroup = [];
        let newFormValues = [...labelGroup];
        let indexKey = 0;
        for (let i = 0; i < newList.sortList.length; i++) {
            indexKey = newFormValues.findIndex(p => p.idu === newList.sortList[i]);
            newLabelGroup = [...newLabelGroup, {idu: newFormValues[indexKey].idu, separator: newFormValues[indexKey].separator, labelList: []}];
        }
        generateLabelGroup(newLabelGroup);

    };

    /*SORTING*/
    /*SORTING*/
    /*SORTING*/

    useEffect(() => {
        saveSortDataToParent(sortLabelGroup);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortLabelGroup])

    const saveSortDataToParent = (labelGroupData) => {
        let newLabelGroup = [];
        for (let i = 0; i < labelGroupData.length; i++) {
            newLabelGroup = [...newLabelGroup, {idu: labelGroupData[i].idu, order: labelGroupData[i].order}];
        }
        let elm = {target: {name: 'selected_sort_list', value: newLabelGroup}};
        onChangeFormDataInput(elm);
    }

    const generateSortLabelGroup = (labelGroupData) => {
        let newLabelGroup = [];
        let newSourceLabels = [...sourceLabels];

        for (let i = 0; i < labelGroupData.length; i++) {
            let order = labelGroupData[i].order;
            let newLabelGroupIDs = new Set(labelGroupData.map(({idu}) => idu));

            let index = newLabelGroupIDs.has(labelGroupData[i].idu);
            if (index) {
                newLabelGroupIDs.delete(labelGroupData[i].idu)
            }
            let combined = [...newSourceLabels.filter(({idu}) => !newLabelGroupIDs.has(idu))];
            let indexKey = combined.findIndex(p => p.idu === labelGroupData[i].idu);
            newLabelGroup = [...newLabelGroup, {idu: combined[indexKey].idu, order: order, labelList: combined}];
        }

        setSortLabelGroup(newLabelGroup);
    }

    const onChangeSortSourceLabel = (e, idu) => {

        let newFormValues = [...sortLabelGroup];
        newFormValues.find((o, i) => {
            if (o.idu === idu) {
                newFormValues[i] = {idu: e.target.value, order: newFormValues[i].order, labelList: []};
                generateSortLabelGroup(newFormValues);
                return true;
            }
            return false;
        });
    };

    const onChangeSortOrderSourceLabel = (e, idu) => {
        let newFormValues = [...sortLabelGroup];
        newFormValues.find((o, i) => {
            if (o.idu === idu) {
                newFormValues[i] = {idu: newFormValues[i].idu, order: e.target.value, labelList: []};
                generateSortLabelGroup(newFormValues);
                return true;
            }
            return false;
        });
    };


    const addSortLabelGroup = () => {
        let newLabelGroup = [...sortLabelGroup];
        let newSourceLabels = [...sourceLabels];

        const newLabelGroup2IDs = new Set(newLabelGroup.map(({idu}) => idu));
        const combined = [...newSourceLabels.filter(({idu}) => !newLabelGroup2IDs.has(idu))];

        newLabelGroup = [...newLabelGroup, {idu: combined[0].idu, order: "asc", labelList: []}];
        generateSortLabelGroup(newLabelGroup);
    }


    const removeSortLabelGroup = (i) => {
        let newLabelGroup = [...sortLabelGroup];
        newLabelGroup.splice(i, 1);
        setShowSeparator(0);
        if (newLabelGroup.length < 1) {
            newLabelGroup = [{idu: sourceLabels[0].idu, order: "asc", labelList: []}];
        }
        generateSortLabelGroup(newLabelGroup);
    }

    const saveSortSortMe = (newList) => {
        let newLabelGroup = [];
        let newFormValues = [...sortLabelGroup];
        let indexKey = 0;
        for (let i = 0; i < newList.sortList.length; i++) {
            indexKey = newFormValues.findIndex(p => p.idu === newList.sortList[i]);
            newLabelGroup = [...newLabelGroup, {idu: newFormValues[indexKey].idu, order: newFormValues[indexKey].order, labelList: []}];
        }
        generateSortLabelGroup(newLabelGroup);

    };

    return (
        <>
            <div className="form-group">
                <label htmlFor="source">Source table:</label>
                <div className="form-group-input">
                    <select value={formData.source} ref={sourceSelectElement} className="form-select" name="source" id="source" onChange={(e) => onChangeSource(e)}>
                        <option key="none" value="none">Select source</option>
                        {formData.source_list.map((e) => {
                            return <option key={e.idu} value={e.idu}>{e.title}</option>;
                        })}
                    </select>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
            {(connectedSourceID !== "none") &&
                <>
                    {(sourceConnectedElementList.length > 0 && labelGroup.length > 0) &&
                        <div className="form-group">
                            <label htmlFor="source">Sync with element:</label>
                            <div className="form-group-input">
                                <select value={connectedSourceElementID} className="form-select" name="connected_source_element_id" id="connected_source_element_id" onChange={(e) => onChangeElementSource(e)}>
                                    <option key="none" value="none">Disable</option>
                                    {sourceConnectedElementList.map((e) => {
                                        return <option key={e.idu} value={e.idu}>{e.title}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                    }

                    {labelGroup.length > 0 ? (
                        <>
                            <div className="add-more-btn-box">
                                <div>
                                    Set label:
                                </div>
                                <div>
                                    {labelGroup.length < sourceLabels.length &&
                                        <div className="add-more-btn mouse-pointer" onClick={() => addLabelGroup()}><i className="fa-solid fa-plus"></i> Add more</div>
                                    }
                                </div>
                            </div>
                            <SortMe saveSortMe={saveLabelSortMe} parentContainer="js-ak-source-label-group">
                                {labelGroup.map((element, index) => (
                                    <FormConnectedSourceGroup key={element.idu} editLabelGroup={editLabelGroup} removeLabelGroup={removeLabelGroup}
                                                              onChangeSourceLabel={onChangeSourceLabel}
                                                              ElementData={element} position={index} totalItems={labelGroup.length}
                                                              idu={element.idu}
                                                              onChangeSeparatorInput={onChangeSeparatorInput}
                                                              showSeparator={showSeparator}
                                    />
                                ))}
                            </SortMe>

                            <div className="add-more-btn-box">
                                <div>
                                    Set sorting:
                                </div>
                                <div>
                                    {sortLabelGroup.length < sourceLabels.length &&
                                        <div className="add-more-btn mouse-pointer" onClick={() => addSortLabelGroup()}><i className="fa-solid fa-plus"></i> Add more</div>
                                    }
                                </div>
                            </div>
                            <SortMe saveSortMe={saveSortSortMe} parentContainer="js-ak-source-sort-label-group">
                                {sortLabelGroup.map((element, index) => (
                                    <FormSortConnectedSourceGroup key={element.idu} removeSortLabelGroup={removeSortLabelGroup}
                                                                  ElementData={element} position={index} totalItems={sortLabelGroup.length}
                                                                  idu={element.idu} onChangeSortSourceLabel={onChangeSortSourceLabel}
                                                                  onChangeSortOrderSourceLabel={onChangeSortOrderSourceLabel}
                                    />
                                ))}
                            </SortMe>

                        </>
                    )
                    :
                    (<>
                        <div className="admiko-ak-loading">
                            <i className="fa-solid fa-circle-notch"></i> Loading...
                        </div>
                    </>)
                }
                </>

            }
        </>
    );

}

export const FormConnectedSourceGroup = (props) => {
    /*set props*/
    const idu = props.idu;
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const onChangeSourceLabel = props.onChangeSourceLabel;
    const editLabelGroup = props.editLabelGroup;
    const removeLabelGroup = props.removeLabelGroup;
    const onChangeSeparatorInput = props.onChangeSeparatorInput;
    const showSeparator = props.showSeparator;

    return (
        <>
            <div className="form-group ak-source-label-group js-ak-source-label-group js-admiko-sort-me-container" data-id={ElementData.idu}>
                <div className="form-group-input">
                    <select className="form-select" value={ElementData.idu} name="source-label" id={`source-label-${idu}`} onChange={(e) => onChangeSourceLabel(e, idu)}>
                        {ElementData.labelList.map((e) => {
                            return <option key={e.idu} value={e.idu}>{e.title}</option>;
                        })}
                    </select>
                    <AdmikoToolboxEditBox position={position} sortMe={sortMe} totalItems={totalItems} editLabelGroup={editLabelGroup} idu={ElementData.idu}/>
                </div>
                <div className={`ak-source-label-group-edit ${showSeparator === idu && "show"}`}>
                    <div className="ak-source-label-group-edit-input">
                        <label htmlFor={`separator-label-${idu}`}>separator:</label>
                        <div className="form-group-input">
                            <input className="form-input" value={ElementData.separator} autoComplete="off" name="separator" id={`separator-label-${idu}`} placeholder="Enter separator" type="text" onChange={(e) => onChangeSeparatorInput(e, idu)}/>
                        </div>
                    </div>
                    <div className="admiko-form-settings">
                        {totalItems > 1 &&
                            <div className="mouse-pointer delete-label-group" onClick={() => removeLabelGroup(position)}><i className="fa-solid fa-trash"></i></div>
                        }
                    </div>
                </div>
            </div>
        </>
    );

}

export const AdmikoToolboxEditBox = (props) => {
    /*set props*/
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;

    const editLabelGroup = props.editLabelGroup;
    const idu = props.idu;
    return (
        <>
            <div className="admiko-form-settings">
                {totalItems > 1 &&
                    <div className="move-section tft-move js-admiko-sort-me">
                        {position > 0 &&
                            <div className="move-up mouse-pointer js-admiko-move-me-up" onClick={(e) => sortMe.SortMoveUp(e)}><i className="fa-solid fa-chevron-up"></i></div>
                        }
                        <div className="mouse-move js-admiko-move-me"><i className="fa-solid fa-up-down-left-right"></i></div>
                        {position + 1 < totalItems &&
                            <div className="move-down mouse-pointer js-admiko-move-me-down" onClick={(e) => sortMe.SortMoveDown(e)}><i className="fa-solid fa-chevron-down"></i></div>
                        }
                    </div>
                }
                <div className="mouse-pointer element-edit" onClick={() => editLabelGroup(idu)}><i className="fa-solid fa-gear"></i></div>

            </div>
        </>
    );
}

/*SORTING LABELS*/
/*SORTING LABELS*/
/*SORTING LABELS*/
export const FormSortConnectedSourceGroup = (props) => {
    /*set props*/
    const idu = props.idu;
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const onChangeSortSourceLabel = props.onChangeSortSourceLabel;
    const removeSortLabelGroup = props.removeSortLabelGroup;
    const onChangeSortOrderSourceLabel = props.onChangeSortOrderSourceLabel;

    return (
        <>
            <div className="form-group ak-source-label-group js-ak-source-sort-label-group js-admiko-sort-me-container" data-id={ElementData.idu}>
                {/*<label htmlFor={`source-label-${idu}`}>Label:</label>*/}
                <div className="form-group-input">
                    <select className="form-select ak-source-sort-label" value={ElementData.idu} name="source-sort-label" id={`source-sort-label-${idu}`} onChange={(e) => onChangeSortSourceLabel(e, idu)}>
                        {ElementData.labelList.map((e) => {
                            return <option key={e.idu} value={e.idu}>{e.title}</option>;
                        })}
                    </select>
                    <select className="form-select ak-source-sort-order" value={ElementData.order} name="source-sort-order" id={`source-sort-order-${idu}`} onChange={(e) => onChangeSortOrderSourceLabel(e, idu)}>
                        <option value="asc">ASC</option>
                        <option value="desc">DESC</option>
                    </select>
                    <AdmikoToolboxSortEditBox position={position} sortMe={sortMe} totalItems={totalItems} removeSortLabelGroup={removeSortLabelGroup}/>
                </div>
            </div>
        </>
    );

}


export const AdmikoToolboxSortEditBox = (props) => {
    /*set props*/
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;

    const removeSortLabelGroup = props.removeSortLabelGroup;
    return (
        <>
            <div className="admiko-form-settings">
                {totalItems > 1 &&
                    <div className="move-section tft-move js-admiko-sort-me">
                        {position > 0 &&
                            <div className="move-up mouse-pointer js-admiko-move-me-up" onClick={(e) => sortMe.SortMoveUp(e)}><i className="fa-solid fa-chevron-up"></i></div>
                        }
                        <div className="mouse-move js-admiko-move-me"><i className="fa-solid fa-up-down-left-right"></i></div>
                        {position + 1 < totalItems &&
                            <div className="move-down mouse-pointer js-admiko-move-me-down" onClick={(e) => sortMe.SortMoveDown(e)}><i className="fa-solid fa-chevron-down"></i></div>
                        }
                    </div>
                }
                {totalItems > 1 &&
                    <div className="mouse-pointer delete-label-group" onClick={() => removeSortLabelGroup(position)}><i className="fa-solid fa-trash"></i></div>
                }
            </div>
        </>
    );
}