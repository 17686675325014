import React from "react";

export const FormTitle = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;
    return (
        <div className="form-group">
            <label htmlFor="title">Title:</label>
            <div className="form-group-input">
                <input className={`form-input ${formData.title === "" && "ak-admiko-form-input-error"}`} autoComplete="off" name="title" id="title" placeholder="Enter title" value={formData.title} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                <p className="ak-admiko-form-error">Required!</p>
            </div>
        </div>
    );

}