import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {apiEditIcon} from "../../Api/useIcons";
import {useIsMountedRef} from "../../Helper/isMounted";
import {rootSetError} from "../../Helper/RootVar/Error/Error";
import {debounceFunction} from "../../Helper/Functions";

export const IconsByVendor = (props) => {
    /*set props*/
    const section = props.section;
    const setSelectedSection = props.setSelectedSection;
    const rootIcons = props.rootIcons;

    const isMountedRef = useIsMountedRef();
    const iconList = useMemo(() => props.rootIcons, [props.rootIcons]);
    const [iconListSearch, setIconListSearch] = useState([]);
    const [iconSelected, setIconSelected] = useState(section.icon);

    /*set focus on input element*/
    const inputFocus = useRef(null);

    const PAGE_SIZE = 117;
    const [currentPage, setCurrentPage] = useState(1);
    const numPages = Math.ceil(iconListSearch.length / PAGE_SIZE);
    const handlePageClick = (pageNum) => {
        if(pageNum < 1){
            pageNum = 1;
        }
        if(pageNum > numPages){
            pageNum = numPages;
        }
        setCurrentPage(pageNum);
    }

    useEffect(() => {
        if(inputFocus.current){
            inputFocus.current.focus();
        }
    }, [iconList]);

    useEffect(() => {
        if (!isMountedRef.current) {
            return false;
        }
        setIconListSearch(rootIcons);
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootIcons])

    const selectIcon = useCallback((e, icon_idu) => {
        setIconSelected(icon_idu);
        (async () => {
            const response = await apiEditIcon({"icon_idu": icon_idu}, section.idu);
            if (!isMountedRef.current) {
                return false;
            }
            if (response.success === true) {
                let newProperty = {...section}
                newProperty.icon = icon_idu;
                setSelectedSection(newProperty);
            } else {
                rootSetError(response);
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // eslint-disable-next-line
    const debounceFilter = useCallback(debounceFunction((nextValue) => setIconListSearch(nextValue), 100), [])

    // const searchIcons = (search) => {
    //     let matchingStrings = iconList.filter(
    //         item => item.name.toLowerCase().includes(search.toLowerCase())
    //     )
    //     debounceFilter(matchingStrings);
    // }

    const searchIcons = useCallback((search) => {
        let matchingStrings = iconList.filter(
            item => item.name.toLowerCase().includes(search.toLowerCase())
        )
        debounceFilter(matchingStrings);
        setCurrentPage(1);
    }, [iconList, debounceFilter]);


    const getPageItems = () => {
        const startIndex = (currentPage - 1) * PAGE_SIZE;
        const endIndex = startIndex + PAGE_SIZE;
        return iconListSearch.slice(startIndex, endIndex);
    };

    return (
        <>
            {(Array.isArray(iconList) && iconList.length > 0) ?
                <>
                    <div className="admiko-action-menu-body ak-admiko-pre-scroll icons-list-container">
                        <div className="icons-list-search">
                            <input type="text" ref={inputFocus} autoComplete="off" placeholder="Search icons" className="form-input" onChange={(e) => searchIcons(e.target.value)}/>
                        </div>
                        <div className="ak-admiko-add-scroll">
                            <div className="ak-admiko-add-scroll-fix">
                                {(Array.isArray(iconListSearch) && iconListSearch.length > 0) ?
                                    <>
                                        <div className={`admiko-action-menu-body-action icons-list`}>
                                            {/*{iconListSearch.map((item) => (*/}
                                            {/*    <div key={item.id} onClick={(e) => selectIcon(e, item.id)} className={`icons ${iconSelected === item.id ? "selected" : ""}`} dangerouslySetInnerHTML={{__html: item.icon}}></div>*/}
                                            {/*))}*/}
                                            {getPageItems().map((item) => (
                                                <div title={item.name}
                                                    key={item.id}
                                                    onClick={(e) => selectIcon(e, item.id)}
                                                    className={`icons ${
                                                        iconSelected === item.id ? "selected" : ""
                                                    }`}
                                                    dangerouslySetInnerHTML={{ __html: item.icon }}
                                                ></div>
                                            ))}
                                        </div>
                                        {numPages > 1 &&
                                            <div className="pagination">
                                                <ul>
                                                    <li onClick={() => handlePageClick(currentPage-1)}>
                                                        <span><i className="fa-solid fa-angle-left"></i></span>
                                                    </li>
                                                    {Array.from({ length: numPages }, (_, i) => i + 1).map(
                                                        (pageNum) => (
                                                            <li key={pageNum} className={currentPage === pageNum ? "active" : ""} onClick={() => handlePageClick(pageNum)}>
                                                                <span>{pageNum}</span>
                                                            </li>
                                                        )
                                                    )}
                                                    <li onClick={() => handlePageClick(currentPage + 1)}>
                                                        <span><i className="fa-solid fa-angle-right"></i></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </>
                                    : <>
                                        <div className={`admiko-action-menu-body-action icons-list`}>No results.</div>
                                    </>}
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="admiko-ak-loading">
                        <i className="fa-solid fa-circle-notch"></i>
                    </div>
                </>}
        </>
    );
}
