import React, {useEffect, useState} from "react";
import {useIsMountedRef} from "../../../../Helper/isMounted";
import {onChangeFormDataGlobal} from "../../../../Helper/Functions";
import {apiEditFormSettings} from "../../../../Api/useTable";
import {rootSetError} from "../../../../Helper/RootVar/Error/Error";
import {FormSubmitButton} from "../../../Helpers/FormSubmitButton";
import {FormCloseButton} from "../../../Helpers/FormCloseButton";

/*ADD new elements to a FORM*/
export const FormSettings = (props) => {

    /*set props*/
    const form_idu = props.form_idu;
    const toggleActionMenuTab = props.toggleActionMenuTab;
    const elements = props.settings;

    const title = props.title;

    const isMountedRef = useIsMountedRef();
    // const [infoClick, setInfoClick] = useState({page_id: false, table_id: false});

    /*GET DATA for form from API server*/
    useEffect(() => {
        LoadFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        LoadFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements])


    const LoadFormData = () => {
        let newProperty = {...formData}
        // newProperty.breadcrumb_idu = elements.breadcrumb_idu;
        newProperty.dynamic_fields = elements.dynamic_fields;
        newProperty.on_update_form = elements.on_update_form;
        newProperty.form_width = elements.form_width;
        setFormData(newProperty);

    }

    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        setSlideIn(true);
    }, [])

    const closeForm = () => {
        hideBack();
    };

    const hideBack = () => {
        setSlideIn(false);

        setTimeout(() => {
            toggleActionMenuTab("main");
        }, 200);
    }

    /*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");
    /*form js*/
    const formFields = {
        // breadcrumb_idu: 0,
        dynamic_fields: 0,
        on_update_form: "back_to_table",
        form_width: "medium",
    }
    const [formData, setFormData] = useState(formFields);

    const formFieldsRules = {
        // breadcrumb_idu: {required: false, type: "select"},
        dynamic_fields: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        on_update_form: {required: false, type: "select"},
        form_width: {required: false, type: "select"},
    }

    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiEditFormSettings({...formData}, form_idu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/

    return (
        <>
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll ${slideIn ? "admiko-show" : ""}`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                            <div className="info">
                                <h2><i className={`${title.icon}`}></i>{title.title}</h2>
                                <p>{title.info}</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {elements ?
                            <div className="admiko-action-menu-body">
                                <div className={`admiko-action-menu-body-action add-menu active`}>
                                    <div>
                                        <form action="">
                                            {/*<div className="form-group">*/}
                                            {/*    <label htmlFor="breadcrumb_idu">Breadcrumb source:</label>*/}
                                            {/*    <div className="form-group-input">*/}
                                            {/*        <select value={formData.breadcrumb_idu} className="form-select" name="breadcrumb_idu" id="breadcrumb_idu" onChange={(e) => onChangeFormData(e)}>*/}
                                            {/*            <option key="0-default" value="0">Default</option>*/}
                                            {/*            {props.settings.breadcrumb_source.map((e) => {*/}
                                            {/*                return <option key={e.idu} value={e.idu}>{e.title}</option>;*/}
                                            {/*            })}*/}
                                            {/*        </select>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="form-group">
                                                <label htmlFor="on_update_form">On update:</label>
                                                <div className="form-group-input">
                                                    <select value={formData.on_update_form} className="form-select" name="on_update_form" id="on_update_form" onChange={(e) => onChangeFormData(e)}>
                                                        <option key="back_to_table" value="back_to_table">Back to table</option>
                                                        <option key="stay_on_form" value="stay_on_form">Stay on form</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="dynamic_fields">Form field manager:</label>
                                                <div className="form-group-input">
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="dynamic_fields" id="dynamic_fields" value="1" checked={formData.dynamic_fields === 1} onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="form_width">Form width:</label>
                                                <div className="form-group-input">
                                                    <select value={formData.form_width} className="form-select" name="form_width" id="form_width" onChange={(e) => onChangeFormData(e)}>
                                                        <option value="small" key="small">Small</option>
                                                        <option value="medium" key="medium">Medium</option>
                                                        <option value="full" key="full">Full</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                            <FormCloseButton closeForm={(e) => closeForm(e)}/>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <div className={`admiko-action admiko-action-menu admiko-show ak-admiko-pre-scroll`}>
                                    <div className="ak-admiko-add-scroll">
                                        <div className="ak-admiko-add-scroll-fix">
                                            <div className="admiko-ak-loading">
                                                <i className="fa-solid fa-circle-notch"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
