
export const ReCaptchaKey = '6LdLWCMaAAAAAPmq7nbH516HdOCk1IE3WEj6FMhX';
export const loadReCaptchaScript = () => {
    if (process.env.NODE_ENV !== 'production') {
        return true;
    }
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render=" + ReCaptchaKey
    script.id = "recaptcha-key"
    document.body.appendChild(script)
}
export const getReCaptchaToken = async () => {
    if (process.env.NODE_ENV !== 'production') {
        return true;
    }
    try {
        return await window.grecaptcha
            .execute(ReCaptchaKey, {action: "validate_captcha"})
            .then(token => {
                return token;
            });
    } catch (err) {
        return false;
    }
}
export const unloadReCaptcha = () => {
    if (process.env.NODE_ENV !== 'production') {
        return true;
    }
    const nodeBadge = document.querySelector('.grecaptcha-badge');
    if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
    }
    const scriptSelector = 'script[src=\'https://www.google.com/recaptcha/api.js?render=' +
        ReCaptchaKey + '\']';
    const script = document.querySelector(scriptSelector);
    if (script) {
        script.remove();
    }
};