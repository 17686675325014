import React, {useEffect, useState} from "react";
import {setRootPageInfoDirect} from "../../Global/PageInfo";
import {apiGetStatements} from "../../Api/useCallApi";


export const Statements = () => {
    const [statements, setStatements] = useState(false);


    useEffect(() => {
        setRootPageInfoDirect({title: "Statements", subtitle: "Your transactions", activeMenu: "statements", breadcrumbs: [{link: "/", title: "Statements"}]});
        (async () => {
            const response = await apiGetStatements();
            if (response.success === true) {
                setStatements(response.return.statements);
            } else {
                /*todo: else*/
            }
        })()

    }, [])


    return <>
        {statements !== false ? (<>
                <div className="block">
                    <div className="max-w-6xl ">
                        <div className="mt-2 flex flex-col">
                            <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead>
                                    <tr>
                                        <th className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900" scope="col">Transaction</th>
                                        <th className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900" scope="col">Amount</th>
                                        <th className="hidden bg-gray-50 px-6 py-3 text-center text-sm font-semibold text-gray-900 md:block" scope="col">Status</th>
                                        <th className="bg-gray-50 px-6 py-3 text-center text-sm font-semibold text-gray-900" scope="col">Date</th>
                                        <th className="bg-gray-50 px-6 py-3 text-center text-sm font-semibold text-gray-900" scope="col">Expire</th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                    {statements.map((item, i) => (
                                        <tr key={i} className="bg-white">
                                            <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                <div className="flex">
                                                    <div className="group inline-flex space-x-2 truncate text-sm">
                                                        <svg className="h-5 w-5 flex-shrink-0 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fillRule="evenodd" d="M1 4a1 1 0 011-1h16a1 1 0 011 1v8a1 1 0 01-1 1H2a1 1 0 01-1-1V4zm12 4a3 3 0 11-6 0 3 3 0 016 0zM4 9a1 1 0 100-2 1 1 0 000 2zm13-1a1 1 0 11-2 0 1 1 0 012 0zM1.75 14.5a.75.75 0 000 1.5c4.417 0 8.693.603 12.749 1.73 1.111.309 2.251-.512 2.251-1.696v-.784a.75.75 0 00-1.5 0v.784a.272.272 0 01-.35.25A49.043 49.043 0 001.75 14.5z" clipRule="evenodd"/>
                                                        </svg>
                                                        <p className="truncate text-gray-900">{item.title}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
                                                <span className="font-medium text-center text-gray-900">{item.total} {item.currency}</span>
                                            </td>
                                            <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 capitalize">success</span>
                                            </td>
                                            <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
                                                <time dateTime="2020-07-11">{item.date}</time>
                                            </td>
                                            <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
                                                <time dateTime="2020-07-11">{item.expire}</time>
                                            </td>
                                        </tr>
                                    ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>)
            :
            <>
                <div className="admiko-ak-loading">
                    <i className="fa-solid fa-circle-notch"></i>
                </div>
            </>
        }</>
}
