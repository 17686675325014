import React, {useEffect, useRef, useState} from "react";

import {FormSubmitButton} from "../../Helpers/FormSubmitButton";
import {useIsMountedRef} from "../../../Helper/isMounted";
import {apiAddMenu} from "../../../Api/useMenu";
import {rootSetError} from "../../../Helper/RootVar/Error/Error";
import {onChangeFormDataGlobal} from "../../../Helper/Functions";

export const TabAddMenu = (props) => {
    /*set props*/
    const toggleActionMenuTab = props.toggleActionMenuTab;

    const isMountedRef = useIsMountedRef();
    /*set focus on input element*/
    const inputFocus = useRef(null);

    const [slideIn, setSlideIn] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSlideIn(true);
        }, 1);
        /*set focus on input element*/
        setTimeout(() => {
            inputFocus.current.focus();
        }, 200)
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hideBack = () => {
        setSlideIn(false);
        setTimeout(() => {
            toggleActionMenuTab("main");
        }, 200)
    }
    /*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");
    /*form js*/
    const formFields = {type: 'container', title: "", description: "", link: "", new_window: 0};
    const formFieldsRules = {
        title: {required: false, type: "input", rules: {minLength: 1, maxLength: 40}},
        type: {required: false, type: "select"},
        description: {required: false, type: "input", rules: {minLength: 1, maxLength: 60}},
        link: {required: false, type: "input", rules: {}},
        new_window: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
    }
    const [formData, setFormData] = useState(formFields);
    const resetFormData = () => {
        setFormData(formFields);
        setFormData(prevFormData => ({...prevFormData, type: formData.type}));
    }
    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };
    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiAddMenu({...formData});
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            resetFormData();
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/

    return (
        <div className={`admiko-action admiko-action-menu ${slideIn ? "admiko-show" : ""}`}>
            <div className="ak-admiko-add-scroll">
                <div className="ak-admiko-add-scroll-fix">
                    <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                        <div className="info">
                            <h2><i className="fa-solid fa-bars"></i>Sidebar helpers</h2>
                            <p>Add sidebar container, header or link.</p>
                        </div>
                        <div className="close">
                            <div className="admiko-toggle-tools">
                                <div className="admiko-toggle-box">
                                    <div>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                            <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="admiko-action-menu-body">
                        <div className={`admiko-action-menu-body-action add-menu active`}>
                            <h3>Add menu element:</h3>
                            <div>
                                <form action="">
                                    <div className="form-group">
                                        <label htmlFor="title">Title:</label>
                                        <div className="form-group-input">
                                            <input className="form-input" ref={inputFocus} autoComplete="off" name="title" id="title" placeholder="Enter title" value={formData.title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="type">Type:</label>
                                        <div className="form-group-input">
                                            <select value={formData.type} className="form-select" name="type" id="type" onChange={(e) => onChangeFormData(e)}>
                                                <option value="container">Container</option>
                                                <option value="menu_header">Header</option>
                                                <option value="menu_link">Link</option>
                                            </select>
                                        </div>
                                    </div>
                                    {formData.type === 'menu_header' &&
                                        <div className="form-group">
                                            <label htmlFor="description">Description:</label>
                                            <div className="form-group-input">
                                                <textarea value={formData.description} className="form-textarea" name="description" placeholder="Enter description" id="description" onChange={(e) => onChangeFormData(e)}></textarea>
                                            </div>
                                        </div>
                                    }
                                    {formData.type === 'menu_link' &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="link">Url:</label>
                                                <div className="form-group-input">
                                                    <textarea value={formData.link} className="form-textarea" name="link" placeholder="Enter url" id="link" onChange={(e) => onChangeFormData(e)}></textarea>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="new_window">New window:</label>
                                                <div className="form-group-input">
                                                    <input className="form-checkbox" type="checkbox" name="new_window" value="1" id="new_window" onChange={(e) => onChangeFormData(e)}/>
                                                </div>
                                            </div>
                                        </>

                                    }
                                    <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>

                                    {formData.type === 'container' &&
                                        <div className="form-group-helper">
                                            <h5>Container</h5>
                                            <p>Create drop down menu and drag & drop any page.</p>
                                        </div>
                                    }
                                    {formData.type === 'menu_header' &&
                                        <div className="form-group-helper">
                                            <h5>Header</h5>
                                            <p>Create visual separator between sidebar links.</p>
                                            <p>Tips: Make title uppercase.</p>
                                        </div>
                                    }
                                    {formData.type === 'menu_link' &&
                                        <div className="form-group-helper">
                                            <h5>Link</h5>
                                            <p>Create link in the sidebar.</p>
                                            <p>New window: Open link in a new window.</p>
                                            <p>Examples:<br/>
                                                https://mysite.com<br/>
                                                /my-page<br/>
                                                { ' {{route("admin.my-route")}} '}
                                            </p>
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
