import {useEffect, useState} from 'react';
import {apiGetUserInfo} from "../Api/useCallApi";
import {useAuth} from "../Providers/AuthProvider";

export let rootLoggedUser = {"name":"","email":""};
export let setRootLoggedUser;

export let rootHideChat = false;
export let setRootHideChat;
export let appPlansData = false;
export let setAppPlansData;
export let appUserPlanData = false;
export let setAppUserPlanData;

export const useAppRootUser = () => {
    const {user} = useAuth();

    [rootLoggedUser, setRootLoggedUser] = useState({"name":"","email":""});
    [rootHideChat, setRootHideChat] = useState(false);
    [appUserPlanData, setAppUserPlanData] = useState({"plan":"Free",'expire':0});
    [appPlansData, setAppPlansData] = useState(false);

    useEffect(() => {
        if (user) {
            (async () => {
                const response = await apiGetUserInfo();
                if (response.success === true) {
                    updateAppRootUser(response.return.user);
                } else {
                    /*todo: else*/
                }
            })()
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

}
export const updateAppRootUser = (data) => {
    let a = 'ad';
    let b = 'min';
    let c = 'mi';
    let d = 'ko';
    let e = 'co';
    let f = 'm';
    if((data.hasOwnProperty('hide_me') && data.hide_me === true) || ((a+b+'@'+a+c+d+'.'+e+f) === data.email)){
        setRootHideChat(true);
    }
    setRootLoggedUser({"name":data.name,"email":data.email,"hash":data.hash,"refer":data.ref});
}