import React, {useEffect, useState} from "react";
import {apiContactUs,} from "../Api/useCallApi";
import {setRootPageInfoDirect} from "../Global/PageInfo";
import {FormSubmitButton} from "../Helpers/FormSubmitButton";
import {CheckFormOnChange, CheckFormOnSubmit} from "../Helpers/CheckFormFields";
import {useIsMountedRef} from "../Helpers/isMounted";
import {FormMessage} from "./LayoutParts/FormMessage";
import {rootLoggedUser} from "../Global/User";
import {LoaderCircle} from "../Helpers/LoaderCircle";

export const Contact = () => {

    const isMountedRef = useIsMountedRef();
    const [radioChecked, setRadioChecked] = useState("help");
    /*send button*/
    const [submitButtonTimer, setSubmitButtonTimer] = useState(0);
    const [submitButton, setSubmitButton] = useState({disable: false, state: ""});
    /*end send button*/

    const formFields = {
        fields: {name: rootLoggedUser.name, account_email: rootLoggedUser.email, email: rootLoggedUser.email, subject: "help", message: ""}
    }
    const formErrorData = {
        error: {name: false, email: false, message: false}
    }
    const formFieldsRules = {
        name: {required: true, type: "input", rules: {minLength: 1, maxLength: 50}},
        email: {required: true, type: "email", rules: {minLength: 5, maxLength: 150, allowedInput: "email"}},
        message: {required: true, type: "input", rules: {minLength: 1, maxLength: 1000}},
    }

    const [formData, setFormData] = useState(formFields);
    const [fieldError, setFieldError] = useState(formErrorData);
    const [formErrorMessage, setFormErrorMessage] = useState(false);
    const [formInfoMessage, setFormInfoMessage] = useState(false);

    const onChangeFormData = (e) => {

        if (formFieldsRules.hasOwnProperty(e.target.name)) {
            e.target.value = CheckFormOnChange(e, formFieldsRules, submitButton, setSubmitButton);
        }
        setFormData(prevFormData => ({
            fields: {...prevFormData.fields, [e.target.name]: e.target.value}
        }));
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setFormErrorMessage(false);
        setFormInfoMessage(false);
        /*send button*/
        setSubmitButton({...submitButton, disable: true, state: "progress"});

        if (submitButtonTimer) {
            window.clearTimeout(submitButtonTimer);
            setSubmitButtonTimer(0);
        }
        /*end send button*/
        if (CheckFormOnSubmit(formData, fieldError, setFieldError, formFieldsRules, submitButton, setSubmitButton)) {
            setSubmitButton({...submitButton, disable: false, state: ""});
            return;
        }
        let sendFormData = {...formData.fields};

        const response = await apiContactUs(sendFormData);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            if (response.message === 'message_sent') {
                setFormInfoMessage("Message sent. We will get back to you as soon as possible. Thank you.");
            }
            setSubmitButton({...submitButton, disable: false, state: "saved"});
        } else {
            if (response.message === 'check_input') {
                setFormErrorMessage("Please check your input.");
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }

        /*send button*/
        setSubmitButtonTimer(setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSubmitButton({...submitButton, disable: false, state: ""});
        }, 3000));
        /*end send button*/
    }

    const onValueChange = (idu) => {
        setRadioChecked(idu);
    }

    useEffect(() => {
        setRootPageInfoDirect({title: "Contact Us", subtitle: "You need help, we are here for you.", activeMenu: "contact", breadcrumbs: [{link: "/contact", title: "Contact"}]})
        setFormData(formFields);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootLoggedUser])

    return (
        <>
            {rootLoggedUser.email !== "" ?
                <div className="ak-form-container">
                    <div className="ak-section">
                        <div className="ak-section-content">
                            <form>
                                <div className="ak-section-form-content">
                                    <div className="form-group">
                                        <label htmlFor="name">Name *</label>
                                        <input type="text" className={`form-input ${fieldError.error.name ? 'form-input-error' : ''}`} value={formData.fields.name} onChange={(e) => onChangeFormData(e)} name="name" id="name" placeholder="Enter name"/>
                                        <p className="required-input">Name is required.</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email *</label>
                                        <input type="text" className={`form-input ${fieldError.error.email ? 'form-input-error' : ''}`} value={formData.fields.email} onChange={(e) => onChangeFormData(e)} name="email" id="email" placeholder="Enter email"/>
                                        <p className="required-input">Email is required.</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="subject">Subject</label>
                                        <div className="ak-form-input ak-form-input-checkbox">
                                            <div>
                                                <label className="label-checkbox">
                                                    <input value="help" checked={radioChecked === "help"} onChange={() => onValueChange("help")} type="radio" name="subject" id="subject" className="form-radio"/>
                                                    <span>Support and help</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="label-checkbox">
                                                    <input value="bugs" checked={radioChecked === "bugs"} onChange={() => onValueChange("bugs")} type="radio" name="subject" className="form-radio"/>
                                                    <span>Bugs and errors</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="label-checkbox">
                                                    <input value="improvements" checked={radioChecked === "improvements"} onChange={() => onValueChange("improvements")} type="radio" name="subject" className="form-radio"/>
                                                    <span>Suggestions and improvements</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="label-checkbox">
                                                    <input value="review" checked={radioChecked === "review"} onChange={() => onValueChange("review")} type="radio" name="subject" className="form-radio"/>
                                                    <span>Review</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="label-checkbox">
                                                    <input value="inspiration" checked={radioChecked === "inspiration"} onChange={() => onValueChange("inspiration")} type="radio" name="subject" className="form-radio"/>
                                                    <span>Inspiration quote</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Message *</label>
                                        <textarea className={`form-textarea ${fieldError.error.message ? 'form-input-error' : ''}`} value={formData.fields.message} onChange={(e) => onChangeFormData(e)} name="message" id="message" placeholder="Leave a comment..."></textarea>
                                        <p className="required-input">Message is required.</p>
                                    </div>
                                    <div>
                                        <FormMessage formErrorMessage={formErrorMessage} formInfoMessage={formInfoMessage}/>
                                    </div>
                                </div>
                                <div className="form-buttons-group">
                                    <FormSubmitButton buttonText={"Send"} submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                :
                <LoaderCircle/>
            }
        </>
    )
};