import React, {useEffect} from "react";
import {FormTitle} from "./Partials/FormTitle";
import {FormDescription} from "./Partials/FormDescription";
import {FormSubmitButton} from "../../../../../Helpers/FormSubmitButton";
import {FormCloseButton} from "../../../../../Helpers/FormCloseButton";
import {FormCustomClass} from "./Partials/FormCustomClass";
import {AdvancedSettings} from "../../../../../Helpers/AdvancedSettings";
import {rootCUSTOM_CLASS_MAX_LENGTH} from "../../../../../../Helper/RootVar/Contstant";

export const Separator = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormData = props.onChangeFormData;
    const sendFormData = props.sendFormData;
    const submitButton = props.submitButton;
    const closeForm = props.closeForm;
    const checkSaveButtonOnChildStart = props.checkSaveButtonOnChildStart;
    useEffect(() => {
        checkSaveButtonOnChildStart(formFieldsRules);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formFieldsRules = {
        title: {required: true, type: "input", rules: {minLength: 1, maxLength: 64}},
        custom_class: {required: false, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_CLASS_MAX_LENGTH, allowedInput: "upper_lower_dash_minus_numbers_space"}},
    }
    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules)
    };
    return (
        <>
            <form action="">
                <FormTitle formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormDescription formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <AdvancedSettings>
                    <FormCustomClass formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                </AdvancedSettings>
                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                <FormCloseButton closeForm={(e) => closeForm(e)}/>
            </form>
        </>
    );

}