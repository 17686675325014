import React, {useEffect, useState} from "react";
import {CheckFormDataFields} from "../../../../../Helper/RootVar/Error/CheckFormFields";


export const LayoutTableElementSettings = (props) => {
    const ElementData = props.ElementData;
    const position = props.position;
    const onChangeSettings = props.onChangeSettings;

    const [elementSettings, setElementSettings] = useState(props.ElementData.settings);

    useEffect(() => {
        setElementSettings(props.ElementData.settings);
    }, [props.ElementData.settings])

    useEffect(() => {
        onChangeSettings(elementSettings, ElementData.idu);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSettings])

    /*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");

    const formFieldsRules = {
        header: {required: false, type: "input", rules: {minLength: 0, maxLength: 50}}
    }
    const onChangeInput = (e) => {
        if (formFieldsRules.hasOwnProperty(e.target.name)) {
            e.target.value = CheckFormDataFields(e, formFieldsRules, submitButton, setSubmitButton);
        }
        setElementSettings({...elementSettings, [e.target.name]: e.target.value});
    }
    const onChangeFormData = (e, formFieldsChildRules, position) => {
        let value;
        if (formFieldsChildRules.hasOwnProperty(e.target.name)) {
            value = CheckFormDataFields(e, formFieldsChildRules, submitButton, setSubmitButton);
        } else {
            value = e.target.value;
        }
        setElementSettings({...elementSettings, [e.target.name]: value});
    };

//layout === "table" &&
    return (
        <>
            {(elementSettings.merge === "none") &&
                <div className="ak-group-element-settings-edit-input">
                    <label htmlFor={`table-header-${ElementData.idu}`}>Table title:</label>
                    <div className="form-group-input">
                        <input className="form-input" value={elementSettings.header} autoComplete="off" name="header" id={`table-header-${ElementData.idu}`} placeholder="Enter table title" type="text" onChange={(e) => onChangeInput(e, ElementData.idu)}/>
                    </div>
                </div>
            }
            {(position !== 0 && ElementData.type !== "parent_child") &&
                <div className="ak-group-element-settings-edit-input">
                    <label htmlFor={`table-merge-${ElementData.idu}`}>Merge left:</label>
                    <div className="form-group-input">
                        <select value={elementSettings.merge} className="form-select" name="merge" id={`table-merge-${ElementData.idu}`} onChange={(e) => onChangeInput(e)}>
                            <option value="none">No action</option>
                            <option value="merge">Merge</option>
                            <option value="merge_new_line">Merge in a new line</option>
                        </select>
                    </div>
                </div>
            }
            <LabelElementsSettings ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
        </>
    );
}


export const LabelElementsSettings = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    switch (ElementData.type) {
        case "text":
            return (
                <>
                    <TextLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "email":
            return (
                <>
                    <EmailLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "checkbox":
            return (
                <>
                    <CheckboxLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "textarea":
            return (
                <>
                    <TextAreaLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "image":
            return (
                <>
                    <ImageLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "file":
            return (
                <>
                    <FileLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "money_measure":
            return (
                <>
                    <MoneyMeasurementLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "numbers":
        case "biginteger":
            return (
                <>
                    <NumbersLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "slug":
            return (
                <>
                    <SlugLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "multi_select":
            return (
                <>
                    <MultiSelectLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "multi_checkbox":
            return (
                <>
                    <MultiCheckBoxLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "multi_select_custom":
        case "multi_checkbox_custom":
            return (
                <>
                    <MultiCustomBoxLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "parent_child":
            return (
                <>
                    <ParentChild ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        case "select":
        case "custom_select":
            return (
                <>
                    <SelectLayout ElementData={ElementData} elementSettings={elementSettings} onChangeFormData={onChangeFormData} position={position}/>
                </>
            );
        default:
            return <></>;
    }
}
export const TextLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        link_to_edit: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        prefix: {required: false, type: "input", rules: {minLength: 0, maxLength: 10}},
        suffix: {required: false, type: "input", rules: {minLength: 0, maxLength: 10}},
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-${ElementData.idu}`}>Edit link:</label>
                <div className="form-group-input">
                    <input className="form-checkbox" type="checkbox" name="link_to_edit" value="1" checked={elementSettings.link_to_edit === 1} id={`data-${ElementData.idu}`} onChange={(e) => onChangeFormDataInput(e)}/>
                </div>
            </div>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-prefix-${ElementData.idu}`}>Prefix:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.prefix} autoComplete="off" name="prefix" id={`data-prefix-${ElementData.idu}`} placeholder="Enter prefix" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-suffix-${ElementData.idu}`}>Suffix:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.suffix} autoComplete="off" name="suffix" id={`data-suffix-${ElementData.idu}`} placeholder="Enter suffix" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
        </>
    );
}
export const MultiSelectLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        result_limit: {required: true, type: "input", rules: {minLength: 1, maxLength: 4, allowedInput: "numbers"}}
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-${ElementData.idu}`}>Max results:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.result_limit} autoComplete="off" name="result_limit" id={`data-${ElementData.idu}`} placeholder="Max results" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
        </>
    );
}
export const MultiCustomBoxLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        result_limit: {required: true, type: "input", rules: {minLength: 1, maxLength: 4, allowedInput: "numbers"}}
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-${ElementData.idu}`}>Max results:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.result_limit} autoComplete="off" name="result_limit" id={`data-${ElementData.idu}`} placeholder="Max results" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
        </>
    );
}
export const MultiCheckBoxLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        result_limit: {required: true, type: "input", rules: {minLength: 1, maxLength: 4, allowedInput: "numbers"}}
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-${ElementData.idu}`}>Max results:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.result_limit} autoComplete="off" name="result_limit" id={`data-${ElementData.idu}`} placeholder="Max results" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
        </>
    );
}
export const EmailLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        email_link: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        prefix: {required: false, type: "input", rules: {minLength: 0, maxLength: 10}},
        suffix: {required: false, type: "input", rules: {minLength: 0, maxLength: 10}},
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-${ElementData.idu}`}>Email link:</label>
                <div className="form-group-input">
                    <input className="form-checkbox" type="checkbox" name="email_link" value="1" checked={elementSettings.email_link === 1} id={`data-${ElementData.idu}`} onChange={(e) => onChangeFormDataInput(e)}/>
                </div>
            </div>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-prefix-${ElementData.idu}`}>Prefix:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.prefix} autoComplete="off" name="prefix" id={`data-prefix-${ElementData.idu}`} placeholder="Enter prefix" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-suffix-${ElementData.idu}`}>Suffix:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.suffix} autoComplete="off" name="suffix" id={`data-suffix-${ElementData.idu}`} placeholder="Enter suffix" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
        </>
    );
}

export const SelectLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        prefix: {required: false, type: "input", rules: {minLength: 0, maxLength: 10}},
        suffix: {required: false, type: "input", rules: {minLength: 0, maxLength: 10}},
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-prefix-${ElementData.idu}`}>Prefix:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.prefix} autoComplete="off" name="prefix" id={`data-prefix-${ElementData.idu}`} placeholder="Enter prefix" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-suffix-${ElementData.idu}`}>Suffix:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.suffix} autoComplete="off" name="suffix" id={`data-suffix-${ElementData.idu}`} placeholder="Enter suffix" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
        </>
    );
}
export const TextAreaLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        shorten_text: {required: true, type: "input", rules: {minLength: 1, maxLength: 4, allowedInput: "numbers"}}
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-${ElementData.idu}`}>Shorten text:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.shorten_text} autoComplete="off" name="shorten_text" id={`data-${ElementData.idu}`} placeholder="Number of characters" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
        </>
    );

}


export const ImageLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        show_as: {required: true, type: "select"},
        show_action: {required: true, type: "select"},
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-${ElementData.idu}`}>Show as:</label>
                <div className="form-group-input">
                    <select value={elementSettings.show_as} className="form-select" name="show_as" id={`data-${ElementData.idu}`} onChange={(e) => onChangeFormDataInput(e)}>
                        <option value="image">Image</option>
                        <option value="file_name">File name</option>
                    </select>
                </div>
            </div>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-link-${ElementData.idu}`}>Set link:</label>
                <div className="form-group-input">
                    <select value={elementSettings.show_action} className="form-select" name="show_action" id={`data-link-${ElementData.idu}`} onChange={(e) => onChangeFormDataInput(e)}>
                        <option value="link_to_file">Link to image url</option>
                        {/*<option value="link_to_edit">Link to edit page</option>*/}
                        <option value="none">No link</option>
                    </select>
                </div>
            </div>
        </>
    );
}


export const FileLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        show_action: {required: true, type: "select"},
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-link-${ElementData.idu}`}>Set link:</label>
                <div className="form-group-input">
                    <select value={elementSettings.show_action} className="form-select" name="show_action" id={`data-link-${ElementData.idu}`} onChange={(e) => onChangeFormDataInput(e)}>
                        <option value="link_to_file">Link to file url</option>
                        {/*<option value="link_to_edit">Link to edit page</option>*/}
                        <option value="none">No link</option>
                    </select>
                </div>
            </div>
        </>
    );
}


export const MoneyMeasurementLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        prefix: {required: false, type: "input", rules: {minLength: 0, maxLength: 10}},
        suffix: {required: false, type: "input", rules: {minLength: 0, maxLength: 10}},
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-prefix-${ElementData.idu}`}>Prefix:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.prefix} autoComplete="off" name="prefix" id={`data-prefix-${ElementData.idu}`} placeholder="Enter prefix" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-suffix-${ElementData.idu}`}>Suffix:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.suffix} autoComplete="off" name="suffix" id={`data-suffix-${ElementData.idu}`} placeholder="Enter suffix" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
        </>
    );
}

export const CheckboxLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        display_as: {required: false, type: "select"},
        checked: {required: false, type: "input", rules: {minLength: 0, maxLength: 20}},
        unchecked: {required: false, type: "input", rules: {minLength: 0, maxLength: 20}},
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-${ElementData.idu}`}>Show as:</label>
                <div className="form-group-input">
                    <select value={elementSettings.display_as} className="form-select" name="display_as" id={`data-${ElementData.idu}`} onChange={(e) => onChangeFormDataInput(e)}>
                        <option value="indicator">Indicator</option>
                        <option value="text">Text</option>
                    </select>
                </div>
            </div>
            {elementSettings.display_as === "text" &&
                <>
                    <div className="form-group ak-group-element-settings-edit-input">
                        <label htmlFor={`data-checked-${ElementData.idu}`}>Checked:</label>
                        <div className="form-group-input">
                            <input className="form-input" value={elementSettings.checked} autoComplete="off" name="checked" id={`data-checked-${ElementData.idu}`} placeholder="Enter value" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                            <p className="ak-admiko-form-error">Required!</p>
                        </div>
                    </div>
                    <div className="form-group ak-group-element-settings-edit-input">
                        <label htmlFor={`data-unchecked-${ElementData.idu}`}>Unchecked:</label>
                        <div className="form-group-input">
                            <input className="form-input" value={elementSettings.unchecked} autoComplete="off" name="unchecked" id={`data-unchecked-${ElementData.idu}`} placeholder="Enter value" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                            <p className="ak-admiko-form-error">Required!</p>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export const NumbersLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        prefix: {required: false, type: "input", rules: {minLength: 0, maxLength: 10}},
        suffix: {required: false, type: "input", rules: {minLength: 0, maxLength: 10}},
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-prefix-${ElementData.idu}`}>Prefix:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.prefix} autoComplete="off" name="prefix" id={`data-prefix-${ElementData.idu}`} placeholder="Enter prefix" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-suffix-${ElementData.idu}`}>Suffix:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.suffix} autoComplete="off" name="suffix" id={`data-suffix-${ElementData.idu}`} placeholder="Enter suffix" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
        </>
    );
}


export const SlugLayout = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        prefix_url: {required: false, type: "input", rules: {minLength: 0, maxLength: 300}},
        prefix: {required: false, type: "input", rules: {minLength: 0, maxLength: 10}},
        suffix: {required: false, type: "input", rules: {minLength: 0, maxLength: 10}},
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-${ElementData.idu}`}>Prefix url:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.prefix_url} autoComplete="off" name="prefix_url" id={`data-${ElementData.idu}`} placeholder="Prefix url" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                </div>
            </div>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-prefix-${ElementData.idu}`}>Prefix:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.prefix} autoComplete="off" name="prefix" id={`data-prefix-${ElementData.idu}`} placeholder="Enter prefix" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-suffix-${ElementData.idu}`}>Suffix:</label>
                <div className="form-group-input">
                    <input className="form-input" value={elementSettings.suffix} autoComplete="off" name="suffix" id={`data-suffix-${ElementData.idu}`} placeholder="Enter suffix" type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                    <p className="ak-admiko-form-error">Required!</p>
                </div>
            </div>
        </>
    );

}

export const ParentChild = (props) => {
    const ElementData = props.ElementData;
    const elementSettings = props.elementSettings;
    const onChangeFormData = props.onChangeFormData;
    const position = props.position;

    const formFieldsRules = {
        table_icon: {required: true, type: "select"},
    }

    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules, position);
    }

    return (
        <>
            <div className="form-group ak-group-element-settings-edit-input">
                <label htmlFor={`data-${ElementData.idu}`}>Table link:</label>
                <div className="form-group-input">
                    <select value={elementSettings.table_icon} className="form-select" name="table_icon" id={`data-${ElementData.idu}`} onChange={(e) => onChangeFormDataInput(e)}>
                        <option value="icon">Icon</option>
                        <option value="icon_title">Icon and title</option>
                        <option value="title">Title</option>
                    </select>
                </div>
            </div>
        </>
    );

}
