import React from "react";

export const FormSortingEnable = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="element_sorting">Enable sorting:</label>
            <div className="form-group-input">
                <div className="form-group-input">
                    <input className="form-checkbox" type="checkbox" name="element_sorting" value="1" checked={formData.element_sorting === 1} id="element_sorting" onChange={(e) => onChangeFormDataInput(e)}/>
                </div>
            </div>
        </div>
    );

}