import React, {PureComponent} from "react";
import {ToolBoxEditButtons} from "./ToolBoxEditButtons";
import {Text} from "./Elements/Text";
import {TextArea} from "./Elements/TextArea";
import {Image} from "./Elements/Image";
import {MoneyMeasurement} from "./Elements/MoneyMeasurement";
import {File} from "./Elements/File";
import {ParentChild} from "./Elements/ParentChild";
import {DateTime} from "./Elements/DateTime";
import {Link, useParams} from "react-router-dom";
import {getRootScrollToID, rootBuilderLink} from "../../../Helper/RootVar/Contstant";
import {TableFooter} from "./Layout/TableFooter";
import {TableHeader} from "./Layout/TableHeader";

class GalleryForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {title: ""};
    }

    componentDidMount() {
        this.setState({title: this.props.info.idu});
    }

    toolBoxNavigate(e) {
        e.preventDefault();
        this.props.navigateToToolboxArea(this.props.info.idu, 'layout')
    };

    componentDidUpdate() {
        let scrollToElement = getRootScrollToID();
        if (scrollToElement) {
            const element = document.querySelector(`[data-id="${scrollToElement}"]`);
            element.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }

    render() {
        return this.props.info.idu ?
            <>
                <div className={`ak-section-container ak-gallery-container content-width-${this.props.info.table_width ?? "full"} 
                js-page-elements-container js-admiko-page-element-sort-me js-admiko-page-element-sort-me-container-false ${this.props.table_id === this.props.url_table_id && "selected"}`} data-id={this.props.info.idu}>
                    <ToolBoxEditButtons idu={this.props.info.idu}
                                        table_id={this.props.table_id} url_table_id={this.props.url_table_id} url_menu_id={this.props.url_menu_id} url_page_id={this.props.url_page_id}
                                        position={this.props.position} table_btn={1} form_btn={1} totalItems={this.props.totalItems} sortMe={this.props.sortMe}
                    />
                    <div className="ak-section ak-gallery">
                        <TableHeader props={this.props}/>

                        <div className="ak-section-content ak-gallery-content">

                            {(this.props.info.data.length >= 1 || this.props.info.image_layout === 1 || this.props.info.child_pages.length >= 1) ? <>
                                {[...Array(10)].map((x, ix) =>
                                    <div className="ak-gallery-item" key={ix}>
                                        {this.props.info.image_layout === 1 &&
                                            <>
                                                <div className="ak-gallery-image-container">
                                                    <div className="ak-gallery-image" style={{backgroundImage: `url(/images/example/${ix + 1}.jpg)`}}></div>
                                                </div>
                                            </>
                                        }
                                        {(this.props.info.data.length >= 1 || this.props.info.child_pages.length >= 1) &&
                                            <div className="ak-gallery-info-container">
                                                {this.props.info.data.map((item, i) => (
                                                    <div className="ak-gallery-info" key={"gic" + i}><TableElementsBody key={`el-` + item.idu} items={item} counter={ix + 1}/></div>
                                                ))}
                                                {this.props.info.child_pages.length >= 1 &&
                                                    <div className="gallery-child-box">
                                                        {this.props.info.child_pages.map((item, i) => (
                                                            //onClick={(e) => this.loadChildPage(e, item.idu)}
                                                            <div key={"cph-" + i} className="ak-gallery-info child-page">
                                                                <div className="table-icon" key={"gicc" + i}>
                                                                    <TableChildPageDisplay item={item}/>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {(this.props.info.pagination === "drag_drop" || this.props.info.disable_edit === 0 || this.props.info.disable_show === 0 || this.props.info.disable_delete === 0) &&
                                            <div className="ak-gallery-tools">
                                                {this.props.info.pagination === "drag_drop" &&
                                                    <div className="sort-btn">
                                                        <div className="table-icon">
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l9.4-9.4V224H109.3l9.4-9.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4H224V402.7l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-9.4 9.4V288H402.7l-9.4 9.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l9.4 9.4H288V109.3l9.4 9.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-64-64z"/></svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {this.props.info.disable_edit === 0 &&
                                                    <div className="edit-btn">
                                                        <div className="table-icon">
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                    <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.props.info.disable_show === 0 &&
                                                    <div className="show-btn">
                                                        <div className="table-icon">
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.props.info.disable_delete === 0 &&
                                                    <>
                                                        {this.props.info.multi_delete === 1 ?
                                                            <div className="delete-btn">
                                                                <div><label><input type="checkbox" className="form-checkbox"/></label></div>
                                                            </div>
                                                            :
                                                            <div className="delete-btn">
                                                                <div className="table-icon">
                                                                    <div>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                            <path d="M160 400C160 408.8 152.8 416 144 416C135.2 416 128 408.8 128 400V192C128 183.2 135.2 176 144 176C152.8 176 160 183.2 160 192V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V192C208 183.2 215.2 176 224 176C232.8 176 240 183.2 240 192V400zM320 400C320 408.8 312.8 416 304 416C295.2 416 288 408.8 288 400V192C288 183.2 295.2 176 304 176C312.8 176 320 183.2 320 192V400zM317.5 24.94L354.2 80H424C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358 317.5 24.94H317.5zM151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1C174.5 48 171.1 49.34 170.5 51.56L151.5 80zM80 432C80 449.7 94.33 464 112 464H336C353.7 464 368 449.7 368 432V128H80V432z"/>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        }
                                    </div>
                                )}
                            </> : <>
                                <div>
                                    Empty
                                </div>
                            </>}
                        </div>
                        <TableFooter props={this.props}/>

                    </div>
                </div>
            </>
            :
            <>
                <div className="ak-menu-item-loading">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </>;
    }

}


export default GalleryForm;


export const TableElementsBody = (props) => {
    const items = props.items;
    const counter = props.counter;
    return (
        <>
            <div className={TableElementsClassName(items)}>
                {items.header !== "" && <>{items.header} </>}
                {items.content.map((item, i) => (
                    <TableElementsBodyContent key={`it-` + item.idu} item={item} header={items.header} counter={counter} index={i}/>
                ))}
            </div>
        </>
    );
}
export const TableElementsClassName = (items) => {

    switch (items.type) {
        case "id":
            return "table-id";
        case "text":
            return "nowrap";
        case "parent_child":
            return "ak-text-center";
        default:
            return " ";
    }
}
export const TableElementsBodyContent = (props) => {

    const item = props.item;
    const index = props.index;
    const counter = props.counter;
    // const header = props.header;

    switch (item.type) {
        case "id":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    {counter}
                </>
            );
        case "text":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    <Text item={item}/>
                </>
            );
        case "textarea":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    <TextArea item={item}/>
                </>
            );
        case "image":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    <Image item={item} counter={counter}/>
                </>
            );
        case "file":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    <File item={item}/>
                </>
            );
        case "money_measure":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    <MoneyMeasurement item={item} counter={counter}/>
                </>
            );
        case "numbers":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    1234
                </>
            );

        case "float":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    {parseFloat("123.4567890123456").toFixed(item.decimal)}
                </>
            );
        case "parent_child":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    <ParentChild item={item} counter={counter}/>
                </>
            );
        case "multi_select":
        case "multi_select_custom":
        case "multi_checkbox":
        case "multi_checkbox_custom":
        case "user_multi_checkbox":
        case "user_multi_select":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    <span className="ak-multi-badge">Lorem</span>
                    <span className="ak-multi-badge">Lorem</span>
                    <span className="ak-multi-badge">Lorem</span>
                </>
            );
        case "radio_group":
        case "radio_group_custom":
        case "select":
        case "user_select":
        case "user_radio_group":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    Lorem Ipsum
                </>
            );
        case "date_time":
        case "date_range":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    <DateTime item={item} counter={counter}/>
                </>
            );
        case "time":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    {new Date().toUTCString().split(" ")[4]}
                </>
            );
        case "email":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    email@site.com
                </>
            );
        case "checkbox":
            return (
                <>
                    <TableElementsMergeNewLine item={item} index={index}/>
                    {item.settings.display_as === "text" ?
                        <>
                            <span>{item.settings.checked}</span>
                        </>
                        :
                        <>
                            <div className="flex justify-center">
                                <div className="w-2 h-2 bg-green-600 rounded-full"></div>
                            </div>
                        </>
                    }

                </>
            );

        default:
            return <>
                <TableElementsMergeNewLine item={item} index={index}/>
                {item.title}
            </>;
    }
}

export const TableElementsMergeNewLine = (props) => {
    const item = props.item;
    const index = props.index;
    if (index > 0 && item.merge === "merge_new_line") {
        return (<br/>);
    }
    return (<> </>);
}

export const TableChildPageDisplay = (props) => {
    const item = props.item;
    const {menu_id = false} = useParams();

    switch (item.settings) {
        case "icon_title":
            return (
                <>
                    <Link to={`${rootBuilderLink}/m/${menu_id}/p/${item.idu}`}>
                        <div dangerouslySetInnerHTML={{__html: (item.icon)}}></div>
                        <span>{item.title}</span>
                    </Link>
                </>
            );
        case "title":
            return (
                <>
                    <Link to={`${rootBuilderLink}/m/${menu_id}/p/${item.idu}`}>{item.title}</Link>
                </>
            );
        default:
            return (
                <>
                    <Link to={`${rootBuilderLink}/m/${menu_id}/p/${item.idu}`}>
                        <div dangerouslySetInnerHTML={{__html: (item.icon)}}></div>
                    </Link>
                </>
            );
    }

}