import React from "react";

export const FormPlaceholder = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <>
            <div className="form-group">
                <label htmlFor="placeholder">Placeholder:</label>
                <div className="form-group-input">
                    <input className="form-input" autoComplete="off" name="placeholder" id="placeholder" placeholder="Enter placeholder text" value={formData.placeholder} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                </div>
            </div>
        </>
    );

}