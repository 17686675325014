import React, {useEffect, useState} from "react";
import {useIsMountedRef} from "../../../../../Helper/isMounted";
import {LoadElementSettings} from "./LoadElementSettings";
import {DeleteElementConfirm} from "./Elements/Partials/DeleteElementConfirm";
import {openRootToolBox, rootAllPageContent, rootBuilderLink, rootFormElementsAll} from "../../../../../Helper/RootVar/Contstant";
import {useNavigate, useParams} from "react-router-dom";
import {arrayFindObjectByProp} from "../../../../../Helper/Functions";
import {SystemFileInfo} from "../../../../Helpers/SystemFileInfo";

/*ELEMENT PROPERTIES RIGHT SIDE*/
export const EditFormElement = (props) => {
    /*set props*/
    const {url_menu_id = false, url_page_id = false, url_form_id = false, url_element_id = false} = useParams();
    const prependLink = `${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}/f/${url_form_id}/edit`;
    let navigate = useNavigate();

    const isMountedRef = useIsMountedRef();

    const [slideIn, setSlideIn] = useState(false);

    const [headerData, setHeaderData] = useState();
    const [elementData, setElementData] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSlideIn(true);
        }, 1);
        openRootToolBox();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setActionMenuTab("main");
        if(elementData === false){
            LoadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementData])

    useEffect(() => {
        setElementData(false);
        setActionMenuTab("main");
        openRootToolBox();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_element_id])


    useEffect(() => {
        LoadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rootAllPageContent[url_form_id])])

    const LoadData = () => {
        if (rootAllPageContent.hasOwnProperty(url_form_id)) {
            if (rootAllPageContent[url_form_id].hasOwnProperty('form_content') && rootAllPageContent[url_form_id].form_content.hasOwnProperty('element_settings')
                && rootAllPageContent[url_form_id].form_content.element_settings.hasOwnProperty(url_element_id)
            ) {
                setElementData(rootAllPageContent[url_form_id].form_content.element_settings[url_element_id]);
                // setHeaderData(arrayFindObjectByProp([...rootFormElementsAll.basic_fields, ...rootFormElementsAll.advanced_fields, ...rootFormElementsAll.other_fields, ...rootFormElementsAll.custom_fields],'type',rootAllPageContent[url_form_id].form_content.element_settings[url_element_id].type));
                setHeaderData(arrayFindObjectByProp([...rootFormElementsAll.basic_fields, ...rootFormElementsAll.advanced_fields, ...rootFormElementsAll.other_fields, ...rootFormElementsAll.custom_fields],'idu',rootAllPageContent[url_form_id].form_content.element_settings[url_element_id].field_type));

            } else {
                setElementData(false);
            }
        } else {
            /*todo: else*/
        }
    }

    const hideBack = () => {
        setSlideIn(false);
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            navigate(prependLink);
        }, 200)
    }

    const deletedParentAction = () => {
        return navigate(prependLink);
    };


    const [actionMenuTab, setActionMenuTab] = useState('main');
    const toggleActionMenuTab = (page) => {
        setActionMenuTab(page);
    };

    /*end form js*/
    return (
        <>
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll ${slideIn ? "admiko-show" : ""}`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix relative">
                        <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                            <div className="info">
                                {headerData ? (
                                        <>
                                            <h2><i className={`${headerData.icon??""}`} style={{marginRight: '0.35rem'}}></i>{headerData.title??""} settings</h2>
                                            <p>{headerData.info??""}</p>
                                        </>
                                    ) :
                                    (<>
                                        <h2>Loading...</h2>
                                        <p>Loading...</p>
                                    </>)}
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(elementData && elementData.can_delete === 1) && (
                            <div className="absolute bg-gray-800 mouse-pointer z-30 top-0 right-0 w-[77px] h-[76px] text-lg hover:bg-gray-700" onClick={(e) => toggleActionMenuTab('delete')}>
                                <div className="w-full h-full border-l border-l-gray-500 border-dotted hover:text-red-500 flex items-center justify-center align-items-stretch transition-colors"><i className="fa-solid fa-trash"></i></div>
                            </div>
                        )}
                        <div className="admiko-action-menu-body">
                            <div className="admiko-action-menu-body-action">
                                {elementData && (
                                    <LoadElementSettings elementData={elementData} form_idu={url_form_id} hideBack={hideBack}/>
                                )}
                            </div>
                        </div>
                        {(elementData && elementData.can_edit === 0) &&
                            <SystemFileInfo/>
                        }
                    </div>
                </div>
            </div>
            {elementData && (
                <>
                    {actionMenuTab === "delete" && <DeleteElementConfirm toggleActionMenuTab={toggleActionMenuTab} elementData={elementData} form_idu={url_form_id} deletedParentAction={deletedParentAction}/>}
                </>
            )}
        </>
    );
}
