import React, {useCallback, useEffect, useState} from "react";
import {useIsMountedRef} from "../../../Helper/isMounted";
import {openRootToolBox, rootBuilderLink} from "../../../Helper/RootVar/Contstant";
import {useNavigate, useParams} from "react-router-dom";
import {setRootBuilderDocuArea} from "../../../Global/AdmikoToolBox/BuilderDocumentation";
import {ElementContainer} from "./ElementContainer";
import {DeleteSectionHeader} from "../Delete/DeleteSectionHeader";
import {DeleteSection} from "../Delete/DeleteSection";
import {WidgetCustomClass} from "./WidgetCustomClass";
import {WidgetCounter} from "./WidgetCounter";
import {checkSaveButton} from "../../../Helper/RootVar/Error/CheckFormFields";
import {WidgetGlobal} from "./WidgetGlobal";
import {WidgetProgress} from "./WidgetProgress";


export const WidgetStart = (props) => {

    const {url_menu_id = false, url_page_id = false, url_widget_id = false} = useParams();

    const prependLink = `${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}`;

    let navigate = useNavigate();
    /*set props*/

    const isMountedRef = useIsMountedRef();

    const [headerData, setHeaderData] = useState({title: "", icon: "", info: ""});
    const [widgetData, setWidgetData] = useState(false);
    useEffect(() => {
        if (props.rootAllPageContent?.[url_widget_id]) {
            setWidgetData(props.rootAllPageContent[url_widget_id]);


            // const filteredItems = rootAllPages.content.filter((item) => {
            //     return item;
            // });
            //
            //  const totalCount = filterPageElements(rootAllPages[url_page_id]?.content).length;


            setToolboxHader(props);
            // if(props.rootAllPageContent[url_widget_id]?.widget_settings?.type === "element_container"){
            //
            // } else if(props.rootAllPageContent[url_widget_id]?.widget_settings?.type === "widget"){
            //
            // }
        } else {
            /*todo: else*/
            //hideBack();
            //setWidgetData(false);
        }
        openRootToolBox();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rootAllPageContent[url_widget_id]?.type, url_widget_id, props.rootAllPageContent])
//,rootAllPages[url_page_id]
//     const filterPageElements = (pageElements) => {
//         return pageElements.filter((item) => {
//             return item.container_idu === url_widget_id && item.layout !== 'element_container';
//         });
//     }

    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        setRootBuilderDocuArea('widgets');
        setSlideIn(true);
    }, [])


    const hideBack = useCallback(() => {
        setSlideIn(false);
        if (!isMountedRef.current) {
            return false;
        }
        return navigate(prependLink);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prependLink, isMountedRef])


    const deletedParentAction = useCallback((response) => {
        hideBack();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*TOGGLE ACTION*/
    //
    const [actionMenuTab, setActionMenuTab] = useState('main');
    const toggleActionMenuTab = useCallback((page) => {
        setActionMenuTab(page);
    }, []);

    // const [formFieldsRulesTemp, setFormFieldsRulesTemp] = useState(null);
    const checkSaveButtonOnChildStart = (formData, formFieldsRules, submitButton, setSubmitButton) => {
        // setFormFieldsRulesTemp(formFieldsRules);
        if (formFieldsRules !== null) {
            checkSaveButton(formData, formFieldsRules, submitButton, setSubmitButton);
        }
    }

    const setToolboxHader = (widgetData) => {
        switch (widgetData.rootAllPageContent[url_widget_id]?.widget_settings?.layout) {
            case 'custom_class':
            case 'custom_html':
                setHeaderData({title: "Custom widget", icon: "fa-solid fa-code", info: ""});
                break;
            case 'widget_counter':
                setHeaderData({title: "Counter", icon: "fa-solid fa-stopwatch", info: ""});
                break;
            case 'widget_progress':
                setHeaderData({title: "Progress", icon: "fa-solid fa-chart-simple", info: ""});
                break;
            case 'widget_line':
                setHeaderData({title: "Line chart", icon: "fa-solid fa-chart-line", info: ""});
                break;
            case 'widget_area':
                setHeaderData({title: "Area chart", icon: "fa-solid fa-chart-area", info: ""});
                break;
            case 'widget_bar':
                setHeaderData({title: "Bar chart", icon: "fa-solid fa-chart-bar", info: ""});
                break;
            case 'widget_column':
                setHeaderData({title: "Column chart", icon: "fa-solid fa-chart-column", info: ""});
                break;
            case 'widget_radar':
                setHeaderData({title: "Radar chart", icon: "fa-solid fa-circle-nodes", info: ""});
                break;
            case 'widget_pie':
                setHeaderData({title: "Pie chart", icon: "fa-solid fa-chart-pie", info: ""});
                break;
            case 'widget_donut':
                setHeaderData({title: "Donut chart", icon: "fa-solid fa-rainbow", info: ""});
                break;
            case 'widget_polar':
                setHeaderData({title: "Polar chart", icon: "fa-solid fa-chart-pie", info: ""});
                break;
            case 'widget_radial_bar':
                setHeaderData({title: "Radial bar chart", icon: "fa-solid fa-spinner", info: ""});
                break;
            default:
                setHeaderData({title: "Container", icon: "fa-regular fa-file-code", info: ""});
        }
    }
    const renderElement = (widgetData) => {
        switch (widgetData.layout) {
            case 'element_container':
                return (<><ElementContainer widgetData={widgetData} hideBack={hideBack}/></>);
            case 'custom_class':
            case 'custom_html':
                return (<><WidgetCustomClass widgetData={widgetData} hideBack={hideBack} checkSaveButtonOnChildStart={checkSaveButtonOnChildStart}/></>);
            case 'widget_progress':
                return (<><WidgetProgress widgetData={widgetData} hideBack={hideBack} activateGroup={1} activateAggregateAddMore={0}/></>);
            case 'widget_counter':
                return (<><WidgetCounter widgetData={widgetData} hideBack={hideBack} activateGroup={0} activateAggregateAddMore={0}/></>);
            case 'widget_pie':
            case 'widget_donut':
            case 'widget_polar':
            case 'widget_radial_bar':
                return (<><WidgetGlobal widgetData={widgetData} hideBack={hideBack} activateGroup={1} activateAggregateAddMore={0}/></>);
            case 'widget_line':
            case 'widget_area':
            case 'widget_bar':
            case 'widget_column':
            case 'widget_radar':
                return (<><WidgetGlobal widgetData={widgetData} hideBack={hideBack} activateGroup={1} activateAggregateAddMore={1}/></>);
            default:
                return (<></>);
        }
    }

    return <>
        <div className="admiko-table-editor">
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll admiko-show ${slideIn ? "" : ""}`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        <div className="flex flex-row">
                            <div>
                                <div className="admiko-action-header mouse-pointer" onClick={hideBack}>
                                    <div className={`info info-header`}>
                                        <h2><i className={`${headerData.icon}`}></i>{headerData.title}</h2>
                                    </div>
                                    <div className="close">
                                        <div className="admiko-toggle-tools">
                                            <div className="admiko-toggle-box">
                                                <div>
                                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" className="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                        <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="relative w-[64px]">
                                {widgetData !== false &&
                                    <>
                                        <DeleteSectionHeader toggleActionMenuTab={(e) => toggleActionMenuTab(e)}/>
                                    </>
                                }
                            </div>
                        </div>

                        {widgetData !== false ?
                            <>
                                {/*<DeleteSectionHeader toggleActionMenuTab={(e) => toggleActionMenuTab(e)}/>*/}
                                {renderElement(widgetData.widget_settings)}
                            </>
                            :
                            (<>
                                <div className="admiko-ak-loading">
                                    <i className="fa-solid fa-circle-notch"></i>
                                </div>
                            </>)
                        }

                    </div>
                </div>
            </div>
            {(actionMenuTab === "delete" && widgetData) &&
                <DeleteSection toggleActionMenuTab={toggleActionMenuTab} url_page_id={url_page_id} section={{"idu": widgetData.widget_settings.idu}} area="PageElement" deletedParentAction={deletedParentAction}/>
            }
        </div>


    </>
}
