import React from "react";

export const FormMaxValue = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="max_value">Max value:</label>
            <div className="form-group-input">
                <input className="form-input" autoComplete="off" name="max_value" id="max_value" placeholder="Enter required max value" value={formData.max_value} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                <p className="ak-admiko-form-error">Required!</p>
            </div>
        </div>
    );

}