import React, {useEffect, useState} from "react";
import {useIsMountedRef} from "../../../Helper/isMounted";
import {useNavigate, useParams} from "react-router-dom";
import {openRootToolBox, rootAllPageContent, rootBuilderLink, rootFormElementsAll, rootSinglePageForm, setRootScrollToID} from "../../../Helper/RootVar/Contstant";
import {AddFormElementContainer} from "./Form/AddNewFormElement/AddFormElementContainer";
import {EditFormElement} from "./Form/EditElement/EditFormElement";
import {FormSettings} from "./Form/FormSettings";
import {SingleFormPage} from "./Form/FormPage/SingleFormPage";
import {SingleFormPageHeaders} from "./Form/FormPage/SingleFormPageHeaders";
import {setRootBuilderDocuArea} from "../../../Global/AdmikoToolBox/BuilderDocumentation";

export const FormStart = (props) => {

    const {url_menu_id = false, url_page_id = false, url_form_id = false, url_element_id = false} = useParams();
    const backLink = `${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}/t/${url_form_id}`;
    let navigate = useNavigate();

    const [formData, setFormData] = useState(false);
    useEffect(() => {
        if (props.rootAllPageContent.hasOwnProperty(url_form_id)) {
            if (props.rootAllPageContent[url_form_id]?.form_content?.settings) {
                setFormData(props.rootAllPageContent[url_form_id].form_content.settings);
            } else {
                hideBack();
                setFormData(false);
            }
        } else {
            /*todo: else*/
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rootAllPageContent[url_form_id]?.form_content?.settings])

    const isMountedRef = useIsMountedRef();

    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        setSlideIn(true);
        openRootToolBox();
    }, [])

    useEffect(() => {
        setRootBuilderDocuArea('form');
        setActionMenuTab("main");
    }, [url_element_id])

    const hideBack = () => {
        if(rootSinglePageForm.page_form_id){
            return;
        }
        setSlideIn(false);
        if (!isMountedRef.current) {
            return false;
        }
        navigate(backLink);
        setRootScrollToID(url_form_id);
    }


    /*TOGGLE ACTION*/
    const [actionMenuTab, setActionMenuTab] = useState('main');
    const [actionMenuTabTitle, setActionMenuTabTitle] = useState({title: "", info: ""});
    const toggleActionMenuTab = (page, title = "", info = "", icon) => {
        setActionMenuTabTitle({title: title, info: info, icon: icon})
        setActionMenuTab(page);
    };

    return (
        <>
            <div className="admiko-form-editor">
                <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll admiko-show ${slideIn ? "" : ""}`}>
                    <div className="ak-admiko-add-scroll">
                        <div className="ak-admiko-add-scroll-fix">

                            <div className="admiko-action-header mouse-pointer" onClick={hideBack}>
                                <div className="info">
                                    <h2><i className="fa-solid fa-align-left"></i>Form builder</h2>
                                </div>
                                <div className="close">
                                    <div className="admiko-toggle-tools">
                                        <div className="admiko-toggle-box">
                                            <div>
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" className="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {Object.keys(rootAllPageContent).length > 0 ? (<>
                                    <div className="admiko-action-header mouse-pointer" onClick={(e) => toggleActionMenuTab('basic_fields', "Basic fields", "Add basic fields to your form.", "fa-solid fa-bars-staggered")}>
                                        <div className="info">
                                            <h2><i className="fa-solid fa-square-poll-horizontal"></i>Basic fields</h2>
                                            <p>Add basic fields to your form.</p>
                                        </div>
                                        <div className="close">
                                            <div className="admiko-toggle-tools">
                                                <div className="admiko-toggle-box">
                                                    <div>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="admiko-action-header mouse-pointer" onClick={(e) => toggleActionMenuTab('advanced_fields', "Advanced fields", "Add advanced fields to your form.", "fa-regular fa-rectangle-list")}>
                                        <div className="info">
                                            <h2><i className="fa-regular fa-rectangle-list"></i>Advanced fields</h2>
                                            <p>Add advanced fields to your form.</p>
                                        </div>
                                        <div className="close">
                                            <div className="admiko-toggle-tools">
                                                <div className="admiko-toggle-box">
                                                    <div>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="admiko-action-header mouse-pointer" onClick={(e) => toggleActionMenuTab('other_fields', "Other fields", "Add fields to your form.", "fa-brands fa-wpforms")}>
                                        <div className="info">
                                            <h2><i className="fa-brands fa-wpforms"></i>Other fields</h2>
                                            <p>Add fields to your form.</p>
                                        </div>
                                        <div className="close">
                                            <div className="admiko-toggle-tools">
                                                <div className="admiko-toggle-box">
                                                    <div>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="admiko-action-header mouse-pointer" onClick={(e) => toggleActionMenuTab('custom_fields', "Custom fields", "Add custom fields to your form.", "fa-solid fa-code")}>
                                        <div className="info">
                                            <h2><i className="fa-solid fa-code"></i>Custom fields</h2>
                                            <p>Add custom fields to your form.</p>
                                        </div>
                                        <div className="close">
                                            <div className="admiko-toggle-tools">
                                                <div className="admiko-toggle-box">
                                                    <div>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {rootSinglePageForm.page_form_id ?
                                        <>
                                            <SingleFormPageHeaders toggleActionMenuTab={toggleActionMenuTab}/>
                                        </>
                                        :
                                        <div className="admiko-action-header mouse-pointer" onClick={(e) => toggleActionMenuTab('form_settings', "Form Settings", "Form configuration.", "fa-solid fa-gears")}>
                                            <div className="info">
                                                <h2><i className="fa-solid fa-gears"></i>Form settings</h2>
                                                <p>Form configuration.</p>
                                            </div>
                                            <div className="close">
                                                <div className="admiko-toggle-tools">
                                                    <div className="admiko-toggle-box">
                                                        <div>
                                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                                <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>)
                                :
                                (
                                    <>
                                        <div className={`admiko-action admiko-action-menu admiko-show ak-admiko-pre-scroll`}>
                                            <div className="ak-admiko-add-scroll">
                                                <div className="ak-admiko-add-scroll-fix">
                                                    <div className="admiko-ak-loading">
                                                        <i className="fa-solid fa-circle-notch"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                {actionMenuTab === "basic_fields" &&
                    <AddFormElementContainer title={actionMenuTabTitle} toggleActionMenuTab={toggleActionMenuTab} elements={rootFormElementsAll.basic_fields} form_idu={url_form_id} parent_idu={url_page_id}/>
                }
                {actionMenuTab === "advanced_fields" &&
                    <AddFormElementContainer title={actionMenuTabTitle} toggleActionMenuTab={toggleActionMenuTab} elements={rootFormElementsAll.advanced_fields} form_idu={url_form_id} parent_idu={url_page_id}/>
                }
                {actionMenuTab === "other_fields" &&
                    <AddFormElementContainer title={actionMenuTabTitle} toggleActionMenuTab={toggleActionMenuTab} elements={rootFormElementsAll.other_fields} form_idu={url_form_id} parent_idu={url_page_id}/>
                }
                {actionMenuTab === "custom_fields" &&
                    <AddFormElementContainer title={actionMenuTabTitle} toggleActionMenuTab={toggleActionMenuTab} elements={rootFormElementsAll.custom_fields} form_idu={url_form_id} parent_idu={url_page_id}/>
                }
                {rootSinglePageForm.page_form_id ?
                    <>
                        <SingleFormPage actionMenuTab={actionMenuTab} toggleActionMenuTab={toggleActionMenuTab}/>
                    </>
                    :
                    <>
                        {actionMenuTab === "form_settings" &&
                            <FormSettings title={actionMenuTabTitle} toggleActionMenuTab={toggleActionMenuTab} settings={formData} form_idu={url_form_id} parent_idu={url_page_id}/>
                        }
                    </>
                }

                {url_element_id !== false && (
                    <EditFormElement/>
                )}
            </div>
        </>
    );
}
