import React, {useEffect} from "react";
import {FormTitle} from "./Partials/FormTitle";
import {FormDescription} from "./Partials/FormDescription";
import {FormRequired} from "./Partials/FormRequired";
import {FormSubmitButton} from "../../../../../Helpers/FormSubmitButton";
import {FormWidth} from "./Partials/FormWidth";
import {FormCloseButton} from "../../../../../Helpers/FormCloseButton";
import {FormCustomValues} from "./Partials/FormCustomValues";
import {FormElementDirection} from "./Partials/FormElementDirection";
import {FormCustomClass} from "./Partials/FormCustomClass";
import {AdvancedSettings} from "../../../../../Helpers/AdvancedSettings";
import {FormColumn} from "./Partials/FormColumn";
import {FormVarLength} from "./Partials/FormVarLength";
import {rootCUSTOM_CLASS_MAX_LENGTH, rootCUSTOM_TITLE_MAX_LENGTH, rootCUSTOM_VALUE_MAX_LENGTH, rootTABLE_COLUMN_MAX_LENGTH} from "../../../../../../Helper/RootVar/Contstant";

export const RadioGroupCustom = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormData = props.onChangeFormData;
    const sendFormData = props.sendFormData;
    const submitButton = props.submitButton;
    const closeForm = props.closeForm;
    const section_id = props.section_id;
    const element_idu = props.element_idu;
    const checkSaveButtonOnChildStart = props.checkSaveButtonOnChildStart;
    useEffect(() => {
        checkSaveButtonOnChildStart(formFieldsRules);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formFieldsRules = {
        title: {required: true, type: "input", rules: {minLength: 1, maxLength: 64}},
        table_column: {required: true, type: "input", rules: {minLength: 1, maxLength: rootTABLE_COLUMN_MAX_LENGTH, allowedInput: "upper_lower_dash_numbers_protect_start_end"}},
        required: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        element_direction: {required: false, type: "select"},
        custom_data: {
            required: true, array_required: true, type: "array", fields: {
                title: {required: true, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_TITLE_MAX_LENGTH}},
                value: {required: true, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_VALUE_MAX_LENGTH, allowedInput: "lower_upper_numbers_dash_minus"}}
            }
        },
        custom_class: {required: false, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_CLASS_MAX_LENGTH, allowedInput: "upper_lower_dash_minus_numbers_space"}},
        var_length: {required: true, type: "input", rules: {minLength: 1, maxLength: 3, allowedInput: "numbers"}},
        element_as_button: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
    }


    const onChangeFormDataInput = (e) => {
        onChangeFormData(e, formFieldsRules)
    };
    return (
        <>
            <form action="">
                <FormTitle formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormColumn formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormWidth formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <FormRequired formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                <FormElementDirection formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                {/*<FormElementAsButton formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>*/}
                <FormCustomValues section_id={section_id} element_idu={element_idu} formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                <AdvancedSettings>
                    <FormDescription formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>

                    <FormVarLength formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                    <FormCustomClass formData={formData} onChangeFormDataInput={onChangeFormDataInput}/>
                </AdvancedSettings>
                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                <FormCloseButton closeForm={(e) => closeForm(e)}/>
            </form>
        </>
    );

}