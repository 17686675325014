import React, {useEffect, useState} from "react";
import {rootHomePageID, rootAllPages, rootBuilderLink, rootSinglePageForm} from "../../Helper/RootVar/Contstant";
import {useNavigate, useParams} from "react-router-dom";

export const PageHeader = () => {
    /*set props*/
    const {url_menu_id = false, url_page_id = false, url_form_id = false} = useParams();

    let navigate = useNavigate();

    const [pageTitle, setPageTitle] = useState(false);
    const [showBack, setShowBack] = useState(false);
    const [pageDescription, setPageDescription] = useState(false);
    useEffect(() => {
        if (rootAllPages.hasOwnProperty(url_page_id)) {
            if (rootAllPages[url_page_id].hasOwnProperty('header') && rootAllPages[url_page_id].header.hasOwnProperty('page_title')) {
                setPageTitle(rootAllPages[url_page_id].header.page_title);
                setPageDescription(rootAllPages[url_page_id].header.description);
            } else {
                setPageTitle(false);
                setPageDescription(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rootAllPages[url_page_id])])

    useEffect(() => {
        let parentsLength = 0;
        if (rootAllPages.hasOwnProperty(url_page_id)) {
            if (rootAllPages[url_page_id].hasOwnProperty('breadcrumbs') && rootAllPages[url_page_id].breadcrumbs.hasOwnProperty('parents')) {
                parentsLength = rootAllPages[url_page_id].breadcrumbs.parents.length;
            }
        }

        if (rootSinglePageForm?.page_form_id) {
            setShowBack(false);
        } else if (url_form_id) {
            setShowBack(true);
        } else if (parentsLength > 1) {
            setShowBack(true);
        } else {
            setShowBack(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_menu_id, url_page_id, url_form_id])
    const goBack = (e) => {
        e.preventDefault();
        let parentsLength = 0;
        if (rootAllPages.hasOwnProperty(url_page_id)) {
            if (rootAllPages[url_page_id].hasOwnProperty('breadcrumbs') && rootAllPages[url_page_id].breadcrumbs.hasOwnProperty('parents')) {
                parentsLength = rootAllPages[url_page_id].breadcrumbs.parents.length;
            }
        }

        if (url_form_id && !rootSinglePageForm.page_form_id) {
            return navigate(`${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}`);
        } else if (parentsLength > 1) {
            return navigate(`${rootBuilderLink}/m/${url_menu_id}/p/${rootAllPages[url_page_id].breadcrumbs.parents[parentsLength - 2].idu}`);
        } else {
            return navigate(`${rootBuilderLink}/m/${rootHomePageID}/p/${rootHomePageID}`);
        }
    }


    return pageTitle ? (
            <>
                <div className="ak-page-title">
                    <h1>{pageTitle}
                    </h1>
                </div>
                <div className="ak-page-info">
                    <p>{pageDescription}</p>
                </div>
                <div className="ak-back-link">
                    {(rootHomePageID !== url_page_id && showBack) && (
                        <a href="/" onClick={(e) => goBack(e)}><i className="fa-solid fa-angle-left"></i> back</a>
                    )}
                </div>
            </>
        ) :
        <>
            <div className="ak-header-loading">
                <div></div>
            </div>
        </>
        ;

}


