import React, {useState} from "react";
import {TabAddMenu} from "./TabAddMenu";
import {TabAddPage} from "./TabAddPage";
import {TabSortContainer} from "./TabSortContainer";
import {updateRootAdmikoToolBoxMenuContainer} from "../../../Helper/RootVar/ToolBox/Menu/MenuToolBox";

export const MenuManage = (props) => {
    /*set props*/
    const rootLeftMenuAll = props.rootLeftMenuAll;

    const [actionMenuTab, setActionMenuTab] = useState('main');
    const toggleActionMenuTab = (page) => {
        setActionMenuTab(page);
    };
    return (
        <>
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll admiko-show`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        <div className="admiko-action-header mouse-pointer" onClick={() => updateRootAdmikoToolBoxMenuContainer(false,true,"menu_manage",-1)}>
                            <div className="info">
                                <h2><i className="fa-regular fa-file-lines"></i>Page</h2>
                                <p>Add new CRUD or empty page.</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" className="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-action-menu-body">
                            <TabAddPage/>
                        </div>
                        <div className="admiko-action-header mouse-pointer" onClick={() => toggleActionMenuTab('menu')}>
                            <div className="info">
                                <h2><i className="fa-solid fa-bars"></i>Sidebar helpers</h2>
                                <p>Add sidebar container, header or link.</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-action-header mouse-pointer" onClick={() => toggleActionMenuTab('sort')}>
                            <div className="info">
                                <h2><i className="fa-solid fa-list-ol"></i>Sidebar sorting</h2>
                                <p>Sort sidebar content.</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {actionMenuTab === "menu" &&
                <TabAddMenu toggleActionMenuTab={toggleActionMenuTab}/>
            }
            {actionMenuTab === "sort" &&
                <TabSortContainer rootLeftMenuAll={rootLeftMenuAll} toggleActionMenuTab={toggleActionMenuTab}/>
            }
        </>
    );
}
