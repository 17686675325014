import React, {useEffect, useState} from "react";
import {apiEditMenu} from "../../../../Api/useMenu";
import {Icons} from "../../../Icons/Icons";
import {DeleteSection} from "../../Delete/DeleteSection";
import {FormSubmitButton} from "../../../Helpers/FormSubmitButton";
import {DeleteSectionHeader} from "../../Delete/DeleteSectionHeader";
import {IconsSectionHeader} from "../../../Icons/IconsSectionHeader";
import {useIsMountedRef} from "../../../../Helper/isMounted";
import {updateRootAdmikoToolBoxMenuContainer} from "../../../../Helper/RootVar/ToolBox/Menu/MenuToolBox";
import {rootSetError} from "../../../../Helper/RootVar/Error/Error";
import {rootAllPages, rootPAGE_ID_MAX_LENGTH, setRootLeftMenuAll} from "../../../../Helper/RootVar/Contstant";
import {onChangeFormDataGlobal} from "../../../../Helper/Functions";

export const MenuLink = (props) => {
    /*set props*/
    const idu = props.idu;
    const rootAdmikoToolBoxMenuContainer = props.rootAdmikoToolBoxMenuContainer;

    const isMountedRef = useIsMountedRef();

    const [selectedSection, setSelectedSection] = useState(0);

    const [actionMenuTab, setActionMenuTab] = useState('main');

    const [infoClick, setInfoClick] = useState({page_id: false});
    const toggleActionMenuTab = (page) => {
        setActionMenuTab(page);
    };
    const deletedParentAction = () => {
        startCloseMe();
    };

    /*GET DATA for form from API server*/
    useEffect(() => {
        setActionMenuTab("main");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (rootAllPages.hasOwnProperty(idu)) {
            setSelectedSection(rootAllPages[idu]);
            setFormData(rootAllPages[idu]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rootAllPages[idu])])

    /*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");
    const formFields = {idu: 0, icon: "", title: "", new_window: "", link: "", page_id: ""};
    const formFieldsRules = {
        title: {required: true, type: "input", rules: {minLength: 1, maxLength: 40}},
        link: {required: false, type: "input", rules: {}},
        new_window: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
        page_id: {required: true, type: "input", rules: {minLength: 3, maxLength: rootPAGE_ID_MAX_LENGTH, allowedInput: "lower_dash_numbers_protect_start_end"}},
    }
    const [formData, setFormData] = useState(formFields);

    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };
    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiEditMenu({...formData}, idu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/

    useEffect(() => {
        if (rootAdmikoToolBoxMenuContainer.close) {
            closeMe()
        }
    }, [rootAdmikoToolBoxMenuContainer])
    const startCloseMe = () => {
        updateRootAdmikoToolBoxMenuContainer(false, true, "menu_link", idu)
    }
    const closeMe = () => {
        setTimeout(() => {
            updateRootAdmikoToolBoxMenuContainer();
        }, 200)
    }
    // ${slideIn ? "admiko-show" : ""}
    return (
        <>
            <div className={`admiko-action admiko-action-menu admiko-show`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        <div className="admiko-action-header mouse-pointer" onClick={() => startCloseMe()}>
                            <div className="info">
                                <h2><i className="fa-solid fa-link"></i>Link settings</h2>
                                <p>Edit link properties.</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" className="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-action-menu-body">

                            <div className={`admiko-action-menu-body-action`}>
                                {(formData.idu !== 0) ? (
                                        <>
                                            <form action="">
                                                <div className="form-group">
                                                    <label htmlFor="title">Title:</label>
                                                    <div className="form-group-input">
                                                        <input className="form-input" autoComplete="off" name="title" id="title" placeholder="Enter title" value={formData.title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                        <p className="ak-admiko-form-error">Required!</p>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="link">URL:</label>
                                                    <div className="form-group-input">
                                                        <textarea value={formData.link} className="form-textarea" name="link" placeholder="Enter url" id="link" onChange={(e) => onChangeFormData(e)}></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="new_window">New window:</label>
                                                    <div className="form-group-input">
                                                        <input className="form-checkbox" type="checkbox" name="new_window" value={formData.new_window} checked={formData.new_window === 1} id="new_window" onChange={(e) => onChangeFormData(e)}/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="page_id" className="mouse-pointer" onClick={() => setInfoClick({page_id: !infoClick.page_id})}>ID: <i className="fa-solid fa-circle-info"></i></label>
                                                    <div className="form-group-input">
                                                        <input className="form-input" autoComplete="off" name="page_id" id="page_id" placeholder="Enter ID" value={formData.page_id} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                        <p className="ak-admiko-form-error">Required min 3 characters!</p>
                                                    </div>
                                                </div>
                                                {infoClick.page_id &&
                                                    <p className="ak-admiko-form-info">Unique ID for gates. Max 30 characters. Can't start with _0-9 or ends with _ (Limited: a-z, 0-9 and _).
                                                        Highly recommended to use only a-z and _</p>
                                                }
                                                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                                <p>Create link in the menu.<br/>
                                                    New window: Open link in a new window.</p>
                                            </form>

                                        </>)
                                    :
                                    <>
                                        <div className="admiko-ak-loading">
                                            <i className="fa-solid fa-circle-notch"></i>
                                        </div>
                                    </>}
                            </div>


                        </div>
                        {(formData.idu !== 0) &&
                            <>
                                <IconsSectionHeader toggleActionMenuTab={(e) => toggleActionMenuTab(e)}/>
                                <DeleteSectionHeader toggleActionMenuTab={(e) => toggleActionMenuTab(e)}/>
                            </>
                        }
                    </div>

                </div>
            </div>
            {actionMenuTab === "icon_manager" && <Icons toggleActionMenuTab={toggleActionMenuTab} section={selectedSection} setSelectedSection={setSelectedSection} setRootLeftMenuAll={setRootLeftMenuAll}/>}
            {actionMenuTab === "delete" && <DeleteSection toggleActionMenuTab={toggleActionMenuTab} section={selectedSection} deletedParentAction={deletedParentAction} setRootLeftMenuAll={setRootLeftMenuAll}/>}
        </>

    );
}
