import { useState } from 'react';

export let appRootClients = [];
export let setAppRootClients;

export const useAppRootClients = () => {
    [appRootClients, setAppRootClients] = useState(false);
}
export const setAppRootClientsDirect = (data) => {
    setAppRootClients(data);
};