import React, {useEffect, useState} from "react";
import SortMe from "../../../../../../../Helper/SortMe";
import {getRandomId} from "../../../../../../../Helper/Functions";
import {rootCUSTOM_VALUE_MAX_LENGTH} from "../../../../../../../Helper/RootVar/Contstant";
import {CheckCustomValuesFields} from "../../../../../../../Helper/RootVar/Error/CheckFormFields";

export const FormUserConnectedSortSource = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    const [customValues, setCustomValues] = useState([]);
    const [showSettings, setShowSettings] = useState("");


    // useEffect(() => {
    //     if (Array.isArray(formData.custom_sort_data) && formData.custom_sort_data.length > 0) {
    //         checkAndSetCustomFields(formData.custom_sort_data);
    //     } else {
    //         createNewCustomFields();
    //     }
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    /*label group*/
    /*label group*/
    /*label group*/
    const createNewCustomFields = () => {
        let newCustomValues = [...customValues];
        newCustomValues = [...newCustomValues, {value: "", order: ""}];
        setShowSettings("");
        checkAndSetCustomFields(newCustomValues);
    }
    const checkAndSetCustomFields = (customValues) => {
        let newCustomValues = [];
        let value_required = true;
        let uniqueKey = "";

        for (let i = 0; i < customValues.length; i++) {
            if (customValues[i].value.length > 0) {
                value_required = false;
            } else {
                value_required = true;
            }
            if(customValues[i].idu){
                uniqueKey = customValues[i].idu;
            } else {
                uniqueKey = getRandomId(customValues)
            }

            newCustomValues[i] = {idu: uniqueKey,value: customValues[i].value, order: customValues[i].order, value_required: value_required};
        }
        setCustomValues(newCustomValues);
        saveData(newCustomValues);
    }

    useEffect(() => {
        if (Array.isArray(formData.custom_sort_data) && formData.custom_sort_data.length > 0) {
            checkAndSetCustomFields(formData.custom_sort_data);
        } else {
            createNewCustomFields();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.formData.custom_sort_data)])

    const saveData = (newValues) => {
        /*save data*/
        let newCustomValues = [];
        for (let i = 0; i < newValues.length; i++) {
            newCustomValues[i] = {idu:newValues[i].idu,value: newValues[i].value, order: newValues[i].order};
        }
        let elm = {target: {name: 'custom_sort_data', value: newCustomValues}};
        onChangeFormDataInput(elm);
    }
    const addLabelGroup = () => {
        createNewCustomFields();
    }

    const removeLabelGroup = (i) => {
        let newCustomValues = [...customValues];
        newCustomValues.splice(i, 1);
        setShowSettings("");
        if (newCustomValues.length < 1) {
            createNewCustomFields();
        }
        setCustomValues(newCustomValues);
        saveData(newCustomValues);
    }
    const editLabelGroup = (idu) => {
        if (showSettings === idu) {
            setShowSettings("");
        } else {
            setShowSettings(idu);
        }
    }

    const formFieldsRules = {
        value: {required: true, type: "input", rules: {minLength: 1, maxLength: rootCUSTOM_VALUE_MAX_LENGTH, allowedInput: "lower_upper_numbers_dash_minus"}},
        order: {required: false, type: "select",rules: {}}
    }

    const onChangeValueInput = (e, index) => {
        if (formFieldsRules.hasOwnProperty(e.target.name)) {
            e.target.value = CheckCustomValuesFields(e, formFieldsRules);
        }
        let newCustomValues = [...customValues];
        newCustomValues[index] = {...newCustomValues[index], [e.target.name]: e.target.value};
        checkAndSetCustomFields(newCustomValues);
    }

    const saveLabelSortMe = (newList) => {
        let newCustomValues = [];
        for (let i = 0; i < newList.sortList.length; i++) {
            newCustomValues[i] = {value: customValues[newList.sortList[i]].value, order: customValues[newList.sortList[i]].order};
        }
        checkAndSetCustomFields(newCustomValues);
    };

    // const onChangeSortOrderSourceLabel = (e, idu) => {
    //     let newFormValues = [...sortLabelGroup];
    //     newFormValues.find((o, i) => {
    //         if (o.idu === idu) {
    //             newFormValues[i] = {idu: newFormValues[i].idu, order: e.target.value, labelList: []};
    //             generateSortLabelGroup(newFormValues);
    //             return true;
    //         }
    //         return false;
    //     });
    // };

    return (
        <>
            <div className="add-more-btn-box">
                <div>
                    Set sort columns:
                </div>
                <div>
                    <div className="form-group">
                        <div className="add-more-btn mouse-pointer" onClick={() => addLabelGroup()}><i className="fa-solid fa-plus"></i> Add more</div>
                    </div>
                </div>
            </div>
            <SortMe saveSortMe={saveLabelSortMe} parentContainer="js-ak-custom-values-sort-group">
                {customValues.map((element, index) => (
                    <CustomValuesGroup key={element.idu} editLabelGroup={editLabelGroup} removeLabelGroup={removeLabelGroup}
                                       ElementData={element} position={index} totalItems={customValues.length}
                                       index={index}
                                       onChangeValueInput={onChangeValueInput}
                                       showSettings={showSettings}
                    />
                ))}
            </SortMe>
        </>
    );

}

export const CustomValuesGroup = (props) => {
    /*set props*/
    const index = props.index;
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const editLabelGroup = props.editLabelGroup;
    const removeLabelGroup = props.removeLabelGroup;
    const onChangeValueInput = props.onChangeValueInput;
    const showSettings = props.showSettings;

    return (
        <>
            <div className="form-group ak-source-label-group js-ak-custom-values-sort-group js-admiko-sort-me-container" data-id={index}>
                <div className="form-group-input">
                    <div className="ak-custom-values-input-value">
                        <input className={`form-input ${ElementData.value_required && "ak-admiko-form-input-error"}`} value={ElementData.value} autoComplete="off" name="value" id={`value-${index}`} placeholder="Enter column name" type="text" onChange={(e) => onChangeValueInput(e, index)}/>
                        <p className="ak-admiko-form-error">Required!</p>
                    </div>
                    <div>
                        <select className="form-select ak-source-sort-order" value={ElementData.order} name="order" id={`source-sort-order-${index}`} onChange={(e) => onChangeValueInput(e, index)}>
                            <option value="asc">ASC</option>
                            <option value="desc">DESC</option>
                        </select>
                    </div>
                    <AdmikoToolboxSortEditBox position={position} sortMe={sortMe} totalItems={totalItems} editLabelGroup={editLabelGroup} idu={index} removeLabelGroup={removeLabelGroup}/>
                </div>
                <div className={`ak-source-label-group-edit ${showSettings === index && "show"}`}>
                    <div className="ak-source-label-group-edit-input">Delete</div>
                    <div className="admiko-form-settings">
                        {totalItems > 1 &&
                            <div className="mouse-pointer delete-label-group" onClick={() => removeLabelGroup(position)}><i className="fa-solid fa-trash"></i></div>
                        }
                    </div>
                </div>
            </div>
        </>
    );

}
export const AdmikoToolboxSortEditBox = (props) => {
    /*set props*/
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;

    const removeLabelGroup = props.removeLabelGroup;
    return (
        <>
            <div className="admiko-form-settings">
                {totalItems > 1 &&
                    <div className="move-section tft-move js-admiko-sort-me">
                        {position > 0 &&
                            <div className="move-up mouse-pointer js-admiko-move-me-up" onClick={(e) => sortMe.SortMoveUp(e)}><i className="fa-solid fa-chevron-up"></i></div>
                        }
                        <div className="mouse-move js-admiko-move-me"><i className="fa-solid fa-up-down-left-right"></i></div>
                        {position + 1 < totalItems &&
                            <div className="move-down mouse-pointer js-admiko-move-me-down" onClick={(e) => sortMe.SortMoveDown(e)}><i className="fa-solid fa-chevron-down"></i></div>
                        }
                    </div>
                }
                {totalItems > 1 &&
                    <div className="mouse-pointer delete-label-group" onClick={() => removeLabelGroup(position)}><i className="fa-solid fa-trash"></i></div>
                }
            </div>
        </>
    );
}
