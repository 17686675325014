import React, {useState, useLayoutEffect} from 'react';

import {PageStart} from "./Page/PageStart";
import {FormStart} from "./Page/TableForm/FormStart";
import {TableStart} from "./Page/TableForm/TableStart";
import {Link} from "react-router-dom";

import {setRootToolBoxVisible, rootAllPages, rootAllPageContent} from "../Helper/RootVar/Contstant";
import {rootBuilderDocuLink} from "../Global/AdmikoToolBox/BuilderDocumentation";
import {WidgetStart} from "./Page/Widgets/WidgetStart";

export const ToolBoxStart = (props) => {
    /*set props*/
    const rootAdmikoToolBoxVisibile = props.rootAdmikoToolBoxVisibile;
    const url_page_id = props.url_page_id;
    const url_table_id = props.url_table_id;
    const url_form_id = props.url_form_id;
    const url_widget_id = props.url_widget_id;

    const [pageData, setPageData] = useState(false);

    useLayoutEffect(() => {
        if (rootAllPages[url_page_id]?.page) {
            setPageData(rootAllPages[url_page_id].page);
        } else {
            setPageData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rootAllPages[url_page_id])]);

// useLayoutEffect(() => {
//         if (rootAllPages[url_page_id]?.page) {
//             setPageData(rootAllPages[url_page_id].page);
//         } else {
//             setPageData(null);
//         }
//     }, [JSON.stringify(rootAllPages[url_page_id])]);

    return (
        <div className={`admiko-tools-container ${!rootAdmikoToolBoxVisibile ? 'admiko-hide-container' : ""}`}>
            <div className="admiko-tools-container-toggle" onClick={() => setRootToolBoxVisible(!rootAdmikoToolBoxVisibile)}><i className="fa-solid fa-caret-left"></i></div>
            <div className="admiko-tools">
                <div className="admiko-header">
                    <div className="flex">
                        <div className="admiko-toggle-tools" onClick={() => setRootToolBoxVisible(!rootAdmikoToolBoxVisibile)}>
                            <div className="admiko-toggle-box">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" preserveAspectRatio="xMidYMid meet" focusable="false">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-toggle-tools">
                            <Link to={rootBuilderDocuLink} target="_blank">
                                <div className="admiko-toggle-box">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="admiko-header-title">Admiko Toolbox</div>
                    <div className="flex">
                        <div className="w-9 h-8"></div>
                        <div className="admiko-toggle-tools">
                            <Link to={"/"}>
                                <div className="admiko-toggle-box">
                                    <div>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="square-arrow-up-right" className="svg-inline--fa fa-square-arrow-up-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM344 312c0 17.69-14.31 32-32 32s-32-14.31-32-32V245.3l-121.4 121.4C152.4 372.9 144.2 376 136 376s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L234.8 200H168c-17.69 0-32-14.31-32-32s14.31-32 32-32h144c17.69 0 32 14.31 32 32V312z"></path>
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="admiko-action-container">
                    <PageStart pageData={pageData} url_page_id={url_page_id} url_table_id={url_table_id} url_widget_id={url_widget_id}/>
                    {/*FORM SETTINGS*/}
                    {url_form_id &&
                        <FormStart rootAllPageContent={rootAllPageContent}/>
                    }
                    {/*TABLE SETTINGS*/}
                    {url_table_id &&
                        <TableStart rootAllPageContent={rootAllPageContent}/>
                    }
                    {/*ELEMENT CONTAINER SETTINGS*/}
                    {url_widget_id &&
                        <WidgetStart rootAllPageContent={rootAllPageContent}/>
                    }
                </div>
            </div>
        </div>
    );
}
