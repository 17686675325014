import React, {useState} from "react";
import {useIsMountedRef} from "../../../../../Helper/isMounted";
import {FormSubmitButton} from "../../../../Helpers/FormSubmitButton";
import {apiAddFormElement} from "../../../../../Api/useForm";
import {rootSetError} from "../../../../../Helper/RootVar/Error/Error";
import {onChangeFormDataGlobal} from "../../../../../Helper/Functions";


/*ADD new elements to a FORM*/
export const AddNewFormElement = (props) => {
    /*set props*/
    const form_idu = props.form_idu;
    const section_idu = props.section_idu;
    const item = props.item;
    const parentChildStatus = props.parentChildStatus;
    const isMountedRef = useIsMountedRef();
    /*set focus on input element*/

    /*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");
    /*form js*/
    const formFields = {idu: item.idu, title: ""};
    const formFieldsRules = {
        title: {required: false, type: "input", rules: {minLength: 1, maxLength: 40}},
        // type: {required: false, type: "select"},
        // description: {required: false, type: "input", rules: {minLength: 1, maxLength: 60}},
        // link: {required: false, type: "input", rules: {}},
        // new_window: {required: false, type: "checkbox", rules: {checked: 1, unchecked: 0}},
    }
    const [formData, setFormData] = useState(formFields);
    const resetFormData = () => {
        setFormData(prevFormData => ({...prevFormData, title: ""}));
    }
    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };

    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiAddFormElement({...formData}, section_idu, form_idu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            resetFormData();
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/

    return (
        <div key={item.idu} className="add-form-element">
            <h3><i className={`${item.icon}`}></i> {item.title}</h3>
            <p>{item.info}</p>
            <div className="add-form-element-form">
                <form action="">
                    {/*parent item IDU in database "form_elements" is lJK6sM2G , type = parent_child*/}
                    {/* && item.type === "parent_child"*/}
                    {(item.type === "parent_child" && parentChildStatus === false) ?
                        (<>
                            <div className="form-group">
                                <div className="form-group-input">
                                    <input className="form-input" disabled autoComplete="off" name="title" placeholder="Enter name" type="text" value=""/>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-group-input">
                                    <button type="submit" className="from-button button-submit" disabled>
                                        <span className="button-text">Save</span>
                                    </button>
                                </div>
                            </div>
                        </>)
                        :
                        (<>
                            <div className="form-group">
                                <div className="form-group-input">
                                    <input className="form-input" autoComplete="off" name="title" placeholder="Enter name" type="text"
                                           value={formData.title} onChange={(e) => onChangeFormData(e, item.idu)}/>
                                </div>
                            </div>
                            <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                        </>)
                    }
                </form>
            </div>
        </div>
    );
}
