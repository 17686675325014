import {fetchData} from "./useCallApi";

/*change table settings*/
export const apiUpdateWidgetsSettings = async (dataToSend,sec_id) => {

    const dataFetch = {action:"update_widget_settings",dataPrepare:dataToSend,"sec_id":sec_id};
    return await fetchData(dataFetch);
}
export const apiGetWidgetConnectedSourceList = async () => {

    const dataFetch = {action:"get_widget_connected_source_list"};
    return await fetchData(dataFetch);
}
export const apiGetWidgetSourceLabels = async (dataToSend) => {

    const dataFetch = {action:"get_widget_source_labels",dataPrepare:dataToSend};
    return await fetchData(dataFetch);
}

// export class apiGetConnectedSourceList {
// }