import React from "react";
import {FormInfo, Required} from "./Partials/Partials";
import {EditBox} from "../FormContentStart";

export const MultiCheckboxCustom = (props) => {
    /*set props*/
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const editElement = props.editElement;

    return (
        <>
            <div className={`ak-form-group ak-w-${ElementData.width} js-form-elements js-admiko-sort-me-container ak-element-direction-${ElementData.direction} ${props.element_id === ElementData.idu ? "selected" : ""} ${ElementData.error === 1 ? "ak-form-error" : ""}`} data-id={ElementData.idu}>
                <div className={`ak-form-group-box`} onClick={() => editElement(ElementData.idu)}>
                    <div className="ak-form-label">
                        <label>{ElementData.title}<Required ElementData={ElementData}/></label>
                    </div>
                    <div className="ak-form-input ak-form-input-checkbox">
                        {Array.isArray(ElementData.form_display) && ElementData.form_display.length > 0 ?
                            <>
                                {ElementData.form_display.map((item, i) => (
                                    <label className="label-checkbox" key={i}>
                                        <input type="checkbox" className="form-checkbox"/> <span>{item}</span>
                                    </label>
                                ))}
                            </>
                            :
                            <>
                                <label className="label-checkbox">
                                    Empty, add data.
                                </label>
                            </>
                        }
                        <FormInfo ElementData={ElementData}/>
                    </div>
                </div>
                <EditBox position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement} idu={ElementData.idu}/>
            </div>
        </>
    );

}