import React, {useEffect, useRef, useState} from "react";

import {FormSubmitButton} from "../../../Helpers/FormSubmitButton";
import {useIsMountedRef} from "../../../../Helper/isMounted";
import {rootSetError} from "../../../../Helper/RootVar/Error/Error";
import {apiAddPageElement} from "../../../../Api/usePageSections";
import {onChangeFormDataGlobal} from "../../../../Helper/Functions";

export const AddPageElement = (props) => {
    /*set props*/
    const idu = props.idu;
    const actionElementTab = props.actionElementTab;
    const toggleActionElementTab = props.toggleActionElementTab;

    const isMountedRef = useIsMountedRef();

    /*set focus on input element*/
    const inputFocus = useRef(null);
    const [slideIn, setSlideIn] = useState(false);
    useEffect(() => {
        /*set focus on input element*/
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            inputFocus.current.focus();
        }, 200)

        setSlideIn(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        setFormData(prevFormData => ({...prevFormData, element: actionElementTab.page}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionElementTab])


    const hideBack = () => {
        setSlideIn(false);
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            toggleActionElementTab("main", "", "", "");
        }, 200)

    }

    /*FORM*/
    const [submitButton, setSubmitButton] = useState("ready");
    /*form js*/
    const formFields = {title: "", element: ""};
    const formFieldsRules = {
        title: {required: false, type: "input", rules: {minLength: 1, maxLength: 64}}
    }
    const [formData, setFormData] = useState(formFields);
    const resetFormData = () => {
        setFormData(prevFormData => ({...prevFormData, title: ""}));
    }
    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };
    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiAddPageElement({...formData},idu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            resetFormData();
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM*/

    return (
        <>
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll ${slideIn ? "admiko-show" : ""}`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">

                        <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                            <div className="info">
                                <h2><i className={`${actionElementTab.icon}`}></i>{actionElementTab.header}</h2>
                                <p>{actionElementTab.info}</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-action-menu-body">
                            <div className={`admiko-action-menu-body-action add-menu active`}>
                                <div>
                                    <form action="">
                                        <div className="form-group">
                                            <label htmlFor="title">Title:</label>
                                            <div className="form-group-input">
                                                <input className="form-input" ref={inputFocus} autoComplete="off" name="title" id="title" placeholder="Enter title" value={formData.title} type="text" onChange={(e) => onChangeFormData(e)}/>
                                                <p className="ak-admiko-form-error">Required!</p>
                                            </div>
                                        </div>
                                        <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/*<div className="admiko-action-header">*/}
                        {/*    <div className="info">*/}
                        {/*        <h2><i className="fa-solid fa-circle-info"></i>Page information:</h2>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    );
}
