import React from 'react';
import {TabSortMenuItem} from "./TabSortMenuItem";

export function TabSortMenuFolder({item,ikey,moveUp,moveDown}) {

    return (
        <div className="ak-admiko-menu-item-folder js-sortable-folder" data-id={item.idu}>
            <TabSortMenuItem item={item} key={"F-"+ikey} detect={1} folder={"ak-admiko-menu-item-folder"} moveUp={moveUp} moveDown={moveDown}/>
            <div className="droppable-folder js-droppable-folder" style={{minHeight:'40px'}}>
                {(Array.isArray(item.child) && item.child.length > 0) ?
                    <>
                        {item.child.map(function (subItem) {
                            return (
                                <TabSortMenuItem key={subItem.idu} item={subItem} folder={item} moveUp={moveUp} moveDown={moveDown}/>
                            )
                        })}
                    </>
                    :
                    <></>
                }
                <div className="empty-folder js-empty-folder js-admiko-menu-item-detect" style={{display: Array.isArray(item.child) && item.child.length > 0 ? 'none' : 'block' }}>Drag & drop page.</div>
            </div>
        </div>
    );
}