import React, {useCallback, useEffect, useState} from "react";
import {useIsMountedRef} from "../../../Helper/isMounted";
import {makeRootHighlightPageContentParts, openRootToolBox, rootAllPageContent, rootBuilderLink, rootHighlightPageContentParts} from "../../../Helper/RootVar/Contstant";
import {DeleteSectionHeader} from "../Delete/DeleteSectionHeader";
import {DeleteSection} from "../Delete/DeleteSection";
import {ChildPage} from "./Table/ChildPage";
import {Settings} from "./Table/Settings";
import {Search} from "./Table/Search";
import {Pagination} from "./Table/Pagination";
import {Layout} from "./Table/Layout";
import {ElementInfo} from "../../Helpers/ElementInfo";
import {useNavigate, useParams} from "react-router-dom";
import {SystemFileInfo} from "../../Helpers/SystemFileInfo";
import {setRootBuilderDocuArea} from "../../../Global/AdmikoToolBox/BuilderDocumentation";

const TabListSettings = {
    layout: {title: "Layout", info: "Set your table layout.", icon: "fa-solid fa-table-columns", hasHighlight: true},
    pagination: {title: "Pagination & sorting", info: "Setup table pagination and sorting..", icon: "fa-solid fa-arrow-up-short-wide", hasHighlight: true},
    search: {title: "Search", info: "Setup table searching.", icon: "fa-solid fa-magnifying-glass", hasHighlight: true},
    settings: {title: "Settings", info: "Advanced table settings.", icon: "fa-solid fa-gears", hasHighlight: false},
    child_page: {title: "Add sub-page", info: "Add sub-page to a table.", icon: "fa-regular fa-object-group", hasHighlight: false},
};

export const TableStart = (props) => {

    const {url_menu_id = false, url_page_id = false, url_table_id = false, url_load_action = false} = useParams();
    const prependLink = `${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}`;
    const prependLinkLoad = `${prependLink}/t/${url_table_id}/l/`;

    let navigate = useNavigate();
    /*set props*/

    const isMountedRef = useIsMountedRef();

    const [tableData, setTableData] = useState(false);
    useEffect(() => {

        if (props.rootAllPageContent?.[url_table_id]?.table_settings) {
            if (props.rootAllPageContent?.[url_table_id]?.table_settings) {
                setTableData(props.rootAllPageContent[url_table_id].table_settings);
            } else {
                hideBack();
                setTableData(false);
            }
        } else {
            /*todo: else*/
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rootAllPageContent[url_table_id]?.table_settings])


    useEffect(() => {
        if(url_load_action){
            toggleActionMenuTab(url_load_action);
        } else {
            toggleActionMenuTab('main');
        }

        openRootToolBox();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_table_id,url_load_action])

    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        setRootBuilderDocuArea('table');
        setSlideIn(true);
    }, [])


    const hideBack = useCallback(() => {
        setSlideIn(false);
        if (!isMountedRef.current) {
            return false;
        }
        return navigate(prependLink);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[prependLink, isMountedRef])

    const goto = useCallback((action) => {
        navigate(`${prependLinkLoad}${action}`);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[prependLinkLoad])

    const deletedParentAction = useCallback((response) => {
        hideBack();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    /*TOGGLE ACTION*/

    const [actionMenuTab, setActionMenuTab] = useState('main');
    const [actionMenuTabTitle, setActionMenuTabTitle] = useState({title: "", info: ""});
    const toggleActionMenuTab = useCallback((page) => {
        if (TabListSettings.hasOwnProperty(page)) {
            setActionMenuTabTitle(TabListSettings[page]);
            if(TabListSettings[page].hasHighlight){
                makeRootHighlightPageContentParts(page);
            }
        } else {
            if(rootHighlightPageContentParts){
                makeRootHighlightPageContentParts(false);
            }
        }
        setActionMenuTab(page);
    },[]);

    return (
        <>
            <div className="admiko-table-editor">
                <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll admiko-show ${slideIn ? "" : ""}`}>
                    <div className="ak-admiko-add-scroll">
                        <div className="ak-admiko-add-scroll-fix">
                            <div className="admiko-action-header mouse-pointer" onClick={hideBack}>
                                <div className={`info ${tableData.type === "tf" && 'info-header'}`}>
                                    <h2><i className="fa-solid fa-table-list"></i>Table builder</h2>
                                    {/*<p>Set up your form.</p>*/}
                                </div>
                                <div className="close">
                                    <div className="admiko-toggle-tools">
                                        <div className="admiko-toggle-box">
                                            <div>
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" className="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {Object.keys(rootAllPageContent).length > 0 ? (<>
                                    {tableData.type === "tf" && <DeleteSectionHeader toggleActionMenuTab={(e) => toggleActionMenuTab(e)}/>}
                                    <div className="admiko-action-header mouse-pointer" onClick={(e) => goto('layout')} data-ak-area="layout">
                                        <div className="info">
                                            <h2><i className="fa-solid fa-table-columns"></i>Layout</h2>
                                            <p>Set your table layout.</p>
                                        </div>
                                        <div className="close">
                                            <div className="admiko-toggle-tools">
                                                <div className="admiko-toggle-box">
                                                    <div>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="admiko-action-header mouse-pointer" onClick={(e) => goto('pagination')} data-ak-area="pagination">
                                        <div className="info">
                                            <h2><i className="fa-solid fa-arrow-up-short-wide"></i>Pagination & sorting</h2>
                                            <p>Setup table pagination and sorting.</p>
                                        </div>
                                        <div className="close">
                                            <div className="admiko-toggle-tools">
                                                <div className="admiko-toggle-box">
                                                    <div>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="admiko-action-header mouse-pointer" onClick={(e) => goto('search')} data-ak-area="search">
                                        <div className="info">
                                            <h2><i className="fa-solid fa-magnifying-glass"></i>Search</h2>
                                            <p>Setup table searching.</p>
                                        </div>
                                        <div className="close">
                                            <div className="admiko-toggle-tools">
                                                <div className="admiko-toggle-box">
                                                    <div>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="admiko-action-header mouse-pointer" onClick={(e) => goto('settings')} data-ak-area="settings">
                                        <div className="info">
                                            <h2><i className="fa-solid fa-gears"></i>Settings</h2>
                                            <p>Advanced table settings.</p>
                                        </div>
                                        <div className="close">
                                            <div className="admiko-toggle-tools">
                                                <div className="admiko-toggle-box">
                                                    <div>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(tableData && tableData.settings.can_edit === 1) &&
                                    <div className="admiko-action-header mouse-pointer" onClick={(e) => goto('child_page')} data-ak-area="child_page">
                                        <div className="info">
                                            <h2><i className="fa-regular fa-object-group"></i>Add sub-page</h2>
                                            <p>Add sub-page to a table.</p>
                                        </div>
                                        <div className="close">
                                            <div className="admiko-toggle-tools">
                                                <div className="admiko-toggle-box">
                                                    <div>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {(tableData && tableData.settings.can_edit === 0) &&
                                        <SystemFileInfo/>
                                    }
                                    {tableData && tableData.type === "tf" &&
                                        <ElementInfo section={tableData.settings.info_details} header="Table information:"/>
                                    }
                                </>) :
                                (<>
                                    <div className="admiko-ak-loading">
                                        <i className="fa-solid fa-circle-notch"></i>
                                    </div>
                                </>)
                            }

                        </div>
                    </div>
                </div>
                {(actionMenuTab === "layout" && tableData) &&
                    <Layout title={actionMenuTabTitle} elements={tableData} form_idu={url_table_id}/>
                }
                {(actionMenuTab === "pagination" && tableData) &&
                    <Pagination title={actionMenuTabTitle} elements={tableData} form_idu={url_table_id}/>
                }
                {(actionMenuTab === "search" && tableData) &&
                    <Search title={actionMenuTabTitle} elements={tableData} form_idu={url_table_id}/>
                }
                {(actionMenuTab === "settings" && tableData) &&
                    <Settings title={actionMenuTabTitle} elements={tableData} form_idu={url_table_id}/>
                }
                {(actionMenuTab === "child_page" && tableData && tableData.settings.can_edit === 1) &&
                    <ChildPage title={actionMenuTabTitle} elements={tableData} form_idu={url_table_id}/>
                }
                {(actionMenuTab === "delete" && tableData) &&
                    <DeleteSection toggleActionMenuTab={toggleActionMenuTab} section={{"idu": url_table_id}} area="PageElement" deletedParentAction={deletedParentAction}/>
                }
            </div>
        </>

    );
}
