import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {apiGetNews} from "../../Api/useCallApi";
import {setRootPageInfoDirect} from "../../Global/PageInfo";
import {LoaderCircle} from "../../Helpers/LoaderCircle";
import moment from "moment";

export const News = () => {

    const [allNews, setAllNews] = useState(false);

    useEffect(() => {
        setRootPageInfoDirect({title: "News", subtitle: "", activeMenu: "news", breadcrumbs: [{link: "/news", title: "News"}]});
        (async () => {
            const response = await apiGetNews();
            if (response.success === true) {
                setAllNews(response.return);
            } else {
                /*todo: else*/
            }
        })()

    }, [])

    return (
        <div className="ak-app-news">
            {allNews ?
                <>
                    {allNews.map((item, i) => (
                        <div key={i} className="mb-12 grid max-w-3xl gap-5">
                            <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                    <div className="flex-1">
                                        <p className="text-sm font-medium text-blue-600">
                                            {item.small_title}
                                        </p>
                                        <div className="mt-2 block">
                                            <h2 className="text-xl font-semibold text-gray-900">{item.title}</h2>
                                            <div className="mt-3 text-base text-gray-500 news-content" dangerouslySetInnerHTML={{__html: item.content}}></div>
                                        </div>
                                    </div>
                                    <div className="mt-2 flex items-center">
                                        <div className="flex-shrink-0">
                                            <Link to="/contact">
                                                <img className="h-10 w-10 rounded-full" src="/images/dm.jpg" alt=""/>
                                            </Link>
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-sm font-medium text-gray-900">
                                                <Link to="/contact">Dragan Matic</Link>
                                            </p>
                                            <div className="flex space-x-1 text-sm text-gray-500">
                                                <time dateTime="2020-03-16">{moment(item.created).format('MMM DD, YYYY')}</time>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
                :
                <LoaderCircle/>
            }
        </div>
    )
};