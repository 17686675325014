import React, {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {rootBuilderLink, rootOpenLeftMenuContainer} from "../../Helper/RootVar/Contstant";

export const MenuLink = (props) => {
    /*set props*/
    const {url_menu_id = false} = useParams();
    const item = props.item;
    const setPage = props.setPage;
    const toggleContainer = props.toggleContainer;
    const activePage = url_menu_id;


    useEffect(() => {
        rootOpenLeftMenuContainer();
    }, [])


    switch (item.type) {
        case "page_crud":
            return (
                <div className={`ak-menu-item ${item.idu === activePage ? 'active' : ''}`}>
                    <Link to={`${rootBuilderLink}/m/${item.idu}/p/${item.idu}`} onClick={(e) => setPage(e, item.idu, item.type, 1, 0)} href="/" className="ak-menu-item-link">
                        <div className="ak-menu-item-icon">
                            <div dangerouslySetInnerHTML={{__html: (item.icon_custom ?? item.icon)}}></div>
                        </div>
                        <div className="ak-menu-item-title">{item.title}</div>
                    </Link>
                </div>
            );
        case "page_empty":
            return (
                <div className={`ak-menu-item ${item.idu === activePage ? 'active' : ''} ${item.first === 1 ? 'first-menu-page' : ''}`}>
                    <Link to={`${rootBuilderLink}/m/${item.idu}/p/${item.idu}`} draggable="false" onClick={(e) => setPage(e, item.idu, item.type, 1, 0)} className="ak-menu-item-link">
                        <div className="ak-menu-item-icon">
                            <div dangerouslySetInnerHTML={{__html: (item.icon_custom ?? item.icon)}}></div>
                        </div>
                        <div className="ak-menu-item-title">{item.title}</div>
                    </Link>
                </div>
            );
        case "menu_link":
            return (
                <div className="ak-admiko-menu-item-link">
                    <div className={`ak-menu-item ${item.idu === activePage ? 'active' : ''}`} onClick={(e) => setPage(e, item.idu, item.type, 0, 1)}>
                        <div className="ak-admiko-menu-edit"><i className="fa-solid fa-gear"></i></div>
                        <a href="/" className="ak-menu-item-link">
                            <div className="ak-menu-item-icon">
                                <div dangerouslySetInnerHTML={{__html: (item.icon_custom ?? item.icon)}}></div>
                            </div>
                            <div className="ak-menu-item-title">{item.title}</div>
                        </a>
                    </div>
                </div>
            );
        case "menu_header":
            return (
                <div className="ak-menu-header-container mouse-pointer" onClick={(e) => setPage(e, item.idu, item.type, 0, 1)}>
                    <div className="ak-admiko-menu-item-link">
                        <div className="ak-admiko-menu-edit"><i className="fa-solid fa-gear"></i></div>
                    </div>
                    <div className="ak-menu-header">
                        <div className="ak-menu-header-title">{item.title}</div>
                        <div className="ak-menu-header-sub-title">{item.desc}</div>
                    </div>
                </div>
            );
        case "container":
            return (
                <div className={`ak-menu-dropdown`}>
                    <div className="ak-menu-item">
                        <div className="ak-admiko-menu-edit" onClick={(e) => setPage(e, item.idu, item.type, 0, 1)}><i className="fa-solid fa-gear"></i></div>
                        <a href="/" className="ak-menu-item-link js-admiko-menu-dropdown-click" onClick={(e) => toggleContainer(e)}>
                            <div className="ak-menu-item-icon">
                                <div dangerouslySetInnerHTML={{__html: (item.icon_custom ?? item.icon)}}></div>
                            </div>
                            <div className="ak-menu-item-title">{item.title}</div>
                            <div className="ak-menu-dropdown-icon">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                </svg>
                            </div>
                        </a>
                    </div>
                    <div className={`ak-menu-dropdown-container ${item.idu === activePage ? 'active' : ''}`}>
                        {(item.child && item.child.length > 0) ?
                            <>
                                {item.child.map(function (subItem) {
                                    return (
                                        <MenuLink key={subItem.idu} item={subItem} setPage={setPage} toggleContainer={toggleContainer}/>
                                    )
                                })}
                            </>
                            :
                            <></>
                        }
                    </div>
                </div>
            );
        default:
            return (<></>);
    }


}