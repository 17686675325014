import React, {createContext, useContext, useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useLocalStorage} from "./useLocalStorage";
import {updateAppRootUser} from "../Global/User";

const AuthContext = createContext(undefined);

export const AuthProvider = ({children}) => {
    const [user, setUser] = useLocalStorage("user", null);
    const navigate = useNavigate();
    let location = useLocation();
    // eslint-disable-next-line
    const login = async (token) => {
        setUser({"token":token});
        navigate(location, {replace: true});
    };
    // // const updateUser = async (token) => {
    // //     setUser({"token":token});
    // // };

    // eslint-disable-next-line
    const logout = () => {
        updateAppRootUser({name:"",email:""});
        setUser(null);
        navigate("/login", {replace: true});
    };


    // const login = useCallback(async (token) => {
    //     setUser({"token":token});
    //     navigate(location, {replace: true});
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // const logout = useCallback(() => {
    //     setUser(null);
    //     navigate("/login", {replace: true});
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);


    const value = useMemo(
        () => ({
                user,
                login,
                logout
            }
        ),
        [user,login, logout]
    );

    // [user, login, logout, updateUser, logoutTest]
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};