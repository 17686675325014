import React, {useEffect, useState} from "react";
import {useIsMountedRef} from "../../../../Helper/isMounted";
import {FormSubmitButton} from "../../../Helpers/FormSubmitButton";
import {FormCloseButton} from "../../../Helpers/FormCloseButton";
import {LayoutChildPages} from "./LayoutChildPages";
import {apiChangeTableLayout} from "../../../../Api/useTable";
import {rootSetError} from "../../../../Helper/RootVar/Error/Error";
import {onChangeFormDataGlobal} from "../../../../Helper/Functions";
import {LayoutTableElements} from "./Partials/LayoutTableElements";
import {useNavigate, useParams} from "react-router-dom";
import {rootBuilderLink} from "../../../../Helper/RootVar/Contstant";

/*ADD new elements to a FORM*/
export const Layout = (props) => {

    const {url_menu_id = false, url_page_id = false, url_table_id = false} = useParams();
    const tableLinkLoad = `${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}/t/${url_table_id}`;
    let navigate = useNavigate();
    /*set props*/
    const form_idu = props.form_idu;

    const elements = props.elements.layout;
    const tableLayout = props.elements.table_layout;
    const title = props.title;

    const isMountedRef = useIsMountedRef();

    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (!isMountedRef.current) {
                return false;
            }
            setSlideIn(true);
        }, 1);
        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const unsubscribe = () => {
        setSlideIn(false);
    }
    const hideBack = () => {
        navigate(`${tableLinkLoad}`);
    }

    /*FORM LAYOUT*/
    const [submitButton, setSubmitButton] = useState("ready");

    const formFields = {layout: tableLayout, image_idu: props.elements.table_layout_image_idu};
    const formFieldsRules = {
        layout: {required: false, type: "select"}
    };
    const [formData, setFormData] = useState(formFields);

    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };
    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiChangeTableLayout({...formData}, form_idu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM ELEMENTS*/

    const closeForm = () => {
        hideBack();
    };

    /*end form js*/
    return (
        <>
            <div className={`admiko-action admiko-action-menu ak-admiko-pre-scroll ${slideIn ? "admiko-show" : ""}`}>
                <div className="ak-admiko-add-scroll">
                    <div className="ak-admiko-add-scroll-fix">
                        <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                            <div className="info">
                                <h2><i className={`${title.icon}`}></i>{title.title}</h2>
                                <p>{title.info}</p>
                            </div>
                            <div className="close">
                                <div className="admiko-toggle-tools">
                                    <div className="admiko-toggle-box">
                                        <div>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="admiko-action-menu-body">
                            <div className={`admiko-action-menu-body-action`}>
                                <form action="">
                                    <div className="form-group ak-group-element-settings-edit-input">
                                        <label htmlFor={`layout_table-${form_idu}`}>Layout:</label>
                                        <div className="form-group-input">
                                            <select value={formData.layout} className="form-select" name="layout" id={`layout_table-${form_idu}`} onChange={(e) => onChangeFormData(e)}>
                                                <option value="table">Table</option>
                                                <option value="table_gallery">Gallery</option>
                                                <option value="table_cards">Cards</option>
                                                {/*<option value="table_people">People</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    {(formData.layout === "table_gallery" || formData.layout === "table_cards") &&
                                        <>
                                            <div className="form-group ak-group-element-settings-edit-input">
                                                <label htmlFor={`layout_image-${form_idu}`}>Image source:</label>
                                                {props.elements.table_layout_images_source.length > 0 ?
                                                    <div className="form-group-input">
                                                        <select value={formData.image_idu} className="form-select" name="image_idu" id={`layout_image-${form_idu}`} onChange={(e) => onChangeFormData(e)}>
                                                            <option value="none">Select source</option>
                                                            {props.elements.table_layout_images_source.map((element, index) => (
                                                                <option key={"img-src-" + index} value={element.idu}>{element.title}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    :
                                                    <>
                                                        <label>Add image element to the form.</label>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                    <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
                                </form>
                                <LayoutTableElements form_idu={form_idu} elements={elements} layout={formData.layout}/>

                                <LayoutChildPages elements={props.elements} form_idu={form_idu}/>
                                <FormCloseButton closeForm={(e) => closeForm(e)}/>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}



