import React from "react";

export const FormFileType = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="file_type">File type:</label>
            <div className="form-group-input">
                <input className="form-input" autoComplete="off" name="file_type" id="file_type" placeholder="ex: .jpg,.png,.jpeg" value={formData.file_type} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
            </div>
        </div>
    );

}