import React from "react";
import {ElementInfo} from "../../../../Helpers/ElementInfo";
import {useParams} from "react-router-dom";
import {rootAllPages} from "../../../../../Helper/RootVar/Contstant";
import {IconsSectionHeader} from "../../../../Icons/IconsSectionHeader";
import {DeleteSectionHeader} from "../../../Delete/DeleteSectionHeader";

/*ADD new elements to a FORM*/
export const SingleFormPageHeaders = (props) => {

    const {url_page_id = false} = useParams();

    const toggleActionMenuTab = props.toggleActionMenuTab;

    return (
        <>
            <div className="admiko-action-header mouse-pointer" onClick={(e) => toggleActionMenuTab('form_settings', "Form Settings", "Form configuration.", "fa-solid fa-gears")}>
                <div className="info">
                    <h2><i className="fa-solid fa-gears"></i>Page settings</h2>
                    <p>Edit page properties.</p>
                </div>
                <div className="close">
                    <div className="admiko-toggle-tools">
                        <div className="admiko-toggle-box">
                            <div>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" className="svg-inline--fa fa-angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <IconsSectionHeader toggleActionMenuTab={(e) => toggleActionMenuTab(e)}/>
            {(rootAllPages[url_page_id].page.can_delete === 1) &&
                <DeleteSectionHeader toggleActionMenuTab={(e) => toggleActionMenuTab(e)}/>
            }
            <ElementInfo section={rootAllPages[url_page_id].page.info_details} header="Page information:"/>
        </>
    );
}
