import React from "react";
import {appRootPageSubtitle, appRootPageTitle} from "../../Global/PageInfo";

export const PageHeader = () => {

    return (
        <>
            <div className="mobile-only">
                            <span>
                                <i className="fa-solid fa-triangle-exclamation"></i>
                                Builder is not optimized for small screens. For the best experience, please use a desktop or laptop computer.
                            </span>
            </div>
            <div className="w-full mt-3 mb-3">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                    {appRootPageTitle}
                </h2>
                <div className="flex text-sm font-medium text-gray-500">
                    {appRootPageSubtitle}
                </div>
            </div>
        </>
    )
};