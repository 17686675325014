import React from "react";
import {Link} from "react-router-dom";
import {rootBuilderLink} from "../../../Helper/RootVar/Contstant";

export function ToolBoxEditButtons(props) {
    const table_btn = props.table_btn;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const folder_idu = props.folder_idu??false;

    const url_menu_id = props.url_menu_id;
    const url_page_id = props.url_page_id;
    const url_table_id = props.url_table_id;
    const table_id = props.table_id;

    const prependLink = `${rootBuilderLink}/m/${url_menu_id}/p/${url_page_id}`;

    return (
        <div className="admiko-tf-settings">
            {table_btn === 1 &&
                <div className={`mouse-pointer table-btn-edit ${(url_table_id && url_table_id === table_id) && "selected"}`}>
                    <Link to={`${prependLink}${(url_table_id && url_table_id===table_id) ? ``:`/w/${table_id}`}`} draggable="false"><i className="fa-solid fa-gear"></i></Link>
                </div>
            }
            {(totalItems > 1 || props.folder_idu !== false) &&
                <div className="move-section table-btn-move js-admiko-sort-me">
                    {(position > 0 || folder_idu !== false) &&
                        <div className="move-up mouse-pointer js-admiko-move-me-up" onClick={(e) => sortMe.SortMoveUp(e)}><i className="fa-solid fa-chevron-up"></i></div>
                    }
                    <div className="mouse-move js-admiko-move-me"><i className="fa-solid fa-up-down-left-right"></i></div>
                    {(position+1 < totalItems || folder_idu !== false) &&
                        <div className="move-down mouse-pointer js-admiko-move-me-down" onClick={(e)=> sortMe.SortMoveDown(e)}><i className="fa-solid fa-chevron-down"></i></div>
                    }
                </div>
            }

        </div>
    );
}