import React from "react";

export const FormUploadFolder = (props) => {
    /*set props*/
    const formData = props.formData;
    const onChangeFormDataInput = props.onChangeFormDataInput;

    return (
        <div className="form-group">
            <label htmlFor="upload_folder">Upload folder:</label>
            <div className="form-group-input">
                <input className="form-input" autoComplete="off" name="upload_folder" id="upload_folder" placeholder="Upload folder" value={formData.upload_folder} type="text" onChange={(e) => onChangeFormDataInput(e)}/>
                <p className="ak-admiko-form-error">Required!</p>
            </div>
        </div>
    );

}