import {fetchData} from "./useCallApi";

export const apiSortTableLayoutElements = async (dataToSend,table_form_idu) => {
    const dataFetch = {action:"sort_table_layout_elements",dataPrepare:dataToSend,"table_form_idu":table_form_idu,use_cancel:true};
    return await fetchData(dataFetch);
}

export const apiVisibleTableLayoutElements = async (dataToSend,table_form_idu) => {
    const dataFetch = {action:"save_table_layout_element",dataPrepare:dataToSend,"table_form_idu":table_form_idu};
    return await fetchData(dataFetch);
}
/*change table layout*/
export const apiChangeTableLayout = async (dataToSend,table_form_idu) => {
    const dataFetch = {action:"change_table_layout",dataPrepare:dataToSend,"table_form_idu":table_form_idu};
    return await fetchData(dataFetch);
}

/*change table pagination*/
export const apiUpdateTablePagination = async (dataToSend,table_form_idu) => {
    const dataFetch = {action:"update_table_pagination",dataPrepare:dataToSend,"table_form_idu":table_form_idu};
    return await fetchData(dataFetch);
}
/*change table search*/
export const apiUpdateTableSearch = async (dataToSend,table_form_idu) => {
    const dataFetch = {action:"update_table_search",dataPrepare:dataToSend,"table_form_idu":table_form_idu};
    return await fetchData(dataFetch);
}
/*change table settings*/
export const apiEditTableSettings = async (dataToSend,sec_id) => {

    const dataFetch = {action:"update_table_settings",dataPrepare:dataToSend,"sec_id":sec_id};
    return await fetchData(dataFetch);
}
export const apiEditFormSettings = async (dataToSend,sec_id) => {

    const dataFetch = {action:"update_form_settings",dataPrepare:dataToSend,"sec_id":sec_id};
    return await fetchData(dataFetch);
}

export const apiAddChildPage = async (dataToSend) => {

    const dataFetch = {action:"page_add",dataPrepare:dataToSend};
    // const dataFetch = {action:"page_add",dataPrepare:dataToSend,"sec_id":sec_id};
    return await fetchData(dataFetch);

}