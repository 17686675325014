import { useState } from 'react';

export let appRootFrameworks = [];
export let setAppRootFrameworks;

export const useAppRootFramework = () => {
    [appRootFrameworks, setAppRootFrameworks] = useState(false);
}
export const setAppRootFrameworksDirect = (data) => {
    setAppRootFrameworks(data);
};