import React, {useEffect} from "react";
import {Link, Route, useLocation} from "react-router-dom";
import {LogoutLink} from "../Login/Logout";
import {appRootActiveMenu} from "../Global/PageInfo";
import {glOpenLeftMenuContainer} from "../Global/Projects";
import {rootLoggedUser} from "../Global/User";
import {Statements} from "./Plans/Statements";

export const Menu = () => {
    const toggleContainer = (e) => {
        e.preventDefault();
        if (e.target.closest(".ak-menu-dropdown").classList.contains('active')) {
            e.target.closest(".ak-menu-dropdown").querySelector('.ak-menu-dropdown-container').style["height"] = '0px';
        } else {
            let totalHeight = e.target.closest(".ak-menu-dropdown").querySelector('.ak-menu-dropdown-container').scrollHeight
            e.target.closest(".ak-menu-dropdown").querySelector('.ak-menu-dropdown-container').style["height"] = totalHeight + 'px';
        }
        e.target.closest(".ak-menu-dropdown").classList.toggle('active');
    }
    const location = useLocation();

    useEffect(() => {
        glOpenLeftMenuContainer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <div className="ak-menu-header-container">
                <div className="ak-menu-header">
                    <span className="ak-menu-header-title">MENU</span>
                    {/*<span className="ak-menu-header-sub-title">Build something awesome!</span>*/}
                </div>
            </div>
            <div className={`ak-menu-item ${appRootActiveMenu === "projects" ? 'active' : ''}`}>
                <Link to="/" draggable="false" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-gears"></i></span> <span className="ak-menu-item-title">My Projects</span></Link>
            </div>
            <div className={`ak-menu-item ${location.pathname === "/news" ? 'active' : ''}`}>
                <Link to="/news" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-bullhorn"></i></span> <span className="ak-menu-item-title">News</span></Link>
            </div>
            <div className={`ak-menu-dropdown`}>
                <div className="ak-menu-item">
                    <a href="/" draggable="false" className={`ak-menu-item-link ${(location.pathname === "/clients" || location.pathname === "/account" || location.pathname === "/global-settings" || location.pathname === "/user" || location.pathname === "/plans" || location.pathname === "/statements") ? 'active' : ''}`} onClick={(e) => toggleContainer(e)}>
                        <span className="ak-menu-item-icon"><i className="fa-solid fa-sliders"></i></span> <span className="ak-menu-item-title">Settings</span>
                        <span className="ak-menu-dropdown-icon"><i className="fa-solid fa-angle-down"></i></span>
                    </a>
                </div>
                <div className="ak-menu-dropdown-container">
                    <div className={`ak-menu-item ${appRootActiveMenu === "clients" ? 'active' : ''}`}>
                        <Link to="/clients" draggable="false" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-user-group"></i></span> <span className="ak-menu-item-title">Clients</span></Link>
                    </div>
                    {/*<div className={`ak-menu-item ${location.pathname === "/users" ? 'active' : ''}`}>*/}
                    {/*    <Link to="/users" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-users"></i></span> <span className="ak-menu-item-title">Users</span></Link>*/}
                    {/*</div>*/}
                    <div className={`ak-menu-item ${appRootActiveMenu === "global-settings" ? 'active' : ''}`}>
                        <Link to="/global-settings" draggable="false" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-cog"></i></span> <span className="ak-menu-item-title">Global settings</span></Link>
                    </div>
                    <div className={`ak-menu-item ${location.pathname === "/plans" ? 'active' : ''}`}>
                        <Link to="/plans" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-medal"></i></span> <span className="ak-menu-item-title">My Plan</span></Link>
                    </div>
                    <div className={`ak-menu-item ${location.pathname === "/statements" ? 'active' : ''}`}>
                        <Link to="/statements" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-file-invoice-dollar"></i></span> <span className="ak-menu-item-title">Statements</span></Link>
                    </div>
                    {rootLoggedUser.refer === 1 &&
                        <div className={`ak-menu-item ${location.pathname === "/affiliate" ? 'active' : ''}`}>
                            <Link to="/affiliate" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-hand-holding-dollar"></i></span> <span className="ak-menu-item-title">Affiliate</span></Link>
                        </div>
                    }
                    <div className={`ak-menu-item ${appRootActiveMenu === "account" ? 'active' : ''}`}>
                        <Link to="/account" draggable="false" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-user"></i></span> <span className="ak-menu-item-title">My Account</span></Link>
                    </div>
                </div>
            </div>
            <div className={`ak-menu-item ${appRootActiveMenu === "contact" ? 'active' : ''}`}>
                <Link to="/contact" draggable="false" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-envelope"></i></span> <span className="ak-menu-item-title">Contact us</span></Link>
            </div>
            <div className={`ak-menu-item ${location.pathname === "/documentation" ? 'active' : ''}`}>
                <Link to="https://admiko.com/docs/" target="_blank" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-book"></i></span> <span className="ak-menu-item-title">Documentation</span></Link>
            </div>
            {/*<div className="ak-menu-dropdown">*/}
            {/*    <div className="ak-menu-item">*/}
            {/*        <a href="" className={`ak-menu-item-link ${(location.pathname === "/contact") ? 'active' : ''}`} onClick={(e) => toggleContainer(e)}><span className="ak-menu-item-icon"><i className="fa-solid fa-circle-info"></i></span> <span className="ak-menu-item-title">Support</span>*/}
            {/*            <span className="ak-menu-dropdown-icon">*/}
            {/*                    <i className="fa-solid fa-angle-down"></i>*/}
            {/*                </span>*/}
            {/*        </a>*/}
            {/*    </div>*/}
            {/*    <div className="ak-menu-dropdown-container">*/}
            {/*        <div className={`ak-menu-item ${location.pathname === "/documentation" ? 'active' : ''}`}>*/}
            {/*            <Link to="/documentation" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-book"></i></span> <span className="ak-menu-item-title">Documentation</span></Link>*/}
            {/*        </div>*/}
            {/*        <div className={`ak-menu-item ${location.pathname === "/video" ? 'active' : ''}`}>*/}
            {/*            <Link to="/video" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-video"></i></span> <span className="ak-menu-item-title">Tutorials</span></Link>*/}
            {/*        </div>*/}
            {/*        <div className={`ak-menu-item ${location.pathname === "/contact" ? 'active' : ''}`}>*/}
            {/*            <Link to="/contact" className="ak-menu-item-link"><span className="ak-menu-item-icon"><i className="fa-solid fa-envelope"></i></span> <span className="ak-menu-item-title">Contact us</span></Link>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="ak-menu-item">
                <LogoutLink/>
            </div>
        </>
    )
};