import React from 'react';
export const ParentChild = (props) => {
    const item = props.item;

    const doNothing = (e) => {
        e.preventDefault();
    };

    if(item.settings.table_icon === "icon_title"){
        return (
            <>
                <a href="/" onClick={(e) => doNothing(e)}><i className="fa-solid fa-bars-staggered"></i> {item.title}</a>
            </>
        );
    } else if(item.settings.table_icon === "title"){
        return (
            <>
                <a href="/" onClick={(e) => doNothing(e)}>{item.title}</a>
            </>
        );
    } else {
        return (
            <>
                <a href="/" onClick={(e) => doNothing(e)}><i className="fa-solid fa-bars-staggered"></i></a>

            </>
        );
    }


}