
export const CheckFormOnChange = (e, formFieldsRules, submitButton="", setSubmitButton="") => {
    let key = e.target.name;
    let returnValue;
    if (formFieldsRules.hasOwnProperty(key)) {
        if (formFieldsRules[e.target.name].type === "checkbox") {
            if (e.target.checked) {
                returnValue = formFieldsRules[e.target.name].rules.checked;
            } else {
                returnValue = formFieldsRules[e.target.name].rules.unchecked;
            }
        } else if (formFieldsRules[e.target.name].type === "select") {
            returnValue = e.target.value;
        } else if (formFieldsRules[e.target.name].type === "email") {
            returnValue = e.target.value;
        } else if (formFieldsRules[e.target.name].type === "input") {
            returnValue = e.target.value;
        } else if (formFieldsRules[e.target.name].type === "numbers") {
            returnValue = e.target.value;
        } else if (formFieldsRules[e.target.name].type === "array") {
            returnValue = e.target.value;
        }
        return returnValue;
    }
}
export const CheckFormOnSubmit = (formData, fieldError, setFieldError, formFieldsRules, submitButton, setSubmitButton) => {
    let errors = {...fieldError};
    let submitError = false;
    Object.keys(fieldError.error).map(function (key) {
        if (formFieldsRules.hasOwnProperty(key)) {
            if (formFieldsRules[key].required === true) {
                if (formFieldsRules[key].rules.hasOwnProperty("minLength")) {
                    if (formFieldsRules[key].rules.minLength > 0) {
                        if (formData.fields[key].length < formFieldsRules[key].rules.minLength) {
                            errors.error[key] = true;
                        } else {
                            errors.error[key] = false;
                        }
                    }
                }
            }
            if (formFieldsRules[key].rules.allowedInput === "email") {
                if(/\S+@\S+\.\S+/.test(formData.fields[key])){
                    errors.error[key] = false;
                } else {
                    errors.error[key] = true;
                }
            }
            if(errors.error[key] === true){
                submitError = true;
            }
        }
        return false;
    })
    setFieldError(errors);
    return submitError;
}
