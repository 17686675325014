import React, {useEffect, useState} from "react";
import {setRootPageInfoDirect} from "../../Global/PageInfo";
import {apiGetPlanLink, apiGetPlans} from "../../Api/useCallApi";
import {Link, useParams} from "react-router-dom";
import {appPlansData, appUserPlanData, setAppPlansData, setAppUserPlanData} from "../../Global/User";


export const Plans = () => {
    const {thank_you = ""} = useParams();
    const [thankYou] = useState(thank_you);
    const [progressButton, setProgressButton] = useState(false);

    function getLinkClick(uid) {
        setProgressButton(uid);
        (async () => {
            const response = await apiGetPlanLink({'uid': uid});
            if (response.success === true) {

                window.location.href = response.return.plan_link;
            } else {
                /*todo: else*/
                setProgressButton(false);
            }

        })()
    }

    useEffect(() => {
        setRootPageInfoDirect({title: "Plans", subtitle: "Select a plan", activeMenu: "plans", breadcrumbs: [{link: "/", title: "Plans"}]});
        (async () => {
            const response = await apiGetPlans();
            if (response.success === true) {
                setAppPlansData(response.return.plans_data);
                setAppUserPlanData(response.return.user_plan);
            } else {
                /*todo: else*/
            }
        })()

        if (thankYou === 'thank_you') {
            window.dataLayer.push({
                event: 'thank_you',
                eventProps: {
                    action: 'purchase',
                    value: 40
                }
            });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // setRootPageInfoDirect({title: "Plans", subtitle: "Select a plan.", activeMenu: "plans", breadcrumbs: [{link: "/plans", title: "Plans"}]})
    //
    // useEffect(() => {
    //
    //     (async () => {
    //         const response = await apiGetPlans();
    //         if (response.success === true) {
    //             setAppPlansData(response.return);
    //         } else {
    //             /*todo: else*/
    //         }
    //     })()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])


    return <>
        {thankYou === 'thank_you' &&
            <>
                <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">

                            <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"/>
                                        </svg>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">Payment successful</h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Note that payment processing can delay the activation of your subscription for up to 10 minutes. Thank you for your patience.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <Link to="/" type="button" className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-700 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 sm:text-sm">Go back to dashboard</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        {appPlansData !== false ? (
                <>
                    <div className="bg-white shadow max-w-6xl rounded-md">
                        <div className="mx-auto py-6 px-0 sm:px-6">
                            {appUserPlanData.plan !== "Free" ?
                                <>
                                    {appUserPlanData.expired === 0 ?
                                        <>
                                            <div className="rounded-md bg-green-50 p-4 mb-6">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd"/>
                                                        </svg>
                                                    </div>
                                                    <div className="ml-3">
                                                        <h3 className="text-sm font-medium text-green-800">{appUserPlanData.plan} Plan</h3>
                                                        <div className="mt-2 text-sm text-green-700">
                                                            <p>Your subscription expires on {appUserPlanData.expire}.</p>
                                                            <p>Project usage: {appUserPlanData.projects_using}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="rounded-md bg-yellow-50 p-4 mb-6">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"/>
                                                        </svg>
                                                    </div>
                                                    <div className="ml-3">
                                                        <h3 className="text-sm font-medium text-yellow-800">Subscription expired</h3>
                                                        <div className="mt-2 text-sm text-yellow-700">
                                                            <p>Your subscription has expired on {appUserPlanData.expire}. Please choose a subscription plan to continue using Admiko.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <div className="block mb-6">
                                    </div>
                                </>
                            }


                            <div className="block">
                                {/*<div className="flex justify-center mb-10">*/}
                                {/*    <span className="inline-flex items-center font-medium rounded-md bg-red-50 px-2.5 py-1 text-lg text-red-600 border border-dashed border-red-400">*/}
                                {/*        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-3">*/}
                                {/*          <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />*/}
                                {/*        </svg>*/}
                                {/*        Lifetime! BETA access discount 50%*/}
                                {/*    </span>*/}
                                {/*</div>*/}
                                <div className={`text-center text-xl font-medium pb-16 px-5 max-w-xl m-auto`}>
                                    Choose your plan and unlock additional features.
                                </div>
                                <table className="h-px w-full table-fixed">
                                    <thead>
                                    <tr>
                                        <th className="w-1/5 xl:w-1/4 px-6 pb-4 text-left text-sm font-medium text-gray-900 hidden lg:table-cell">
                                            <span>Plans</span>
                                        </th>
                                        <th className="w-1/3 xl:w-1/4 px-6 pb-4 text-left text-xl font-bold leading-6 text-gray-900" scope="col">PRO</th>
                                        <th className="w-1/3 xl:w-1/4 px-6 pb-4 text-left text-xl font-bold leading-6 text-gray-900" scope="col">GURU</th>
                                        <th className="w-1/3 xl:w-1/4 px-6 pb-4 text-left text-xl font-bold leading-6 text-gray-900" scope="col">BUSINESS</th>
                                    </tr>
                                    </thead>

                                    <tbody className="divide-y divide-gray-200 border-t border-gray-200">
                                    <tr>
                                        <th className="py-8 px-6 text-left align-top text-sm font-medium text-gray-900 hidden lg:table-cell" scope="row">Price per year</th>
                                        {appPlansData.plans.map((item, i) => (
                                            <>
                                                {item.months === 12 &&
                                                    <td key={i} className="h-full py-8 px-6 align-top">
                                                        <div className="relative table h-full">
                                                            <p>
                                                                {/*{item.full_price > 0 &&*/}
                                                                {/*    <span className="text-base font-bold tracking-tight text-red-500 line-through block">${item.full_price}/year</span>*/}
                                                                {/*}*/}
                                                                <span className="text-2xl md:text-2xl font-bold tracking-tight text-gray-900">${item.price}</span>
                                                                <span className="text-base font-medium text-gray-500">/year</span>
                                                            </p>
                                                            <p className="mt-4 mb-16 text-sm text-gray-500">
                                                                {item.description}
                                                            </p>
                                                            <button onClick={() => getLinkClick(item.uid)} disabled={(!appPlansData.plans_active.includes(item.uid) && appUserPlanData.expired === 0)} className="flex justify-center items-center disabled:bg-gray-100 disabled:text-gray-300 disabled:border-gray-200 disabled:ring-0 disabled:ring-offset-0 absolute bottom-0 w-full flex-grow rounded-md border border-gray-900 bg-gray-900 py-2.5 text-center text-sm font-semibold text-white hover:bg-gray-900 hover:ring hover:ring-gray-900 hover:ring-offset-2 transition-all">
                                                                <span className={`subscription-progress animate-spin ${progressButton === item.uid ? 'block' : 'hidden'}`}>
                                                                    <i className="fa-solid fa-circle-notch"></i>
                                                                </span>
                                                                <span className="pl-1.5">
                                                                    {(appUserPlanData.expired === 0 && appUserPlanData.plan !== "Free") ? <>Upgrade to</> : <>Buy</>} {item.title}
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                }
                                            </>
                                        ))}
                                    </tr>
                                    <tr>
                                        <th className="py-8 px-6 text-left align-top text-sm font-medium text-gray-900 hidden lg:table-cell" scope="row">Price per month</th>
                                        {appPlansData.plans.map((item, i) => (
                                            <>
                                                {item.months === 1 &&
                                                    <td key={i} className="h-full py-8 px-6 align-top">

                                                        <div className="relative h-full">
                                                            <p className="pb-4">
                                                                <span className="text-2xl md:text-2xl font-bold tracking-tight text-gray-900">${item.price}</span>
                                                                <span className="text-base font-medium text-gray-500">/month</span>
                                                            </p>
                                                            <button onClick={() => getLinkClick(item.uid)} disabled={(!appPlansData.plans_active.includes(item.uid) && appUserPlanData.expired === 0)} className="flex justify-center items-center disabled:bg-gray-100 disabled:text-gray-300 disabled:border-gray-200 disabled:ring-0 disabled:ring-offset-0 w-full flex-grow rounded-md border border-gray-900 bg-gray-900 py-2.5 text-center text-sm font-semibold text-white hover:bg-gray-900 hover:ring hover:ring-gray-900 hover:ring-offset-2 transition-all">
                                                                <span className={`subscription-progress animate-spin ${progressButton === item.uid ? 'block' : 'hidden'}`}>
                                                                    <i className="fa-solid fa-circle-notch"></i>
                                                                </span>
                                                                <span className="pl-1.5">
                                                                    {(appUserPlanData.expired === 0 && appUserPlanData.plan !== "Free") ? <>Upgrade to</> : <>Buy</>} {item.title}
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                }
                                            </>
                                        ))}
                                    </tr>
                                    </tbody>
                                </table>
                                {appPlansData.plans_compare ?
                                    <>
                                        <div className="w-full px-6 pb-4 text-left text-lg font-medium leading-6 text-gray-900 mt-20 mb-5">Compare plans:</div>
                                        <table className="h-px w-full table-fixed">
                                            <thead>
                                            <tr>
                                                <th className="w-1/5 xl:w-1/4 px-6 pb-4 text-left text-sm font-medium text-gray-900" scope="col">
                                                    <span>Plans</span>
                                                </th>
                                                <th className="w-1/4 px-6 pb-4 font-medium leading-6 text-gray-900 text-center" scope="col">Free</th>
                                                <th className="w-1/4 px-6 pb-4 text-xl font-semibold leading-6 text-gray-900 text-center" scope="col">PRO</th>
                                                <th className="w-1/4 px-6 pb-4 text-xl font-semibold leading-6 text-gray-900 text-center" scope="col">GURU</th>
                                                <th className="w-1/4 px-6 pb-4 text-xl font-semibold leading-6 text-gray-900 text-center" scope="col">BUSINESS</th>
                                            </tr>
                                            </thead>

                                            <tbody className="divide-y divide-gray-200 border-t border-gray-200">
                                            <tr>
                                                <th className="py-8 px-6 text-left text-sm font-medium text-gray-900" scope="row">Price</th>
                                                <td className="h-full py-8 px-6 align-middle text-center">
                                                    <p>
                                                        <span className="text-xl font-bold tracking-tight text-gray-900">$0</span>
                                                        <span className="block md:inline text-sm md:text-base font-medium text-gray-500">/year</span>
                                                    </p>
                                                </td>
                                                {appPlansData.plans.map((item, i) => (
                                                    <>
                                                        {item.months === 12 &&
                                                            <td key={i} className="h-full py-8 px-6 align-middle text-center">
                                                                <div>
                                                                    <span className="text-xl font-bold tracking-tight text-gray-900">${item.price}</span>
                                                                    <span className="block md:inline text-xs md:text-base font-medium text-gray-500">/year</span>
                                                                </div>
                                                            </td>
                                                        }
                                                    </>
                                                ))}
                                            </tr>
                                            {/*<tr>*/}
                                            {/*    <th className="py-8 px-6 text-left text-sm font-medium text-gray-900" scope="row">Price</th>*/}
                                            {/*    <td className="h-full py-8 px-6 align-middle text-center">*/}
                                            {/*        <p>*/}
                                            {/*            <span className="text-xl font-bold tracking-tight text-gray-900">$0</span>*/}
                                            {/*            <span className="block md:inline text-sm md:text-base font-medium text-gray-500">/year</span>*/}
                                            {/*        </p>*/}
                                            {/*    </td>*/}
                                            {/*    {appPlansData.plans.map((item, i) => (*/}
                                            {/*        <>*/}
                                            {/*            {item.months === 1 &&*/}
                                            {/*                <td key={i} className="h-full py-8 px-6 align-middle text-center">*/}
                                            {/*                    <div>*/}
                                            {/*                        <span className="text-xl font-bold tracking-tight text-gray-900">${item.price}</span>*/}
                                            {/*                        <span className="block md:inline text-xs md:text-base font-medium text-gray-500">/year</span>*/}
                                            {/*                    </div>*/}
                                            {/*                </td>*/}
                                            {/*            }*/}
                                            {/*        </>*/}
                                            {/*    ))}*/}
                                            {/*</tr>*/}
                                            <tr>
                                                <th className="py-8 px-6 text-left text-sm font-medium text-gray-900" scope="row">Projects</th>
                                                <td className="h-full py-8 px-6 align-top text-center text-xl font-bold text-gray-600">
                                                    2
                                                </td>
                                                {appPlansData.plans.map((item, i) => (
                                                    <>
                                                        {item.months === 12 &&
                                                            <td className="h-full py-8 px-6 align-top text-center text-xl font-bold text-gray-600">
                                                                {item.projects_limit}
                                                            </td>
                                                        }

                                                    </>
                                                ))}
                                            </tr>

                                            {appPlansData.plans_compare.map((item, i) => (
                                                <React.Fragment key={i}>
                                                    <tr>
                                                    <th className="bg-gray-50 py-3 pl-6 text-left text-sm font-medium text-gray-900" scope="colgroup">{item.title}</th>
                                                        <th className="bg-gray-50 py-3 px-3 text-center text-sm font-medium text-gray-900" scope="colgroup">Free</th>
                                                        <th className="bg-gray-50 py-3 px-3 text-center text-sm font-medium text-gray-900" scope="colgroup">Pro</th>
                                                        <th className="bg-gray-50 py-3 px-3 text-center text-sm font-medium text-gray-900" scope="colgroup">Guru</th>
                                                        <th className="bg-gray-50 py-3 px-3 text-center text-sm font-medium text-gray-900" scope="colgroup">Business</th>
                                                    </tr>

                                                    {item.features.map((features_item, ii) => (
                                                        <React.Fragment key={ii}>
                                                            <tr>
                                                                <th className="py-5 px-6 text-left text-sm font-normal text-gray-500" scope="row">{features_item.title}</th>
                                                                <td className="py-5 px-6">
                                                                    {features_item.plan_access.free === 1 ?
                                                                        <>
                                                                            <div className="text-center flex justify-center">
                                                                                <svg className="mx-auto h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd"/>
                                                                                </svg>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="text-center flex justify-center">
                                                                                <svg className="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
                                                                                </svg>
                                                                            </div>
                                                                        </>}

                                                                </td>
                                                                <td className="py-5 px-6">
                                                                    {features_item.plan_access.pro === 1 ?
                                                                        <>
                                                                            <div className="text-center flex justify-center">
                                                                                <svg className="mx-auto h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd"/>
                                                                                </svg>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="text-center flex justify-center">
                                                                                <svg className="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
                                                                                </svg>
                                                                            </div>
                                                                        </>}
                                                                </td>

                                                                <td className="py-5 px-6">
                                                                    {features_item.plan_access.guru === 1 ?
                                                                        <>
                                                                            <div className="text-center flex justify-center">
                                                                                <svg className="mx-auto h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd"/>
                                                                                </svg>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="text-center flex justify-center">
                                                                                <svg className="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
                                                                                </svg>
                                                                            </div>
                                                                        </>}

                                                                </td>
                                                                <td className="py-5 px-6">
                                                                    {features_item.plan_access.business === 1 ?
                                                                        <>
                                                                            <div className="text-center flex justify-center">
                                                                                <svg className="mx-auto h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd"/>
                                                                                </svg>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="text-center flex justify-center">
                                                                                <svg className="mx-auto h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
                                                                                </svg>
                                                                            </div>
                                                                        </>}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                                </React.Fragment>
                                            ))}

                                            </tbody>
                                            <tfoot>
                                            <tr className="border-t border-gray-200">
                                                <td className="px-6 pt-5">
                                                </td>
                                                <td className="px-6 pt-5">
                                                </td>
                                                <td className="px-6 pt-5">
                                                </td>
                                                <td className="px-6 pt-5">
                                                </td>
                                                <td className="px-6 pt-5">
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </table>

                                    </>
                                    :
                                    <>
                                        <div className="admiko-ak-loading">
                                            <i className="fa-solid fa-circle-notch"></i>
                                        </div>
                                    </>
                                }
                            </div>

                        </div>

                    </div>

                    <div className="max-w-6xl my-10">
                        <div className="rounded-md bg-white p-4 border border-rose-500 border-dashed mb-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <svg className="h-5 w-5 text-rose-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"/>
                                    </svg>
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-semibold text-rose-600">You can't use 2Checkout or PayPal?</h3>
                                    <div className="mt-2 text-sm">
                                        <p>Please <Link className="underline" to="/contact">contact us</Link> if you are having trouble using 2Checkout or Paypal.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flex gap-2 py-4 items-center">
                                <img alt="" src={`/images/SSL-Secure-Connection.png`} className="h-[60px]"/>
                                <img alt="" src={`/images/paypal.png`} className="h-[60px]"/>
                                <img alt="" src={`/images/2checkout-logo.svg`} className="h-[40px]"/>
                            </div>
                            <div>Your payment is securely processed by our partner, 2Checkout.</div>
                            <div className="pt-4 text-sm">
                                <b>7 Days Money-Back Guarantee</b><br/>
                                The time limit for our 7 DAYS MONEY BACK Guarantee starts when you place the order. If for any reason you decide Admiko isn't for you within 7 days of your purchase, just send us an email and we will refund you within 48 hours. No questions asked.
                                <br/>For more information please check our <a rel="noreferrer" href="https://admiko.com/terms_and_privacy" target="_blank"><u>Terms & Privacy</u></a>
                            </div>
                            <p className="pt-4 text-sm">
                                The charge on your bank statement will appear as 2CO.com*admiko.com.<br/>
                                Verifone Payments BV dba 2Checkout acts as an authorized payment service provider of online products and services.
                            </p>
                        </div>
                    </div>
                </>
            ) :
            <>
                <div className="admiko-ak-loading">
                    <i className="fa-solid fa-circle-notch"></i>
                </div>
            </>
        }
    </>
}
