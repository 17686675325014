import React from "react";
import {FormInfo, Required} from "./Partials/Partials";
import {EditBox} from "../FormContentStart";

export const Numbers = (props) => {
    /*set props*/
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const editElement = props.editElement;

    return (
        <>
            <div className={`ak-form-group ak-w-${ElementData.width} js-form-elements js-admiko-sort-me-container ${props.element_id === ElementData.idu ? "selected" : ""}`} data-id={ElementData.idu}>
                <div className={`ak-form-group-box`} onClick={() => editElement(ElementData.idu)}>
                    <div className="ak-form-label">
                        <label>{ElementData.title}<Required ElementData={ElementData}/></label>
                    </div>
                    <div className={`ak-form-input ak-form-number`}>
                        <div className={`${ElementData.prefix !== "" ? "ak-form-prefix" : ""} ${ElementData.suffix !== "" ? "ak-form-suffix" : ""}`}>
                            {ElementData.prefix !== "" && <span className="ak-prefix">{ElementData.prefix}</span>}
                            <input autoComplete="off" type="text" className="form-input" placeholder={ElementData.placeholder}/>
                            {ElementData.suffix !== "" && <span className="ak-suffix">{ElementData.suffix}</span>}
                        </div>
                        <FormInfo ElementData={ElementData}/>
                    </div>
                </div>
                <EditBox position={position} sortMe={sortMe} totalItems={totalItems} editElement={editElement} idu={ElementData.idu}/>
            </div>
        </>
    );

}