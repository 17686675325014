import React, {useEffect, useState} from "react";

import TabSortMenu from "../Sort/TabSortMenu";

export const TabSortContainer = (props) => {
    /*set props*/
    const toggleActionMenuTab = props.toggleActionMenuTab;
    const rootLeftMenuAll = props.rootLeftMenuAll;

    const [slideIn, setSlideIn] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setSlideIn(true);
        }, 10)
    }, [])

    const hideBack = () => {
        setSlideIn(false);
        setTimeout(() => {
            toggleActionMenuTab("main");
        }, 200)
    }
    return (
        <div className={`admiko-action admiko-action-menu ${slideIn ? "admiko-show" : ""}`}>
            <div className="admiko-action-header reverse mouse-pointer" onClick={hideBack}>
                <div className="info">
                    <h3><i className="fa-solid fa-list-ol"></i>Sidebar sorting</h3>
                    <p>Sort sidebar content.</p>
                </div>
                <div className="close">
                    <div className="admiko-toggle-tools">
                        <div className="admiko-toggle-box">
                            <div>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                    <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TabSortMenu rootLeftMenuAll={rootLeftMenuAll}/>

        </div>
    );
}
