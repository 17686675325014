import React from "react";
export const ElementInfo = (props) => {
    /*set props*/
    const header = props.header;
    const section = props.section;


    return (
        <>
            <div className="admiko-action-header admiko-action-header-info">
                <div className="info">
                    <h2><i className="fa-solid fa-circle-info"></i>{header}</h2>
                </div>
            </div>
            <div className="admiko-action-menu-body admiko-action-menu-info">
                <div className={`admiko-action-menu-body-action add-menu active`}>
                    {section.url !== "" && <>
                        <label>Url:</label>
                        <code className="infoCode" dangerouslySetInnerHTML={{__html: section.url}} />
                    </>}
                    {section.database !== "" &&
                        <><label>Database table:</label>
                            <code className="infoCode" dangerouslySetInnerHTML={{__html: section.database}} />
                        </>
                    }
                    {section.controller !== "" && <>
                        <label>Controller:</label>
                        <code className="infoCode" dangerouslySetInnerHTML={{__html: section.controller}} />
                    </>}
                    {section.extend_controller !== "" && <>
                        <code className="infoCode" dangerouslySetInnerHTML={{__html: section.extend_controller}} />
                    </>}
                    {section.model !== "" && <>
                        <label>Model:</label>
                        <code className="infoCode" dangerouslySetInnerHTML={{__html: section.model}} />
                    </>}
                    {section.extend_model !== "" && <>
                        <code className="infoCode" dangerouslySetInnerHTML={{__html: section.extend_model}} />
                    </>}
                    {section.request !== "" && <>
                        <label>Request:</label>
                        <code className="infoCode" dangerouslySetInnerHTML={{__html: section.request}} />
                    </>}
                    {section.extend_request !== "" && <>
                        <code className="infoCode" dangerouslySetInnerHTML={{__html: section.extend_request}} />
                    </>}
                    {(section.blade_index !== "" || section.blade_table !== "" || section.blade_form !== "") && <>
                        <label>View Files:</label>
                        {section.blade_index !== "" &&
                            <code className="infoCode" dangerouslySetInnerHTML={{__html: section.blade_index}} />
                        }
                        {section.blade_table !== "" &&
                            <code className="infoCode" dangerouslySetInnerHTML={{__html: section.blade_table}} />
                        }
                        {section.blade_form !== "" &&
                            <code className="infoCode" dangerouslySetInnerHTML={{__html: section.blade_form}} />
                        }
                    </>}
                </div>
            </div>
        </>
    );
}
