import React, {useEffect, useRef, useState} from "react";
import SortMe from "../../../../../Helper/SortMe";
import {useIsMountedRef} from "../../../../../Helper/isMounted";
import {rootSetError} from "../../../../../Helper/RootVar/Error/Error";
import {onChangeFormDataGlobal} from "../../../../../Helper/Functions";
import {apiVisibleTableLayoutElements} from "../../../../../Api/useTable";
import {FormSubmitButton} from "../../../../Helpers/FormSubmitButton";
import {LayoutTableElementSettings} from "./LayoutTableElementSettings";

export const LayoutTableElements = (props) => {
    /*set props*/
    const elements = props.elements;
    const form_idu = props.form_idu;
    const layout = props.layout;

    const isMountedRef = useIsMountedRef();

    /*LABEL*/
    const [labelGroup, setLabelGroup] = useState([])
    const [showSettings, setShowSettings] = useState(0)

    /*FORM LAYOUT*/
    const [submitButton, setSubmitButton] = useState("ready");
    //
    const formFields = {selected_elements: []};
    const formFieldsRules = {};
    const [formData, setFormData] = useState(formFields);
    //
    const onChangeFormData = (e) => {
        onChangeFormDataGlobal(e, formFieldsRules, setFormData, submitButton, setSubmitButton);
    };
    const sendFormData = async (e) => {
        e.preventDefault();
        setSubmitButton("progress");
        const response = await apiVisibleTableLayoutElements({...formData}, form_idu);
        if (!isMountedRef.current) {
            return false;
        }
        if (response.success === true) {
            setSubmitButton("saved");
        } else {
            setSubmitButton("ready");
            rootSetError(response);
        }
    };
    /*END FORM ELEMENTS*/

    useEffect(() => {
        let visibleElements = elements.filter(function (item) {
            return item.visible === 1;
        });
        generateLabelGroup(visibleElements);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let visibleElements = elements.filter(function (item) {
            return item.visible === 1;
        });
        generateLabelGroup(visibleElements);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements])


    /*label group*/
    /*label group*/
    /*label group*/


    useEffect(() => {
        saveDataToParent(labelGroup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [labelGroup])

    const saveDataToParent = (labelGroupData) => {
        let newLabelGroup = [];
        for (let i = 0; i < labelGroupData.length; i++) {
            newLabelGroup = [...newLabelGroup, {idu: labelGroupData[i].idu, settings: labelGroupData[i].settings}];
        }
        let elm = {target: {name: 'selected_elements', value: newLabelGroup}};
        onChangeFormData(elm);
    }

    const generateLabelGroup = (labelGroupData) => {

        let newLabelGroup = [];
        let newSourceLabels = [...elements];

        for (let i = 0; i < labelGroupData.length; i++) {
            let newLabelGroupIDs = new Set(labelGroupData.map(({idu}) => idu));
            let index = newLabelGroupIDs.has(labelGroupData[i].idu);
            if (index) {
                newLabelGroupIDs.delete(labelGroupData[i].idu)
            }
            let combined = [...newSourceLabels.filter(({idu}) => !newLabelGroupIDs.has(idu))];
            let indexKey = combined.findIndex(p => p.idu === labelGroupData[i].idu);
            let settings = combined[indexKey].settings;
            if (labelGroupData[i].hasOwnProperty("settings")) {
                settings = labelGroupData[i].settings;
            }
            newLabelGroup = [...newLabelGroup, {idu: combined[indexKey].idu, type: combined[indexKey].type, settings: settings, labelList: combined}];
        }
        setLabelGroup(newLabelGroup);
    }

    const addLabelGroup = () => {
        let newLabelGroup = [...labelGroup];
        let newSourceLabels = [...elements];
        const newLabelGroup2IDs = new Set(newLabelGroup.map(({idu}) => idu));
        const combined = [...newSourceLabels.filter(({idu}) => !newLabelGroup2IDs.has(idu))];
        newLabelGroup = [...newLabelGroup, {idu: combined[0].idu, type: combined[0].type, settings: combined[0].settings, labelList: []}];
        generateLabelGroup(newLabelGroup);
        setShowSettings(0);
    }

    const removeLabelGroup = (i) => {
        let newLabelGroup = [...labelGroup];
        newLabelGroup.splice(i, 1);
        setShowSettings(0);
        if (newLabelGroup.length < 0) {
            newLabelGroup = [{idu: elements[0].idu}];
        }
        generateLabelGroup(newLabelGroup);
    }
    const editLabelGroup = (idu) => {
        if (showSettings === idu) {
            setShowSettings(0);
        } else {
            setShowSettings(idu);
        }
    }

    const onChangeSourceLabel = (e, idu) => {
        let newFormValues = [...labelGroup];
        newFormValues.find((o, i) => {
            if (o.idu === idu) {
                newFormValues[i] = {idu: e.target.value};
                generateLabelGroup(newFormValues);
                return true;
            }
            return false;
        });
    };

    const onChangeSettings = (settings, idu) => {
        let newLabelGroup = [...labelGroup];
        let indexKey = newLabelGroup.findIndex(p => p.idu === idu);

        if (indexKey !== -1) {
            newLabelGroup[indexKey] = {idu: newLabelGroup[indexKey].idu, type: newLabelGroup[indexKey].type, settings: settings, labelList: []};
            generateLabelGroup(newLabelGroup);
        }
    }


    const saveLabelSortMe = (newList) => {
        let newLabelGroup = [];
        let newFormValues = [...labelGroup];
        let indexKey = 0;
        for (let i = 0; i < newList.sortList.length; i++) {
            indexKey = newFormValues.findIndex(p => p.idu === newList.sortList[i]);
            newLabelGroup = [...newLabelGroup, {idu: newFormValues[indexKey].idu, type: newFormValues[indexKey].type, settings: newFormValues[indexKey].settings, labelList: []}];
        }
        generateLabelGroup(newLabelGroup);
    };

    /*toggle settings box*/
    const settingsContainer = useRef();
    const toggleContainer = () => {
        let element = "";
        const myElements = settingsContainer.current.getElementsByClassName('ak-group-element-settings-edit');
        if (showSettings) {
            if (myElements.length > 0) {
                for (let i = 0; i < myElements.length; i++) {
                    element = myElements[i];
                    if (element.classList.contains('selected')) {
                        let totalHeight = element.querySelector('.ak-group-element-settings-edit-table-layout').scrollHeight;
                        element.style["height"] = totalHeight + 'px';
                    } else {
                        element.style["height"] = '0px';
                    }
                }
            }
        } else {
            for (let i = 0; i < myElements.length; i++) {
                element = myElements[i];
                element.style["height"] = '0px';
            }
        }
    }
    const updateToggleContainerHeight = () => {
        const myElements = settingsContainer.current.querySelector('.ak-group-element-settings-edit.selected');
        if(myElements){
            let totalHeight = myElements.querySelector('.ak-group-element-settings-edit-table-layout').scrollHeight;
            myElements.style["height"] = totalHeight + 'px';
        }
    }
    useEffect(() => {
        toggleContainer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSettings])
    return (
        <>
            <div ref={settingsContainer}>
                <div className="add-more-btn-box">
                    <div>
                        Show in table:
                    </div>
                    <div>
                        {labelGroup.length < elements.length &&
                            <div className="add-more-btn mouse-pointer" onClick={() => addLabelGroup()}><i className="fa-solid fa-plus"></i> Add</div>
                        }
                    </div>
                </div>
                <SortMe saveSortMe={saveLabelSortMe} parentContainer="js-ak-source-table-visible">
                    {labelGroup.map((element, index) => (
                        <LabelElements key={element.idu} editLabelGroup={editLabelGroup} removeLabelGroup={removeLabelGroup}
                                       onChangeSourceLabel={onChangeSourceLabel}
                                       ElementData={element} position={index} totalItems={labelGroup.length}
                                       idu={element.idu}
                                       onChangeSettings={onChangeSettings}
                                       showSettings={showSettings}
                                       form_idu={form_idu} layout={layout}
                                       updateToggleContainerHeight={updateToggleContainerHeight}
                        />
                    ))}
                </SortMe>
                <FormSubmitButton submitButton={submitButton} sendFormData={(e) => sendFormData(e)}/>
            </div>
        </>
    );

}

export const LabelElements = (props) => {
    /*set props*/
    const idu = props.idu;
    const ElementData = props.ElementData;
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;
    const onChangeSourceLabel = props.onChangeSourceLabel;
    const editLabelGroup = props.editLabelGroup;
    const removeLabelGroup = props.removeLabelGroup;
    const onChangeSettings = props.onChangeSettings;
    const showSettings = props.showSettings;
    const form_idu = props.form_idu;
    const layout = props.layout;
    const updateToggleContainerHeight = props.updateToggleContainerHeight;

    useEffect(() => {
        updateToggleContainerHeight()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ElementData.settings])

    return (
        <>
            <div className="form-group ak-source-label-group ak-layout-group js-ak-source-table-visible js-admiko-sort-me-container" data-id={ElementData.idu}>
                <div className="form-group-input">
                    {(position !== 0 && ElementData.settings.merge !== "none") &&
                        <>
                            <div className="merged-icon"><i className="fa-solid fa-arrow-turn-up"></i></div>
                        </>
                    }
                    <select className="form-select" value={ElementData.idu} disabled={ElementData.type === "parent_child"} name="source-label" id={`source-label-${idu}`} onChange={(e) => onChangeSourceLabel(e, idu)}>
                        {ElementData.labelList.map((e, key) => {
                            return <option key={e.idu} value={e.idu}>{e.title}</option>;
                        })}
                    </select>
                    <AdmikoToolboxEditBox position={position} sortMe={sortMe} totalItems={totalItems} editLabelGroup={editLabelGroup} idu={ElementData.idu}/>
                </div>
                {/* show-layout-${Object.keys(ElementData.settings).length}*/}
                {/*${showSettings === idu && "show-layout"}*/}
                <div className={`ak-group-element-settings-edit ${showSettings === idu ? "selected" : ""}`}>
                    <div className={`ak-group-element-settings-edit-table-layout`}>
                        <div className={`ak-group-element-settings-edit-table-layout-form`}>
                            {/*{showSettings === idu &&*/}
                            <LayoutTableElementSettings onChangeSettings={onChangeSettings} ElementData={ElementData} position={position} form_idu={form_idu} layout={layout}/>
                            {/*}*/}
                        </div>
                        <div className="admiko-form-settings">
                            {(totalItems > 0 && ElementData.type !== "parent_child") &&
                                <div className="mouse-pointer delete-label-group" onClick={() => removeLabelGroup(position)}><i className="fa-solid fa-trash"></i></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export const AdmikoToolboxEditBox = (props) => {
    /*set props*/
    const totalItems = props.totalItems;
    const sortMe = props.sortMe;
    const position = props.position;

    const editLabelGroup = props.editLabelGroup;
    const idu = props.idu;


    return (
        <>
            <div className="admiko-form-settings">
                {totalItems > 1 &&
                    <div className="move-section tft-move js-admiko-sort-me">
                        {position > 0 &&
                            <div className="move-up mouse-pointer js-admiko-move-me-up" onClick={(e) => sortMe.SortMoveUp(e)}><i className="fa-solid fa-chevron-up"></i></div>
                        }
                        <div className="mouse-move js-admiko-move-me"><i className="fa-solid fa-up-down-left-right"></i></div>
                        {position + 1 < totalItems &&
                            <div className="move-down mouse-pointer js-admiko-move-me-down" onClick={(e) => sortMe.SortMoveDown(e)}><i className="fa-solid fa-chevron-down"></i></div>
                        }
                    </div>
                }
                <div className="mouse-pointer element-edit" onClick={(e) => editLabelGroup(idu)}><i className="fa-solid fa-gear"></i></div>
            </div>
        </>
    );
}
