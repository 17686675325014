import { useState } from 'react';
export let appRootPageTitle = [];
export let setAppRootPageTitle;

export let appRootPageSubtitle = [];
export let setAppRootPageSubtitle;

export let appRootPageBreadcrumbs = [];
export let setAppRootPageBreadcrumbs;

export let appRootActiveMenu = [];
export let setAppRootActiveMenu;

export const useAppRootPageInfo = () => {
    [appRootPageTitle, setAppRootPageTitle] = useState("");
    [appRootPageSubtitle, setAppRootPageSubtitle] = useState("");
    [appRootPageBreadcrumbs, setAppRootPageBreadcrumbs] = useState([]);
    [appRootActiveMenu, setAppRootActiveMenu] = useState([]);
}
export const setRootPageInfoDirect = (props) => {
    setAppRootPageTitle(props.title);
    let title = "";
    if(props.hasOwnProperty('title')){
        title = 'Admiko - ' + props.title;
    } else {
        title = 'Admiko';
    }
    document.title = title;

    /*goggle analytics tracking*/
    window.dataLayer.push({
        event: 'pageview',
        page: {
            title: title,
            url: window.location
        }
    });

    setAppRootPageSubtitle(props.subtitle);
    setAppRootPageBreadcrumbs(props.breadcrumbs);
    setAppRootActiveMenu(props.activeMenu);
};